import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Grundviden/DigitaleServoerOgBECStroemforsyninger/fig1.jpg";
import fig2 from "../../../images/Grundviden/DigitaleServoerOgBECStroemforsyninger/fig2.jpg";
import fig3 from "../../../images/Grundviden/DigitaleServoerOgBECStroemforsyninger/fig3.jpg";
import fig4 from "../../../images/Grundviden/DigitaleServoerOgBECStroemforsyninger/fig4.jpg";
import fig5 from "../../../images/Grundviden/DigitaleServoerOgBECStroemforsyninger/fig5.jpg";
import fig6 from "../../../images/Grundviden/DigitaleServoerOgBECStroemforsyninger/fig6.jpg";
import fig7 from "../../../images/Grundviden/DigitaleServoerOgBECStroemforsyninger/fig7.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Digitale servoer og BEC strømforsyninger</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Digitale servoer og BEC str&oslash;mforsyninger</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>
              Publiceret april 2007.
              <br />
            </p>
            <h2>Digitale servoer bliver mindre og billigere</h2>
            <p>
              I forbindelse med mit seminar ved Comets for&aring;rstr&aelig;f
              blev jeg opm&aelig;rksom p&aring; at digitale servoer var blevet
              b&aring;de mindre og billigere. Se billede 1.
            </p>
            <p>
              <img src={fig1} />
              <br />
              Billede 1
            </p>
            <p>
              Her vises et eksempel p&aring; 2 sm&aring; servoer. Den venstre
              analog p&aring; 7 gram og den h&oslash;jre digital p&aring; 12
              gram
              <br />
              med n&aelig;sten samme m&aring;l. Den digitale koster det dobbelte
              af den analoge, s&aring; det kunne v&aelig;re relevant at
              unders&oslash;ge fordele og ulemper.&nbsp;
            </p>
            <h2>Princippet i den digitale servo</h2>
            <p>
              Jeg vil f&oslash;rst fastsl&aring; at der ikke er nogle
              kompatibilitets problemer, alts&aring; at man uden videre kan
              tilkoble en digitale servo til sin modtager, som enhver anden
              servo og forvente at den virker. For bedre at forst&aring; hvad
              det er der sker, vil jeg pr&oslash;ve at forklare princippet i den
              digitale servo,&nbsp;
              <br />
              Den digital servo indeholder de samme komponenter som den analoge,
              alts&aring; en j&aelig;vnstr&oslash;msmotor, en gearkasse, et
              potentiometer, en servo arm og noget elektronik p&aring; en chip.
              Det er kun chippen som er forskellen. Den er udviklet af
              Mitsubishi og er interessant til andre form&aring;l end lige
              modelflyvning for eksempel robotteknologi.
              <br />
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Se princip diagrammet fig. 1 Vi starter i nederste venstre
              hj&oslash;rne med at se p&aring; den impuls, som kommer ind fra
              senderen via modtageren. Den er typisk 1,5millisekundt bred og
              gentages for hver 20 millisekunder. Hvis du betjener din kontrol
              stick til den ene side bliver den l&aelig;ngere (ca.
              2millisekunder) Til den anden side bliver den kortere
              (ca.1millisekund) Nu finder du kassen hvor der st&aring;r
              X-taloscillator. En s&aring;dan krystal oscillator kender du
              m&aring;ske i forvejen fra din sender. Det er et kredsl&oslash;b
              som kan frembringe svingninger med en meget n&oslash;jagtig
              frekvens, alts&aring; antal svingninger pr. sekund. Disse
              svingninger f&oslash;res nu igennem en port som kun er &aring;ben
              s&aring;l&aelig;nge der er en impuls. Frekvensen bliver nu talt op
              af en t&aelig;ller og du kan se at jo l&aelig;ngere pulsen varer,
              jo flere svingninger kommer der igennem. N&aring;r pulsen er
              f&aelig;rdig (bagkanten) stopper opt&aelig;llingen og resultatet
              overf&oslash;res til et data-register A p&aring; 12 bit, hvis
              indhold s&aring; kunne v&aelig;re 100110101110 (bin&aelig;rt
              talsystem).&nbsp;
              <br />
              Hvis vi t&aelig;nker at indgangsimpulsen bliver bredere vil
              motoren k&oslash;re, motoren tr&aelig;kker gennem gearkassen
              potentiometeret, som leverer en stigende sp&aelig;nding til den
              sp&aelig;ndingsstyrede oscillator (VCO) Denne &oslash;ger
              frekvensen, som over et pr&aelig;cist tidsforl&oslash;b
              opt&aelig;ller antallet af svingninger i t&aelig;ller B.
              Resultatet overf&oslash;res til data-register B. De to registre
              sammenlignes. S&aring; l&aelig;nge A er st&oslash;rre end B vil
              motoren k&oslash;re. Styringen af motoren finder sted med en
              frekvens p&aring; 300Hz, dette er 6 gange h&oslash;jere end
              indgangspulsen. Styringen foretages af et computerprogram, som
              n&oslash;je varetager motorens behov. N&aring;r register B
              indholdet, bit for bit, er lig register A indholdet, alts&aring;
              100110101110 stopper motoren. 12 bit medf&oslash;rer at servoen
              har en opl&oslash;sning p&aring; 4096 trin over ca. 120
              grader.&nbsp;
            </p>
            <h2>Fordele og ulemper</h2>
            <p>
              Ud fra funktionsbeskrivelsen kan nu udledes f&oslash;lgende. Vi
              har dels f&aring;et et meget pr&aelig;cist og f&oslash;lsomt
              system, dels bliver motoren programmeret 6 gange hurtigere og med
              en mere effektiv styring. Dette betyder hurtigere acceleration og
              mere konstant drejmoment. Se fig. 2 Her sammenlignes 2 Futaba
              servoer af samme st&oslash;rrelse. Den digitale S9450 opn&aring;r
              maximalt drejmoment efter ca. 40 micro sekunder, mens den analoge
              S9402 er ca. 3 gange s&aring; l&aelig;nge om det.
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig. 2
            </p>
            <p>
              Hvis blot det mindst betydende bit &aelig;ndre sig, alts&aring;
              100110101111 g&aring;r der str&oslash;m i motoren. Dette
              medf&oslash;rer at servoen har stor holdekraft, hvis den bliver
              belastet af en rorflade. I denne situation tr&aelig;kker servoen
              kraftig str&oslash;m, intet er gratis. Den digitale arbejder
              ogs&aring; mere for at opn&aring; den n&oslash;jagtige position.
              Generelt bruger digitale servoer 10 til 30&nbsp;% mere
              str&oslash;m end tilsvarende analoge. Dette sammen med en
              h&oslash;jere pris udg&oslash;r ulemperne ved den digitale servo.
            </p>
            <h2>Tekniske bem&aelig;rkninger</h2>
            <p>
              Det at der stadig anvendes et potentiometer er ikke s&aring;
              tiltalende ud fra slidtage og linearitets betragtninger. Man ville
              kunne frembringe impulser, alts&aring; en frekvens med en
              tachoskive, som man kunne t&aelig;lle direkte p&aring;.
              Tachoskiven er en skive med sorte og hvide felter som belyses og
              aftastes af en foto diode. Det ville v&aelig;re en forbedring, men
              den er sandsynligvis vanskelig at lave s&aring; lille.
              <br />
              En anden ting er, at hvis man anvender en PCM modtager, sidder der
              i udgangen af denne en digital til analog puls oms&aelig;tter.
              Denne ville kunne undv&aelig;res, hvis man direkte f&oslash;rte
              det digitale signal ind i den digitale servo. N&aring;r man ikke
              g&oslash;r dette, er det fordi man derved mister kompatibiliteten
              til det analoge system. N&oslash;jagtig samme problem kendes ved
              fladsk&aelig;rme til computere. Her omdanner man computerens
              digitale signal til et analogt signal (VGA) for derefter at
              g&aring; den modsatte vej i sk&aelig;rmen.&nbsp;
              <br />
              Nogle grafikkort og sk&aelig;rme undg&aring;r dog dette, hvis
              grafikkortet har DVI udgang og sk&aelig;rmen har DVI indgang.
            </p>
            <h2>Normal BEC str&oslash;mforsyning</h2>
            <p>
              Som jeg var inde p&aring; under ulemperne brugte de digitale
              servoer mere str&oslash;m. Dette f&aring;r betydning for BEC
              kredsl&oslash;bet. BEC er en forkortelse for Batteri Eliminating
              Circuits, alts&aring; at du kan str&oslash;mforsyne modtager og
              servoer fra motorbatteriet via motor regulatoren p&aring; et
              elfly. Fig. 3 viser princippet.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Hvis man ville lave BEC til en regulator, som skulle anvende 5
              serie forbundne Li-Po celler ville den maximale indgangs
              sp&aelig;nding v&aelig;re 21&nbsp;V. Da modtager og servoer kun
              anvender 5&nbsp;V, er det n&oslash;dvendigt at skaffe sig af med
              16&nbsp;V. Hvis man skal bestemme nogle effekter er det
              n&oslash;dvendigt at vide hvor meget str&oslash;m servoerne
              tr&aelig;kker. Det er ikke helt nemt idet det afh&aelig;nger
              af:&nbsp;
              <br />
            </p>
            <ol>
              <li>Antallet af servoer.</li>
              <li>Typen &ndash; Fabrikat &ndash; Analog &ndash; Digital.</li>
              <li>Belastningen fra rorfladen under flyvningen.</li>
              <li>Hvor mange der arbejder p&aring; en gang.</li>
              <li>Hvor tit de starter. (Flyve stil)</li>
              <li>Hvor let tr&aelig;kket g&aring;r til rorflader.</li>
            </ol>
            <p>
              Ofte kan man finde stall str&oslash;mmen, det vil sige den
              str&oslash;m som servoen bruger, hvis man tvangs standser den. F.
              eks bruger den tidligere omtalte BMS 375 DMG digital servo 480 mA.
              Desv&aelig;rre m&aring; man v&aelig;re meget pessimistisk og
              g&aring; ud fra at samtlige servoer er stallede, idet den stallede
              str&oslash;m i servoen svarer til start str&oslash;mmen. Dette vil
              forekomme, hvis samtlige servoer starter p&aring; n&oslash;jagtig
              samme tidspunkt. Problemet er at modtagerens
              str&oslash;mforsyning, ikke under nogen omst&aelig;ndigheder
              m&aring; g&aring; ned. For at v&aelig;re sikker, er der s&aring;
              valgt en spidsstr&oslash;m p&aring; 3&nbsp;A. Modtageren med
              servoer optager s&aring; en spidseffekt p&aring;
              5&nbsp;Vx3&nbsp;Amp = 15&nbsp;W. Regulatoren afs&aelig;tter i
              varme 16&nbsp;Vx3&nbsp;Amp = 48&nbsp;W som spidseffekt til ingen
              nytte. Dette er bestemt ikke hensigtsm&aelig;ssigt og det er da
              ogs&aring; grunden til at fartregulatorer beregnet til over 4
              Li-Po celler normalt ikke anvender BEC.
            </p>
            <h2>Switch mode BEC</h2>
            <p>
              En bedre l&oslash;sning vil v&aelig;re at anvende en switch mode
              BEC. Princippet er her at man ituhakker j&aelig;vnsp&aelig;ndingen
              og foretager en nedtransformering i en s&aring;kaldt step-down
              converter. Dette kan g&oslash;res med en h&oslash;j virkningsgrad,
              alts&aring; n&aelig;sten uden varmetab se fig4. Bem&aelig;rk at
              selvom der stadig tr&aelig;kkes 3&nbsp;Amp p&aring; 5&nbsp;V siden
              til modtager og servoer bruges der kun 0,7&nbsp;Amp ved 21&nbsp;V
              = 15&nbsp;W. Effekten p&aring; indgangssiden og udgangssiden er
              n&aelig;sten lig hinanden p&aring; grund af den h&oslash;je
              virkningsgrad.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <p>
              En s&aring;dan switch mode BEC kan k&oslash;bes separat, den vejer
              ca10g. Se billede 2. Den har den ekstra facilitet, at man ved
              hj&aelig;lp af en jumper kan omstille den til 6&nbsp;V. Der er dog
              et problem man b&oslash;r v&aelig;re opm&aelig;rksom p&aring; og
              det er at den kan udsende radiost&oslash;j. Man b&oslash;r derfor
              v&aelig;re opm&aelig;rksom p&aring; om man f&aring;r en reduceret
              r&aelig;kkevidde p&aring; modtageren.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Billede 2
            </p>
            <p>
              N&aelig;rliggende ville det v&aelig;re at indbygge switch mode
              BECen i motorregulatoren. Disse regulatorer er da ogs&aring;
              begyndt at dukke op. Eksempelvis kan n&aelig;vnes Jeti spin 44,
              som er beregnet til max 6Li-Po celler og som kan levere en BEC
              str&oslash;m p&aring; max 5&nbsp;Amp. Man kan ogs&aring;
              m&oslash;de betegnelsen Cool-BEC fordi der ikke afs&aelig;ttes
              varme.&nbsp;
            </p>
            <h2>Sp&aelig;ndings kontrol</h2>
            <p>
              Hvordan kan forsyningssp&aelig;ndingens stabilitet m&aring;les?
              Dette er ikke s&aring; let. Som det tidligere er omtalt
              frembringer servoerne store kortvarige str&oslash;mst&oslash;d.
              Det meget kortvarige fald i forsyningssp&aelig;ndingen kan ikke
              registreres hverken af et digitalt voltmeter eller et voltmeter
              med viser, fordi de er for langsomme. Billigst og nemmest er det
              at anvende et diodevoltmeter Se billede 3. Hvis sp&aelig;ndingen
              kommer under 4,7&nbsp;V vil den r&oslash;de lysdiode l&aelig;ngst
              til venstre v&aelig;re t&aelig;ndt. Med ca. 0,1&nbsp;V forskel
              t&aelig;nder dioderne op til dioden l&aelig;ngst til h&oslash;jre,
              som er t&aelig;ndt ved 5,35&nbsp;V.(G&aelig;lder i 4,8&nbsp;V
              stillingen) Fordelen ved dette lille m&aring;leapparat er at det
              er lyn hurtigt. Det kan naturligvis ogs&aring; m&aring;le
              sp&aelig;ndingen hvis du anvender et almindeligt modtagerbatteri.
            </p>
            <p>
              <img src={fig7} />
              <br />
              Billede 3
            </p>
            <p>
              Jeg h&aring;ber hermed at have belyst nogle principper for nogle
              af de nyere produkter som anvendes til vores modelfly. Produkterne
              forhandles af RC-netbutik.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
