import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles, Link } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/JetiBoxProfiExDel2/fig1.jpg";
import fig2 from "../../../images/Jeti/JetiBoxProfiExDel2/fig2.jpg";
import fig3 from "../../../images/Jeti/JetiBoxProfiExDel2/fig3.jpg";
import fig4 from "../../../images/Jeti/JetiBoxProfiExDel2/fig4.jpg";
import fig5 from "../../../images/Jeti/JetiBoxProfiExDel2/fig5.jpg";
import fig6 from "../../../images/Jeti/JetiBoxProfiExDel2/fig6.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>
            Jeti Box Profi EX. Del 2. - en trådløs terminal med data recorder og
            2,4GHz sender modul
          </title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>
              Jeti Box Profi EX. Del 2. &ndash; en tr&aring;dl&oslash;s terminal
              med data recorder og 2,4GHz sender modul
            </h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret April 2012</p>
            <p>
              Denne artikel er en forts&aelig;ttelse af emnet fra forrige nummer
              af Modelflyvenyt. Det er nok klogt, at l&aelig;se den
              f&oslash;rst, hvis du ikke har gjort det. Artiklen kan ogs&aring;
              l&aelig;ses her:{" "}
              <Link href="/Jeti/Jeti-Box-Profi-EX-Del-1" target="_blank">
                Jeti Box Profi EX Del 1
              </Link>
              .
            </p>
            <h2>Data Flight Recorder</h2>
            <p>
              Data Flight Recorderen er en naturlig udvikling af den langsigtede
              udviklingsstrategi Jeti har haft. N&aring;r man nu har haft
              m&aring;lemodulerne og den tilh&oslash;rende telemetri, vil det
              v&aelig;re indlysende at kunne gemme den datastr&oslash;m med
              v&aelig;rdifulde oplysninger, som downstreames oppe fra flyveren
              ned til senderen p&aring; jorden. Det er samme ide Airbus arbejder
              med, idet det kan v&aelig;re uhyre besv&aelig;rligt og kostbart,
              at finde den sorte box (som er orange), n&aring;r der sker
              ulykker, specielt p&aring; dybt vand.
            </p>
            <p>
              Profi Boxen kan alts&aring; opsamle og gemme str&oslash;mmen af
              telemetri data under flyvningen. Disse data omfatter oplysninger
              fra: m&aring;lemoduler, modtagerens forsyningssp&aelig;nding, samt
              signalstyrken p&aring; modtagerens antenner. Hukommelsen er
              p&aring; 2GB svarende til mellem 263 og 439 timers flyvning
              afh&aelig;ngig af datam&aelig;ngden.
            </p>
            <p>
              Data Flight Recorderen har tre modes som kan anvendes p&aring;
              f&oslash;lgende m&aring;de:
            </p>
            <p>
              <img src={fig1} />
            </p>
            <p>
              For at kunne analysere flight data har Jeti udviklet et Flight
              Monitor program, som frit kan downloades til din Windows PC.
              (Windows XP og h&oslash;jere). Som det f&oslash;rste billede
              viser, best&aring;r brugerfladen af vinduer, som man selv kan
              flytte, lukke, &aelig;ndre st&oslash;rrelser o.s.v.. Dette er
              velkendt i et Windows milj&oslash;. Programmet er i version 0,7 og
              forventes at blive udviklet med flere funktioner
              efterh&aring;nden.{" "}
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Optagelsen stammer fra en flyvning, hvor jeg har brugt den gamle
              Futaba guldsender med Profi Boxen som 2,4GHz sendermodul. Denne
              ops&aelig;tning blev beskrevet som mode 4 i den forrige artikel.
              M&aring;lemodulet er en MUI75, som i det &oslash;verste vindue
              viser flyets str&oslash;mforbrug og batteri sp&aelig;ndingen
              p&aring; en 3S Li-Po pakke. Bem&aelig;rk sp&aelig;ndingen dykker
              lidt, hver gang du &oslash;ger str&oslash;mmen. Den mellemste
              kurve er altid tilg&aelig;ngelig p&aring; alle Jeti modtagere uden
              m&aring;lemoduler tilkoblet. Den viser antenne sp&aelig;ndingerne
              under flyvningen p&aring; de to modtager antenner i en skala
              p&aring; 0 til 9. Modtageren anvender hele tiden det bedste signal
              (diversity), desuden monitoreres modtagerens
              forsyningssp&aelig;nding, se den gr&aring; stabile kurve. Den
              nederste kurve viser forbruget i mA/h p&aring; pakken. Man skal
              v&aelig;re opm&aelig;rksom p&aring; at programmet ogs&aring; er en
              &rdquo;b&aring;ndafspiller&rdquo; med en cursorlinie, som
              l&oslash;bende viser v&aelig;rdierne &oslash;verst i h&oslash;jre
              vindue. Man kan alts&aring; afspille hele sin flyvning og ganske
              n&oslash;jagtig finde &oslash;nskede h&aelig;ndelser.
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              N&aelig;ste billede viser forsyningssp&aelig;ndingens variationer
              p&aring; et anl&aelig;g med et 3 &aring;r gammelt 4 cellet Ni-Mh
              modtager batteri. Her er sp&aelig;ndingsdykket fra 5,4&nbsp;V ned
              til 4,9&nbsp;V, ved den r&oslash;de pil, for&aring;rsaget af at
              blot 2 servoer starter samtidigt. Hvis modtagerens
              forsyningssp&aelig;nding kommer under 3,2&nbsp;Volt g&aring;r det
              galt, fordi modtageren stopper. Dette
              m&aring;lev&aelig;rkt&oslash;j er simpelthen genialt og giver
              brugeren stor sikkerhed, du kan sekund for sekund se hvad der
              sker. Her er noget du b&oslash;r t&aelig;nke over, is&aelig;r hvis
              du anvender digitale servoer, som er s&aelig;rlig
              str&oslash;mkr&aelig;vende.
            </p>
            <p>
              Hvis du anvender Profi Boxen i mode 1, alts&aring; der hvor du
              tilslutter m&aring;lemodulerne direkte til UNI-stikket, vil du
              kunne foretage logning af alle mulige m&aring;linger p&aring; dit
              v&aelig;rksted. Du kan m&aring;le og logge omdrejninger,
              virkningsgrader, str&oslash;m, sp&aelig;nding, batterikapacitet,
              temperatur, lufthastighed m.m. Et fremragende
              m&aring;lev&aelig;rkt&oslash;j for modelflyveren. Alle el-piloter
              burde m&aring;le den faktiske ydeevne/kapacitet p&aring; deres
              Li-Po pakker under kontrollerede forhold. Ved efterf&oslash;lgende
              brug af MUI-modulet i flyet, vil du hurtigt kunne spare en del
              &oslash;delagte Li-Po`er og flyvere, ved kun at anvende 70&nbsp;%
              af den faktiske kapacitet.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Optagelsen af det sidste billede knytter sig til en flyvning i
              Soderup, hvor der flyves med et stort sv&aelig;veplan og hvor
              Profi Boxen nu er koblet op som &rdquo;lytter&rdquo;. (Se
              f&oslash;rste artikel, Mode 3.) M&aring;lemodulet er Jetis GPS
              modtager.
            </p>
            <p>
              &Oslash;verst haves atter antenne sp&aelig;ndinger til analyse af
              modtagningens kvalitet og modtager antennernes placering. P&aring;
              det viste tidspunkt af flyvningen i en afstand af 198m, holdt jeg
              med h&aring;nden p&aring; antennen. Dette for&aring;rsagede en
              &rdquo;Weak Signal Alarm&rdquo;, fordi jeg havde indstillet alarm
              niveauet til 2. Som du kan se &oslash;verst til h&oslash;jre er
              der 0 p&aring; antenne 1 og 1 p&aring; antenne 2, d.v.s. at begge
              antenner er under 2. Der er stadig meget stor reserve i
              r&aelig;kkevidden, fors&oslash;get viser blot, at systemet er i
              stand til at overv&aring;ge kvaliteten af radiotransmissionen.
              Hvis du s&aelig;tter alarmv&aelig;rdien til 0, vil du kunne
              forvente alarm ved en afstand i luften p&aring; mellem 800 og
              1600m med korrekt monterede modtager antenner. Jeg har nu valgt at
              s&aelig;tte &rdquo;Weak Signal Alarmen&rdquo; til 1.
            </p>
            <p>
              Det mellemste vindue viser flyveh&oslash;jde og flyvefart over
              jorden. Der blev udl&oslash;st en lyd h&oslash;jde alarm ved 90
              meter. Lyd alarmerne vises ikke i logningerne, hvilket ogs&aring;
              er un&oslash;dvendigt. Nederste vindue viser afstanden i luftlinje
              fra startstedet. Det skal bem&aelig;rkes at mit GPS startsted ikke
              er der, hvor denne flyvning begynder, men er logget ind v. h. af
              12 satellitter til at v&aelig;re starten af bane 27 i Soderup.
              &Oslash;verste h&oslash;jre billede kalder Flight Monitor
              programmet automatisk ind fra Google Earth, under
              foruds&aelig;tning af at din computer er p&aring; internettet,
              ganske nemt og smart.
            </p>
            <h3>Binding, programmering og profiler</h3>
            <p>
              For bedre at forst&aring; hvad det er der sker gennemg&aring;s
              principperne for binding, programmering og oprettelse af profiler
              i Jeti EX systemet. Det skal f&oslash;rst pointeres, at alle
              2,4GHz sendere har deres egne unikke koder skabt p&aring;
              fabrikken. Dette g&aelig;lder ogs&aring; Profi Boksens sender og
              Jeti modtageres telemetri sender. Disse koder er 8 cifrede hvilket
              betyder at der 100 millioner kombinationer. Idet et system til et
              fly kr&aelig;ver to kombinationer, vil der v&aelig;re 50 millioner
              unikke koder til r&aring;dighed.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <p>
              Af p&aelig;dagogiske grunde anvender jeg i denne forklaring kun to
              cifre til koden. I &oslash;verste tegning er Profi boxen
              f&oslash;dt med kode 12. Den telemetri sender som sidder i flyet
              har kode 14. Hvis du t&aelig;nder for systemet vil det ikke virke
              f&oslash;r du har l&aelig;rt de to sendere (Ti) og modtagere (Rex)
              at kende hinanden. Dette g&oslash;res en gang for alle ved
              bindingen. Du kan f&aring; mere teoretisk viden om bindingen ved
              at l&aelig;se artiklen: &rdquo;
              <Link
                href="/Grundviden/Grundlaeggende-2.4-GHz-teknik"
                target="_blank"
              >
                Grundl&aelig;ggende 2,4GHz teknik
              </Link>
              &rdquo;.
            </p>
            <p>
              &Oslash;verste tegning viser et jomfrueligt system, lige pakket
              ud. Bindingen foretages i praksis ved at du:
            </p>
            <ol>
              <li>
                Is&aelig;tter en binding plug i modtagerens data connector.
              </li>
              <li>T&aelig;nder modtageren.</li>
              <li>T&aelig;nder senderen</li>
              <li>Fjerner binding pluggen.</li>
            </ol>
            <p>
              P&aring; mellemste tegning er flyver A bindet. Nu kan man
              programmere s&aring;vel modtageren, som m&aring;lemoduler med alle
              &oslash;nskede indstillinger, herunder alarmv&aelig;rdier. Du skal
              v&aelig;re opm&aelig;rksom p&aring;, at alle Jeti modtagere kan
              programmeres til alle de funktioner, som du normalt s&aelig;tter
              op i en moderne sender, s&aring; som MIX, EXPO, ATV, Fail Safe,
              Servoforsinkelse m.m. Disse ting huskes i modtageren.
            </p>
            <p>
              Selve programmeringen er meget overskuelig, idet Jeti altid
              anvender det princip, at man altid kun kan g&aring; op, ned, til
              h&oslash;jre eller venstre p&aring; de fire taster som findes
              p&aring; alle 3 Jeti Boxe. Se et udsnit af et programmerings kort.
            </p>
            <p>
              <img src={fig6} />
            </p>
            <p>
              En tilsvarende programmering foretages af m&aring;lemodulet ved
              hj&aelig;lp af manualen. N&aring;r dette er foretaget kan du via
              telemetri senderen overf&oslash;re m&aring;lev&aelig;rdier og
              alarmer til Profi Boxen, hvor 4 sk&aelig;rmsider s&aring; kan
              programmeres til flyver A.
            </p>
            <p>
              Hvad sker der s&aring; hvis du skifter til en ny flyver, som i
              forvejen er bindet og programmeret? Se nederste tegning. N&aring;r
              du t&aelig;nder for flyver B, vil telemetri senderen i flyveren
              udskifte kode 14 med kode 16 i Jeti boxen, som
              efterf&oslash;lgende henter de til kode 16 4 nye tilh&oslash;rende
              sk&aelig;rmsider, lydalarmer, modtager ops&aelig;tninger m.m. som
              h&oslash;rer til flyver B. P&aring; denne m&aring;de kan du
              oprette lige s&aring; mange flyvere, som du har brug for uden at
              foretage dig andet end at t&aelig;nde for den &oslash;nskede
              flyver. Du skriver flyvernes navne ind i profilerne. Systemet
              gemmer altid profilen p&aring; den sidste flyver. Flyverens navn
              vil blive oplyst, n&aring;r du t&aelig;nder for flyveren.
              Profilnavnet f&oslash;lger ogs&aring; automatisk over i Flight
              Monitor programmet. S&aring; kan det vel ikke blive nemmere og
              mere sikkert.
            </p>
            <h3>Kompatibilitet</h3>
            <p>Forskellige forhold skal fastsl&aring;s:</p>
            <ul>
              <li>
                EX systemet er gensidigt kompatibelt med 1. generation af Jeti
                2,4GHz Duplex produkter, men med begr&aelig;nsede funktioner.
              </li>
              <li>
                Alt skal v&aelig;re EX system for at f&aring; fuldt udbytte af
                Profi boxen. Dette g&aelig;lder ogs&aring; sender moduler.
              </li>
              <li>Noget udstyr kan opdateres via en USB adapter fra Jeti.</li>
              <li>
                Man m&aring; ikke forvente at kunne opdatere udstyr der er mere
                end 2 &aring;r gammelt.
              </li>
            </ul>
            <p>
              Dette er en pris for udviklingen, som ogs&aring; kendes fra mange
              andre produkter, s&aring; som for&aelig;ldede computere og TV
              systemer.
            </p>
            <h3>Hvorfor v&aelig;lge Jeti EX systemet?</h3>
            <p>
              Brugere af 2,4 GHz RC-anl&aelig;g st&aring;r over komplicerede
              beslutninger, n&aring;r de skal k&oslash;ber ind i et marked, hvor
              der ikke er kompatibilitet mellem de enkelte fabrikater. Samtidig
              finder den teknologiske udvikling sted med st&aelig;rkt stigende
              hast. Udviklingsarbejdet finder nu sted, sidel&oslash;bende med at
              produkterne frig&oslash;res, desuden vil et produkt med fejl
              lynhurtigt f&aring; problemer p&aring; grund af
              informationsspredningen p&aring; internettet. Det er den
              virkelighed producenterne og k&oslash;berne befinder sig i. Disse
              forhold g&oslash;r at produkter bliver forsinkede, men da vi har
              med flyvemaskiner at g&oslash;re, vil vi blive rigtig glade hvis
              alt virker, som det skal, det g&oslash;r Jeti. Det er derfor
              vigtigt at have tillid til leverand&oslash;ren, t&aelig;nk
              p&aring; IC4 tog!
            </p>
            <p>Nogle grunde til at v&aelig;lge Jeti kunne v&aelig;re:</p>
            <ul>
              <li>
                Systemets ide er at s&aelig;tte en &rdquo;software kasse&rdquo;
                af sikkerhed rundt om din dyrebare model i form af alarmer
                s&aring;som, for lave batteri sp&aelig;ndinger, opbrugt
                kapacitet, d&aring;rlig antenne montage m.m.
              </li>
              <li>
                Jeti systemet er meget omfattende og h&oslash;jflexibelt og kan
                bruges til alle t&aelig;nkelige opgaver, som en modelflyver kan
                t&aelig;nke sig.
              </li>
              <li>
                Du kan ogs&aring; begynde ganske beskedent med en gammel sender,
                en Profi Box og en lille 4 kanal modtager, som beskrevet i
                f&oslash;rste artikel.
              </li>
              <li>
                Ved nedstyrtning eller bortflyvning kan man efterf&oslash;lgende
                analysere de i Profi Boxen gemte data og dermed finde fejl og
                den forsvundne model.
              </li>
              <li>
                Konkurrencepiloter og flykonstrukt&oslash;rer kan foretage
                performance optimeringer.
              </li>
              <li>
                M&aring;lemodulerne kan erstatte andre instrumenter som f.eks.
                tangamperemeter eller omdrejningsm&aring;ler.
              </li>
              <li>
                Er i mods&aelig;tning til nogle produkter dokumenteret
                s&aelig;rdeles grundigt, godt nok kun p&aring; engelsk, eller
                tysk.
              </li>
              <li>
                Modtager st&oslash;tte fra EU's teknologi fond hvilket giver en
                vis sikkerhed for at produktet ikke forsvinder ud af markedet,
                som der desv&aelig;rre er eksempler p&aring;.
              </li>
              <li>Der gives effektiv dansk support.</li>
            </ul>
            <h3>Nye fartregulatorer og sendere</h3>
            <p>
              Jeti har gennem en &aring;rr&aelig;kke leveret fartregulatorer af
              h&oslash;j kvalitet til b&oslash;rstel&oslash;se elmotorer. Det er
              derfor logisk, at Jeti ogs&aring; introducerer en ny serie af
              regulatorer som er beregnet til EX systemet. Disse regulatorer
              giver naturligvis mulighed for telemetri samt opsamling og
              afspilning af data, ligesom regulatorerne kan programmeres
              tr&aring;dl&oslash;st via Profi Boxen, mens de sidder i modellen
              p&aring; samme m&aring;de som m&aring;lemodulerne.
            </p>
            <p>
              N&aelig;sten samtidig med fremkomsten af Profi Boxen introducerer
              Jeti to nye 16 kanal sendere, nemlig DC16 og DS 16. Disse sendere
              anvender naturligvis den samme teknologi og har alle de samme
              faciliteter, som jeg allerede har beskrevet. Som en interessant
              nyskabelse indeholder styrepindene ikke potentiometre, men man
              drejer en magnet koblet til en chip som ved hj&aelig;lp af hall
              sensorer, kan oms&aelig;tte magnetfeltets retning til digitale
              signaler. Dette system er uopslideligt, i mods&aelig;tning til
              potentiometeret.
            </p>
            <h2>Afslutning</h2>
            <p>
              Det m&aring; erkendes at denne og den foreg&aring;ende artikel
              kr&aelig;ver en del teknisk fordybelse og viden. Det er imidlertid
              mit h&aring;b, at denne grundige gennemgang har givet dig en bedre
              forst&aring;else af digitale RC-systemer og dermed et bedre
              beslutningsgrundlag for dine valg i et kompliceret marked.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
