const styles = theme => ({
  root: {
    marginBottom: "60px",
    width: "100%"
  },
  article: {
    padding: theme.spacing.unit * 3,
    marginLeft: "auto" as "auto",
    marginRight: "auto" as "auto",
    maxWidth: "900px",
    "& p": {
      "& img": {
        display: "block",
        position: "relative" as "relative",
        maxWidth: "100%",
        pageBreakInside: "avoid" as "avoid"
      }
    },
    "& table": {
      display: "inline-block",
      overflowX: "scroll" as "scroll"
    }
  },
  scrollToTop: {
    margin: 0,
    top: "auto",
    left: 20,
    bottom: 20,
    right: "auto",
    position: "fixed" as "fixed",
    zIndex: 1400
  },
  print: {
    margin: 0,
    top: "auto",
    left: "50%",
    marginLeft: "-28px",
    bottom: 20,
    right: "auto",
    position: "fixed" as "fixed",
    zIndex: 1400
  },
  red: {
    color: "red"
  },
  blue: {
    color: "blue"
  },
  green: {
    color: "green"
  },
  breakWord: {
    wordBreak: "break-word" as "break-word"
  },
  box: {
    border: "1px solid black"
  },
  "@media print": {
    scrollToTop: {
      display: "none"
    },
    print: {
      display: "none"
    }
  }
});

export default styles;
