import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/FlightMonitorFlyvninger/fig1.jpg";
import fig2 from "../../../images/Jeti/FlightMonitorFlyvninger/fig2.jpg";
import fig3 from "../../../images/Jeti/FlightMonitorFlyvninger/fig3.jpg";
import fig4 from "../../../images/Jeti/FlightMonitorFlyvninger/fig4.jpg";
import fig5 from "../../../images/Jeti/FlightMonitorFlyvninger/fig5.jpg";
import fig6 from "../../../images/Jeti/FlightMonitorFlyvninger/fig6.jpg";
import fig7 from "../../../images/Jeti/FlightMonitorFlyvninger/fig7.jpg";
import fig8 from "../../../images/Jeti/FlightMonitorFlyvninger/fig8.jpg";
import fig9 from "../../../images/Jeti/FlightMonitorFlyvninger/fig9.jpg";
import fig10 from "../../../images/Jeti/FlightMonitorFlyvninger/fig10.jpg";
import fig11 from "../../../images/Jeti/FlightMonitorFlyvninger/fig11.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Flight Monitor målinger på flyvninger</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Flight Monitor m&aring;linger p&aring; flyvninger</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret november 2012</p>
            <h2>Indledning</h2>
            <p>
              Denne beskrivelse viser nogle forskellige anvendelses muligheder
              af et &rdquo;Black Box&rdquo; flight recorder system til modelfly.
              Data opsamles via telemetri i en Jeti Profi Box eller i den nye
              Jeti DC16/DS16 sender, med henblik p&aring; senere analyse i
              Windows programmet Jeti Flight Monitor. Systemet virker p&aring;
              samme m&aring;de som den sorte box, man altid leder efter ved
              flyveulykker.
            </p>
            <h2>
              M&aring;ling af h&oslash;jde, afstand, groundspeed,
              gennemfl&oslash;jet distance og energiforbrug for en komplet
              flyvning
            </h2>
            <p>
              <img src={fig1} />
            </p>
            <p>
              Ovenst&aring;ende billede viser en hel flyvning med Fanlineren.
              Den r&oslash;de kurve viser flyvefart i forhold til jorden
              m&aring;lt i meter pr sekund (max. 31m/s). Den bl&aring; kurve
              viser at flyveh&oslash;jden holdes under 100m, mens den gr&aring;
              kurve viser afstanden fra senderen ud til flyveren i luftlinie
              (max 294m). Sensorv&aelig;rdierne efter landingen viser, at flyet
              har gennemfl&oslash;jet en distance p&aring; 7235m med et forbrug
              p&aring; 2168&nbsp;mA/h. D.v.s. at 4km pr. amperetime alts&aring;
              er et udtryk for &rdquo;br&aelig;ndstofforbrug&rdquo;. Dette er
              naturligvis ikke noget, som interesserer en modelflyver, til
              geng&aelig;ld k&aelig;mper luftfarts- og bilindustrien om at kunne
              bev&aelig;ge sig s&aring; langt som muligt p&aring; hver liter
              br&aelig;ndstof. En analyse af flere flyvninger med det samme fly
              viser naturligvis et udsving, s&aring; med den m&aring;leteknik,
              som nu er tilg&aelig;ngelig, kan man &oslash;ve sig p&aring; at
              flyve s&aring; &oslash;konomisk som muligt. Det vil ogs&aring;
              v&aelig;re muligt at s&aelig;tte distancerekorder. Den
              l&aelig;ngste flyvning med Fanlineren er forel&oslash;big godt
              11km. mere end man umiddelbart skulle tro. Telemetrien &aring;bner
              for helt&nbsp;nye konkurrenceformer indenfor modelflyvningen!
            </p>
            <p>
              <img src={fig2} />
              Fanlineren klar til start. Flyver ca. 9 km pr. flyvning og ca.4 km
              for et forbrug p&aring; 1 amperetime eller 22&nbsp;Wtimer.
            </p>
            <p>
              De efterf&oslash;lgende flyvninger med Fanlineren er udf&oslash;rt
              i helt stille luft.
            </p>
            <h3>Start</h3>
            <p>
              <img src={fig3} />
              Fanlineren starter p&aring; bane 27 i Soderup. Der gives
              &rdquo;gas&rdquo; kl. 2.24. Flyet holder i GPS startpunktet.
            </p>
            <p>
              Den r&oslash;de kurve viser accelerationen. Flyet roterer 2.29
              efter at have k&oslash;rt 50meter, se den gr&aring; kurve.
            </p>
            <p>
              Starten er ikke helt vellykket, idet den gr&oslash;nne
              &oslash;verste kurve viser kursafvigelser under starten. Banen er
              anlagt stik vest 270grader. Hastigheden ved take off er 14,8m/s
              svarende til 53km/t.
            </p>
            <h3>M&aring;ling af climb rate</h3>
            <p>
              <img src={fig4} />
              P&aring; 12 sekunder climbes fra 20m til 88m alts&aring; 68m.
              Climbrate =5,6m/sek, hvilket er ganske p&aelig;nt.
            </p>
            <p>
              Take off tilf&oslash;rt effekt p&aring; de to motorer er
              52&nbsp;Ax20,2&nbsp;V = 1050&nbsp;W svarende til 1,428HK.
            </p>
            <h3>M&aring;ling af Max speed</h3>
            <p>
              <img src={fig5} />
              Med et dyk fra 80m til 30m opn&aring;s med fuld motor 36,7m/sek
              svarende til 132km/t. Speed warning er sat til 100km/t og blev
              dermed udl&oslash;st.
            </p>
            <h3>M&aring;ling af &rdquo;&oslash;ko&rdquo; speed</h3>
            <p>
              <img src={fig6} />
              Med et forbrug p&aring; 11&nbsp;A flyves ca. 16m/sek = ca. 57km/s.
              Den tilf&oslash;rte effekt er 11&nbsp;A x 22&nbsp;V = 242&nbsp;W.
            </p>
            <h3>M&aring;ling af Stall speed</h3>
            <p>
              <img src={fig7} />
              Med lukket motorstr&oslash;m (gul kurve) mindskes farten gradvis
              (r&oslash;d kurve) Stallet indtr&aelig;ffer ved cursorlinien med
              et pludseligt h&oslash;jde tab (bl&aring; kurve). Stall speed kan
              afl&aelig;ses til 35km/t svarende til 9,7m/s.
            </p>
            <h3>Landing</h3>
            <p>
              <img src={fig8} />
            </p>
            <p>
              Kurverne p&aring; Google Earth kortet til h&oslash;jre viser
              pilotens tr&aelig;ning med at f&aring; Fanlineren til at lande
              pr&aelig;cist p&aring; bane 27 med en holdt kurs. Graferne til
              venstre viser 20 sekunder af Fanlinerens landingsforl&oslash;b.
              Den gr&aring; kurve viser afstanden til GPS startpunkt,
              alts&aring; der hvor bane 27 begynder. P&aring; h&oslash;jre side
              af curserlinien kan man p&aring; den gr&aring; kurve afl&aelig;se
              bremsel&aelig;ngden til at v&aelig;re 57m. P&aring; den
              r&oslash;de kurve kan man afl&aelig;se opbremsningen. Til venstre
              viser den r&oslash;de kurve, at der indflyves med en nogenlunde
              konstant fart.
            </p>
            <h3>Touch and go</h3>
            <p>
              <img src={fig9} />
              Der udf&oslash;res et touch and go med en pr&aelig;cis indflyvning
              p&aring; 270 grader, se den gr&aring; kurve.
            </p>
            <h3>Flyvning med en termik model</h3>
            <p>
              <img src={fig10} />
              Billedet viser den anvendte termik model en Grand Esprit
              sv&aelig;ver bygget i 1975 og senere modificeret med en elmotor
              med klappropel.
            </p>
            <p>
              <img src={fig11} />
              Den 17,5 km lange termikflyvning er her vist i luftrummet over KFK
              plads i Soderup.
            </p>
            <h3>
              M&aring;ling af stigevne, glidetal og synkehastighed p&aring;
              Grand Esprit
            </h3>
            <p>
              Ved hj&aelig;lp af optagne grafer (som ikke vises her) kan
              f&oslash;lgende m&aring;les og beregnes:&nbsp;
            </p>
            <p>
              Der m&aring;les p&aring; 14 sek en stigning p&aring; 60m = 4,28m/s
            </p>
            <p>
              Ved et h&oslash;jdetab p&aring; 86m m&aring;les en tripl&aelig;nge
              p&aring; 890m, hvilket giver et glidetal p&aring; 10,34:1
            </p>
            <p>
              Et h&oslash;jdetab p&aring; 86m tager 96sek. Synkehastighed =
              0,9m/s
            </p>
            <p>
              M&aring;lingerne skal udf&oslash;res i helt stille luft uden
              termik, det som forfatteren kalder &rdquo;komputerluft&rdquo;
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
