import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Grundviden/MaalingerAfEtElflysData/fig1.jpg";
import fig2 from "../../../images/Grundviden/MaalingerAfEtElflysData/fig2.jpg";
import fig3 from "../../../images/Grundviden/MaalingerAfEtElflysData/fig3.jpg";
import fig4 from "../../../images/Grundviden/MaalingerAfEtElflysData/fig4.jpg";
import fig5 from "../../../images/Grundviden/MaalingerAfEtElflysData/fig5.jpg";
import fig6 from "../../../images/Grundviden/MaalingerAfEtElflysData/fig6.jpg";
import fig7 from "../../../images/Grundviden/MaalingerAfEtElflysData/fig7.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>
            Hvordan elflyet virker - Del 3. Målinger af et elflys data
          </title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Del 3. Målinger af et elflys data</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret nov 2013.</p>
            <p>
              <Link href="/Grundviden/HvordanElflyetVirker/Beregninger-af-et-elflys-data">
                I del 1 bliver opstillet en fremgangsm&aring;de, s&aring;ledes
                du kunne beregne et elflys data.
              </Link>
              <br />
              <Link href="/Grundviden/HvordanElflyetVirker/Simulation-af-et-elflys-data">
                I del 2 foretages en computer simulation af data ved hj&aelig;lp
                af programmet MotoCalc.
              </Link>
              <br />
              I del 3 m&aring;les flyets data i praksis, ved at telemetridata
              recordes og efterf&oslash;lgende analyseres.
              <br />
              M&aring;let med de tre artikler er, at modelflyveren f&aring;r en
              bedre forst&aring;else af, hvordan elflyet virker.
            </p>
            <p>
              I denne 3. del foretages nu m&aring;linger p&aring; Flexflyeren,
              som skal fors&oslash;ge at vise virkeligheden under forskellige
              flyvesekvenser. Til dette form&aring;l anvendes Jetis
              telemetrisystem med tilh&oslash;rende Flight Recorder. Se
              n&aelig;rmere p&aring; Elflytec.dk hvor systemet er beskrevet
              meget detaljeret.
            </p>
            <h2>Problemer med m&aring;len&oslash;jagtigheder</h2>
            <h3>
              <strong>
                1. Batterisp&aelig;nding under afladeforl&oslash;bet
              </strong>
            </h3>
            <p>
              <img src={fig1} />
              <br />
              Sk&aelig;rmbillede 1
            </p>
            <p>
              Ved m&aring;lingerne p&aring; sk&aelig;rmbillede 1 holder modellen
              p&aring; jorden med fuld motor. Disse m&aring;linger viser, at
              batterisp&aelig;ndingen (bl&aring; kurve) og dermed den afsatte
              effekt p&aring; akslen (gr&aring; kurve) er yderst afh&aelig;ngige
              af afladekurven for Li-Po batteriet. Effekten falder fra
              350&nbsp;W til 200&nbsp;W p&aring; akslen af motoren ved et fuldt
              afladeforl&oslash;b af batteriet. Dette problem kan kun
              l&oslash;ses ved at m&aring;le p&aring; tidspunkter, hvor
              batterisp&aelig;ndingen har samme st&oslash;rrelse, nemlig omkring
              10,5&nbsp;V. Det vil v&aelig;re teknisk vanskeligt, at&nbsp;
              <br />
              konstruere en stabiliseret sp&aelig;ndingsforsyning i flyveren,
              til brug for den store motor str&oslash;m! Batterisp&aelig;ndingen
              starter p&aring; 12,3&nbsp;V og slutter p&aring; 10,0&nbsp;V.
              Bem&aelig;rk at batterisp&aelig;ndingen stiger til 10,7&nbsp;V
              efter at motoren er stoppet. Det skal hermed p&aring;peges at man
              ikke kan bruge en evt. telemetreret batterisp&aelig;nding til at
              bestemme om batteriet er afladt. Det er vigtigt at du kender den
              faktiske kapacitet, som i den viste m&aring;ling endte med at
              v&aelig;re 3223&nbsp;mA/t ved en stopsp&aelig;nding p&aring;
              10&nbsp;V = 3,33&nbsp;V pr. celle. Det foresl&aring;s, at du
              lander, n&aring;r du har brugt 70&nbsp;% af den faktiske
              kapacitet. Batteriet er p&aring;stemplet 4000&nbsp;mA/t og er
              brugt 45 gange over 18 m&aring;neder. <br />
              De nederste kurver viser temperatur stigningen p&aring; motoren
              (lyser&oslash;d) og controlleren (m&oslash;rker&oslash;d)
              <br />
              Temperaturstigningerne er altid et udtryk for tab, s&aring; jo
              bedre virkningsgrad der kan opn&aring;s desto mindre varme. Det er
              varmen som s&aelig;tter gr&aelig;nsen for hvorn&aring;r motor og
              kontroller bliver &oslash;delagt.&nbsp;
              <br />
              Men bem&aelig;rk at temperaturstigning forts&aelig;tter fra
              45&nbsp;C til 55&nbsp;C grader, efter at motoren er stoppet, og en
              afk&oslash;ling af drivliniens komponenter varer op til en halv
              time, f&oslash;r de atter er nede p&aring; omgivelsestemperaturen.
            </p>
            <h3>
              <strong>2. Vind og termik</strong>
            </h3>
            <p>
              Vind og termik p&aring;virker naturligvis m&aring;lingerne
              kraftigt. Dette kan im&oslash;deg&aring;s ved, at piloten
              st&aring;r op klokken 4 om morgenen en stille sommerdag, lige
              f&oslash;r solen st&aring;r op. Her haves det, som kunne betegnes
              &rdquo;computerluft&rdquo;. Det har jeg ikke gjort, men
              fors&oslash;ger at im&oslash;deg&aring; problemer med luften
              p&aring; forskellig vis.
            </p>
            <h3>
              <strong>3. Piloten</strong>
            </h3>
            <p>
              Under de forskellige flyvninger stod det klart, at det ikke var
              nok bare at flyve rundt, som jeg plejede, med at der var store
              krav til at holde f.eks. flyveh&oslash;jde og flyvefart konstant.
              P&aring; et tidspunkt monterede jeg derfor en Eagle Gurdian 2D/3D
              stabiliser, et 3 akset gyro system til stabilisering af
              fastvingfly.
              <br />
              Dette var en stor hj&aelig;lp specielt ved langsom flyvning.
            </p>
            <h3>
              <strong>4. Jeti systemet</strong>
            </h3>
            <p>
              Selve Jeti telemetri og flight recorder systemet har naturligvis
              ogs&aring; en n&oslash;jagtighed, som jeg tidligere har
              unders&oslash;gt n&oslash;je I forhold til de 3 ovenn&aelig;vnte
              faktorer er disse un&oslash;jagtigheder sm&aring;.
              <br />
              Det skal dog bem&aelig;rkes, at hvis man opsamler s&aring; mange
              data, som jeg g&oslash;r, (25 stk., se sk&aelig;rmbillede 4), og
              hvis disse data &aelig;ndres hastigt, opst&aring;r der
              samtidighedsproblemer p&aring; grund af den langsomme serielle
              datatransmission (baudrate = 9600) i forhold til, hvor hurtig
              flyvning foreg&aring;r.
            </p>
            <h2>Statisk m&aring;ling p&aring; jorden</h2>
            <p>
              F&oslash;r flyet slippes l&oslash;s, ser jeg f&oslash;rst p&aring;
              nogle statiske m&aring;linger, hvor flyet holder stille p&aring;
              jorden.
            </p>
            <p>
              De simulerede MotoCalc data stammer fra sk&aelig;rmbillede 3,
              artikel 2. Den f&oslash;rste r&aelig;kke er Airspeed = 0 m/sek
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Flexflyer Statisk på jord</TableCell>
                  <TableCell>Simuleret Air speed = 0m/sek</TableCell>
                  <TableCell>Målt ved 2111&nbsp;mA/h forbrug</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Motoromdrejninger</TableCell>
                  <TableCell>11765&nbsp;omdr./min</TableCell>
                  <TableCell>10680&nbsp;omdr./min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Statisk træk</TableCell>
                  <TableCell>1243 gram</TableCell>
                  <TableCell>
                    1330 gram. <br />
                    Målt med fjedervægt.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Effekt på motorakslen</TableCell>
                  <TableCell>323&nbsp;W</TableCell>
                  <TableCell>237&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorstrøm</TableCell>
                  <TableCell>35,9&nbsp;A</TableCell>
                  <TableCell>29,4&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorspænding</TableCell>
                  <TableCell>10,5&nbsp;V</TableCell>
                  <TableCell>10,5&nbsp;V</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tilført effekt</TableCell>
                  <TableCell>377&nbsp;W</TableCell>
                  <TableCell>308&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Virkningsgrad på regulator og motor</TableCell>
                  <TableCell>81&nbsp;%</TableCell>
                  <TableCell>76,9&nbsp;%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Nu er flexflyeren oppe i luften, og der sendes ca. 80 kbit data
              ned til Flight Recorderen pr. min.
            </p>
            <h2>Stigning</h2>
            <p>Nu skal flexflyeren op i luften.</p>
            <p>
              <img src={fig3} />
              <br />
              Sk&aelig;rmbillede 2
            </p>
            <p>
              Der &oslash;nskes f&oslash;rst m&aring;lt max. stigevne. P&aring;
              den bl&aring; kurve m&aring;les tiden for stigningen fra 20 meter
              til 80 meter til at v&aelig;re 9 sekunder, d.v.s at
              stigehastigheden m&aring; v&aelig;re 60m/9sek = 6,6 m pr sek. Der
              er desv&aelig;rre problemer med at holde flyvehastigheden hel
              konstant. Husk at bev&aelig;gelsesenergi og beliggenhedsenergi
              p&aring;virker hinanden. GPS m&aring;lingen af hastigheden bliver
              desuden for lav, fordi hastigheden m&aring;les i forhold til
              jorden.&nbsp;
              <br />
              Motoromdrejningerne er m&aring;lt til 12060 omdr. pr. minut. Det
              er s&aring;dan, at hvis man vil fordoble omdrejningerne p&aring;
              en motor for en given propel, kr&aelig;ver det en 8 dobling i den
              tilf&oslash;rte effekt. Det betyder, at hvis man ved, hvor mange
              omdrejninger en given propel skal k&oslash;re for n&oslash;jagtig
              at optage 100&nbsp;W. (N100w), kan man regne ud, hvor mange watt
              den optager ved et givet omdrejningstal. Omdrejningstallet bliver
              m&aring;lt af omdrejningsm&aring;leren, som ogs&aring; beregner
              effekten p&aring; akslen, hvis den f&aring;r N100w&rsquo;s
              v&aelig;rdien at vide via indprogrammering. Propellen APCE 9x6 kan
              findes til 7997 omdr., se tabel. Det er s&aring;dan Jeti
              m&aring;ler 314&nbsp;W p&aring; akslen.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Input til regulatoren er 10,5&nbsp;V og 27,9&nbsp;A svarende til
              293&nbsp;W. Virkningsgraden p&aring; regulator og motor er
              s&aring;ledes 93&nbsp;%
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Flexflyer under stigning</TableCell>
                  <TableCell>Beregnet</TableCell>
                  <TableCell>Simuleret</TableCell>
                  <TableCell>Målt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Stigning</TableCell>
                  <TableCell>6 m/sek</TableCell>
                  <TableCell>6,28 m/sek</TableCell>
                  <TableCell>6,6 m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motoromdrejninger</TableCell>
                  <TableCell>10236 omdr/min</TableCell>
                  <TableCell>11700 omdr/min</TableCell>
                  <TableCell>12060 omdr/min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Effekt på motorakslen</TableCell>
                  <TableCell>240&nbsp;W</TableCell>
                  <TableCell>322&nbsp;W</TableCell>
                  <TableCell>314&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorstrøm</TableCell>
                  <TableCell>30,9&nbsp;A</TableCell>
                  <TableCell>35,8&nbsp;A</TableCell>
                  <TableCell>27,9&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorspænding</TableCell>
                  <TableCell>10,6&nbsp;V</TableCell>
                  <TableCell>10,6&nbsp;V</TableCell>
                  <TableCell>10,5&nbsp;V</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tilført effekt</TableCell>
                  <TableCell>324&nbsp;W</TableCell>
                  <TableCell>376&nbsp;W</TableCell>
                  <TableCell>292&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Virkningsgrad på regulator og motor</TableCell>
                  <TableCell>74&nbsp;%</TableCell>
                  <TableCell>80,8&nbsp;%</TableCell>
                  <TableCell>93&nbsp;%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Glideflyvning</h2>
            <p>
              Flexflyeren er bragt op i max tilladelig toph&oslash;jde i helt
              stille d&oslash;d luft uden termik, derefter lukkes gassen helt.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Sk&aelig;rmbillede 3
            </p>
            <p>
              P&aring; den bl&aring; kurve sk&aelig;rmbillede 2 m&aring;les
              tiden for h&oslash;jdetabet fra 84 meter til 28 meter til at
              v&aelig;re 33 sekunder,&nbsp;d.v.s&nbsp;at synkehastigheden
              m&aring; v&aelig;re 84 m/33&nbsp;sek&nbsp;= 1,7 m pr sek. P&aring;
              den r&oslash;de kurve m&aring;les speeden til at v&aelig;re
              omkring 9,7 m pr&nbsp;sek&nbsp;i gennemsnit.
            </p>
            <p>
              Glidetallet kan nu bestemmes til at v&aelig;re Speeden 9,7
              m/sek&nbsp;divideret med synkehastigheden 1,7
              m/sek&nbsp;alts&aring; 5,7 til 1. Propellen roterer med ca. 2640
              omdrejninger pr. min. drevet af luften. Ved en flyvefart p&aring;
              9,7 m pr. sek. skal 9x6 propellen k&oslash;re mindst 3818
              omdrejninger for overhovedet at tr&aelig;kke noget som helst,
              alts&aring; bremser den og medvirker til d&aring;rligt glidetal.
              Det er naturligvis, derfor man anvender en klappropel p&aring;
              el-sv&aelig;vfly.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Flexflyer under glideflyvning</TableCell>
                  <TableCell>Beregnet</TableCell>
                  <TableCell>Simuleret</TableCell>
                  <TableCell>Målt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Synkehastighed</TableCell>
                  <TableCell>2 m/sek</TableCell>
                  <TableCell>2,12 m/sek</TableCell>
                  <TableCell>1,7 m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Glidetal</TableCell>
                  <TableCell>5 til 1</TableCell>
                  <TableCell>5,85 til 1</TableCell>
                  <TableCell>5,7 til 1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Flyvefart</TableCell>
                  <TableCell>10 m/sek</TableCell>
                  <TableCell>10 m/sek</TableCell>
                  <TableCell>9,7 m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motoromdrejninger</TableCell>
                  <TableCell>Beregnes ikke</TableCell>
                  <TableCell>Beregnes ikke</TableCell>
                  <TableCell>
                    2640 omdr/min <br />
                    drevet rundt af luften.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Effekt på motorakslen</TableCell>
                  <TableCell>0&nbsp;W</TableCell>
                  <TableCell>0&nbsp;W</TableCell>
                  <TableCell>3&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorstrøm</TableCell>
                  <TableCell>0&nbsp;A</TableCell>
                  <TableCell>0&nbsp;A</TableCell>
                  <TableCell>
                    0,3&nbsp;A <br />
                    forbrug på RC komponenter
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Maximal speed</h2>
            <p>
              Der &oslash;nskes nu m&aring;lt, hvor hurtigt flyet kan flyve.
              H&oslash;jden s&oslash;ges holdt konstant i ca. 40 m. Der flyves i
              svag sidevind, idet man skal huske, at GPS&rsquo;en m&aring;ler
              ground speed.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Sk&aelig;rmbillede 4
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Flexflyer max speed</TableCell>
                  <TableCell>Beregnet</TableCell>
                  <TableCell>Simuleret</TableCell>
                  <TableCell>Målt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Max speed</TableCell>
                  <TableCell>20 m/sek</TableCell>
                  <TableCell>22,5 m/sek</TableCell>
                  <TableCell>28,7 m/sek = 103km/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motoromdrejninger</TableCell>
                  <TableCell>10236 omdr/min</TableCell>
                  <TableCell>12329 omdr/min</TableCell>
                  <TableCell>11760 omdr/min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Effekt på motorakslen</TableCell>
                  <TableCell>240&nbsp;W</TableCell>
                  <TableCell>322&nbsp;W</TableCell>
                  <TableCell>317&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorstrøm</TableCell>
                  <TableCell>30.9&nbsp;A</TableCell>
                  <TableCell>27,3&nbsp;A</TableCell>
                  <TableCell>30,5&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorspænding</TableCell>
                  <TableCell>10,6&nbsp;V</TableCell>
                  <TableCell>10,6&nbsp;V</TableCell>
                  <TableCell>10,5&nbsp;V</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tilført effekt</TableCell>
                  <TableCell>328&nbsp;W</TableCell>
                  <TableCell>290&nbsp;W</TableCell>
                  <TableCell>320&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Virkningsgrad på regulator og motor</TableCell>
                  <TableCell>73&nbsp;%</TableCell>
                  <TableCell>80,8&nbsp;%</TableCell>
                  <TableCell>99&nbsp;%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>Virkningsgraden p&aring; 99 % er n&aelig;ppe realistisk.</p>
            <h2>Cruise speed, r&aelig;kkevidde og flyvetid</h2>
            <p>
              I den n&aelig;ste flyvning vil vi flyve s&aring; langt og
              l&aelig;nge som muligt. Her s&oslash;ges holdt en konstant
              flyveh&oslash;jde p&aring; omkring 30 m og en flyvefart omkring 13
              m/sek.
            </p>
            <p>
              <img src={fig7} />
              <br />
              Sk&aelig;rmbillede 5
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Flexflyer cruise speed</TableCell>
                  <TableCell>Beregnet</TableCell>
                  <TableCell>Simuleret</TableCell>
                  <TableCell>Målt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Cruise speed</TableCell>
                  <TableCell>13 m/sek</TableCell>
                  <TableCell>12,5 m/sek</TableCell>
                  <TableCell>15 m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motoromdrejninger</TableCell>
                  <TableCell>8188 omdr/min</TableCell>
                  <TableCell>7424 omdr/min</TableCell>
                  <TableCell>7230 omdr/min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Effekt på motorakslen</TableCell>
                  <TableCell>60&nbsp;W</TableCell>
                  <TableCell>64,1&nbsp;W</TableCell>
                  <TableCell>73&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorstrøm</TableCell>
                  <TableCell>6,7&nbsp;A</TableCell>
                  <TableCell>6,9&nbsp;A</TableCell>
                  <TableCell>8,2&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorspænding</TableCell>
                  <TableCell>10,6&nbsp;V</TableCell>
                  <TableCell>10,6&nbsp;V</TableCell>
                  <TableCell>10,9&nbsp;V</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tilført effekt</TableCell>
                  <TableCell>76&nbsp;W</TableCell>
                  <TableCell>74,5&nbsp;W</TableCell>
                  <TableCell>89&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Virkningsgrad på regulator og motor</TableCell>
                  <TableCell>79&nbsp;%</TableCell>
                  <TableCell>86&nbsp;%</TableCell>
                  <TableCell>82&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Forbrug mA/t</TableCell>
                  <TableCell>2500&nbsp;mA/t</TableCell>
                  <TableCell>3800&nbsp;mA/t</TableCell>
                  <TableCell>970&nbsp;mA/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Flyvetid</TableCell>
                  <TableCell>21&nbsp;min</TableCell>
                  <TableCell>
                    32&nbsp;min <br />
                    Baseret på manøvre fri flyvning.
                  </TableCell>
                  <TableCell>16&nbsp;min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Omregnet flyvetid i forhold til et forbrug på 2500mA/t
                  </TableCell>
                  <TableCell>21&nbsp;min</TableCell>
                  <TableCell>21&nbsp;min</TableCell>
                  <TableCell>20,3&nbsp;min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Distance</TableCell>
                  <TableCell>16,4&nbsp;km</TableCell>
                  <TableCell>
                    24&nbsp;km <br />
                    Baseret på manøvre fri flyvning.
                  </TableCell>
                  <TableCell>13,17&nbsp;km</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Omregnet distance i forhold til et forbrug på 2500&nbsp;mA/t
                  </TableCell>
                  <TableCell>16,4&nbsp;km</TableCell>
                  <TableCell>15,78&nbsp;km</TableCell>
                  <TableCell>16,7&nbsp;km</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Hvad kan de 3 metoder bruges til?</h2>
            <p>
              De 3 artikler er et fors&oslash;g p&aring; at koble de tre
              forskellige metoder sammen, nemlig beregning, simulation og
              m&aring;ling, for at f&aring; bedre indsigt
              i&nbsp;elflyets&nbsp;funktioner. Jeg pr&oslash;ver at konkludere:
            </p>
            <ul>
              <li>
                Grundl&aelig;ggende er der god overensstemmelse mellem de tre
                former for data, som samtidig kan supplere hinanden for at
                f&aring; det mest n&oslash;jagtige resultat.&nbsp;Dog har max.
                speed v&aelig;ret en del bedre end b&aring;de de beregnede og de
                simulerede v&aelig;rdier.&nbsp;
              </li>
              <li>
                Det b&oslash;r bem&aelig;rkes at de 3 metoder b&oslash;r
                underst&oslash;tte hinanden, idet nogle data kan v&aelig;re
                un&oslash;jagtige. I den f&oslash;rste artikel skrev jeg: "Som
                udgangspunkt s&aelig;tter vi flyets max. speed til det dobbelte,
                alts&aring; 20&nbsp;m/sek". Dette udgangspunkt var for lavt og
                burde nu revideres.
              </li>
              <li>
                Hvis du tilh&oslash;rer den udd&oslash;ende race af
                modelflyvere, som selv konstruerer og bygger&nbsp;elfly, vil du
                kunne have stor gl&aelig;de af at kunne beregne og simulere
                elflyet, f&oslash;r du bygger. Det fratager godt nok
                sp&aelig;ndingen ved f&oslash;rste pr&oslash;veflyvning!
              </li>
              <li>
                Hvis du selv tilpasser komponenter i&nbsp;drivlinien,
                s&aring;som valg af elmotoren, regulatoren, Li-Po batteriet og
                is&aelig;r propellen, vil du ligeledes kunne f&aring; nytte af
                artikel 1 og 2. Du kan dels optimere modellen, dels undg&aring;
                fejlindk&oslash;b og f.eks. afbr&aelig;ndte regulatorer.
              </li>
              <li>
                Ved is&aelig;r at fokusere p&aring; modellens energitilstand,
                fart og h&oslash;jde og dermed energiforbrug, har det i hvert
                tilf&aelig;lde &aelig;ndret min flyvestil til at flyve mere
                &oslash;konomisk, alts&aring; brug
                &rdquo;gash&aring;ndtaget&rdquo; langt mere situationsbestemt.
              </li>
            </ul>
            <h2>
              Hvad kan
              telemetri,&nbsp;voice-call&nbsp;og&nbsp;datarecording&nbsp;bruges
              til?
            </h2>
            <p>
              I denne sidste 3. artikel er anvendt en del nyere teknologi til at
              gennemf&oslash;re flyvningerne og m&aring;lingerne med
              f&oslash;lgende konklusioner:
            </p>
            <ul>
              <li>
                Af de data som et RC-anl&aelig;g kan&nbsp;telemetrere&nbsp;til
                pilotens sk&aelig;rm, er der kun et data, som er vigtig,
                nemlig&nbsp;elflyets mA/t forbrug. Dette udl&aelig;ser piloten
                under flyvningen, p&aring; velvalgte tidspunkter til sidst under
                flyvningen.&nbsp;<u>Hold &oslash;jnene p&aring; flyveren!</u>
                &nbsp;Det skal dog bem&aelig;rkes, at piloten kan anvende en
                hj&aelig;lper til at afl&aelig;se andre data under flyvningen.
              </li>
              <li>
                Hvis RC-anl&aelig;gget har Voice-call&nbsp;i klar tale,
                &oslash;ges anvendeligheden af telemetrien meget betydeligt,
                noget du b&oslash;r v&aelig;re opm&aelig;rksom p&aring; ved valg
                af nye RC-anl&aelig;g.
              </li>
              <li>
                Datarecordingen&nbsp;af flyvningen muligg&oslash;r en
                n&aelig;rmere analyse af flyvningen.
              </li>
            </ul>
            <p>
              {" "}
              Den blev foretaget i nogenlunde sand tid, sendt ned til jorden og
              analyseret efterf&oslash;lgende.
            </p>
            <ul>
              <li>
                Du skal v&aelig;re opm&aelig;rksom p&aring;, at
                logningsprodukter fra SM
                eller&nbsp;Eagle&nbsp;Tree&nbsp;m&aring;ske er bedre, fordi data
                opsamles i modellen og ikke skal downloades ned til modellen via
                en langsom dataforbindelse med en baudrate p&aring; 9600.
                Flyvning g&aring;r st&aelig;rkt og stiller store krav til den
                hastighed logningen finder sted med.&nbsp;
              </li>
            </ul>
            <p>
              Hvis du er interesseret i at se flere m&aring;linger p&aring;
              flyvninger, 2,4&nbsp;GHz signaler eller komponenter, kan de findes
              her p&aring; hjemmesiden under Jeti RC og telemetri.
            </p>
            <h2>
              Hvorfor er der ikke flere data p&aring; alle de fine f&aelig;rdige
              modeller?
            </h2>
            <p>
              Man kan undre sig over, at der ikke findes flere data p&aring;
              alle de mange f&aelig;rdige fly, som kan k&oslash;bes.
              <br />
              Om alle andre produkter, s&aring; som computere, kameraer, biler
              m.m. oplyses et v&aelig;ld af specifikationer, som giver
              k&oslash;beren mulighed for at v&aelig;lge. Ofte har du kun
              prisen, sp&aelig;ndvidden og et billede, n&aring;r det drejer sig
              om modelfly. Til sidst h&aring;ber jeg at du selv kan anvende
              nogle af de ting i dine egne modeller, som jeg har belyst i de 3
              artikler.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
