import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/FlyByWire/UdviklingstendenserIRCTeknologierne/fig1.jpg";
import fig2 from "../../../images/FlyByWire/UdviklingstendenserIRCTeknologierne/fig2.jpg";
import fig3 from "../../../images/FlyByWire/UdviklingstendenserIRCTeknologierne/fig3.png";
import fig4 from "../../../images/FlyByWire/UdviklingstendenserIRCTeknologierne/fig4.png";
import fig5 from "../../../images/FlyByWire/UdviklingstendenserIRCTeknologierne/fig5.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Udviklingstendenser i RC teknologierne</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Udviklingstendenser i RC teknologierne</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn.</p>
            <p>Publiceret marts 2015.</p>
            <p>
              Jeg vil fors&oslash;ge at give et overblik over
              udviklingstendenserne i RC teknologierne. Det g&aring;r nu
              s&aring; st&aelig;rkt, at nye produkter og ideer opst&aring;r
              s&aring; hurtigt, at det er sv&aelig;rt at f&oslash;lge med.
            </p>
            <h2>Eksponentiel digital v&aelig;kst</h2>
            <p>
              I 1965 forudsagde Moore, medstifter af Intel, at antallet af
              transistorer eller elektriske komponenter, alt efter fortolkning,
              i et integreret kredsl&oslash;b vil fordobles hver 18.
              m&aring;ned. I dag 50 &aring;r senere er den smart-phone, du har
              liggende i din lomme, flere tusinde gange hurtigere og over 1
              million gange mindre og billigere end verdens hurtigste computer i
              1968. Udviklingen er alts&aring; ikke line&aelig;r, men
              forl&oslash;ber st&aelig;rkt stigende med en s&aring;dan fart, at
              den bliver ganske uoverskuelig for b&aring;de politikkerne,
              investorerne, producenterne, forhandlerne og forbrugerne. Se
              skema.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Stærkt stigende tendens</TableCell>
                  <TableCell>Stærkt faldende tendens</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Mængden af beregninger</TableCell>
                  <TableCell>Prisen</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beregningshastigheden</TableCell>
                  <TableCell>Størrelsen på hardwaren</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hukommelsers størrelser</TableCell>
                  <TableCell>Vægten af hardwaren</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Softwares kompleksitet</TableCell>
                  <TableCell>Strømforbruget</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hvad tingene kan</TableCell>
                  <TableCell>Fejl i hardwaren</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Innovationskryds</h2>
            <p>
              Et innovationskryds er der hvor forskellige teknologier
              m&oslash;des og giver et s&aelig;rligt udviklingsspring til
              frembringelse af et konkret produkt.
            </p>
            <p>
              Overordnet set er det der, hvor man kan kombinere nogle af
              f&oslash;lgende omr&aring;der:
            </p>
            <ul>
              <li>Computerkraft</li>
              <li>Kommunikationsteknologi</li>
              <li>Kunstig intelligens</li>
              <li>Bioteknologi</li>
              <li>Nanoteknologi</li>
            </ul>
            <p>
              Eksempler p&aring; innovationskryds kan v&aelig;re
              selvk&oslash;rende biler, droner, briller med datask&aelig;rme,
              kunstig intelligens, robotter og andre produkter, hvis eksistens
              vi ikke kender endnu.
            </p>
            <h2>Hvad med softwaren?</h2>
            <p>
              Uden softwaren kan computeren ingenting. Lige fra begyndelsen,
              hvor man fra udviklingen af den f&oslash;rste computer, baseret
              p&aring; at man havde en aritmetisk logisk enhed, et programlager,
              et datalager, en programt&aelig;ller m.m., blev det
              n&oslash;dvendigt at fort&aelig;lle, hvad det var for en opgave
              der skulle udf&oslash;res helt ned p&aring; bitniveau. Det blev
              hurtigt klart, at selvom programmerne skrevet i maskinkode var
              ganske korte, opstod der fejl hvis programm&oslash;ren ikke
              forstod mikroprocessorens funktioner. De fejl gav
              &oslash;jeblikkelig og kontant afregning, tingene virkede ikke. Et
              eksempel kunne v&aelig;re at NASAs dygtige programm&oslash;rer
              havde en gravemaskine, som gik i st&aring; p&aring; Mars, p&aring;
              grund af en softwarefejl. Fejlen blev fundet p&aring; jorden, og
              det rettede program blev oploadet til maskinen, som derefter kunne
              grave videre.
            </p>
            <p>
              Hvordan ser det s&aring; ud i dag? Svaret er, at det ikke ser godt
              ud. Et Windows 7 program best&aring;r af 45 millioner kodelinier
              og det ansl&aring;s, at der er 22500 fejl i alt, alts&aring; en
              fejl pr. 2000 kodelinier! Se skema:
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Program</TableCell>
                  <TableCell>Millioner af kodelinier</TableCell>
                  <TableCell>Antal fejl i alt</TableCell>
                  <TableCell>Antal kodelinier pr. fejl</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Windows 7</TableCell>
                  <TableCell>45</TableCell>
                  <TableCell>22500</TableCell>
                  <TableCell>2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Android</TableCell>
                  <TableCell>12</TableCell>
                  <TableCell>1000</TableCell>
                  <TableCell>12000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>F22 fighter</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>2.000.000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>F35 fighter</TableCell>
                  <TableCell>8</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>2.000.000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rumfærgen</TableCell>
                  <TableCell>0,4</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>400.000</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Det bliver alts&aring; bedre, n&aring;r der st&aring;r mange penge
              p&aring; spil, og konsekvenserne bliver mere alvorlige end at en
              Android eller Windows computer fejler. F&oslash;lgende kan
              konstateres:
            </p>
            <ul>
              <li>Der findes ikke fejlfri software.</li>
              <li>
                Af ovenst&aring;ende skema kan konstateres, at det er meget
                kostbart at holde fejlene nede. Det ansl&aring;s at
                rumf&aelig;rgesoftwaren har kostet mere end 3 millioner
                arbejdstimer.
              </li>
              <li>
                Der er ingen firmaer der giver nogen som helst form for
                produktgaranti for softwaren.
              </li>
              <li>
                Der findes ikke lovgivning som d&aelig;kker softwarefejl.
                S&aring; hvis din selvk&oslash;rende Google-bil laver en ulykke
                opst&aring;r der et juridisk problem!
              </li>
              <li>
                Politikerne har ikke taget stilling til den form for
                problemstillinger p&aring; trods af, at hele vores digitale
                verden er omgivet og styret af software!
              </li>
              <li>
                Al software kan hackes, hvis der kan skaffes adgang til
                systemet.
              </li>
            </ul>
            <p>
              Brugeren er alts&aring; stort set p&aring; herrens mark og bliver
              n&oslash;dt til at stole p&aring; kvaliteten af softwaren, og det
              princip at producenten fortl&oslash;bende tilbyder at rette fejl
              og forbedre produktet ved hj&aelig;lp af opdateringer.
            </p>
            <h2>RC teknologi</h2>
            <p>
              Efter de mere generelle betragtninger vil jeg nu rette blikket mod
              modelflyvningen.
            </p>
            <p>
              I modelflyvning er elflyet et eksempel p&aring; et
              innovationskryds. Det var opfindelsen af Litium
              Polymere-batteriet, som indeholder stor energi og har en lav
              v&aelig;gt, sammen med Field Effekt Transistoren, som kan styre
              store str&oslash;mme, den vedligeholdelsesfrie
              b&oslash;rstel&oslash;se motor uden kul og mikroprocessoren, som
              gjorde at man fik en effektiv drivlinie, som kunne hamle op med
              br&aelig;ndstofmotorer.&nbsp;
            </p>
            <p>
              Jeg vil nu pr&oslash;ve at give et bud p&aring; nye
              innovationskryds drevet af tilg&aelig;ngelig hardware s&aring;
              som:
            </p>
            <ul>
              <li>Mikro Elektro Mekaniske Systemer p&aring; chips</li>
              <li>Wi-Fi netv&aelig;rks chips til 2,4 og 5,8GHz</li>
              <li>Digitale kameraer</li>
              <li>Sk&aelig;rme og briller</li>
              <li>GPS modtagere</li>
            </ul>
            <p>
              Med den n&aelig;vnte hardware vil jeg pr&oslash;ve at beskrive et
              maksimalt udbygget system, som det er muligt at k&oslash;be for
              hobbypiloter i for&aring;ret 2015.
            </p>
            <h2>Hvad betyder begreberne?</h2>
            <p>
              Det er imidlertid f&oslash;rst n&oslash;dvendigt at forklare nogle
              af de nye udtryk, som forekommer og som du m&aring;ske ikke
              kender. Begreberne kommer alle fra det engelske sprogs forskellige
              fagomr&aring;der og det vil ikke danne menig, at fors&oslash;ge at
              finde danske ord. Det er bedre at forst&aring;, hvad ordene
              betyder, fordi du ikke skal forvente danske manualer.
            </p>
            <Table>
              <TableRow>
                <TableCell>Begreb</TableCell>
                <TableCell>Betydning</TableCell>
              </TableRow>
              <TableHead />
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Drone</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Drone er en generel betegnelse for et
                      f&oslash;rerl&oslash;st luftfart&oslash;j, p&aring;
                      engelsk betegnet som Unmanned Aerial Vehicle, forkortet
                      UAV ubemandet luftfart&oslash;j.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>UAV</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Unmanned Aerial Vehicle. Udtrykket anvendes af
                      trafikstyrelsen som bestemmer lovgivningen.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>BL9-4</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Lovgivning som fastl&aelig;gger regler for UAV,
                      alts&aring; modelflyvning i Danmark.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Modelfly</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Her skelner jeg mellem fastvingefly eller multirotorfly,
                      alts&aring; to forskellige typer af modelfly.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Fastvingeflyets tre akser:</p>
                    <p>Pitch</p>
                    <p>Roll</p>
                    <p>Yaw</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <img src={fig1} />
                      Fastvingeflyet er mere energi&oslash;konomisk end
                      multirotorflyet.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Multirotor fly</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Multirotorflyet anvender almindeligvis ikke servoer til
                      styringen, men &aelig;ndrer omdrejningstallet p&aring;
                      motorerne.
                    </p>
                    <p>
                      <img src={fig2} />
                    </p>
                    <p>
                      Forskellige varianter ses her, men med samme princip. Hvis
                      vi ser p&aring; Quad-Copter-X bem&aelig;rkes det at rotor
                      1 og 4 k&oslash;rer den modsatte vej af rotor 2 og 3.
                      Deres drejmoment oph&aelig;ver derved hinanden. Hvis 1 og
                      4 mindskes 200 omdr. samtidig med at 2 og 3 &oslash;ges
                      200 omdr. vil den samlede opdrift v&aelig;re den samme,
                      men Quad-Copteren vil rotere omkring sin yaw akse. Hvis 3
                      og 4 &oslash;ger omdr. samtidig med at 1 og 2 mindsker
                      omdr. vil Quad-Copteren glide til h&oslash;jre. Hvis alle
                      motorer &oslash;ger omdrejninger vil den naturligvis
                      stige.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>FBW</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Begrebet Fly By Wire blev f&oslash;rst rigtig udbredt af
                      Airbus, da man i 1984 anvendte en computer hvorigennem
                      alle pilotens beslutninger skulle passere. Form&aring;let
                      var at holde flyet inden for sin Flight Envelope.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Flight Envelope</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Hvis et fly skal flyves sikkert, taler man om, at det
                      opererer indenfor Flight Envelopen.
                    </p>
                    <p>
                      Det kommer udenfor, hvis det fx tr&aelig;kker for mange g
                      eller kommer i en tilstand, hvor det ikke kan rette op
                      igen.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Feed Back System</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      For at forst&aring; FBW begrebet er det n&oslash;dvendigt
                      at se p&aring;, hvordan feed back virker i et system.
                    </p>
                    <p>
                      <img src={fig3} /> Fig. 1.
                    </p>
                    <p>
                      Til h&oslash;jre er den v&aelig;rdi der haves, til venstre
                      er den v&aelig;rdi, som &oslash;nskes.
                    </p>
                    <p>
                      Man bem&aelig;rker, at der f&oslash;res en del af den
                      v&aelig;rdi, der haves, tilbage til et subtraktionspunkt,
                      hvor man foretager en sammenligning med den &oslash;nskede
                      v&aelig;rdi. Der kan nu forekomme tre tilstande:
                    </p>
                    <p>
                      1. Hvis den v&aelig;rdi der haves, er lig med den
                      &oslash;nskede v&aelig;rdi, er systemet i balance.
                    </p>
                    <p>
                      2. Hvis den v&aelig;rdi der haves, er mindre end den
                      &oslash;nskede v&aelig;rdi, fremkommer der en forskel, som
                      vil bringe systemet mod balance.
                    </p>
                    <p>
                      3. Hvis den v&aelig;rdi der haves, er st&oslash;rre end
                      den &oslash;nskede v&aelig;rdi, fremkommer der en forskel,
                      som ligeledes vil bringe systemet mod balance, blot den
                      modsatte vej. Som du kan se, bev&aelig;ger man sig fra en
                      tilstand til en anden tilstand. Det skal gerne ske
                      s&aring; hurtigt som muligt. Da der er tale om fysiske
                      ting, som er i bev&aelig;gelse, kan flyet finde p&aring;
                      at bev&aelig;ge sig lidt for langt p&aring; grund af
                      enertien, se den r&oslash;de kurve p&aring; fig. 2. Hvis
                      potentiometeret p&aring; fig. 1. drejes ned mod minimum,
                      mindsker man feedback funktionen, indtil en passende
                      st&oslash;rrelse haves illustreret ved den sorte kurve.
                      For lav feedback er vist ved bl&aring; kurve, hvor
                      systemet bliver for sl&oslash;vt.
                    </p>
                    <p>
                      Flyet skal alts&aring; tilstr&aelig;be at opn&aring; en
                      &oslash;nsket v&aelig;rdi. Det kan v&aelig;re en bestemt
                      flyvefart, flyveh&oslash;jde, stigvinkel, eller et bestemt
                      sted i form af GPS koordinater.
                    </p>
                    <p>
                      <img src={fig4} /> Fig. 2.
                    </p>
                    <p>
                      Feedback har altid v&aelig;ret brugt indenfor elektronik
                      og reguleringsteknik.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Feed Back Gain</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Bestemmer hvor kraftig indflydelse Flight Controlleren
                      skal have. 0% ingen indflydelse. 100 % max indflydelse.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Flight Controller</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Der indskydes en computer mellem RC modtageren og flyets
                      servoer/rotormotorer. Computeren modtager dels styring fra
                      RC-piloten, dels styring fra en r&aelig;kke sensorer i
                      flyet.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Stabiliser</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Et andet udtryk for en Flight Controller, med mere simple
                      funktioner, som typisk automatisk stabiliserer flyet i
                      forhold til horisonten. Er i dag indbygget i mange RC
                      anl&aelig;gs modtagere.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Direct Mode</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Styresignalerne f&oslash;res direkte igennem Flight
                      Controlleren, s&aring; Flight Controlleren ingen
                      indflydelse har p&aring; flyets styring.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>MEMS teknik.</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Mikro Elektro Mekaniske Systemer. Specialiserede chips som
                      indeholder komplette systemer af micromekaniske sensorer
                      kombineret med mikroprocessorer til m&aring;ling af
                      is&aelig;r bev&aelig;gelser. Anvendes en m&aelig;ngde
                      steder i f.eks. tablets, udl&oslash;sning af airbags,
                      antiudskridnings systemer m.m. MEMS er en
                      foruds&aelig;tning for multirotor flyvning og indg&aring;r
                      som en meget vigtig komponent i innovationskrydset.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Treakset Gyro</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Treakset gyro som kan fastholde flyets Pitch, Roll og Yaw
                      i forhold til en vandret horisont og en fast kompaskurs.
                      Baseret p&aring; MEMS teknik.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Treakset Accel.</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Et accelerometer som kan registrere G-p&aring;virkninger i
                      tre akser. Baseret p&aring; MEMS teknik.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Magnetometer</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Magnetometer som kan m&aring;le p&aring; jordens
                      magnetfelt. Man f&aring;r dermed et kompas som peger
                      p&aring; jordens magnetiske nordpol. Baseret p&aring; MEMS
                      teknik.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Barometer</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Lufttryksm&aring;ler til n&oslash;jagtig bestemmelse af
                      flyveh&oslash;jden. Baseret p&aring; MEMS teknik.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Variometer</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Beregner hvor meget flyet stiger eller falder i fx meter
                      pr. sekund.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Pitotr&oslash;r</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Pitotr&oslash;r som m&aring;ler flyets fart i forhold til
                      luften. MEMS sensomrene m&aring;ler statisk og dynamisk
                      tryk og enheden beregner True Air Speed.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>MUI</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Modul til m&aring;ling af sp&aelig;nding (U) og
                      str&oslash;m (I). Enheden beregner ogs&aring; mA/h
                      forbruget p&aring; Li-Po drivbatteriet og er p&aring; den
                      m&aring;de en vigtig
                      &rdquo;br&aelig;ndstofm&aring;ler&rdquo; til elflyet.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>A/V</p>
                  </TableCell>
                  <TableCell>
                    <p>Audio/Video Lyd/billede</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Tx</p>
                  </TableCell>
                  <TableCell>
                    <p>Transmitter Radiosender</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Rx</p>
                  </TableCell>
                  <TableCell>
                    <p>Receiver Radiomodtager</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Mode</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      En tilstand til opn&aring;else af en bestemt
                      kontroladf&aelig;rd af flyet.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>2D mode</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Modellen fors&oslash;ger at holde den stilling pitch,
                      roll, yaw pindene st&aring;r i. Et eksempel: Hvis du vil
                      stige 20 grader skal du holde pinden, s&aring; den svarer
                      til en &oslash;nsket v&aelig;rdi p&aring; 20 grader.
                      Modellen vil s&aring; ved hj&aelig;lp af feedback systemet
                      tilstr&aelig;be en stigning p&aring; 20 grader.
                    </p>
                    <p>
                      Bem&aelig;rk modellen bliver stabil i forhold til
                      horisonten, hvis du slipper roll/pitch pinden. Det kan
                      virkelig hj&aelig;lpe begyndere og andre ud af kritiske
                      flyvestillinger.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>3D mode</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Modellen fors&oslash;ger at holde sin aktuelle stilling i
                      luften hvis du slipper roll/pitch pinden. Det g&oslash;res
                      ved at den bev&aelig;ger rorfladerne automatisk.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>GPS</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Global Position System. Amerikansk system til bestemmelse
                      af sted og h&oslash;jde p&aring; jordkloden ved
                      hj&aelig;lp af satellitter.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>RTH mode</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Returen To Home mode betyder at modellen automatisk flyver
                      tilbage til sit udgangspunkt (GPS startkoordinater)
                      n&aring;r nogle bestemte betingelser er opfyldt fx
                      fail-safe.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Fail-Safe mode</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Digitale RC-anl&aelig;g har ofte den facilitet, at hvis
                      2,4GHz forbindelsen mellem senderen og modtageren svigter
                      hentes en fail-safe stilling som bringer rorfladerne i
                      neutral stilling og motoren i tomgang. Modellen kan
                      s&aring; muligvis lande uskadt et eller andet sted.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>AHM</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Altitude Hold Mode. Modellen holder en bestemt
                      flyveh&oslash;jde.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>ESC</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Elektronisk Speed Controller. Fartregulator til
                      b&oslash;rstel&oslash;s motor.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Telemetri</p>
                  </TableCell>
                  <TableCell>
                    <p>Fjernm&aring;ling af data.</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Data Flight Recorder</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Hukommelse som opsamler data fra flyvningen.
                      Popul&aelig;rt omtales den som den sorte box man altid
                      leder efter ved fly ulykker.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>FPV</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      First Personal View. FPV flyvning foreg&aring;r ved, at
                      piloten ser flyet igennem et par videobriller eller en
                      sk&aelig;rm, som modtager billeder og telemetridata fra
                      flyet. Det muligg&oslash;r at man kan flyve s&aring; langt
                      v&aelig;k at man ikke kan se modellen mere, hvilket er
                      ulovligt. Se BL 9-4.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>OSD</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      On Screen Display. Flight data og alarmer som vises oven i
                      videobilledet.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Augmented Reality</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Et system hvor piloten ser ud i virkeligheden, men
                      samtidig ser vigtige flydata p&aring; et gennemsigtigt
                      medie, som fx en brille med en indbygget dataprojektor.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Voice Readout</p>
                  </TableCell>
                  <TableCell>
                    <p>Stemmeudl&aelig;sning af data og alarmer.</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Diversity Rx</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Et system hvor man har flere antenner og modtagere og hvor
                      man kun automatisk anvender det bedste signal.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Firmware</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Det program/software som ligger i Flight Controlleren.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Autonom</p>
                  </TableCell>
                  <TableCell>
                    <p>Selvstyrende.</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Geofence</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Flyet kan kun flyve indenfor en afgr&aelig;nset box
                      (h&oslash;jde og afstand) eller kan ikke flyve ind i
                      u&oslash;nskede omr&aring;der. Indhegningen opn&aring;s
                      med GPS koordinater og omfattende software.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Waypoint</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Pejlem&aelig;rker er i dag ikke l&aelig;ngere et fysisk
                      punkt i terr&aelig;net men er GPS koordinater. De
                      muligg&oslash;r at du kan flyve en bestemt rute, nemlig
                      fra Waypoint til Waypoint.
                    </p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>
              Beskrivelse af et maksimalt udbygget system til fastvinge- og
              multirotorfly
            </h2>
            <p>
              <img src={fig5} />
            </p>
            <ul>
              <li>
                I den ovenst&aring;ende mindmap kan du genfinde et helt
                traditionelt fjernstyringsanl&aelig;g, hvor senderen RC Tx vil
                sende styreinformation til modtageren RC Rx, som derefter styrer
                fastvinge flyets servoer og fartregulator ESC direkte. Den mode
                opn&aring;s ved at feed back gain er 0 % hvorved signalerne
                kobles direkte i gennem Flight Controlleren. Direct Mode
                anvendes til start og landinger.
              </li>
              <li>
                Hvis vi taler om et multirotor fly kan det kun holdes stabilt
                ved at man hele tiden har en aktiv Flight Controller. Man vil
                som minimum modtage input fra den treaksede gyro og det
                treaksede Accelerometer for overhovedet at kunne holde
                multirotor flyet stabilt. Det vil normalt ske i 2Dmode. Flight
                Controlleren kan v&aelig;re bygget ind i produktet.
              </li>
              <li>
                Hvis man vil bestemme et flys position p&aring; jordoverfladen
                bliver man n&oslash;dt til at koble en GPS modtager til Flight
                Controlleren. M&aring;len&oslash;jagtigheden vil h&oslash;jest
                v&aelig;re ca. 6 meter n&aring;r enheden har v&aelig;ret
                t&aelig;ndt nogle minutter og man modtager ca. 10 satellitter.
                N&oslash;jagtigheden afh&aelig;nger st&aelig;rkt af
                satellitternes position og antal. H&oslash;jdebestemmelsen er
                generelt d&aring;rlig. GPS modtageren kan endvidere kun
                m&aring;le kompaskurs, hvis den bev&aelig;ger sig, fordi
                modtageren beregner kursen udfra &aelig;ndringer af
                l&aelig;ngde- og breddegrader. GPS modtageren kan ogs&aring;
                beregne flyets fart i forhold til jorden. (Ground Speed). Der
                udf&oslash;res mellem 1 og 10 m&aring;ling pr. sekund.
              </li>
              <li>
                Hvis man &oslash;nsker at forbedre h&oslash;jdem&aring;lingen,
                kan man med fordel anvende et barometersystem, som
                autocalibrerer p&aring; det aktuelle lufttryk n&aring;r systemet
                t&aelig;ndes og herfor viser h&oslash;jden 0 meter.
                M&aring;len&oslash;jagtigheden er 1 til 2 meter. Barometeret kan
                ogs&aring; bruges som variometer med en max f&oslash;lsomhed
                p&aring; ca. 10cm h&oslash;jdevariation.{" "}
              </li>
              <li>
                Idet GPS-modtageren ikke kan registrere kompaskurs hvis den
                st&aring;r stille, vil det v&aelig;re muligt at m&aring;le
                p&aring; jordens magnetfelt ved hj&aelig;lp af et magnetometer,
                som s&aring; beregner kursen i forhold til nord. Dette er
                s&aelig;rlig aktuelt ved multirotor fly, som jo kan st&aring;
                stille i luften.
              </li>
              <li>
                Endelig kobles alle de input som kommer fra MEMS sensorerne,
                MUIen og GPSen sammen med pilotens input, som dels er
                styresignaler, dels modeinformation. Sidstn&aelig;vnte
                kontrolleres med vippe-kontakter p&aring; RC senderen.{" "}
              </li>
              <li>
                N&aring;r det viste system tages i brug f&oslash;rste gang, skal
                Flight Controllerens output som noget af det f&oslash;rste
                programmeres til den p&aring;g&aelig;ldende flytype. (Se nr. 2
                de r&oslash;de bokse.)
              </li>
              <li>
                Piloten m&aring; hele tiden v&aelig;re helt klar over hvilken
                mode der anvendes (Se nr. 3 de gr&oslash;nne bokse), fordi det
                p&aring; afg&oslash;rende vis bestemmer flyvningen.
              </li>
              <li>
                Telemetrisystemet (Se nr. 5 violette bokse) modtager data fra
                MEMS sensorerne, GPSen samt forskellige elektriske
                m&aring;lev&aelig;rdier (MUI). De data overf&oslash;res dels via
                A/V systemet til piloten i sand tid, samtidig med at de gemmes i
                Data Flight Recorderen, som s&aring; kan udl&aelig;ses efter
                landingen.
              </li>
              <li>
                A/V signalet med telemetri overf&oslash;res fra flyet til jorden
                p&aring; frekvenser i 5,8GHz b&aring;ndet. (Se nr. 4 de gule
                bokse.) N&aring;r man anvender det b&aring;nd er det fordi det
                ville v&aelig;re alt for risikabelt at have en kraftig 2,4GHz
                sender i kort afstand fra flyets 2,4GHz modtager, hvor det
                modtagne signal fra RC senderen kan v&aelig;re meget svagt.
              </li>
              <li>
                I flyet er monteret et fremadrettet videokamera som leverer
                videosignaler til en analog TV sender. A/V senderen
                tilf&oslash;res endvidere A/V telemetridata, som l&aelig;gges
                oven i billedet s&aring; man et f&aring;r et OSD. A/V signalet
                modtages af en analog A/V diversity modtager, som kan levere A/V
                signaler til en sk&aelig;rm eller et par video briller.
                Teknikken i al det er et gammeldags analogt PAL TV-system med en
                b&aring;ndbredde p&aring; ca. 6 MHz. Den store b&aring;ndbredde
                muligg&oslash;r at man kan overf&oslash;re store
                datam&aelig;ngder uden forsinkelse. Til A/V modtageren kan
                tilkobles en A/V recorder. Dette kan v&aelig;re fornuftigt fordi
                man i tilf&aelig;lde af bortflyvninger kan afl&aelig;se de
                sidste GPS koordinater og dermed m&aring;ske finde flyet.
                Antennetrackeren er kun n&oslash;dvendig hvis du flyver langt
                v&aelig;k. Den er en retningsbestemt antenne som automatisk
                rettes mod flyet. Alle bokse med r&oslash;de flag st&aring;r
                p&aring; jorden.
              </li>
              <li>
                En meget vigtig del er systemets software, den er i
                mods&aelig;tning hardwaren ikke synlig i en
                k&oslash;bssituation. Producenten af hardwaren har udviklet
                b&aring;de software og firmware specifikt til det
                p&aring;g&aelig;ldende produkt og det er en v&aelig;sentlig del
                af produktets pris. Hvis du ser p&aring; mindmappen f&aring;r
                Flight Controlleren forbindelse med Windows Computeren gennem et
                USB kabel. F&oslash;lgende funktioner haves:
              </li>
            </ul>
            <ol>
              <li>
                F&oslash;rstegangsops&aelig;tning af hele systemet via den
                p&aring; PCen installerede software.
              </li>
              <li>
                Opdatering af firmwaren for nye funktioner og rettelse af fejl.
              </li>
              <li>
                Download af telemetridata fra Flight Data Recorderen til Flight
                Monitor Programmet s&aring; en detaljeret analyse af flyvningen
                kan foretages.
              </li>
            </ol>
            <h2>Fordele ved det maksimale system</h2>
            <ul>
              <li>
                Med 2D funktionen bliver et fastvingefly ekstremt selvstabilt,
                og kan flyve autonomt. Det betyder, at man ikke beh&oslash;ver
                at v&aelig;re nerv&oslash;s, hvis man flyver langt v&aelig;k og
                ikke kan se modellens pr&aelig;cise stilling i luften. Flyet
                forbliver stabilt i selv voldsom turbulens. Den samlede virkning
                p&aring; modellen er, at en lille model f&oslash;les som en
                meget st&oslash;rre model med stor inerti. Modellen flyver
                stabilt som p&aring; skinner.
              </li>
              <li>
                Med Altitude Hold holder flyet en bestemt flyveh&oslash;jde.
              </li>
              <li>
                Med RTH vender flyet tilbage til GPSens home koordinater, hvis
                en af f&oslash;lgende betingelse er til stede:
                <br />
                Det kan hjemkaldes af piloten med en switch p&aring; senderen
                eller
                <br />
                Modtageren g&aring;r i fail-safe eller
                <br />
                Flyet kommer over en indprogrammet max. h&oslash;jde eller
                <br />
                Flyet kommer uden for en indprogrammet max. afstand fra GPSens
                home koordinater.
                <br /> Flyet kan simpelthen ikke l&aelig;ngere flyve v&aelig;k
                fordi det er hegnet inde. (Geofence)
              </li>
              <li>
                Waypoints kan udg&oslash;re en rute som flyet kan gennemflyve
                autonomt.{" "}
              </li>
              <li>
                Telemetrien frembringer en m&aelig;ngde data, som kan
                afl&aelig;ses eller afgives som alarmer under flyvningen.
              </li>
              <li>
                Systemet kan ogs&aring; dels l&aelig;se forskellige data op
                under flyvningen, som fx forbrugte mA/h, dels afgive lydalarmer.
                Det er s&aelig;rlig smart fordi piloten ikke beh&oslash;ver at
                se p&aring; sin sk&aelig;rm.
              </li>
              <li>
                Data Flight Recorderen, som kan udl&aelig;ses via Flight Monitor
                programmet, kan pr&aelig;sentere data som tal, grafer eller
                tegne ruten ind p&aring; Google Earth.&nbsp;Analyser af
                flyvningen kan give bedre resultater for konkurrence piloter,
                give bedre sikkerhed, forklare utilsigtede h&aelig;ndelser og
                opklare ulykker.
              </li>
              <li>
                GPS-data kan anvendes til at finde bortfl&oslash;jne modeller,
                det kunne jo v&aelig;re at dronen fl&oslash;j v&aelig;k pga af
                en softwarefejl! Data m&aring; s&aring; v&aelig;re telemetreret
                tilbage til jorden og ikke ligge i modellen!
              </li>
              <li>A/V systemet betyder at du kan flyve FPV.</li>
              <li>
                OSD systemet betyder at du f&aring;r et komplet s&aelig;t
                cockpit instrumenter p&aring; din sk&aelig;rm med fart- og
                h&oslash;jdem&aring;ler, kompas, kunstig horisont m.m.
              </li>
            </ul>
            <h2>Pilotens &aelig;ndrede rolle</h2>
            <p>RC flyvningen vil i stigende grad ligne fullsizeflyvning.</p>
            <ul>
              <li>
                Det vil fx v&aelig;re fornuftigt at anvende tjeklister, som
                enten allerede findes i systemets software eller som med fordel
                kan l&aelig;gges ind i en tablet computer.
              </li>
              <li>
                Piloten f&aring;r ogs&aring; en &aelig;ndret rolle fordi
                flyvningen finder sted som et samspil mellem pilot og et
                kompliceret system.
              </li>
              <li>
                Anvendelsen af stabiliseringssystemet medf&oslash;rer
                desv&aelig;rre ogs&aring; tab af basale flyvef&aelig;rdigheder,
                noget som ogs&aring; pr&aelig;ger luftfartsindustrien, i form af
                nye typer ulykker n&aring;r automatikken fejler.
              </li>
              <li>
                Der vil ogs&aring; tit v&aelig;re en &rdquo;andenpilot&rdquo;
                til at afl&aelig;se sk&aelig;rme og give anden assistance, noget
                som BL 9-4 kr&aelig;ver ved FPV.
              </li>
              <li>
                En anden parallel er at flyvninger kan analyseres ganske
                n&oslash;je ved at se p&aring; de data som ligger i Flight
                Recorderen.
              </li>
            </ul>
            <h2>Hvad skal man dog mene om al den RC teknologi?</h2>
            <p>
              Da bilen blev opfundet, m&aring;tte den kun k&oslash;re 5 km i
              timen og der skulle g&aring; en mand med et r&oslash;dt flag
              foran. Hvordan er det i dag og hvordan ser det ud om bare 3
              &aring;r? Alts&aring;, vi kan nok ikke undg&aring; udviklingen. 35
              Mhz er n&aelig;sten v&aelig;k, der er telemetri, tale og
              stabiliser funktioner indbygget i mange RC-anl&aelig;g. Du er
              m&aring;ske ogs&aring; allerede ejer af en tablet computer, en GPS
              til bilen, en smart phone og et digitalt kamera, alle apparater
              som anvender samme teknologier som beskrevet her i artiklen. Det
              skal ogs&aring; n&aelig;vnes at mange af de ting jeg har beskrevet
              her ogs&aring; vil g&oslash;re sig g&aelig;ldende, i de nye biler
              som er p&aring; vej. Lad mig n&aelig;vne automatisk nedbremsning,
              vognbane assistenter, detektering af cyklister ved h&oslash;jre
              sving, black box recorder m.m. M&aring;let er at bilen kan
              g&oslash;res f&oslash;rerl&oslash;s, ligesom Googlecar, vent og
              se! Hele den globale bilindustri er under kraftig
              omv&aelig;ltning. Det samme g&aelig;lder vores RC teknologi!{" "}
            </p>
            <h2>N&aelig;ste innovationsspring</h2>
            <p>
              Et af de problemer piloten har, er at han skal kunne se to steder
              p&aring; en gang. En F16 pilot kan se ud ad cockpitruden, samtidig
              med at der projekteres flydata op p&aring; ruden, et s&aring;kaldt
              head up display. Piloten beh&oslash;ver ikke l&aelig;ngere at
              flytte &oslash;jnene. Modelpiloten eftersp&oslash;rger et
              tilsvarende system, hvor piloten ser ud i virkeligheden, men
              samtidig ser vigtige flydata p&aring; et gennemsigtigt medie, som
              fx en brille med en indbygget dataprojektor, vi taler om Augmented
              Reality. En r&aelig;kke firmaer som Google og Microsoft m.fl.
              udvikler i &oslash;jeblikket Augmented Reality produkter. AR
              brillerne skal nok komme.
            </p>
            <p>
              Kunne vores modelfly lande automatisk? Ja, hvis man sk&aelig;rper
              kravet til at flyet ved n&oslash;jagtig, hvor det er i forhold til
              landingsbanens placering. En kommende hj&aelig;lp kunne v&aelig;re
              det nye europ&aelig;iske satellitnavigationssystem (GNSS) med
              navnet&nbsp;Galileo,&nbsp;som er under udvikling af EU og European
              Space Agency (ESA). Projektet omfatter opsendelsen af ca. 30
              satellitter til en pris af 5,4&nbsp;milliarder euro og forventes
              klar i 2019. Det vil kunne bruges af alle og dermed erstatte vores
              kendte amerikanske GPS system. Den forventede n&oslash;jagtighed
              til almindelige brugere vil v&aelig;re p&aring; ca. 1 meter og for
              betalende brugere omkring f&aring; centimeter.&nbsp;
            </p>
            <p>
              Med den store navigationsn&oslash;jagtighed og kraftig software
              vil det ogs&aring; v&aelig;re muligt at kunne s&aelig;tte et
              geofence op for flyet. Man kan hegne flyet b&aring;de inde og ude.
              Man kan hegne det inde, s&aring; det flyver i et afgr&aelig;nset
              rum bestemt af h&oslash;jde og afstand, s&aring; det ikke flyver
              v&aelig;k. Man kan ogs&aring; forhindre det i at flyve ind i
              forbudte omr&aring;der, som fx lufthavne eller over byer.{" "}
            </p>
            <p>
              At droner ikke l&aelig;ngere kun er en hobby, men udg&oslash;r et
              kraftigt innovationsspring skal ses i lyset af at v&aelig;ksten i
              droneindustrien er p&aring; omkring 35 % om &aring;ret med en
              global markedsv&aelig;rdi p&aring; mellem 1,7 og 2,5 mia. dollars.
              Dronerne kan anvendes til landbrug, byggebranchen, energisektoren,
              filmoptagelser, ved katastrofer og til overv&aring;gning m.m. De
              skal kunne kommunikere med hinanden, s&aring; de dels ikke
              st&oslash;der sammen, dels kunne samarbejde om l&oslash;sningen af
              en f&aelig;lles opgave. Det vil ogs&aring; v&aelig;re
              n&aelig;rliggende at de kobles op p&aring; internettet. For at al
              det kan lade sig g&oslash;re kr&aelig;ves uh&oslash;rt kraftig
              software, hvor der nok skal v&aelig;re nogle fejl. Al den
              udvikling vil givetvis smitte af p&aring; vores hobby.
            </p>
            <h2>Eagle Tree Vector</h2>
            <p>
              Efter de rigtig gode erfaringer jeg har med tre stk. Eagle Tree
              Guardian stabilisere, har jeg valgt at afpr&oslash;ve og beskrive
              Eagle Tree Vector i den n&aelig;ste artikel i et fastvinge fly.
              Vector bygger p&aring; firmaets erfaring med telemetri og
              stabiliserprodukter. Vektor systemet indeholder Flight
              Controlleren, MEMS sensorerne, Flight Datarecorderen, en MUI, samt
              str&oslash;mforsyning til A/V sender og camera. Hvis du vil
              unders&oslash;ge softwaren er det muligt installere den til
              gennemsyn uden f&oslash;rst at have k&oslash;bt produktet, ligesom
              det kan anbefales at skimme den 89 sider lange engelske manual i
              gennem.
            </p>
            <p>
              Se:&nbsp;
              <Link
                href="http://www.eagletreesystems.com/index.php?route=information/information&information_id=12"
                target="_blank"
              >
                http://www.eagletreesystems.com/index.php?route=information/information&information_id=12
              </Link>
            </p>
            <p>
              Produkterne kan k&oslash;bes i RC-netbutik. Se&nbsp;
              <Link
                href="http://www.rc-netbutik.dk/group.asp?group=217"
                target="_blank"
              >
                http://www.rc-netbutik.dk/group.asp?group=217
              </Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
