import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig1.png";
import fig2 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig2.jpg";
import fig3 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig3.jpg";
import fig4 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig4.jpg";
import fig5 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig5.jpg";
import fig6 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig6.png";
import fig7 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig7.jpg";
import fig8 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig8.jpg";
import fig9 from "../../../images/FlyByWire/FlyByWireFlyvningMedEagleTreeVector/fig9.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Fly By Wire flyvning med Eagle Tree Vector</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Fly By Wire flyvning med Eagle Tree Vector</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret maj 2015</p>
            <p>
              Du kan med stor fordel l&aelig;se den foreg&aring;ende artikel med
              titlen &rdquo;Udviklingstendenser i RC teknologierne&rdquo; fordi
              den dels giver dig et overblik over systemerne samt forklarer de
              grundl&aelig;ggende termer, som anvendes. Artiklen kan findes i
              Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt2.dk/modelflyvenyt2/2015/Modelflyvenyt.2.2015.pdf"
              >
                Nr 2 2015
              </Link>{" "}
              eller p&aring; f&oslash;lgende link:&nbsp;
              <Link
                href="https://elflytec.dk/FlyByWire/Udviklingstendenser-i-RC-teknologierne"
                target="_blank"
              >
                Udviklingstendenser i RC teknologierne
              </Link>
            </p>
            <h2>Hvad kan Eagle Tree Vector systemet?</h2>
            <ul>
              <li>
                Med 2D funktionen bliver et fastvingefly ekstremt selvstabilt og
                kan flyve autonomt.
              </li>
              <li>
                Med Altitude Hold kan flyet holde en bestemt flyveh&oslash;jde.
              </li>
              <li>Med GPS- og magnetkompas kan flyet holde en kompaskurs.</li>
              <li>
                Med RTH vender flyet autonomt tilbage til GPSens home
                koordinater og kan s&aring;ledes ikke flyve v&aelig;k.{" "}
              </li>
              <li>
                Flyet kan flyve autonomt rundt om en GPS-koordinat i Loiter
                Mode.&nbsp;
              </li>
              <li>
                Telemetrien frembringer en m&aelig;ngde data, som kan
                afl&aelig;ses eller afgives som alarmer under flyvningen.
              </li>
              <li>
                Data Flight Recorderen, som kan udl&aelig;ses via Flight Monitor
                programmet, kan pr&aelig;sentere data som tal, grafer eller
                tegne ruten ind p&aring; Google Earth.&nbsp;Analyser af
                flyvningen kan give bedre sikkerhed, forklare utilsigtede
                h&aelig;ndelser og opklare ulykker.
              </li>
              <li>
                GPS-data kan anvendes til at finde bortfl&oslash;jne modeller.
              </li>
              <li>
                A/V systemet betyder, at du kan flyve FPV. OSD systemet betyder,
                at du f&aring;r et komplet s&aelig;t cockpit instrumenter
                p&aring; din sk&aelig;rm med fart- og h&oslash;jdem&aring;ler,
                kompas, kunstig horisont m.m.
              </li>
            </ul>
            <h2>Hardware indk&oslash;b</h2>
            <p>
              Lad mig starte med indk&oslash;bet af hardwaren: Eagle Tree Vektor
              pakken indeholder hovedsystemet, en dobbelt str&oslash;mforsyning
              PSU (Power Supply Unit) med MUI samt kombineret GPS og
              Magnetometer. Alle ledninger medf&oslash;lger. Derudover
              tilk&oslash;bes et video kamera, en 5,8GHz video TX, samt en
              airspeedm&aring;ler. Al dette til montage i flyet. P&aring; jorden
              anvender jeg en Futaba T9CP sender med et 2,4GHz Jeti Tx modul og
              en LCD sk&aelig;rm med en indbygget diversty 5,8Ghz Rx. Du vil
              kunne anvende alle fabrikater 2,4GHz RC sendere med minimum 6
              kanaler ogs&aring; Futaba anl&aelig;g med S-bus. Alt er
              k&oslash;bt i RC-Netbutik, hvor du kan finde priserne.
            </p>
            <h2>Systembeskrivelsen</h2>
            <p>
              For at lette forst&aring;elsen af hvordan Fly By Wire Vector
              systemet virker, vil jeg beskrive hvordan det er opkoblet i mit
              tomotorede fastvinge fly. Se fig. 1. Selve controlleren indeholder
              &oslash;verst 3 sensor systemer. Den treaksede gyro sikrer
              referencen til flyets orientering i forhold til horisonten samt
              yaw aksen. Det treaksede accelerometer registrerer alle flyets
              bev&aelig;gelser, mens barometeret giver en n&oslash;jagtig
              h&oslash;jdem&aring;ling i forholdt til jorden, idet barometeret
              autocalibrerer p&aring; luftens tryk i det &oslash;jeblik systemet
              t&aelig;ndes. Controlleren tilsluttes en GPS modtager med
              indbygget magnet kompas, via en seriel databus. Til GPSen er
              yderligere tilsluttet en air speed m&aring;ler med
              pitotr&oslash;r. Denne enhed tilk&oslash;bes som ekstra udstyr.
              Bem&aelig;rk at der kan tilsluttes flere enheder s&aring;som en
              RPM m&aring;ler, efter det s&aring;kaldte daisy chain princip. De
              bl&aring; forbindelser er serielle data busser. Ved siden af
              barometeret sidder et termometer, som m&aring;ler temperaturen
              internt i controlleren. Den almindelige 2,4GHx modtager er
              tilkoblet i venstre side. P&aring; oversiden findes en to farvet
              lysdiode, hvis blinksekvenser viser kontrollerens status.{" "}
            </p>
            <p>
              Den gr&oslash;nne kasse med de to kanaler bestemmer systemets mode
              v. hj. af 2 tre stillings kontakter p&aring; senderens kanal 5 og
              6. Den f&oslash;rste kontakts tredje stilling tilkobler den anden
              kontakt, og derved kan opn&aring;s i alt max 5 forskellige modes.
              Jeg har dog valgt kun at anvende 3 modes samt variabel feedback
              gain. Nederst i systemet haves den aktuelle firmware, som er
              downloadet via USB stikket. Til h&oslash;jre for dette ses en
              avanceret Flight Data Recorder, som optager 48 forskellige
              parametre under flyvningen. Udover dette optages notifikationer,
              som l&oslash;bende beskriver systemets status. Se senere.
            </p>
            <p>
              Alle de gule kasser tilh&oslash;rer A/V og OSD systemet. Flyets
              farve kamera leverer et video signal til controlleren. Oven i
              dette signal mixes alle telemetri data sammen og tilf&oslash;res
              derefter en 5,8GHz video sender. Det down streamede signal
              modtages af to 5,8GHz diversity modtagere, som er indbygget i LCD
              sk&aelig;rmen.&nbsp;
            </p>
            <p>
              Str&oslash;mforsyningen varetages af et Li-Po batteri p&aring; 3S
              4000mA/timer, som via Vector Power Supply Unitet forsyner hele
              flyet. PSUen indeholder en MUI, som m&aring;ler str&oslash;mmen,
              sp&aelig;ndingen og det samlede str&oslash;mforbrug og leverer
              dermed data til telemetri- og displaysystemet via en databus.
              PSUen indeholder to PSUer p&aring; 12V/1A, som leverer
              forsyningssp&aelig;nding til kameraet og videosenderen. P&aring;
              h&oslash;jre side af str&oslash;mm&aring;leren udtages
              batterisp&aelig;ndingen til en Dualsky Switch Mode Power Supply,
              som leverer 5V til modtageren og controlleren med tilkoblede
              enheder herunder servoer. Drivlinien best&aring;r af to
              parallelforbundne ESCer, som betjener hver sin
              b&oslash;rstel&oslash;se motor. Bem&aelig;rk at der er monteret en
              sikkerhedskontakt, som kan afbryde motorerne, n&aring;r flyet er
              p&aring; jorden. &Aring;rsagen er, at der kan opst&aring;
              programfejl, som kan starte motorerne utilsigtet.
            </p>
            <p>
              <img src={fig1} />
              <br />
              Fig. 1. System diagram.
            </p>
            <h2>Software, firmware og manual</h2>
            <p>
              P&aring; grund af den eksponentielle digitale v&aelig;kst er det
              n&oslash;dvendigt altid at anvende sidste udgave af software,
              firmware og manual. Da jeg k&oslash;bte systemet i december 2014,
              hed softwaren 11.48 og firmwaren 1.31. Tre m&aring;neder senere
              hedder den 11.70 og 1.73, ligesom manualen er opdateret. Disse
              &aelig;ndringer styres af Eagle Trees serveren. Bem&aelig;rk at
              sk&aelig;rmbilledet fig. 2 indeholder versionsnummeret nede i
              venstre hj&oslash;rne p&aring; aktuelle software, og at der er 2
              faneblade til henholdsvis firmware opdatering af controlleren og
              manualen. Nu er du i besiddelse af nyeste udgave af manualen, som
              du vil blive n&oslash;dt til at l&aelig;se og forst&aring;.
            </p>
            <h2>Praktisk montage</h2>
            <p>
              I f&oslash;rste omgang har jeg monteret det hele op p&aring; en
              lang liste uden at forkorte ledninger for at have samling p&aring;
              enhederne. Se billede 1. Senere blev det hele tilpasset flyet,
              hvor Controlleren, PSUen, 2,4GHz modtageren og 5V power supplyen
              blev monteret p&aring; en plade, som kan udtages som en samlet
              enhed. Til denne enhed tilsluttes s&aring; de &oslash;vrige
              komponenter, som sidder rundt om i flyet. Controlleren skal sidde
              fast i flyets tyngdepunkt, fordi Gyroer og accelerometre skal
              m&aring;le her. Du skal kunne tilslutte et USB kabel fra siden
              p&aring; modellen.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Billede 1. Fra venstre mod h&oslash;jre ses: Video kamera, GPS,
              Li-Po batteri, PSU med MUI, 5V PSU, Controlleren, 6 kanal Jeti RC
              modtager og en 5,8Ghz Video sender.
            </p>
            <h2>F&oslash;rste gangs ops&aelig;tning</h2>
            <ol>
              <li>Opdater firmware via USB.</li>
              <li>
                Klarg&oslash;r din sender og modtager i sine grundindstillinger.
                Der m&aring; ikke v&aelig;re mix, dual rate eller andre
                specielle indstillinger p&aring; dine 4 kanaler
                tilh&oslash;rende de 2 styrepinde. RC anl&aelig;gget skal virke
                normalt p&aring; alle kanaler. Der skal indf&oslash;res
                failsafe, hvis det er muligt. I Jeti systemet g&oslash;res dette
                i modtageren.
              </li>
              <li>
                V&aelig;lg flytypen. Der kan her v&aelig;lges mellem 4
                forskellige fastvinge flytyper og 8 forskellige multirotor fly.
              </li>
              <li>
                Tilslut Softwaren til Vector controlleren via USB kablet.
                Tilf&oslash;r systemet forsyningssp&aelig;nding og t&aelig;nd
                senderen og modtageren. Nu skal systemet l&aelig;re at kende din
                sender at kende, samtidig med at du v&aelig;lger at konfigurere
                3 stillings mode kontakten og feedback gain. Se fig. 2&acute;s
                nederste 1/3 af sk&aelig;rmbilledet &ldquo;Receiver Input
                Monitor and Switch Mapping&rdquo;. Jeg har p&aring; mode
                switchen valgt 2D+Alt Hold, Loiter og RTH test. Sub mode
                switchen er ikke aktiv, men den 6. kanal anvendes til gain
                kontrol med skydefunktionen, som sidder p&aring; siden af
                senderen. N&aring;r det hele er sat op, kan du kontrollere, at
                det hele virker ved at bev&aelig;ge pindene og modekontakten.
              </li>
              <li>
                Som det n&aelig;ste ops&aelig;ttes hvor f&oslash;lsom
                reguleringen skal v&aelig;re samt nulstillingen af den kunstige
                horisont. Ved nulstillingen skal flyet klodses op til at
                v&aelig;re absolut vandret og i ro. Ved denne nulstilling af den
                treaksede gyro indl&aelig;ses den &oslash;nskede v&aelig;rdi for
                2D flyvningen i feedback systemet.
              </li>
              <li>
                P&aring; n&aelig;ste faneblad bestemmes data omkring GPS
                modtagerens, tilvalg og kalibrering af magnetometeret.
              </li>
              <li>
                P&aring; det sidste faneblad ops&aelig;ttes Geofence,
                alts&aring; hvor langt v&aelig;k flyet m&aring; komme inden det
                vender tilbage til home pointet, flyveh&oslash;jden p&aring;
                retur flyvningen samt dreje diameteren n&aring;r flyet
                overflyver homepointet.
              </li>
            </ol>
            <p>
              Nu er ops&aelig;tningen foretaget, og du skal nu skifte til OSD
              set up. Se menu tr&aelig;et til venstre p&aring; sk&aelig;rmen.
              Det er muligt at opbygge sk&aelig;rmen fuldst&aelig;ndig, som det
              passer dig. Det vil f&oslash;re for vidt at beskrive de meget
              omfattende OSD menuer, men p&aring; fig. 3 ses den ops&aelig;tning
              jeg har valgt.
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig. 2. Eagle Tree Vektors start sk&aelig;rm.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig. 3 Video sk&aelig;rm med telemetri data. Data l&aelig;gges
              oven i billedet s&aring;kaldt On Screen Display. Man kan blandt
              andet afl&aelig;se batterisp&aelig;ndingen p&aring; flybatteriet,
              det vigtige milliamperetimeforbrug og str&oslash;mforbruget. Der
              findes en airspeedm&aring;ler og en h&oslash;jdem&aring;ler samt
              afstand fra homepoint. Derudover haves et kompas, GPS koordinater
              samt en kunstig horisont.
            </p>
            <h2>Modellen</h2>
            <p>
              Som model er anvendt forfatterens E-BUS, en 18 &Aring;r gammel
              egen konstruktion, som nu har gennemg&aring;et en opdatering af al
              elektronikken. V&aelig;gten er 2200g hvilket sammen med en
              planbelastning p&aring; 36g/dm2 giver en s&aelig;rdeles langsom og
              velflyvende testplatform for FBW flyvningen. For at kunne foretage
              programmeringen er det n&oslash;dvendigt at kende modellens data,
              som dels er beregnet dels m&aring;lt. Se skema.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Billede 2. Viser min E-BUS. I baggrunden ses LCD sk&aelig;rmen.
            </p>
            <p>Skema for flydata.</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Flyvesekvens</p>
                  </TableCell>
                  <TableCell>
                    <p>Flight mode</p>
                  </TableCell>
                  <TableCell>
                    <p>Stig</p>
                    <p>Synk</p>
                  </TableCell>
                  <TableCell>
                    <p>Airspeed</p>
                    <p>&nbsp;Km/t</p>
                  </TableCell>
                  <TableCell>
                    <p>Airspeed</p>
                    <p>&nbsp;m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor</p>
                    <p>str&oslash;m</p>
                  </TableCell>
                  <TableCell>
                    <p>Throthle %</p>
                  </TableCell>
                  <TableCell>
                    <p>H&oslash;jderor</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Climb</p>
                  </TableCell>
                  <TableCell>
                    <p>2D</p>
                  </TableCell>
                  <TableCell>
                    <p>5m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>48 km/t</p>
                  </TableCell>
                  <TableCell>
                    <p>13,3m/s</p>
                  </TableCell>
                  <TableCell>
                    <p>28A</p>
                  </TableCell>
                  <TableCell>
                    <p>92%</p>
                  </TableCell>
                  <TableCell>
                    <p>Opad ror</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max speed</p>
                  </TableCell>
                  <TableCell>
                    <p>2D+H Alt</p>
                  </TableCell>
                  <TableCell>
                    <p>0m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>60 km/t</p>
                  </TableCell>
                  <TableCell>
                    <p>16,6m/s</p>
                  </TableCell>
                  <TableCell>
                    <p>28 A</p>
                  </TableCell>
                  <TableCell>
                    <p>92%</p>
                  </TableCell>
                  <TableCell>
                    <p>0 grader</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Cruise</p>
                  </TableCell>
                  <TableCell>
                    <p>2D+H Alt</p>
                  </TableCell>
                  <TableCell>
                    <p>0m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>43 km/t</p>
                  </TableCell>
                  <TableCell>
                    <p>11,9m/s</p>
                  </TableCell>
                  <TableCell>
                    <p>11 A</p>
                  </TableCell>
                  <TableCell>
                    <p>66%</p>
                  </TableCell>
                  <TableCell>
                    <p>0 grader</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Cruise</p>
                  </TableCell>
                  <TableCell>
                    <p>2D</p>
                  </TableCell>
                  <TableCell>
                    <p>* se 1</p>
                  </TableCell>
                  <TableCell>
                    <p>36 km/t</p>
                  </TableCell>
                  <TableCell>
                    <p>10m/s</p>
                  </TableCell>
                  <TableCell>
                    <p>7,9 A*</p>
                  </TableCell>
                  <TableCell>
                    <p>58%</p>
                  </TableCell>
                  <TableCell>
                    <p>0 grader</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Decent</p>
                  </TableCell>
                  <TableCell>
                    <p>2D</p>
                  </TableCell>
                  <TableCell>
                    <p>1,5m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>28km/t</p>
                  </TableCell>
                  <TableCell>
                    <p>7,7m/s</p>
                  </TableCell>
                  <TableCell>
                    <p>0 A</p>
                  </TableCell>
                  <TableCell>
                    <p>0%</p>
                  </TableCell>
                  <TableCell>
                    <p>0 grader</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Stall</p>
                  </TableCell>
                  <TableCell>
                    <p>2D</p>
                  </TableCell>
                  <TableCell>
                    <p>---------</p>
                  </TableCell>
                  <TableCell>
                    <p>20km/t</p>
                  </TableCell>
                  <TableCell>
                    <p>5,5m/s</p>
                  </TableCell>
                  <TableCell>
                    <p>0 A</p>
                  </TableCell>
                  <TableCell>
                    <p>0%</p>
                  </TableCell>
                  <TableCell>
                    <p>Opad ror</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              &nbsp;*1 Hvis str&oslash;mmen kommer under 8 A udkobler Vektor
              automatisk h&oslash;jdestabiliseringen for at modellen ikke
              staller.
            </p>
            <h2>OSD programmering</h2>
            <p>
              F&oslash;rstegangsops&aelig;tningen blev foretaget med en Windows
              PC, og man kunne s&aring; tro, at det var n&oslash;dvendigt at
              have en b&aelig;rbar computer med ud i marken for at foretage
              &aelig;ndringer i firmwaren. Dette er imidlertid ikke
              n&oslash;dvendigt, fordi alle menupunkter er tilg&aelig;ngelige
              via din RC sender. Det virker p&aring; den m&aring;de, at du
              vipper 2 gange p&aring; mode kontakten, som s&aring; aktiverer en
              startmenu p&aring; sk&aelig;rmen. Du kan nu med din Pitch Roll
              stik bev&aelig;ge dig rundt i menuerne og indl&aelig;se nye data.
              Det er fint t&aelig;nkt af Eagle Tree, fordi du bliver
              uafh&aelig;ngig af PC hj&aelig;lp ude i marken. De
              &aelig;ndringer, du foretager i firmwaren, bliver automatisk
              synkroniseret med softwaren i Windows maskinen.
            </p>
            <h2>FBW flyvning</h2>
            <p>
              Jeg vil nu beskrive en FBW flyvning (se fig. 4.) som p&aring;
              mange punkter adskiller sig fra hvordan jeg hidtil har styret
              RC-fly. Alle de bl&aring; m&aring;l i luftrummet er
              forprogrammeret i controllerens firmware. Jeg starter med at
              gennemg&aring; checklisten inden f&oslash;rste flyvning med blandt
              andet f&oslash;lgende punkter:
            </p>
            <ol>
              <li>Indl&aelig;sning af drivbatteriets faktiske kapasitet.</li>
              <li>Reset af flight recorderens hukommelse.</li>
              <li>
                Reset af Home Point, som samtidig nulstiller
                h&oslash;jdem&aring;leren efter det faktiske lufttryk.
              </li>
              <li>Gasstick p&aring; nul. </li>
              <li>Mode kontakt i 2D og feedback gain p&aring; 100 %</li>
              <li>
                Bev&aelig;gelse af h&oslash;jderor og ballanceklapper. P&aring;
                dette tidspunkt skal modellen vende den rigtige vej p&aring;
                startbanen.
              </li>
              <li>Kontrol af Controllerens LED indikator.</li>
              <li>Kontrol af GPS forbindelsen (SAT FIX).</li>
              <li>Tilkobling af sikkerhedskontakten til motorerne.</li>
            </ol>
            <p>
              <strong>
                Start og climb
                <br />
              </strong>
              Som du kan se starter jeg i 2D + alt hold mode med fuld gas,
              hvilket betyder, at n&aring;r jeg roterer for enden af startbanen,
              skal jeg holde h&oslash;jderorspinden fast i en stilling, som
              svarer til en stigning p&aring; fx. 20 grader helt op til den
              &oslash;nskede toph&oslash;jde. Hvis pinden slippes, flyver
              modellen &oslash;jeblikkelig vandret p&aring; grund af
              gyrostabiliseringen, og flyet vil nu automatisk konstant holde sin
              flyveh&oslash;jde, indtil jeg m&aring;ske tildeler en ny
              flyveh&oslash;jde ved hj&aelig;lp af h&oslash;jderoret.
            </p>
            <p>
              <strong>
                Cruise
                <br />
              </strong>
              Gaspinden justeres til 11A Pindene slippes! Modellen flyver nu en
              konstant kompaskurs med konstant h&oslash;jde. Airspeed er
              12m/sek. Groundspeed afh&aelig;nger af vinden. Bem&aelig;rk
              stadig, at hvis en ny flyveh&oslash;jde &oslash;nskes,
              tr&aelig;kkes i h&oslash;jderoret. N&aring;r h&oslash;jderoret
              slippes, l&aring;ses flyveh&oslash;jden. Hvis man mindsker gassen,
              kan flyet naturligvis ikke holde sin flyveh&oslash;je, og man kan
              l&oslash;be den risiko, at flyet kommer uden for sin flight
              envelope og staller. Dette undg&aring;r systemet ved at udkoble
              den automatiske h&oslash;jderegulering, hvis motorstr&oslash;mmen
              kommer under en programmeret v&aelig;rdi p&aring; 8A, som jeg har
              lagt ind.{" "}
            </p>
            <p>
              <strong>
                Loiter
                <br />
              </strong>
              Modekontakt skiftes til Loiter. I det &oslash;jeblik kontakten
              skiftes, indl&aelig;ses der stedets GPS koordinater, som flyet
              s&aring; automatisk flyver rundt om. I modvindssvinget vil
              groundspeed falde. Hvis groundspeed kommer under 6m/sek m&aring;lt
              af GPSen, vil gassen stige automatisk til 30A ellers ville det
              ikke v&aelig;re muligt at kunne flyve rundt om GPS punktet,
              n&aring;r det bl&aelig;ser. Nu skiftes s&aring; tilbage til 2D +
              Alt. Hold. Modellen flyver nu videre lige ud.
            </p>
            <p>
              <strong>
                Return To Home
                <br />
              </strong>
              N&aring;rderflyves igennem GPS begr&aelig;nsningen 250m v&aelig;k
              fra Home Point, vil systemet automatisk udl&oslash;se sin RTH
              mode, hvor det ubetinget af sig selv vil flyve tilbage mod Home
              Pointet. Da du altid kan overrule controlleren med pindene, har du
              er en farlig situation, hvis du ikke forst&aring;r, hvem det er
              der bestemmer. Du kan tvinge flyet til at flyve l&aelig;ngere
              v&aelig;k samtidig med, at du hurtig kommer s&aring; langt
              v&aelig;k, at det er sv&aelig;rt at se, hvad der sker. S&aring;
              fingerne v&aelig;k fra pindene og stol p&aring; teknologien! Du
              ser alts&aring;, at nogle gange skifter du mode, andre gange
              skifter systemet selv mode! N&aring;r modellen er udenfor GPS
              hegnet, kan man fornuftigvis ikke fjerne gyrostabiliseringen, og
              hvis satellit forbindelsen skulle svigte, vil gassen g&aring; i
              nul og modellen lande et eller andet sted. Under RTH flyvningen
              vil h&oslash;jden blive reduceret til 40 m, n&aring;r modellen
              kommer indtil 100 m fra Home Pointet. Da et fastvingefly ikke kan
              holde stille, vil det overflyve Home Pointet og begynde at cirkle
              rundt. Inden det sker, griber jeg ind og begynder at styre flyet.
              &Aring;rsagen er, at jeg ikke &oslash;nske at lave autonom
              overflyvning af pilotfelt, biler og klubhus. F&oslash;rste gang
              jeg betjener pindene indenfor GPS hegnet, bringes det ud af RTH
              mode. Man kan v&aelig;lge at l&aelig;gge Home Point et stykke
              v&aelig;k fra pilotfeltet, men det er ikke s&aring; vigtigt. Det
              centrale er, at flyet selv vender om, hvis man er ved at flyve
              v&aelig;k. Hvis groundspeed bliver under 6m/sek, &oslash;ger
              kontrolleren automatisk til fuld speed for at modvirke modvinden.
              Det kan naturligvis bl&aelig;se s&aring; meget, at modellen ikke
              kan komme hjem.
            </p>
            <p>
              <strong>
                Landingen
                <br />
              </strong>
              Landingsrunden p&aring;begyndes i 2D mode. Gassen mindskes til 3A.
              Synkehastigheden er programmeret til 2m/sek med en airspeed
              p&aring; 25km i timen. Modellen holdes inden for sin flight
              envelope p&aring; grund af gyrostabiliseringen. Pas altid p&aring;
              medvindssvinget. L&aelig;g indflyvningen til banen lidt for lavt
              og underst&oslash;t med lidt gas. Ved en airspeed p&aring; 18 til
              20km staller modellen.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig. 4. Figuren skal vise en FBW flyvning. De bl&aring; data er
              lagt ind i controllerens firmware. De r&oslash;de notater viser
              hvor jeg eller systemet skifter flight mode. P&aring; fig. 5 ses
              de optagne data fra et fors&oslash;g p&aring; at flyve som vist
              p&aring; figuren.
            </p>
            <h2>Data Flight Recorder</h2>
            <p>
              Eagle Tree Vector indeholder en avanceret Data Flight Recorder,
              som kan logge f&oslash;lgende data:
            </p>
            <ul>
              <li>
                Alt hvad piloten foretager sig med senderen registreres og kan
                ikke bortforklares senere!!!
              </li>
              <li>De servostillinger som controlleren beregner.</li>
              <li>Flyets n&oslash;jagtige stilling omkring de tre akser.</li>
              <li>Flyets g-p&aring;virkninger.</li>
              <li>
                Elektriske st&oslash;rrelser som str&oslash;mforbrug, mA/h
                forbrug, 4 forskellige forsyningssp&aelig;ndinger.
              </li>
              <li>Temperatur.</li>
              <li>N&oslash;jagtig h&oslash;jde m&aring;ling.</li>
              <li>GPS- og Airspeed m&aring;ling.</li>
              <li>GPS koordinater.</li>
              <li>Antal modtagne satellitter.</li>
              <li>Kompaskurs.</li>
              <li>Afstand fra pilot/homepoint.&nbsp;</li>
              <li>
                Logger forskellige notifikationer dvs. notater om controllerens
                status.
              </li>
              <li>Der ud over optegnes flyvningen i Google Earth.</li>
            </ul>
            <p>
              Recorderens hukommelse er p&aring; 2Gbyte og har f&oslash;lgende
              kapacitet:&nbsp;
            </p>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Log hastighed</p>
                  </TableCell>
                  <TableCell>
                    <p>1Hz</p>
                  </TableCell>
                  <TableCell>
                    <p>2Hz</p>
                  </TableCell>
                  <TableCell>
                    <p>5Hz</p>
                  </TableCell>
                  <TableCell>
                    <p>10Hz</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Log tid</p>
                  </TableCell>
                  <TableCell>
                    <p>500min</p>
                  </TableCell>
                  <TableCell>
                    <p>250min</p>
                  </TableCell>
                  <TableCell>
                    <p>100min</p>
                  </TableCell>
                  <TableCell>
                    <p>50min</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Logning af den flyvning som er vist p&aring; fig. 4</h2>
            <p>
              <img src={fig7} />
              <br />
              Fig. 5. Logning af den flyvning som er vist p&aring; fig. 4.
            </p>
            <p>
              Toph&oslash;jden 64 m opn&aring;s ved tiden 200 sek. derefter
              betjenes h&oslash;jderoret ikke forel&oslash;big. Lige f&oslash;r
              tiden 210 sek. skifter jeg til Loiter mode, bem&aelig;rk
              notifikationen. Flyet cirkler nu automatisk rundt 3 gange med en
              diameter p&aring; ca. 60 meter og en h&oslash;jde p&aring; mellem
              60 og 64 meter. Ved tiden 264 sek. ses en ny notifikation, en
              lille gr&oslash;n firkant, nu skifter jeg tilbage til 2D og Alt
              Hold. Flyet forts&aelig;tter nu med at flyve videre lige ud i 64
              m, indtil GPS hegnet rammes ved 250 m fra Home Pointet,
              bem&aelig;rk notifikationerne p&aring; X-aksen, RTH mode
              udl&oslash;ses nu helt automatisk. Flyet n&aring;r at komme godt
              40 meter yderligere v&aelig;k, men er derefter i gang med en
              automatisk tilbageflyvning, som ender i 34 meter h&oslash;jde og
              en tilsvarende afstand fra Home Pointet. P&aring; dette tidspunkt
              griber jeg ind, fordi jeg ikke m&aring; overflyve pilotfelt m.m.
              Afslutningsvis p&aring;begyndes en manuel styret indflyvning til
              landingen. Bem&aelig;rk den gule notifikation lige f&oslash;r 330
              sek. Den viser, at den automatiske fastholdelse af h&oslash;jden
              ikke l&aelig;ngere er mulig, fordi motorstr&oslash;mmen er under
              8A. Bem&aelig;rk at al det som er fl&oslash;jet, bortset fra stig
              sekvensen og landingen, er gennemf&oslash;rt uden at jeg har
              r&oslash;rt pindene! Det er klart, at piloten mister sine basale
              flyvef&aelig;rdigheder, men skal til geng&aelig;ld vide hvordan
              systemet virker.
            </p>
            <h2>Nedstyrtning</h2>
            <p>
              Efter at have fl&oslash;jet 23 flyvninger i marts og april og
              tilbagelagt 221 km, styrtede E-bussen ned i kanten af flyvepladsen
              p&aring; den 24. flyvning. Dette gav anledning til en meget
              omfattende gennemgang af hele ulykkens forl&oslash;b, hvor Flight
              Recorderen virkelig skulle vise sin v&aelig;rdi.
            </p>
            <p>
              Nedstyrtningen starter 800 sekunder henne i flyvningen i en
              h&oslash;jde af 32 m og med en airspeed p&aring; 32 km i timen. Se
              fig. 6. E-Bussen s&aelig;nker venstre vinge til i et rul p&aring;
              ca. 80 grader samtidig med, at det roterer 2 &frac34; gang omkring
              Z-aksen mod uret, m&aring;lt p&aring; kompasset, for til sidst
              efter 22 sekunder at ramme jorden. Ved sammenst&oslash;det
              m&aring;les flyets roll vinkel til -148grader alts&aring; er det
              p&aring; vej om p&aring; ryggen med en lav airspeed. Ved
              sammenst&oslash;det er der m&aring;lt f&oslash;lgende
              g-p&aring;virkninger: X=-2,26 g, Y=-2,18 g, Z=0,78 g. Ikke
              s&aelig;rlig store v&aelig;rdier fordi hele forkroppen bryder
              sammen som p&aring; en moderne bil. Venstre vinge br&aelig;kker,
              fordi den rammer jorden f&oslash;rst. Ballanceklap servoen stod
              omkring neutral stilling. Alle elektronik systemer virkede efter
              sammenst&oslash;det. Desv&aelig;rre kunne jeg ikke pr&oslash;ve
              ballanceklap servoen, fordi vingen blev skilt fra kroppen. Li-Po
              batteriet fik ingen mekaniske skader. Hvad kan der s&aring;
              udledes?
            </p>
            <p>
              <img src={fig8} />
              <br />
              Fig. 6. Graferne viser motorstr&oslash;m, h&oslash;jde og airspeed
              under nedstyrtningen.
            </p>
            <p>
              Det er f&oslash;rste gang jeg har en nedstyrtning, hvor der er
              s&aring; mange data til r&aring;dighed, s&aring; det &aring;bner
              et helt nyt felt i min modelflyvning. Efter at have
              gennemg&aring;et alle de mange data kan det konstateres, at
              f&oslash;lgende er helt i orden: Radioforbindelsen, Satellit
              forbindelsen (Sat fix = 10 stk.) Motorbatteriet,
              Forsyningssp&aelig;nding til Rx, controller og servoer (5,13V) og
              stabiliseringsgain 54 %.&nbsp;
            </p>
            <p>
              I et Fly By Wire system kan der v&aelig;re en diskussion om, hvem
              det er, der styrer flyet. Denne diskussion er ogs&aring; velkendt
              i luftfartsindustrien, f.eks. hos Airbus.
            </p>
            <p>
              S&aring; f&oslash;rst m&aring; udledes, hvad piloten foretog sig
              de 22 sekunder: Efter 3&frac12; sekunder giver piloten fuld pind
              til h&oslash;jre for at rette flyet op. I 2 sekunder bliver den
              dog sluppet. Gassen har ogs&aring; v&aelig;ret &oslash;get fra 800
              til 804sekunder, fordi jeg var bange for, at jeg fl&oslash;j for
              langsomt.
            </p>
            <p>
              Hvad gjorde Vector kontrolleren? Efter &frac12; sekund sendte den
              ballanceklap servoen helt ud i yderstilling for at rette flyet op.
              Dette vedblev den med hele vejen ned i 22 sekunder og reagerede
              dermed hurtigt og korrekt og var egentlig ligeglad med piloten.
              Kr&aelig;ngningen oversteg 60 grader, som var den maximale
              kr&aelig;ngning, FBW systemet var programmeret til at tillade.
              S&aring; hvorfor rettede flyet ikke op? Fordi selve ballanceklap
              servoen ikke udf&oslash;rte ordren! Da vingen var aftagelig, var
              der en l&oslash;s ledning med en JR connector, som slaskede rundt
              inde i kroppen. Fremover bliver det s&aring;dan, at ballanceklap-
              og h&oslash;jderors forbindelsen skal sidde fast og best&aring; af
              to kvalitetsstik med dobbelte parallelle kontakter, det kunne
              v&aelig;re det gr&oslash;nne 6 polede Multiplex stik, som skulle
              anvendes. For at fjerne muligheden for at selve servoen kunne
              v&aelig;re fejlbeh&aelig;ftet, er den blevet testet i en
              servotester med over 500 k&oslash;rsler.
            </p>
            <p>
              <img src={fig9} />
              <br />
              Fig. 7 viser hvad flyet gjorde, hvad piloten gjorde og hvad
              controlleren gjorde i de 22 kritiske sekunder. Disse tre parametre
              viser, hvordan der kan m&aring;les p&aring; feedback begrebet i et
              FBW fly.
            </p>
            <h2>Afslutning</h2>
            <p>
              Eagle Tree Systemet har i alle tilf&aelig;lde virket perfekt og
              bliver nu flyttet over i min Fanliner, som er et 2-motoret fan fly
              bygget i 2005, se&nbsp;
              <Link
                href="https://elflytec.dk/Andet/Konstruktion-af-en-composit-airliner"
                target="_blank"
              >
                Konstruktion af en composit airliner
              </Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
