import React, { Component } from "react";
import { Grid, withStyles, Typography, Link } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
  withRouter,
  RouterProps,
  RouteChildrenProps,
  RouteComponentProps,
  StaticContext
} from "react-router";

interface Props {
  canonical: string;
}

class FourOhFour extends Component<Props & RouteComponentProps<any>> {
  constructor(props) {
    super(props);
    if (location.search.length === 0) {
      window.location.search = "?notFound=true";
    }
  }
  render() {
    const { canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>404 - Siden fandtes ikke</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <Grid container>
          <Grid item md={3} />
          <Grid item xs={12} md={6}>
            <Typography variant="h2">404 - Siden fandtes ikke</Typography>
          </Grid>
          <Grid item md={3} />
        </Grid>
      </>
    );
  }
}

export default withRouter(FourOhFour);
