import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles, Link } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig1.jpg";
import fig2 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig2.jpg";
import fig3 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig3.png";
import fig4 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig4.jpg";
import fig5 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig5.jpg";
import fig6 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig6.png";
import fig7 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig7.jpg";
import fig8 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig8.jpg";
import fig9 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig9.jpg";
import fig10 from "../../../images/Jeti/LogningOgAnalyseJetiGPS/fig10.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Logning og analyse af flyvninger med Jeti-GPS</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Logning og analyse af flyvninger med Jeti-GPS</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn.</p>
            <p>Publiceret April 2011.</p>
            <p>
              Dette er en forts&aelig;ttelse af 3 tidligere artikler bragt i
              bladet Modelflyvenyt&nbsp;
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2010/modelflyvenyt%201-2010.pdf"
                target="_blank"
              >
                1-2010
              </Link>
              ,&nbsp;
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2010/modelflyvenyt%205-2010.pdf"
                target="_blank"
              >
                5-2010
              </Link>
              &nbsp;og&nbsp;
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2010/modelflyvenyt%206-2010.pdf"
                target="_blank"
              >
                6-2010
              </Link>
              , som du med fordel kan l&aelig;se. Artiklerne kan ogs&aring;
              findes p&aring; forfatterens webserver&nbsp;
              <Link href="https://elflytec.dk/" target="_blank">
                elflytec.dk
              </Link>
            </p>

            <h2>Flight-loggeren</h2>
            <p>
              Ved start p&aring; et nyt flyvested er det vigtigt at
              fort&aelig;lle loggeren, hvor p&aring; jorden den befinder sig,
              idet alle m&aring;linger refererer til startstedet. Dette
              g&oslash;res via Jeti boksens menu &rdquo;set origin&rdquo;.
            </p>
            <p>
              Hver gang anl&aelig;gget t&aelig;ndes, vil der dannes en ny logfil
              indeholdende data fra flyvningen. Disse kan efter flyvningernes
              afslutning overf&oslash;res fra GPS modulet til en PC, hvor de
              enkelte flyvninger s&aring; kan analyseres. For at g&oslash;re
              dette er det n&oslash;dvendigt at have et Jeti USB kit, der
              forbinder Jeti GPS`en til en computer.
            </p>
            <p>
              <img src={fig1} />
              Efter landing er data fra dagens flyvninger klar til download via
              USB kittet.
            </p>
            <p>
              Den n&oslash;dvendige software hentes over internettet fra firmaet
              Elfly`s hjemmeside under Jeti MGPS produktet. Der skal installeres
              en driver til USB kittet, og et program, der kan overf&oslash;re
              de loggede data i GPS modulet til PC&acute;en. Driveren er
              certificeret af Microsoft til Windows styresystemer: XP, Vista og
              7. Ved hj&aelig;lp af programfilen &rdquo;getmgps1.exe&rdquo; kan
              man nu overf&oslash;re flyvningernes data til Pc&rsquo;en.
            </p>
            <p>
              <img src={fig2} />
              Data overf&oslash;rt til PC.
            </p>
            <h2>GPS filerne</h2>
            <p>
              De filer, som overf&oslash;res, er kommaseparerede tekstfiler med
              file extention txt. De kan &aring;bnes og l&aelig;ses af en
              teksteditor. Nedenst&aring;ende viser overf&oslash;rte flight-data
              af 3 sekunders flyvning.
            </p>
            <p className={classes.breakWord}>
              $GPGGA,094005.000,5535.9099,N,01216.8092,E,1,10,0.84,59.9
              ,M,42.0,M,,*52
              <br />
              $GPGGA,094006.000,5535.9233,N,01216.8078,E,1,10,0.84,70.2
              ,M,42.0,M,,*57
              <br />
              $GPGGA,094007.000,5535.9356,N,01216.8021,E,1,10,0.84,79.3
              ,M,42.0,M,,*50
              <br />
              $GPGGA,094008.000,5535.9459,N,01216.7936,E,1,10,0.96,90.1
              ,M,42.0,M,,*51
            </p>
            <p>
              Data ligger som ASCII i NMEA-Format 2.0. (NMEA st&aring;r for
              National Marine Electronics Association) Hvert sekund er en linje.
              Linjen begynder med $GP for&nbsp;&rdquo;Global Positioning&rdquo;.
              Derefter f&oslash;lger et underformat her GGA for &bdquo;Fixed
              Data 3 D Location&ldquo;, som s&aring; f&oslash;lges af
              kommaseparerede parametre. F&oslash;rste data er den
              n&oslash;jagtige UTC tid &rdquo;Universal Time Coordinated&rdquo;,
              derefter f&oslash;lger GPS koordinaterne og en r&aelig;kke andre
              date blandt andet h&oslash;jde over jorden. Det hele afsluttes med
              en check-sum.
            </p>
            <h2>GPS software-Analyser</h2>
            <p>
              Der gives nu flere muligheder for at analysere dine flyvninger.
              Jeti anbefaler at anvende programmet Visualiser, som jeg tidligere
              har beskrevet. Imidlertid findes der en r&aelig;kke programmer
              p&aring; nettet som kan give en bedre analyse af flyvningerne. I
              det efterf&oslash;lgende henvises til&nbsp;
              <Link
                href="https://www.heise.de/download/product/gps-track-analyse.net-56439/download/danke?id=56439-1"
                target="_blank"
              >
                GPS-Track-Analyse.NET
              </Link>
              &nbsp;som kan installeres p&aring; Windows XP og 7 computere.
              <br />
              <i>
                Bemærk at softwaren ikke er vedligheholdt siden 2011, overvej at
                finde et nyere produkt.
              </i>
              <br />
              Inden programmet kan indl&aelig;se logfilerne, er det vigtigt, at
              &aelig;ndre file extention fra .txt til .nma. ved at rename
              filerne.
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Efter starten af programmet tastes &bdquo;Datei
              &ouml;ffnen&ldquo;, og de analyserede data vises som oven for i
              funktionen &bdquo;Trackbearbeitung / 2D H&ouml;henmodell&ldquo;
              Den &oslash;verste kurve viser h&oslash;jdeforl&oslash;bet, og den
              nederste hastigheden i forhold til jorden af en komplet flyvning.
              Flyet er forfatterens Fanliner, et electric ducted fan fly med 2
              motorer. De enkelte m&aring;lev&aelig;rdier oplistes i en tabel
              med et sekunds interval. Hvis man klikker i kurvefelterne, kan man
              markere et udsnit af flyvningen, i dette tilf&aelig;lde et
              climbforl&oslash;b.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Der foretages her en udvidelse af x-aksen med 10 gange. Se
              bl&aring; cirkel. Man kan nu analysere landingen. Beslutningen om
              at lande tr&aelig;ffes, der hvor curser linien er lige efter 4,4
              km og hvor h&oslash;jden er 33m. Hastigheden ned gennem glidepath
              er rimelig konstant 50km/t. H&oslash;jde tabet er ca. 2m/sek.
              Flyet tager jorden ved den r&oslash;de cirkel. Der vises 0.00m
              h&oslash;jde, hvorefter hastigheden aftager p&aring;
              landingsbanen. Derefter registreres taxihastigheden, hvis den er
              over 10km/h.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <p>
              Ovenst&aring;ende tabel viser take-off i samme flyvning.
              Fanlineren starter p&aring; betonbanen i Greve. Efter kun 4
              sekunder letter flyet ved omkring 60km/t (r&oslash;d linie), og
              efter yderligere 8 sekunder er flyet ved 71,5km/t i en
              h&oslash;jde p&aring; 38,1m. Som det ses, kan en m&aelig;ngde
              fly-data analyseres. Dog skal man v&aelig;re opm&aelig;rksom
              p&aring; vindens p&aring;virkninger, idet den m&aring;lte fart er
              ground speed. Det skal bem&aelig;rkes, at flyvningen blev optaget
              en dag hvor vinden var svag.
            </p>
            <p>
              <img src={fig6} />
              Hvis funktionen &rdquo;3D/hoenmodell&rdquo; anvendes vises
              ovenst&aring;ende kunstv&aelig;rk.
            </p>
            <p>
              <img src={fig7} />
              Det er endvidere muligt at udl&aelig;se en statistik af
              flyvningen, som har varet i 6&frac12;minut over en str&aelig;kning
              af 4,723km, og st&oslash;rste flyvefart var 119,3km/t med en max
              h&oslash;jde p&aring; 98,5meter.
            </p>
            <p>
              <img src={fig8} />
              Flyvningen kan nu l&aelig;gges ind i en 3D model over Greve
              RC-Center via Google Earth.
            </p>
            <p>
              <img src={fig9} />
            </p>
            <p>
              N&aelig;ste billede viser loggede tracks optaget med et
              sv&aelig;vefly over KFK flyvepladsen i Soderup. Hvis curseren
              bringes indover de enkelte punkter, kan der ses detaljerede
              oplysninger. Bem&aelig;rk at der er en frisk vind fra syd, hvilket
              tydeligt ses p&aring; afstanden mellem track-punkterne p&aring;
              grund af mod- og medvind. Samlet flyvestr&aelig;kning 5,7km.
            </p>
            <h2>Kommende udvikling af Jeti-systemerne</h2>
            <p>
              I den gamle Jeti box kunne man udl&aelig;se afstanden og kursen ud
              til modellen v. hj&aelig;lp af telemetri fra GPS modulet. Dette
              var fint, men piloten ville n&oslash;dvendigvis have travlt med at
              se p&aring; modellen i en situation, hvor den var ved at flyve
              v&aelig;k. Det bet&oslash;d at det sidste
              &rdquo;bortflyvningsdata&rdquo; ikke blev husket. Den kommende
              Jeti Box Profi med 1GB hukommelse og de to nye sendere DS-16 og
              DC-16 med 2GB hukommelser, vil under flyvningen kunne optage og
              gemme telemetri data. Det sidst modtagne data vil blive
              overf&oslash;rt til senderen og ligger gemt i denne. Det er
              n&oslash;dvendigt, at piloten kan foretage en udl&aelig;sning af
              det sidst modtagne og gemte data, hvis telemetriforbindelsen
              eventuelt bliver brudt p&aring; grund af svigtende
              r&aelig;kkevidde.
            </p>
            <p>
              <img src={fig10} />
            </p>
            <p>
              Det gemte &rdquo;bortflyvningsdata&rdquo; kan nu anvendes til at
              finde modellen ved hj&aelig;lp af Google Earth`s linealfunktion.
              Billedet viser et eksempel p&aring; et sv&aelig;vefly, som er
              n&oslash;dlandet 818m fra startstedet i en kompaskurs p&aring;
              317grader. Flyet er til at g&aring; ud at finde! Husk at anvende
              failsafe funktionen p&aring; spoilers, hvis de findes. De vil
              s&aring; blive udl&oslash;st hvis 2,4GHz r&aelig;kkevidden slipper
              op lav h&oslash;jde.
            </p>
            <p>
              Det skal endvidere n&aelig;vnes, at der i de nye produkter
              ogs&aring; vil komme valgfri stemme opkald p&aring; alarmerne i
              stedet for morsekoder, samt en r&aelig;kke nye funktioner.
            </p>
            <h2>Konklusion</h2>
            <p>
              Jeti-GPS systemet m&aring; siges at v&aelig;re s&aelig;rdeles
              velegnet til forskellige anvendelser s&aring;som m&aring;ling af
              hastighed, flyveh&oslash;jder, stigevne, kontrol af landinger,
              starter, flyveruter og som efters&oslash;gnings
              v&aelig;rkt&oslash;j. Is&aelig;r vil m&aring;linger p&aring; de
              f&oslash;rste flyvninger med en ny model v&aelig;re af interesse.
              Det skal n&aelig;vnes at loggeren kan arbejde selvst&aelig;ndigt
              uden en Jeti modtager, blot skal den s&aelig;ttes op med
              Jetiboksen og anbringes i flyveren.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
