import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Andet/GenerationerAfRCTeknik/fig1.jpg";
import fig2 from "../../../images/Andet/GenerationerAfRCTeknik/fig2.jpg";
import fig3 from "../../../images/Andet/GenerationerAfRCTeknik/fig3.jpg";
import fig4 from "../../../images/Andet/GenerationerAfRCTeknik/fig4.jpg";
import fig5 from "../../../images/Andet/GenerationerAfRCTeknik/fig5.jpg";
import fig6 from "../../../images/Andet/GenerationerAfRCTeknik/fig6.jpg";
import fig7 from "../../../images/Andet/GenerationerAfRCTeknik/fig7.jpg";
import fig8 from "../../../images/Andet/GenerationerAfRCTeknik/fig8.jpg";
import fig9 from "../../../images/Andet/GenerationerAfRCTeknik/fig9.jpg";
import fig10 from "../../../images/Andet/GenerationerAfRCTeknik/fig10.jpg";
import fig11 from "../../../images/Andet/GenerationerAfRCTeknik/fig11.jpg";
import fig12 from "../../../images/Andet/GenerationerAfRCTeknik/fig12.jpg";
import fig13 from "../../../images/Andet/GenerationerAfRCTeknik/fig13.jpg";
import fig14 from "../../../images/Andet/GenerationerAfRCTeknik/fig14.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Generationer af RC-teknik</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Generationer af RC-teknik</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret maj 2011.</p>
            <p>
              Dette er en beskrivelse den tekniske udvikling RC-anl&aelig;ggene
              har v&aelig;ret i gennem fra starten til i dag.
            </p>
            <h2>Radior&oslash;r</h2>
            <p>
              Efter opfindelsen af radior&oslash;ret og opdagelsen af at det var
              muligt at sende morse signaler jorden rundt v.hj. af
              radiob&oslash;lger, var der nogle som fik den ide, at man
              m&aring;tte kunne styre modelfly tr&aring;dl&oslash;st. De
              f&oslash;rste kendte anl&aelig;g bestod af en radiosender med et
              radior&oslash;r og en tilsvarende modtager ogs&aring; kun med et
              radior&oslash;r. Princippet var, at senderen sendte en
              b&aelig;reb&oslash;lge, der s&aring; kunne afbrydes med et
              ringetryk, som man stod med i h&aring;nden. Flyveren kunne
              s&aring; dreje til venstre ved et tryk, n&aring;r man slap knappen
              fl&oslash;j den s&aring; lige ud. N&aelig;ste gang man trykkede
              p&aring; knappen fl&oslash;j den s&aring; til h&oslash;jre. Lidt
              mere avanceret er fig. 1. hvor b&aelig;reb&oslash;lgen moduleres
              med en tone, som man s&aring; kan afbryde.
            </p>
            <p>
              <img src={fig1} />
            </p>
            <p>
              Fig. 1. Diagram af super simpel sender med et radior&oslash;r.
              Bem&aelig;rk den h&oslash;je sp&aelig;nding p&aring; 100V som
              kommer fra et tungt og dyrt anodebatteri, lige som der anvendes et
              gl&oslash;destr&oslash;msbatteri. Senderen stod p&aring; jorden
              fordi den meget tung.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Fig. 2. Et r&oslash;rs modtager med et rel&aelig; som kan styre et
              s&aring;kaldt escapment.
            </p>
            <p>
              Rormaskinen i disse anl&aelig;g var et escapment, som fik sin
              energi fra en gummimotor. (snoet elastik) N&aring;r der gik
              str&oslash;m i rel&aelig;spolen, tiltr&aelig;kkes vippearmen,
              hvorved elastikken kunne dreje sideroret via en krumtap. Systemet
              var f&oslash;lsomt over for vibrationer og var yders
              up&aring;lideligt.
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig. 3 Simpel rormaskine som tr&aelig;kkes af gummimotor.
            </p>
            <p>
              Den her beskrevne f&oslash;rste generation af fjernstyring har jeg
              ikke selv bygget, men oplevet i anvendelse i 1954.
            </p>
            <h2>Transistoren</h2>
            <p>
              Et fuldst&aelig;ndigt afg&oslash;rende gennembrud for
              fjernstyringen og hele radioverdenen var opfindelsen af
              transistoren i 1947. Den var lille, kunne arbejde med lave
              sp&aelig;ndinger f.eks. 5V, kr&aelig;vede ingen
              gl&oslash;destr&oslash;m og var robust. Transistoren dannede
              derfor grundlag for mit f&oslash;rste hjemmebyggede RC-anl&aelig;g
              omkring 1960. Modtageren kunne nu bygges med transistorer. Se fig.
              4. Det kunne lidt mere end det oven n&aelig;vnte
              r&oslash;ranl&aelig;g, idet det var muligt at opn&aring; en
              proportional virkning p&aring; sideroret Se fig. 5.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig. 4. Min f&oslash;rste transistor modtager med 3 transistorer
              og et rel&aelig;.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig. 5 Eksempel p&aring; proportional rormaskine. Jo hurtigere
              motoren k&oslash;rer rundt desto mere slynges armene ud. Sideroret
              holdes p&aring; plads af en lille fjeder. Halv fart giver ligeud
              flyvning.
            </p>
            <p>
              I 1964 udviklede jeg helt fra bunden et proportionalt analogt 3
              kanal anl&aelig;g. Transistorer som kunne arbejde ved 27MHz var
              kommet p&aring; markedet. Senderen var nu transistoriseret og
              kunne nu holdes i h&aring;nden. Se Fig. 6. Modtageren var en
              s&aring;kaldt superregenerativ modtager uden krystal, hvilket
              medf&oslash;rte at der kun kunne v&aelig;re en model i luften ad
              gangen p&aring; hele 27MHz b&aring;ndet. Se fig.7. Modtageren
              arbejder med to frekvensvarierende toner og DC styrede servoer. En
              god sikkerhed var, at hvis radioforbindelsen svigtede, failsafede
              anl&aelig;gget automatisk og modellen en Falcon 56, som var
              selvstabil landede uden skader. Dette skete par gange.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig 6 Transistor AM sender baseret p&aring; analogteknik. 3
              proportionale kanaler. Bem&aelig;rk b&oslash;jlerne til den
              hjemmelavede joy-stick.
            </p>
            <p>
              <img src={fig7} />
              <br />
              Diagram af ovenst&aring;ende sender. Hvis du er god til
              diagramanalyse vil du genfinde en astabil multivibrator, 2 stk.
              wien-bro oscillatorer, en x-tal oscillator, et HF udgangstrin,
              samt en AM-modulator.
            </p>
            <p>
              <img src={fig8} />
              <br />
              Fig. 7. Tilh&oslash;rende modtager med hjemmelavet proportional DC
              servo.
            </p>
            <h2>PPM anl&aelig;g med super modtagere</h2>
            <p>
              Forfatterens sidste hjemmebyggede sender var en s&aring;kaldt
              singel stick sender, hvor alle 3 akser p&aring; flyet kunne styres
              fra samme h&aring;nd. Dette var ikke nogen d&aring;rlig ide, idet
              NASA ved fors&oslash;g havde vist under konstruktion af
              m&aring;nelandingsmodulet, at det ville v&aelig;re optimalt kun at
              bruge den ene hjernehalvdel. I senderen p&aring; fig. 10 skal
              begge hjernehalvdele arbejde sammen!
            </p>
            <p>
              <img src={fig9} />
              <br />
              Fig. 8 Singel stick sender.
            </p>
            <p>
              I slutningen af tresserne kunne man k&oslash;be servoer, som var
              baseret p&aring; at der kom en impuls ind i servoen, s&aring; man
              ved hj&aelig;lp af pulsbredden kunne bestemme hvor servoen skulle
              st&aring;, alts&aring; en proportional servo. Dette princip har
              siden v&aelig;ret ener&aring;dende ogs&aring; i nutidens digitale
              servoer, fordi de digitale servoer skulle kunne anvendes i alle
              anl&aelig;g. Disse anl&aelig;g br&oslash;d med analogteknikken,
              idet der blev anvendt impulsteknik. Systemet ben&aelig;vntes Puls
              Position Modulation PPM. Senderen var opbygget af et st&oslash;rre
              antal transistorer der virkede som kontakter der enten var on
              eller off. Se Fig.10. Modtageren benyttede sig nu af et princip
              hvor man v. hj. af et krystal nu kunne skifte frekvens
              s&aring;ledes at mange modeller kunne flyve samtidig. Dette
              princip ben&aelig;vnes som en superheterodynmodtager eller bare en
              supermodtager. Se fig. 9. Vi er nu n&aring;et op til en teknik som
              anvendes i de billigste 35MHz i dag. Inde i anl&aelig;gget
              begyndte man gradvis at erstatte de mange modstande og
              transistorer med integrerede kredse, s&aring;kaldte chips hvor man
              p&aring; en siliciumskive kunne bygge hele kredsl&oslash;b.
            </p>
            <p>
              <img src={fig10} />
            </p>
            <p>
              Fig. 9. De to &oslash;verste modtagere er en videre udvikling af
              den traditionelle 35MHz supermodtager. Modtageren &oslash;verst
              til venstre kan registrere hvor mange forstyrrelser den har
              v&aelig;ret udsat for, mens den lille bl&aring; modtager fra MZK
              var gjort intelligent, idet den kunne genkende sin egen sender og
              undertrykke andre forstyrrende sendere. De forskellige fabrikater
              p&aring; 35MHz kan som regel anvendes i fl&aelig;ng, er kompatible
              om end det ikke kan anbefales.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig11} />
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig12} />
                </p>
              </Grid>
            </Grid>
            <p>
              Fig. 10 &rdquo;Guldsenderen&rdquo; fra Futaba var et eksempel
              p&aring; at der nu var et kommercielt marked for fjernstyring som
              hjemmebyggeriet ikke kunne hamle op med. Man anvendte nu en
              blanding af transistorer og sm&aring; chips. Ved hj&aelig;lp af 6
              skyde kontakter kunne man vende servoretningen, et stort
              fremskridt!
            </p>
            <h2>PCM anl&aelig;g</h2>
            <p>
              N&aelig;ste store spring i 1990 f&oslash;rer op til den teknik,
              som mange af jer stadig anvender i dag, nemlig den s&aring;kaldte
              Puls Codede Modulation. Forkortet PCM. Udviklingen af chip
              teknologien havde muliggjort at man kunne fremstille sm&aring;
              computere ogs&aring; kaldet micro processorer, som kunne arbejde
              digitalt, alts&aring; brug at 0 og 1 v&aelig;rdier. Det
              f&oslash;rste firma som kom p&aring; markedet var Futaba, som
              efter at have brugt et million bel&oslash;b, kunne introducere den
              s&aring;kaldte 1024chip. Denne chip kunne foretage digital
              signalbehandling, hvorved senderen kunne udstyres med helt nye
              funktioner, s&aring;som mixning af kanaler, dual-rate, exponential
              udslag af ror, fail-safe og meget andet. Det var den samme chip
              som blev anvendt i b&aring;de sender og modtager, ved at et af
              benene p&aring; chippen var forbundet til enten 1 eller 0. Mellem
              sender og modtager fl&oslash;d der en hastig str&oslash;m af data
              i form af ettaller og nuller. Mens det var muligt for mig at kunne
              reparere f.eks. &rdquo;guldsenderen&rdquo; var det ikke
              l&aelig;ngere muligt at kunne udf&oslash;re alle reparationer
              p&aring; computersenderen. Jeg havde imidlertid den
              forn&oslash;jelse at eje og teste det f&oslash;rste Futaba 1024
              anl&aelig;g i Danmark og hj&aelig;lpe den dav&aelig;rende
              import&oslash;r med den danske brugsanvisning. Denne var vigtig,
              fordi helt nye begreber var opst&aring;et.
            </p>
            <p>
              <img src={fig13} />
              <br />
              Fig. 11. Moderne PPM/PCM Futaba sender som nu viser overgangen til
              den n&aelig;ste generation, idet den er udstyret med et Jeti
              2,4GHz sendermodul og via Jetiboksen kan modtage data fra flyet
              mens det flyver.
            </p>
            <h2>2,4GHz teknik</h2>
            <p>
              Efter &aring;rtis tilsyneladende stilstand begyndte det
              amerikanske firma Spectrum i 2007 at sende en helt ny type
              anl&aelig;g p&aring; markedet baseret p&aring; samme chip og
              teknik som anvendes i tr&aring;dl&oslash;se data netv&aelig;rk. I
              begyndelsen var Spectrum systemet kun beregnet til inded&oslash;rs
              flyvning med begr&aelig;nset r&aelig;kkevidde. Siden blev der
              udviklet anl&aelig;g til udend&oslash;rs brug med fuld
              r&aelig;kkevidde. Spectrum drev ogs&aring; en ny udvikling i gang
              med sm&aring; el-modeller som er ultralette og som anvendte den
              s&aring;kaldte DSM2 standart. Alle fabrikanter er
              efterh&aring;nden fulgt efter med 2,4GHz anl&aelig;g.&nbsp;
              <u>
                <strong>
                  Disse anl&aelig;g er ikke indbyrdes kompatible, idet
                  fabrikaterne anvendte forskellige standarter og systemer.
                </strong>
              </u>
              &nbsp;Brugere m&aring; s&aring; bestemme sig i et sv&aelig;rt
              gennemskueligt marked. Min historie her er kort fordi vi nu har
              n&aring;et det tid vi alle er midt i. Vil du vide mere om 2,4GHz
              s&aring; se under{" "}
              <Link
                target="_blank"
                href="https://elflytec.dk/Grundviden/Grundlaeggende-2.4-GHz-teknik"
              >
                Grundl&aelig;ggende 2,4GHz teknik
              </Link>{" "}
              i Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt.dk/modelflyvenyt/2009/modelflyvenyt%205-2009.pdf"
              >
                Nr 5 2009
              </Link>{" "}
              eller her p&aring; hjemmesiden.
            </p>
            <h2>2,4GHz teknik med telemetri</h2>
            <p>
              I 2010 fortsatte udviklingen af 2,4GHz teknikken med at
              overf&oslash;re forskellige data i sand tid fra flyet ned til
              piloten. Denne telemetri teknik medf&oslash;rte dels stigende krav
              piloten om at kunne anvende oplysningerne under og efter
              flyvningerne. Fig. 12 er et eksempel p&aring; den nyeste
              generation af RC-sendere.
            </p>
            <p>
              <img src={fig14} />
              <br />
              Fig. 12. Jetis nye DS-16 sender. Kommer p&aring; markedet medio
              2011.
            </p>
            <p>F&oslash;lgende data kan fremh&aelig;ves:</p>
            <ul>
              <li>
                <p>
                  Datatransmission: To vejs 2,4GHz Frekvens Hop over 16
                  frekvenser i Spread Spectrum teknologi til opn&aring;else af
                  maximal sikkerhed med indbygget telemetri.
                </p>
              </li>
              <li>
                <p>
                  Display: 3,8 inch med baggrundsbelysning 320x240 pixels. Fulde
                  telemetri oplysninger p&aring; display i sandtid.
                </p>
              </li>
              <li>
                <p>Integreret 2,4GHz antenne.</p>
              </li>
              <li>
                <p>
                  Str&oslash;mforsyning: Integreret long-life Li-Ion akkumulator
                  med power management system.
                </p>
              </li>
              <li>
                <p>
                  Kontrol pinde: Variable i l&aelig;ngde, Digital aftastning med
                  hall sensorer (ingen potentiometre) i en opl&oslash;sning
                  p&aring; 4096 trin, svarende til 12 bit. Kan omstilles fra
                  mode 1 til 4.
                </p>
              </li>
              <li>
                <p>Data hukommelse 2GB intern.</p>
              </li>
              <li>
                <p>Audio output: Over h&oslash;jttaler eller &oslash;reprop.</p>
              </li>
              <li>
                <p>Sprog: engelsk, tysk, fransk, polsk eller tjekkisk.</p>
              </li>
              <li>
                <p>Software til modeltyperne ACRO-HELI og GLID.</p>
              </li>
              <li>
                <p>
                  Voice alarmer: De alarmer som Jeti telemetri modulerne
                  tidligere udsendte som morsekoder, kan nu afgives som valgbare
                  individuelle opkald som f.eks. &rdquo;Stall warning&rdquo;,
                  &rdquo;100 meters altitude&rdquo; eller &rdquo;Battery
                  low&rdquo;
                </p>
              </li>
              <li>
                <p>
                  Kan gemme &rdquo;flyaway&rdquo; data, s&aring;som afstand og
                  kurs s&aring;ledes at bortfl&oslash;jne modeller kan findes,
                  hvis man anvender GPS modulet.
                </p>
              </li>
              <li>
                <p>
                  USB forbindelse til PC muligg&oslash;r: Firmware opdateringer,
                  settings af alle senderens, modtagerens og telemetrimodulernes
                  parametre samt download af telemetri data med henblik p&aring;
                  analyse af flyvninger.
                </p>
              </li>
            </ul>
            <p>
              Udviklingen slutter n&aelig;ppe her og det har v&aelig;ret
              interessant at deltage i den. Det er m&aring;ske lidt trist at vi
              i dag k&oslash;ber anl&aelig;g og modeller f&aelig;rdige og derved
              ikke oplever den kreative proces selv at bygge tingene, men det er
              en uundg&aring;elig f&oslash;lge af udviklingen.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
