import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig1.png";
import fig2 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig2.png";
import fig3 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig3.png";
import fig4 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig4.png";
import fig5 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig5.png";
import fig6 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig6.jpg";
import fig7 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig7.png";
import fig8 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig8.png";
import fig9 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig9.png";
import fig10 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig10.png";
import fig11 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig11.png";
import fig12 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig12.png";
import fig13 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig13.png";
import fig14 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig14.jpg";
import fig15 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig15.png";
import fig16 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig16.png";
import fig17 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig17.png";
import fig18 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig18.jpg";
import fig19 from "../../../images/Grundviden/BestemmelseAfTyngdepunkt/fig19.png";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Bestemmelse af længdestabilitet og tyngdepunkt</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Bestemmelse af l&aelig;ngdestabilitet og tyngdepunkt</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret april 2014</p>
            <h2>Typer af stabilitet</h2>
            <p>
              De fleste modelflyvere ved, at tyngdepunktet skal v&aelig;re
              placeret rigtigt p&aring; deres model for at f&aring; en
              velflyvende stabil model. Denne artikel skal n&aelig;rmere belyse,
              hvad dette betyder. Vi starter fra begyndelsen med at se p&aring;
              begrebet stabilitet. Pr&oslash;v at se p&aring; de 4 kugler
              p&aring; fig. 1.
              <strong>
                <br />
              </strong>
            </p>
            <ul>
              <li>
                Kugle 1 vil s&oslash;ge mod bunden af sk&aring;len, hvis den
                modtager en p&aring;virkning udefra. Den m&aring; siges at
                v&aelig;re&nbsp;<strong>overstabil.</strong>
              </li>
              <li>
                Kugle 2 er&nbsp;<strong>stabil,&nbsp;</strong>hvis den modtager
                en p&aring;virkning udefra.
              </li>
              <li>
                Kugle 3, som kunne ligge p&aring; et billardbord, er&nbsp;
                <strong>neutral stabil</strong>, hvis den bliver p&aring;virket.
                Den kan ligge stille overalt p&aring; bordet.
              </li>
              <li>
                Kugle 4 er i balance, men hvis den bliver p&aring;virket falder
                den ned, den er helt klart&nbsp;<strong>ustabil.</strong>
              </li>
            </ul>
            <p>
              Hvis vi relaterer kuglerne til vores flyvere, kan vi ved at flytte
              tyngdepunktet f&aring; flyveren til at optr&aelig;de som alle 4
              kugler, hvor kugle 4, den ustabile, m&aring; siges at v&aelig;re
              u&oslash;nsket. Vi bliver nu n&oslash;dt til at se p&aring;, hvad
              det er, tyngdepunktet skal flyttes i forhold til. Det er ikke
              umiddelbart klart!
            </p>
            <p>
              <img src={fig1} />
              <br />
              Fig. 1
            </p>
            <h2>F&oslash;rst bestemmes Neutral Punktet</h2>
            <p>
              Det tyngdepunktet skal ses i forhold til er flyets Neutral Punkt.
              (NP), som vi derfor bliver n&oslash;dt til at bestemme. Neutral
              Punktet er det punkt p&aring; flyet, hvor det optr&aelig;der
              neutral stabilt (kugle 3), hvis det bliver udsat for en udefra
              kommende p&aring;virkning, som skyldes, at luften stort set aldrig
              er helt rolig.
            </p>
            <p>
              Neutral Punktets placering for hele flyet er bestemt af 3
              forskellige elementer:
            </p>
            <ul>
              <li>St&oslash;rst indflydelse har vingen.</li>
              <li>
                Dern&aelig;st kommer haleplanets st&oslash;rrelse og afstand fra
                vingen.
              </li>
              <li>
                Endelig kommer kroppens udformning, som normalt har ganske lille
                indflydelse.
              </li>
            </ul>
            <h3>Vingen</h3>
            <p>
              Udgangspunktet for vingen er vingens Aerodynamiske Center (AC),
              som er beliggende p&aring; &frac14; af vingens gennemsnitlige
              aerodynamiske korde m&aring;lt fra forkanten, stort set
              uafh&aelig;ngig af vingens profil. P&aring; engelsk anvendes
              udtrykket Mean Aerodynamic Cord (MAC).
            </p>
            <p>
              Hvis vi begynder med simpel rektangul&aelig;r vinge, er den
              gennemsnitlige aerodynamiske korde MAC lig med den geometriske
              korde. En l&oslash;s vinge er ustabil (Kugle 4), hvilket du
              m&aring;ske har oplevet, hvis du har set en l&oslash;srevet vinge
              rotere faldende til jorden. Det skal bem&aelig;rkes at vingens
              profil stort set ikke har nogen indflydelse p&aring; AC, hvad man
              ellers skulle tro og derfor ikke indg&aring;r i bestemmelsen af
              NP.
            </p>
            <h3>Haleplan</h3>
            <p>
              For at f&aring; en stabil flyver bliver man n&oslash;dt til at
              have et haleplan, som mere rigtigt kan kaldes en stabilisator. Se
              fig. 2. En betingelse for at bestemme flyets tyngdepunkt er, at
              man kan bestemme neutralpunkets (NP) n&oslash;jagtige placering
              for hele flyet. NP flytter sig bagud i forhold til vingens AC
              bestemt af en r&aelig;kke forhold, s&aring; som haleplanets area
              og afstand fra hovedvingen m.m.
            </p>
            <p>&nbsp;NP korrektions faktoren for haleplanet er lig:</p>
            <p>
              <img src={fig2} />
              <br />
              Fig. 2
            </p>
            <p>
              Vingens sideforhold er sp&aelig;ndvidden divideret med vingens
              gennemsnitlige geometriske korde. Den 4. rod kan du finde ved at
              tage kvadratroden 2 gange.
            </p>
            <h3>Kroppen</h3>
            <p>
              Kroppen har normalt ikke den store indflydelse, men det
              afh&aelig;nger af flytypen. Se senere.
            </p>
            <h3>Hele modellens Neutral Punkt</h3>
            <p>Lad os regne p&aring; en simpel flyver. Se fig. 2.</p>
            <p>
              <img src={fig3} />
              <br />
              Vingens AC = &frac14; MAC = 0.25MAC
            </p>
            <p>
              <img src={fig4} />
              <br />
              Modellens Neutral Punkt NP = 1/4MAC + Np korrektion = 0,25 + 0,269
              = 0,519
            </p>
            <p>
              Resultatet har ingen dimensioner, men er den br&oslash;kdel af
              MAC, som viser hvor NP ligger i forhold til forkanten af MAC.
              Neutralpunktet m&aring; alts&aring; ligge p&aring; 20&nbsp;cm x
              0,519 = 10,38&nbsp;cm fra forkant af MAC. Kroppen har p&aring;
              denne model ingen betydning, fordi den er tynd.
            </p>
            <h2>Dern&aelig;st bestemmes tyngdepunktet</h2>
            <p>
              Vi kan f&oslash;rst nu begynde at bestemme tyngdepunktet (TP)
              udfra f&oslash;lgende.
            </p>
            <ol>
              <li>
                Hvis TP er placeret i NP, er modellen neutral stabil (Kugle
                3)&nbsp;
              </li>
              <li>
                Hvis TP er placeret bag NP, er modellen ustabil (Kugle 4) og kan
                i princippet ikke flyve.
              </li>
              <li>
                Hvis TP er placeret foran NP, er modellen mere eller mindre
                stabil (Kugle 1 og 2)
              </li>
            </ol>
            <p>
              <strong>
                Der findes ikke een rigtig placering af TP, fordi du ved at
                placere TP i forhold til NP kan bestemme hvilke egenskaber
                modellen skal have.
              </strong>
              &nbsp;Ved et tyngdepunkt, som ligger meget langt fremme, f&aring;r
              du en model, som er meget stabil p&aring; bekostningen af
              man&oslash;vredygtigheden, mens du ved et tyngdepunkt som ligger
              meget langt tilbage, f&aring;r en meget man&oslash;vredygtig
              model, som ikke er s&aelig;rlig stabil. Som et anbefalet
              udgangspunkt for TP kan du p&aring; en normal model starte med, at
              TP ligger p&aring; 15&nbsp;% af MAC foran NP. P&aring; fig. 2
              bliver det 0,15 x 20&nbsp;cm = 3&nbsp;cm. TP ligger alts&aring;
              3&nbsp;cm foran NP.&nbsp;
              <strong>
                Afstanden mellem TP og NP kaldes den statiske Stabilitets Margin
                (SM).
              </strong>
              &nbsp;Hvor stor en SM du anvender, afh&aelig;nger af modellen, og
              de egenskaber du &oslash;nsker at give modellen, samt din
              erfaring. Et 3D kunstfly med symmetrisk profil vil med fordel have
              en SM p&aring; 5&nbsp;% for at kunne ligge ens i alle
              man&oslash;vrene. Flyet er s&aring;ledes n&aelig;sten ikke
              stabilt, men bliver liggende i den stilling piloten anbringer
              flyet i, som kugle 3. En flyvende vinges udgangspunkt er en SM
              p&aring; 10&nbsp;%. Ved pr&oslash;veflyvninger af normale
              modeller, kan du for at v&aelig;re sikker p&aring; at have en
              meget stabil og d&oslash;d model anvende en SM p&aring; 20&nbsp;%
              for derefter gradvis at mindske SM ved at flytte TP bagud.
            </p>
            <h2>Andre planformer</h2>
            <p>
              Efterf&oslash;lgende skal vi se p&aring;, hvordan man kan betragte
              modeller, som afviger fra den simple model p&aring; fig. 2.
              F&oslash;rst skal vises, hvordan man finder MAC p&aring; en vinge,
              som b&aring;de har pileform og er tilspidset. Den simpleste
              fremgangsm&aring;de er at lave en tegning. Se fig. 3.&nbsp;
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig. 3
            </p>
            <ol>
              <li>
                Tipkorden (gr&oslash;n) l&aelig;gges i forl&aelig;ngelse af
                rodkorden til begge sider.
              </li>
              <li>
                Rodkorden (r&oslash;d) l&aelig;gges i forl&aelig;ngelse af tip
                korden til begge sider.
              </li>
              <li>Derefter tegnes to diagonaler.</li>
              <li>
                MACkorden indtegnes, der hvor de to diagonaler krydser hinanden.
              </li>
              <li>
                MACkorden projekteres ind til rodkorden. Se stiplede linjer.
              </li>
              <li>&frac14; MAC findes.</li>
              <li>Placeringen af vingens Aerodynamiske Center er nu fundet.</li>
            </ol>
            <p>
              Det skal m&aring;ske n&aelig;vnes at gennemsnitskorden, som er
              Korden ved roden + Korden ved tippen divideret med 2 ikke er lig
              MAC, men en smule mindre ved vinger som tilspidser.
              Gennemsnitskorden skal til geng&aelig;ld anvendes, hvis vingens
              areal &oslash;nskes beregnet, som i fig. 3.
            </p>
            <p>
              Vi vil nu se p&aring; nogle forskellige modeltyper, som
              forfatteren har konstrueret og anvender.
            </p>
            <h2>Eksempler p&aring; fly</h2>
            <p>
              F&oslash;rste eksempel er Fanlineren p&aring; billede1 og fig. 4.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Billede 1
            </p>
            <p>
              <img src={fig7} />
              <br />
              AC for hovedvinge = 0,25MAC
            </p>
            <p>
              <img src={fig8} />
            </p>
            <p>
              NP = 0,25 + 0,324 = 0,574 x 30 MAC = 17,2&nbsp;cm fra forkant af
              MAC.
            </p>
            <p>SM = 15&nbsp;% af MAC = 0,15 x30&nbsp;cm = 4,5&nbsp;cm.</p>
            <p>
              TP = 17,2&nbsp;cm &ndash; 4,5&nbsp;cm = 12,7&nbsp;cm fra forkant
              af MAC
            </p>
            <p>
              Hvis forkroppen fors&oslash;ges regnet med, vil det flytte
              modellens NP frem med f&oslash;lgende effekt:
              <br />
              <img src={fig9} />
            </p>
            <p>
              Faktoren for den runde forkrop kan ansl&aring;s til at v&aelig;re
              0,1 ud fra, at en rund krop form ikke har samme opdrift som et
              planprofil. F&oslash;lgende beregning viser, at forkroppen alene
              kan ansl&aring;s til at flytte NP frem med 4&nbsp;%.
            </p>
            <p>
              <img src={fig10} />
            </p>
            <p>
              Hvis vi regner p&aring; bagkroppen bliver virkningen 2,5&nbsp;%,
              som modvirker de 4&nbsp;%, s&aring;ledes at den samlede effekt
              ansl&aring;s til 1,5&nbsp;%.
            </p>
            <p>
              Konklusionen er, at kroppenes indflydelse p&aring; modellerne
              almindeligvis er uden betydning.
            </p>
            <p>
              I praksis vil vi nu l&oslash;fte modellen, bringe den i balance og
              se, hvor TP ligger. En mere elegant m&aring;de at finde TP
              p&aring; er at finde v&aelig;gten p&aring; n&aelig;se hjulet og
              p&aring; hovedhjulene og derefter anvende f&oslash;lgende:
            </p>
            <p>
              <img src={fig11} />
            </p>
            <p>
              <img src={fig12} />
              <br />
              Fig. 5
            </p>
            <p>
              Vejning og opm&aring;linger af Fanlineren viser f&oslash;lgende:
              (688g x 63&nbsp;cm) / 3755g&nbsp;= 11,2&nbsp;cm som er lig X
              p&aring; fig. 5. TP opm&aring;les til at ligge p&aring;
              10,5&nbsp;cm fra forkant af vingen. Da NP ligger p&aring;
              17,2&nbsp;cm bliver SM = 17,2&nbsp;cm &ndash; 10,5&nbsp;cm =
              6,7&nbsp;cm = 22,3&nbsp;%. Konklusionen er, at modellen er yderst
              l&aelig;ngdestabil, hvilket er tilstr&aelig;bt og ogs&aring;
              opleves i praksis. Man kan nu med fordel fremstille en
              m&aring;ler, som kan limes p&aring; undersiden af vingen. Se fig.
              6. Da MAC p&aring; Fanlineren er 30&nbsp;cm vil 10&nbsp;%
              v&aelig;re lig 3&nbsp;cm. NP udm&aring;les til at v&aelig;re
              17,2&nbsp;cm fra forkanten, som vi lige har beregnet.
            </p>
            <p>
              <img src={fig13} />
              <br />
              Fig. 6
            </p>
            <p>
              P&aring; elfly b&oslash;r Li-Po batteriet kunne flyttes til
              regulering af tyngdepunktet, idet det virker forkert at justere
              v.hj.af bly, hvis man har gjort alt for at g&oslash;re flyet let.
              Det skal samtidig understreges at den tunge batteripakke skal
              sidde ordentlig fast for ikke at f&aring; en forskydning af TP,
              is&aelig;r bagud!{" "}
            </p>
            <p>
              N&aelig;ste eksempel er forfatterens Blended Wing Body. BWB
              konstruktionen m&aring; betegnes som en fremtids konstruktion, som
              b&aring;de NASA, Boeing og Airbus arbejder med. Vinger og krop
              udg&oslash;r en integreret enhed, ligesom halefinnens funktion
              udg&oslash;res af de 2 vinglets. Se billede 2.
            </p>
            <p>
              <img src={fig14} />
              <br />
              Billede 2
            </p>
            <p>
              Tyngdepunktet skal nu bestemmes ved at bestemme det Aerodynamiske
              Center for hele modellen. Som det ses p&aring; tegningen,
              bestemmes MAC for inderdelen og yderdelen hver for sig, som det
              lige er beskrevet. Derefter beregnes afstanden til den
              resulterende MAC ved hj&aelig;lp af f&oslash;lgende formel til at
              v&aelig;re 24,12mm:
            </p>
            <p>
              <img src={fig15} />
            </p>
            <p>
              <img src={fig16} />
            </p>
            <p>
              som s&aring; kan tegnes ind som resulterende MAC, og derefter
              udm&aring;les til 39,6mm. Den resulterende MAC projekteres ind
              p&aring; kroppens centerlinie, og man kan nu finde 1/4MAC til at
              v&aelig;re beliggende 42,9mm fra flyets n&aelig;se. Da BWB
              konstruktionen kan betragtes som en flyvende vinge placeres TP 10
              % af MAC = 3,9mm foran AC eller 39mm fra n&aelig;sen. Da tegningen
              er i skala 1:10 vil 1mm svare til 1&nbsp;cm p&aring; modellen.
              N&aelig;sen er s&aring; lille, at den er uden betydning, og
              p&aring; grund af konstruktionen er der intet haleplan.
            </p>
            <p>
              <img src={fig17} />
              <br />
              Fig. 7
            </p>
            <p>
              Sidste eksempel er forfatterens Bristol Boxkite model fra 1911. Se
              billede 3. Da de to hovedvinger ligger lige over hinanden, kan de
              regnes som en vinge med hensyn til en &frac14; MAC. Haleplans-
              korrektionen beregnes og l&aelig;gges til, mens forvingens
              korrektion&nbsp;<strong>tr&aelig;kkes fra,</strong>&nbsp;n&aring;r
              AC skal bestemmes.
            </p>
            <p>
              <img src={fig18} />
              <br />
              Billede 3&nbsp;Fly By Wire begrebet har ikke noget at g&oslash;re
              med dette fly, hvor alle rorflader er trukket af snore!!!
            </p>
            <h2>Fly By Wire til modelfly</h2>
            <p>
              FBW teknikken blev for alvor kendt, da flyproducenten Airbus i
              1984 introducerede deres A320 fly, hvor man radikalt &aelig;ndrede
              m&aring;den at styre fly p&aring;. Mellem piloten og flyet blev
              der indskudt et computersystem, hvor igennem alle pilotens
              beslutninger skulle passere. Denne FBW teknik kan nu anvendes i
              fastvinge modelfly takket v&aelig;re fremkomsten af micro
              mekaniske komponenter (MEMS), s&aring;som 3 akset gyroer og
              accelerometre, kombineret med micro computere indeholdende flight
              software. Se artiklen i Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt2.dk/modelflyvenyt2/2013/Modelflyvenyt.4.2013.pdf"
              >
                nr. 4 2013
              </Link>{" "}
              eller{" "}
              <Link
                target="_blank"
                href="https://elflytec.dk/FlyByWire/Fly-by-Wire"
              >
                Fly By Wire til modelfly
              </Link>
              . Det er alts&aring; ikke l&aelig;ngere SM, som bestemmer
              l&aelig;ngdestabiliteten alene, men et Micro Elektronisk Mekanisk
              System.
            </p>
            <p>
              I 3D modeller vil det nu v&aelig;re muligt at flyve med TP
              placeret t&aelig;t p&aring; NP og dermed f&aring; neutral stabile
              fly. Udover at v&aelig;re l&aelig;ngdestabil skal modellen
              ogs&aring; v&aelig;re retnings- og tv&aelig;rstabil. Disse
              stabilitets former kan ogs&aring; kontrolleres via FBW teknikken.
            </p>
            <p>
              I praksis kan disse stabiliseringssystemer enten k&oslash;bes
              separat og indkobles i alle RC-fabrikater mellem modtageren og
              servoerne, som f.eks. Eagle Tree og Dualsky. Eller de kan
              v&aelig;re fuldt integreret i modtageren, som Graupner /SJ Hott
              gyromodtager eller Spectrum AS3X Stability System. Jeti vil til
              sommeren 2014 introducere to nye FBW produkter med betegnelsen
              Stabilization System Assist, som tilsluttes Jeti modtagerens
              databus. Udover gyroer og accelerometre anvendes ogs&aring;
              h&oslash;jem&aring;lere og magnetometre.
            </p>
            <h2>Test i luften</h2>
            <p>
              I praksis kan du udf&oslash;re f&oslash;lgende dyktest af SM i
              stille luft. Du trimmer f&oslash;rst modellen til at flyve vandret
              og lige ud med ca. &frac12; gas i god h&oslash;jde. Derefter giver
              du lidt nedad ror et par sekunder. Efterf&oslash;lgende slipper du
              pinden til neutral, og du m&aring; nu ikke r&oslash;re den mere.
              Dette svarer til, at du p&aring;virker en at de 4 kugler. Modellen
              vil nu g&aring; ind i et svagt dyk og du skal nu efter
              f&oslash;lgende n&oslash;je iagttage, hvad der sker. Se fig. 8.
            </p>
            <ul>
              <li>
                En overstabil model vil vende n&aelig;sen op for hurtigt som
                kurve 1, og du m&aring; fjerne v&aelig;gt fra n&aelig;sen,
                hvilket m&aring;ske ikke er helt logisk.{" "}
              </li>
              <li>
                En stabil model vil roligt rette op i et svagt stig og genfinde
                sin oprindelige fart som kurve 2.
              </li>
              <li>
                En model som n&aelig;rmer sig neutral stabilitet vil
                forts&aelig;tte med at dykke som kurve 3, og du m&aring;
                naturligvis gribe ind f&oslash;r du rammer jorden.
              </li>
              <li>
                Kurve 4 vil du ikke kunne opleve, fordi modellen ikke kan komme
                op i flyveh&oslash;jden p&aring; grund af ustabilitet.
              </li>
              <li>
                Hvis modellen er udstyret med FBW i 2D mode, vil den
                &oslash;jeblikkelig rette op, fordi en sluppet rorpind virker
                som en reference p&aring; 0 grader, alts&aring; vandret
                flyvning!
              </li>
            </ul>
            <p>
              <img src={fig19} />
              <br />
              Fig. 8
            </p>
            <p>
              Jeg h&aring;ber, hermed du har f&aring;et en bedre
              forst&aring;else af de forskellige begreber og at du kan anvende
              teorien p&aring; dine egne modeller. Du kan ogs&aring; anvende en
              &rdquo;tyngdepunks regnemaskine&rdquo; p&aring; www-adressen:
            </p>
            <p>
              <Link href="http://www.geistware.com/rcmodeling/cg_super_calc.htm">
                http://www.geistware.com/rcmodeling/cg_super_calc.htm
              </Link>
              &nbsp;
            </p>
            <p>
              Ligesom det kan anbefales l&aelig;se Alasdair Sutherlands bog
              Basic Aeronautics for Modellers som kan findes her:
            </p>
            <p>
              <Link href="http://gb.trapletshop.com/basic-aeronautics-for-modellers-2nd-edition-by-alasdair-sutherland">
                http://gb.trapletshop.com/basic-aeronautics-for-modellers-2nd-edition-by-alasdair-sutherland
              </Link>
            </p>
            <p>Happy Landing.</p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
