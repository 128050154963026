import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Lipo/VaerdAtVideOmLipo/fig1.jpg";
import fig2 from "../../../images/Lipo/VaerdAtVideOmLipo/fig2.png";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Værd at vide om Li-Po batterier</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>V&aelig;rd at vide om Li-Po batterier</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret august 2011.</p>
            <p>
              J&oslash;rgen Bj&oslash;rn samler her sin viden om brug af Li-Po
              batterier, herunder 3. generations typer.
            </p>
            <p>
              <span className={classes.red}>
                R&oslash;d tekst refererer til forhold, som kan medf&oslash;re
                &oslash;del&aelig;ggelse af batteriet eller brandfare.
              </span>
            </p>
            <p>
              <span className={classes.green}>
                Gr&oslash;n tekst refererer til forhold som forl&aelig;nger
                batteriets levetid.
              </span>
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Begreb</TableCell>
                  <TableCell>Beskrivelse</TableCell>
                  <TableCell>
                    Eksempel med bemærkninger.
                    <br />I nedenstående eksempel er anvendt et 3. generations
                    batteri på 4000&nbsp;mA/h 11,1&nbsp;V 3S 25&nbsp;C
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Kapacitet</TableCell>
                  <TableCell>
                    Det antal mA der kan aftages fra batteriet i en time.
                  </TableCell>
                  <TableCell>
                    Et batteri på 4000&nbsp;mA/h kan afgive 4&nbsp;Amp i en
                    time. Den dobbelte strøm 8&nbsp;Amp kan da afgives på ½time.
                    o.s.v.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>S størrelse</TableCell>
                  <TableCell>
                    Antal af serieforbundne celler i batteriet. Hver celle har
                    en nominel spænding på 3,7&nbsp;V Opgives på batteriet som
                    en spænding.
                  </TableCell>
                  <TableCell>
                    Batteriet er påstemplet 11,1&nbsp;V. Det indeholder 3
                    serieforbundne Li-Po celler og benævnes også 3S.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>C-værdi</TableCell>
                  <TableCell>
                    1&nbsp;C er den strøm der er lig med mA/h kapaciteten.
                    (milliampere/hour)
                  </TableCell>
                  <TableCell>
                    Batteriet på 4000&nbsp;mA/h vil have en C værdi på
                    4&nbsp;Amp, der således er 1&nbsp;C.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max afladestrøm kontinuerlig.</TableCell>
                  <TableCell>Er opgivet på batteriet som C-værdi.</TableCell>
                  <TableCell>
                    Batteriet er påstemplet 25&nbsp;C. Der må således højest
                    aftages 25x4&nbsp;A=100&nbsp;A kontinuerligt.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Indre modstand</TableCell>
                  <TableCell>
                    Den indre modstand (Ri) er et udtryk for, hvor godt
                    batteriet holder spændingen under belastning. En stor C
                    værdi vil give en lille indre modstand. Nogle ladere kan
                    bestemme Ri i batteriet.
                  </TableCell>
                  <TableCell>
                    Batteriets indre modstand Ri måles til ca. 8 milliohm.
                    <br />
                    Batterispændingen vil således i princippet falde 0,8&nbsp;V,
                    hvis batteriet belastes med 100&nbsp;A. Den indre modstand
                    er temperatur afhængig. Ri falder fra 8 milliohm ved 26
                    grader til 5 milliohm ved 36 grader. Ved minusgader stiger
                    den så meget, at batteriets ydeevne er reduceret i betydelig
                    grad.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Arbejde</TableCell>
                  <TableCell>
                    Arbejde = effekt x tid. Effekt = UxI
                    <br />
                    Det arbejde batteriet kan præstere er derfor = UxIxt.
                  </TableCell>
                  <TableCell>
                    Middelspændingen er 11,1&nbsp;V ved 3 celler i serie.
                    <br />
                    Middelarbejdet bliver derfor 11,1&nbsp;V x 4&nbsp;A x 1 time
                    = 44,4&nbsp;Watt time. Det er Li-Po batteriteknologi, som
                    kan præstere der største arbejde i forhold til sin vægt.
                    Batteriet vejer 316g svarende til 0,14&nbsp;W pr. gram.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cellespredning</TableCell>
                  <TableCell>
                    Da hver enkelt celle har en produktion tolerance, vil de
                    enkelte celler sprede lidt i spænding og kapacitet.
                  </TableCell>
                  <TableCell>
                    Cellespredningen er på ovennævnte batteri 2 til 8&nbsp;mV.
                    Det er karakteristisk, at cellespredningen stiger meget ved
                    den allersidste del af afladningen helt op til 83&nbsp;mV,
                    fordi cellerne ikke har nøjagtig samme kapacitet.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Balancering</TableCell>
                  <TableCell>
                    Er en foranstaltning, som er ubetinget nødvendig for at
                    udligne spændingsforskellen mellem de enkelte celler.
                  </TableCell>
                  <TableCell>
                    Moderne ladere har et balancer kredsløb, som skal tilsluttes
                    batteriets balancer stik. Den eller de celler, som ligger
                    for højt, vil så blive afladt til den spænding, som er på
                    den laveste celle.{" "}
                    <span className={classes.green}>
                      En celle spredning på over 30&nbsp;mV bør give anledning
                      til, at man forsøger at foretage ekstra balancering.
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Højeste oplade spænding.</TableCell>
                  <TableCell>
                    <strong>4,200&nbsp;V pr.celle. Må ikke overskrides.</strong>
                  </TableCell>
                  <TableCell>
                    <strong>
                      <span className={classes.red}>
                        Hvis cellespændingen 4,230&nbsp;V overskrides, kan
                        cellen antænde og bryde i brand.
                      </span>
                    </strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Mindste afladespænding</TableCell>
                  <TableCell>
                    <strong>
                      Den enkelte celle må aldrig komme under 3,0&nbsp;V
                    </strong>
                  </TableCell>
                  <TableCell>
                    <strong>
                      <span className={classes.red}>
                        Hvis cellespændingen kommer under 2,5&nbsp;V, bliver
                        cellen uopretteligt ødelagt.
                      </span>
                    </strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className={classes.green}>Opbevaring</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.green}>
                      <strong>
                        Når batteriet ikke er i brug, skal det opbevares køligt
                        ved en spænding på omkring 3,83&nbsp;V, hvilket svarer
                        til 60&nbsp;% af fuld kapacitet.
                      </strong>
                    </span>{" "}
                    Dette opbevarings krav gælder ikke Li-Fe batterier.
                  </TableCell>
                  <TableCell>
                    <span className={classes.green}>
                      Et fuldt opladt batteri opbevaret i 1 år ved 25 grader
                      taber uopretteligt ca. 20&nbsp;% af sin kapacitet, mens et
                      batteri opbevaret ved 3,83&nbsp;V pr. celle og 25 grader
                      kun taber omkring 2 til 5&nbsp;%.
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>1 Cykle</TableCell>
                  <TableCell>
                    Er en hel eller delvis afladning efterfulgt af en opladning
                    af batteriet.
                  </TableCell>
                  <TableCell>
                    Svarer almindeligvis til en normal flyvning.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className={classes.green}>
                      Ibrugtagning (burn in)
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.green}>
                      Det kan anbefales, at du starter et nyt batteri med et
                      ibrugtagnings program, som langsomt øger belastningen.
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.green}>
                      Det kan bestå af 4 cykles, hvor opladningen holdes på ½C,
                      og hvor afladning øges gradvis 1&nbsp;C, 2&nbsp;C,
                      4&nbsp;C og 8&nbsp;C med tilhørende balancering af
                      cellerne.
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Opladning på gamle typer</TableCell>
                  <TableCell>
                    <strong>
                      Der må kun anvendes en lader, som er beregnet til Li-Po
                      batterier
                      <br />
                      Der oplades med max 1&nbsp;C som ikke må overskrides.
                      <span className={classes.red} />
                    </strong>
                    <br />
                    <span className={classes.green}>
                      Oplad ikke et varmt batteri.
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.green}>
                      Ved opladning til 95&nbsp;% af fuld opladning opnås
                      længere levetid og kun en ringe reduktion af mindsket
                      brugbar kapacitet, samt kortere ladetid.
                    </span>
                    <br />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Opladning ved 1&nbsp;C = 4&nbsp;A
                          </TableCell>
                          <TableCell>Tid</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            Opladning fra{" "}
                            <span className={classes.green}>
                              30&nbsp;% af faktisk kapacitet til 95&nbsp;% af
                              fuld opladning.
                            </span>
                          </TableCell>
                          <TableCell>26 min</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Opladning fra{" "}
                            <span className={classes.green}>
                              30&nbsp;% af faktisk kapacitet til{" "}
                            </span>{" "}
                            fuldt opladt.
                          </TableCell>
                          <TableCell>33 min</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>3. generation</TableCell>
                  <TableCell>
                    3. generations Li-Po batterier er kendetegnet ved at anvende
                    nano teknologi, som betyder, at der kan overføres flere
                    elektroner hurtigere mellem anode og katode.
                  </TableCell>
                  <TableCell>
                    Batteriet kan tåle større C-værdier ved både op og
                    afladning, samtidig opnås mindre indre modstand.
                    <br />
                    Teknikken er udviklet for at opnå korte oplade tider,
                    specielt en nødvendighed for fremtidens elbil marked.
                    <br />
                    Max 6&nbsp;C = 24&nbsp;A. Dog anbefales højest 5&nbsp;C =
                    20&nbsp;A.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Opladning på 3.generation</TableCell>
                  <TableCell>
                    <strong>
                      <span className={classes.red}>
                        Der må kun anvendes en lader, som er beregnet til Li-Po
                        batterier
                      </span>
                    </strong>
                    <br />
                    <span className={classes.red}>
                      Der må max oplades med den C-værdi fabrikanten
                      foreskriver.
                    </span>
                    <br />
                    <span className={classes.green}>
                      Oplad ikke et varmt batteri
                    </span>
                    <br />
                    Da batteriet ikke må ligge opladt, stilles der krav til
                    hurtig opladning, når det skal anvendes.
                    <strong>
                      Dette stiller stigende krav til laderens opladestrøm og
                      dermed effekt.
                    </strong>
                  </TableCell>
                  <TableCell>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Opladning ved 2&nbsp;C = 8&nbsp;A
                          </TableCell>
                          <TableCell>Tid</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            Opladning fra opbevaringsspænding (11,4&nbsp;V) til{" "}
                            <span className={classes.green}>
                              95&nbsp;% af fuld opladning
                            </span>
                          </TableCell>
                          <TableCell>16 min</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Opladning fra opbevaringsspænding (11,4&nbsp;V) til
                            fuldt opladt.
                          </TableCell>
                          <TableCell>21 min</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Opladning fra{" "}
                            <span className={classes.green}>
                              30&nbsp;% af faktisk kapacitet til 95&nbsp;% af
                              fuld opladning
                            </span>
                          </TableCell>
                          <TableCell>30 min</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Opladning ved 4&nbsp;C = 16&nbsp;A
                          </TableCell>
                          <TableCell>Tid</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            Opladning fra{" "}
                            <span className={classes.green}>
                              30&nbsp;% af faktisk kapacitet til 95&nbsp;% af
                              fuld opladning
                            </span>
                          </TableCell>
                          <TableCell>9 min</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Opladning fra 30&nbsp;% af faktisk kapacitet til
                            fuld opladning.
                          </TableCell>
                          <TableCell>15 min</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start kapacitet</TableCell>
                  <TableCell>Målt kapacitet ved første cykle.</TableCell>
                  <TableCell>
                    Målt til 3780&nbsp;mA/h = 94&nbsp;% af påstemplet kapacitet.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Faktisk kapacitet</TableCell>
                  <TableCell>
                    Faktisk kapacitet er den kapacitet batteriet har fra fuldt
                    opladt til den{" "}
                    <span className={classes.green}>
                      første celle i batteriet når 3,3&nbsp;V målt ved 1&nbsp;C.
                    </span>
                  </TableCell>
                  <TableCell>
                    Ved cykle 30 og 16 måneder gammelt måles den faktiske
                    kapacitet til 3489&nbsp;mA/h = 92&nbsp;% af start
                    kapaciteten.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Brugbar kapacitet og afladning</TableCell>
                  <TableCell>
                    <strong>
                      <span className={classes.green}>
                        Ca. 70&nbsp;% af den faktiske kapacitet.
                      </span>
                    </strong>
                  </TableCell>
                  <TableCell>
                    <span className={classes.green}>
                      Beregnes til 70&nbsp;% af 3489&nbsp;mA/h = 2442&nbsp;mA/h.
                      Det er, hvad du må bruge, når du flyver. Kun godt det
                      halve af hvad der står på batteriet!
                    </span>
                    <br /> Afladningen foregår af gode grunde normalt oppe i
                    luften og kan derfor ikke så nemt overvåges men: Som minimum
                    bør anvendes et ur, som måler flyvetiden. På nogle sendere
                    findes der en timer som aktiveres af motorkanalen.{" "}
                    <span className={classes.green}>
                      Ideelt telemetreres mA/h forbruget tilbage til et display
                      under flyvningen (Se f.eks. JETI systemet). Regulatoren
                      indstilles til 3,3&nbsp;V pr.celle, fordi den måler{" "}
                      <strong>cellespændingernes sum</strong>, og derfor ikke
                      tager hensyn til cellespredningen.
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Temperatur</TableCell>
                  <TableCell>
                    <span className={classes.red}>Max 60&nbsp;C grader.</span>
                  </TableCell>
                  <TableCell>
                    Ved 7½C = 30&nbsp;A kontinuerlig afladestrøm stiger
                    temperatur med 10 grader uden køling.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PC tilslutning og grafer</TableCell>
                  <TableCell>
                    Mange ladere kan via USB tilsluttes en Windows computer og
                    dermed udskrive grafer over alle tænkelige parametre,
                    herunder temperatur hvis en føler tilsluttes. Enten
                    medfølger softwaren til laderen eller også kan anvendes
                    programmet LogView.
                  </TableCell>
                  <TableCell>
                    <img src={fig1} /> Eksempel på en graf.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Logning</TableCell>
                  <TableCell>
                    Logning af batteriet er et ”regnskab”, hvor der registreres,
                    hvor mange cykles m.m. batteriet gennemløber i sit liv.
                  </TableCell>
                  <TableCell>
                    Manuel logning skrevet på batteriet. Gummibåndet viser, at
                    batteriet er fuldt opladt, således at du undgår at montere
                    et afladt batteri i flyet. <img src={fig2} /> Hvis en
                    Hyperion Net- eller Duolader tilkobles en Windows computer,
                    oprettes automatisk en logning (SQL database), som
                    registrerer al trafik på laderen med tilhørende grafer.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max antal cykles og levetid</TableCell>
                  <TableCell>
                    Det antal cykles batteriet kan opnå, indtil ydelsen bliver
                    dårligere end 70&nbsp;% af startkapaciteten.
                  </TableCell>
                  <TableCell>
                    Levetiden påvirkes af tiden, hvor mange C der trækkes,
                    spændingsgrænser samt temperaturen. Man behøver naturligvis
                    ikke at anvende 70&nbsp;%, men er normalt grænsen. Man kan
                    forvente mellem 100 og 200 cykles.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sikkerhed</TableCell>
                  <TableCell>
                    Litium kan eksplodere i en termisk run-away proces. Dette
                    betyder, at batteriet selv fortsætter med at frembringe
                    varme. En proces der kan risikere at antænde brand, hvis
                    batteriet håndteres forkert.
                  </TableCell>
                  <TableCell>
                    <strong>
                      <span className={classes.red}>
                        Vær omhyggelig med at indstille din lader korrekt før
                        opladning påbegyndes.
                        <br /> Opladning skal altid overvåges fra start til
                        slut.
                        <br /> Batteriet anbringes på ikke brændbart underlag og
                        sådan at en brand ikke kan spredes. Gerne i en LiPo
                        pose.
                        <br /> Oplad ikke batteriet i en bil.
                        <br /> I tilfælde af brand anvendes en pulverslukker og
                        absolut ikke vand, som kan medføre eksplosion.
                        <br /> Mekaniske skader efter nedstyrtning medfører, at
                        batteriet er brandfarligt, fjern det hurtigst muligt fra
                        modellen.
                        <br /> Hvis batteriet har oppustede celler, skal det
                        kasseres.
                      </span>
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
