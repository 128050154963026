import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig1.jpg";
import fig2 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig2.jpg";
import fig3 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig3.jpg";
import fig4 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig4.png";
import fig5 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig5.png";
import fig6 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig6.png";
import fig7 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig7.png";
import fig8 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig8.jpg";
import fig9 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig9.jpg";
import fig10 from "../../../images/Grundviden/BeregningerAfEtElflysData/fig10.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>
            Hvordan elflyet virker - Del 1. Beregninger af et elflys data
          </title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Del 1. Beregninger af et elflys data</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret juli 2013.</p>
            <p>
              I del 1 bliver opstillet en fremgangsm&aring;de, s&aring;ledes du
              kunne beregne et elflys data.
              <br />
              <Link href="/Grundviden/HvordanElflyetVirker/Simulation-af-et-elflys-data">
                I del 2 foretages en computer simulation af data ved hj&aelig;lp
                af programmet MotoCalc.
              </Link>
              <br />
              <Link href="/Grundviden/HvordanElflyetVirker/Maalinger-af-et-elflys-data">
                I del 3 m&aring;les flyets data i praksis, ved at telemetridata
                recordes og efterf&oslash;lgende analyseres.
              </Link>
              <br />
              M&aring;let med de tre artikler er, at modelflyveren f&aring;r en
              bedre forst&aring;else af, hvordan elflyet virker.
            </p>
            <h2>Flyets energiindhold og flyvesekvenser</h2>
            <p>
              N&aring;r et fly flyver er det i besiddelse af b&aring;de
              beliggenheds- og bev&aelig;gelsesenergi. Beliggenhedsenergien
              ogs&aring; kaldet potentiel energi kan bestemmes som 9,81 x flyets
              v&aelig;gt x flyveh&oslash;jden. Bev&aelig;gelsesenergien kan
              bestemmes som &frac12; x flyets v&aelig;gt x med flyvefarten i
              anden potens. Disse to energiformer kan veksles med hinanden.
              H&oslash;jde til speed eller speed til h&oslash;jde. For at kunne
              beregne, simulere og m&aring;le p&aring; flyet, er det
              n&oslash;dvendigt, at opdele flyvningen i klart adskilte
              flyvesekvenser hvor man kun &aelig;ndre den ene energiform. Det er
              ligeledes n&oslash;dvendigt at holde hastigheden konstant.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sekvens</TableCell>
                  <TableCell>Beskrivelse</TableCell>
                  <TableCell>Resultatet af artiklens beregninger</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Stigning</TableCell>
                  <TableCell>
                    I stigsekvensen bringes flyet til at stige så meget som
                    muligt, med konstant hastighed, indtil en max. højde på 100
                    m nås.
                  </TableCell>
                  <TableCell>6m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max. speed</TableCell>
                  <TableCell>
                    Det undersøges, hvor hurtigt flyet kan flyve ved vandret
                    flyvning med konstant hastighed og højde.
                  </TableCell>
                  <TableCell>20m/sek = 72 km/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cruise speed</TableCell>
                  <TableCell>
                    Der flyves på en sådan måde, at den maximale flyvetid og
                    flyvestrækning opnås ved vandret flyvning med konstant
                    hastighed og højde.
                  </TableCell>
                  <TableCell>13m/sek = 46,8 km/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Min. speed</TableCell>
                  <TableCell>
                    Der flyves så langsomt som muligt, men flyet skal være
                    stabilt med konstant hastighed og højde.
                  </TableCell>
                  <TableCell>11m/sek = 39,6 km/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Glide speed</TableCell>
                  <TableCell>
                    Glideflyvning med roterende propel. Motoren tilføres ikke
                    effekt.{" "}
                  </TableCell>
                  <TableCell>10m/sek = 36 km/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Stall speed</TableCell>
                  <TableCell>Flyet staller.</TableCell>
                  <TableCell>9m/sek = 32,4 km/t</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Udledte data</h2>
            <p>
              Ud fra flyvesekvenserne kan yderligere udledes en r&aelig;kke
              data, som beskriver flyets ydeevne.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sekvens</TableCell>
                  <TableCell>Beskrivelse</TableCell>
                  <TableCell>Resultatet af artiklens beregninger</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Max. tophøjde</TableCell>
                  <TableCell>
                    Højden 100m må ikke overskrides, men kan beregnes.
                  </TableCell>
                  <TableCell>1746 meter</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Synkehastighed</TableCell>
                  <TableCell>
                    Højdetab med stoppet motor og roterende propel.
                  </TableCell>
                  <TableCell>2m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Flyvetid</TableCell>
                  <TableCell>Maksimal flyvetid.</TableCell>
                  <TableCell>21min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Glidetal</TableCell>
                  <TableCell>
                    Antal meter flyet flyver for 1 meters højdetab, med
                    roterende propel og uden tilført effekt til motor.
                  </TableCell>
                  <TableCell>5 til 1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rækkevidde</TableCell>
                  <TableCell>Maksimal gennemfløjet distance.</TableCell>
                  <TableCell>16,4km</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Speed-range</TableCell>
                  <TableCell>Forskellen mellem min. og max. speed. </TableCell>
                  <TableCell>2x</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>”Brændstof økonomi”</TableCell>
                  <TableCell>
                    Hvor mange watttimer bruges pr. km ved cruise speed.{" "}
                  </TableCell>
                  <TableCell>1,6&nbsp;W/time pr. km</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Flexflyer</h2>
            <p>
              Elflybegrebet sp&aelig;nder i dag meget vidt fra indend&oslash;rs
              fly med en total v&aelig;gt p&aring; under 10g og op til meget
              store konstruktionen p&aring; mange kg. Flyene kan ogs&aring; have
              meget forskellige egenskaber, s&aring; som kunstfly, 3D,
              sv&aelig;vefly eller F5B hot-linere. Det vil f&oslash;re for vidt
              at se p&aring; alle disse variationer, men beregningsgrundlaget er
              det samme for alle elfly.
            </p>
            <p>
              Der tages nu udgangspunkt i et bestemt fly, nemlig forfatterens
              Flexflyer, som er en egen konstruktion bygget i 2001. Det er en
              ordin&aelig;r allround tr&aelig;ner, som kan anvendes til
              kunstflyvning. Der er anvendt 4mm kulr&oslash;r i kroppen for max
              styrke.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig1} />
                  <br />
                  Flexflyeren anvendes som eksempel i alle 3 artikler.
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Flexflyer data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Spændvidde: 136&nbsp;cm</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Korde: 28&nbsp;cm</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Planareal: 36,4dm</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Profil: Eppler 474 symmetrisk </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total vægt: 1500g</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Propel: 9x5</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Motor: Mega 30/3</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Batteri: Li-Po 3S 25&nbsp;C 3700&nbsp;mA/h
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Eagle Tree Guardian 2D/3D Stabiliser. (3akset gyro
                        fly-by wire)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Modtager: Jeti 2,4 GHz med 4 telemetri moduler og flight
                        recorder.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Se nærmere i menuen "Jeti RC og Telemetri"
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <p>
              Inden beregningerne foretages, skal vi se p&aring; profilet som i
              h&oslash;j grad er afg&oslash;rende for flyveegenskaberne. Der er
              valgt et Eppler 474symmetrisk 14&nbsp;% tykt profil, som er
              velegnet til kunstflyvning, se fig. 1. Med et reynoldstal p&aring;
              ca. 200000 staller profilet ved en opdrift koefficient p&aring;
              ca. 0,8. Reynoldstallet beregnes som flyvefart x korde x 700.
              (10m/sek x 28&nbsp;cm x 700 = 196000) Derefter udl&aelig;ses
              opdrift koefficienten i polardiagrammet. Ved langsomst mulig
              flyvning anvendes derfor 0,7. Se fig. 2 r&oslash;d pil.
            </p>
            <p>
              Med sit lille sideforhold, fast understel, symmetriske profil og
              en bremsende roterende propel m&aring; forventes et d&aring;rligt
              glideforhold og en stor synkehastighed.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig2} />
                  <br />
                  Fig. 1
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig3} />
                  <br />
                  Fig. 2
                </p>
              </Grid>
            </Grid>
            <h2>Fremgangsm&aring;de for overslagsberegning af et elfly</h2>
            <p>
              Der skal nu opstilles fremgangsm&aring;de og teori for, hvordan
              flyets ydeevne i grove tr&aelig;k kan bestemmes, s&aring;ledes at
              du f&aring;r en bedre forst&aring;else af, hvordan tingene
              h&aelig;nger sammen. Alle beregninger er meget grove
              overslagsberegninger og tjener kun til at vise sammenh&aelig;nge,
              som forh&aring;bentlig hj&aelig;lper til at forst&aring;
              &rdquo;mekanismerne&rdquo; i elflyet. Eksemplet Flexflyer findes i
              de firkantede kasser.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <h3>1. Vurdering af synkehastighed</h3>
            <p>
              For at opn&aring; mindst mulig synkehastighed skal flyet have
              s&aring; stort glidetal som muligt og samtidig have en s&aring;
              lav flyvefart som mulig. Da glidetallet bestemmes af forholdet
              mellem vingens opdrift og flyets samlede luftmodstand g&aelig;lder
              det naturligvis om at holde al un&oslash;dvendig luft modstand
              nede. Jo langsommere flyet flyver nedad glidebanen, desto
              l&aelig;ngere flyvetid f&aring;s. Flyets flyvefart bestemmes af
              flyets v&aelig;gt, planarealet og vingens evne til at b&aelig;re.
              Til sidstn&aelig;vnte evne knyttes opdriftkoeficienten, som er
              bestemt af profilet, indfaldsvinklen og reynoldstal. De viste
              ligninger beskriver sammenh&aelig;nget mellem 3 vigtige begreber.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <h3>2. Fastl&aelig;ggelse af stighastighed</h3>
            <p>
              Med udgangspunkt i flytypen bestemmes, hvor hurtigt flyet skal
              stige. Dette afh&aelig;nger naturligvis af flytypen. En typisk
              indend&oslash;rsmodel skal blot flyve rund i en hal med en
              stighastighed p&aring; 1m/sek, mens en F3F hot-liner
              konkurrencemodel stiger med over 30m/sek.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  Som beregningseksempel anvendes den viste Flexflyer, som vil
                  være 17 sekunder om at nå 100meters højde.
                </p>
              </Grid>
              <Grid item xs={12} md={6} className={classes.box}>
                <p>
                  Flexflyer: <br />
                  Stig hastighed fastsættes til 6m/sek
                </p>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <h3>3. Sum af stig- og synkehastighed</h3>
                <p>
                  Til en vurdering af hvor stor en effekt flyet skal have
                  tilf&oslash;rt, n&aring;r det skal stige, indg&aring;r
                  ogs&aring; synkehastigheden udfra den betragtning, at
                  synkehastigheden er et udtryk for et effekttab, som under alle
                  omst&aelig;ndigheder skal erstattes, uanset om flyet flyver
                  vandret eller stiger.
                </p>
              </Grid>
              <Grid item xs={12} md={6} className={classes.box}>
                <p>
                  Flexflyer: <br />
                  Sum af stig og synk = 6m/sek +2m/sek = 8m/sek
                </p>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <h3>4. Modellens v&aelig;gt</h3>
                <p>
                  Det er altid &oslash;nskeligt at holde modellens v&aelig;gt
                  s&aring; lav som muligt. Bem&aelig;rk f&oslash;lgende:
                </p>
              </Grid>
              <Grid item xs={12} md={6} className={classes.box}>
                <p>Flexflyer:</p>
                <p>Hard-ware: 800g</p>
                <p>Balsa-ware: 700g</p>
                <p>I alt 1,5kg</p>
              </Grid>
            </Grid>
            <ul>
              <li>
                Hvis v&aelig;gten &oslash;ges til det dobbelte stiger stall
                speed 40&nbsp;%.
              </li>
              <li>
                Hvis v&aelig;gten &oslash;ges, &oslash;ges indfaldsvinklen for
                at frembring kompenserende opdrift, dermed stiger vingens
                modstand. Hvis fremdriften er den samme vil den maximale
                flyvefart falde.
              </li>
              <li>Et tungere fly har alt andet lige en mindre speed-range.</li>
              <li>
                Hvis et fly flyver 40&nbsp;% hurtigere bliver indholdet af
                bev&aelig;gelsesenergi dobbelt s&aring; stort. Har betydning for
                bremsel&aelig;ngder ved landinger og kollisioner med jorden!
              </li>
              <li>
                Nye materialer s&aring;som kul og depron kan mindske
                v&aelig;gten. Flexflyer anvender 4mm kulr&oslash;r i kroppen.
              </li>
              <li>
                Det er retningsgivende, at halvdelen af v&aelig;gten af elflyet
                er hardware s&aring; som motor, batteri, regulator, radio og
                servoer.
              </li>
            </ul>
            <h3>5. Beregning af propellens n&oslash;dvendige effekt</h3>
            <p>
              Opgaven g&aring;r nu ud p&aring; at kunne sl&aelig;be en masse
              p&aring; 1,5kg til vejrs med en stighastighed p&aring; 6m/sek og
              kompensere for modellens tab. Efter 16,6 sekunder vil modellen
              befinde sig i 100 meter h&oslash;jde og v&aelig;re i besiddelse af
              beliggenhedsenergi. For at bestemme den n&oslash;dvendige effekt
              anvender vi formelen for beliggenhedsenergi. Faktoren 9,81 er
              knyttet til jordens tyngde acceleration. Tallet rundes op til 10,
              hermed antyder jeg at alle beregninger er overslagsberegninger med
              betydelig usikkerhed.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  P<sub>prop</sub> = 10 x modellens v&aelig;gt x (summen af
                  stige og synkehastighed)
                </p>
              </Grid>
              <Grid item xs={12} md={6} className={classes.box}>
                <p>
                  Flexflyer: P<sub>prop</sub> = 10 x 1,5kg x 8 = 120&nbsp;watt
                </p>
              </Grid>
            </Grid>
            <p>
              Propellen skal alts&aring; yde en effekt p&aring; 120&nbsp;W i
              form af tr&aelig;k og fart i 16,6 sekunder for at f&aring;
              modellen p&aring; 1,5kg op i 100m. I det udf&oslash;rt arbejde er
              effekt x tid kan man opstille f&oslash;lgende tabel for en model
              p&aring; 1,5Kg.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Stigehastighed</TableCell>
                  <TableCell>
                    P<sub>prop</sub>
                  </TableCell>
                  <TableCell>Tid for 100m</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>3m/sek</TableCell>
                  <TableCell>75&nbsp;W</TableCell>
                  <TableCell>33sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>6m/sek (valgt til Flexflyeren)</TableCell>
                  <TableCell>120&nbsp;W</TableCell>
                  <TableCell>17sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>12/sek</TableCell>
                  <TableCell>210&nbsp;W</TableCell>
                  <TableCell>8sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>24/sek</TableCell>
                  <TableCell>390&nbsp;W</TableCell>
                  <TableCell>4sek</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Som det kan ses af ovenst&aring;ende, er det stigevnen og
              modellens v&aelig;gt, som stiller effekt kravene til propel,
              motor, regulator og Li-Po akkumulator.
            </p>
            <p>
              F&oslash;r vi g&aring;r videre, skal vi se p&aring; propellen.
              Propellens opgave er at oms&aelig;tte den effekt, der er til
              r&aring;dighed p&aring; den roterende motoraksel til fremdrift og
              fart af modellen s&aring; effektivt som muligt.
              <br />
              Fremdriften kan dels ses som et tryk (aktion, reaktion) dels kan
              propellen betragtes som en roterende vinge med et profil som
              frembringer opdrift, alts&aring; fremdrift eller tr&aelig;k i
              flyveretningen. Se Fig. 3
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig. 3
            </p>
            <p>
              Denne opgave er kompliceret, men samtidig er det her,
              modelflyveren har en nem mulighed for at &aelig;ndre flyets
              ydeevne betydeligt.
              <br />
              F&oslash;lgende sammenh&aelig;nge skal belyse nogle af begreberne
              omkring propellen:
            </p>
            <p>
              {" "}
              Optaget effekt = K<sub>p</sub> x Diameteren<sup>4</sup> x
              Stigningen x Omdrejningerne<sup>3</sup>
            </p>
            <p>Udfra formelen kan ses at:</p>
            <ul>
              <li>
                Hvis du fordobler diameteren 16 dobler, du den effekt propellen
                optager. (Du skifter fra en 6x6 til en 12x6 ved de samme
                omdrejninger)
              </li>
              <li>
                Hvis du fordobler stigningen, dobler du den effekt propellen
                optager. (Du skifter fra en 12x6 til en 12x12 ved de samme
                omdrejninger.)
              </li>
              <li>
                Hvis du fordobler omdrejningerne, 8 dobler du den effekt
                propellen optager. (Du fordobler omdrejningerne p&aring; den
                samme propel)
              </li>
              <li>
                Kp er en koefficient, som er forskellig fra propelfabrikat til
                propelfabrikat.
              </li>
              <li>
                For nogle propeller kan du f&aring; oplyst, hvor mange
                omdrejninger den skal k&oslash;re for at den lige
                n&oslash;jagtig optager 100&nbsp;W.
              </li>
            </ul>
            <p>
              Trykket er forskellen mellem lufthastigheden bag propellen og
              foran propellen. Det st&oslash;rste tryk er det statiske tryk, som
              opn&aring;s n&aring;r flyet st&aring;r stille. Trykket er nul,
              hvis der ikke er forskel p&aring; lufthastigheden foran og bagved
              propellen, denne tilstand kan frembringes i et dyk.
            </p>
            <p>
              Den effekt der tilf&oslash;res flyet fra propellen er lig tryk x
              flyvehastighed.
            </p>
            <p>
              <img src={fig7} />
              <br />
              Fig. 4
            </p>
            <p>
              Fig. 4 viser sammenh&aelig;nget mellem tr&aelig;k, den effekt
              propellen optager, samt virkningsgraden p&aring; en 9x6 propel ved
              10000 omdrejninger p&aring;virket af en luftstr&oslash;m i en
              virtuel vindtunnel med en hastighed fra 0 til 30m/sek.
            </p>
            <ul>
              <li>
                Bem&aelig;rk at tr&aelig;kket falder ved stigende hastighed for
                at ende p&aring; 0. Hvis hastigheden stiger yderligere, vil
                propellen virke som luftbremse. Kunne ske hvis modellen dykker.
              </li>
              <li>
                Den effekt som propellen optager, vil ligeledes falde ved
                stigende hastighed og motoren vil bruge mindre str&oslash;m.
              </li>
              <li>
                Virkningsgraden er 0, n&aring;r flyet holder stille samtidig med
                at det statiske tr&aelig;k er maximalt. Ved 30m/sek. var
                virkningsgraden ligeledes 0. Der m&aring; alts&aring; v&aelig;re
                en bestemt flyvefart, hvor propellen har den maximale
                virkningsgrad.
              </li>
            </ul>
            <p>
              Det er vigtigt at beregne alt s&aring; optimalt som muligt, for at
              f&aring; s&aring; h&oslash;j en virkningsgrad som muligt i det
              valgte flyvefartsomr&aring;de.
            </p>
            <h3>
              6. Bestemmelse af max flyvehastighed ved vandret flyvning og valg
              af propellens stigning
            </h3>
            <p>
              Flyets minimums speed er beregnet til 10m/sek. Som udgangspunkt
              s&aelig;tter vi flyets max speed til det dobbelte, alts&aring;
              20m/sek. F&oslash;lgende skal bem&aelig;rkes:{" "}
            </p>
            <ul>
              <li>
                Propellens tr&aelig;k aftager med stigende hastighed, fordi
                forskellen mellem lufthastigheden lige bag propellen og foran
                propellen bliver mindre.
              </li>
              <li>
                Luftmodstanden p&aring; modellen stiger n&aelig;sten kvadratisk.
                L&oslash;seligt ansl&aring;et er luftmodstanden ved 20m/sek
                blevet n&aelig;sten fire gange s&aring; stor som ved 10m/sek.
                N&aring;r jeg skriver n&aelig;sten, er det fordi, vingens
                modstand falder lidt p&aring; grund af en mindre indfaldsvinkel
                ved 20m/sek.
              </li>
              <li>
                Max speed ved vandret flyvning optr&aelig;der, n&aring;r
                fremdriften ved max motor omdrejninger er lig med modellens
                luftmodstand.
              </li>
            </ul>
            <h3>7. Beregning af motorens omdrejninger</h3>
            <p>
              Hvis der v&aelig;lges en propel med en stigning p&aring; 6 tommer,
              vil det nu v&aelig;re muligt at beregne motoromdrejningerne, hvis
              du &oslash;nsker at flyve 20m/sek. (72 km/t)
              <br />
              Hvis man drejer propellen ind i en sm&oslash;rklump, kan vi regne
              ud, hvor langt frem den kommer. Helt s&aring; langt frem kommer
              propellen ikke i luften. Et godt udgangspunkt, er at
              lufthastigheden lige bag propellen er 1,3 gange st&oslash;rre end
              flyets fart. Ud fra dette kan opstille f&oslash;lgende ligning:
            </p>
            <p>
              <img src={fig8} />
            </p>
            <h3>8. Valg af propellens diameter og virkningsgrad</h3>
            <p>
              Generelt kan man sige, at hvis diameteren er dobbelt s&aring; stor
              som stigningen, er propellen egnet til langsomme modeller. Ved 3D
              fly er diameteren mere end dobbelt s&aring; stor som stigningen,
              for at muligg&oslash;re at flyet i lodret stilling kan h&aelig;nge
              stille i luften, g&aelig;lder ogs&aring; for en helikopter. Hvis
              stigningen er lig diameteren, er propellen egnet til meget hurtige
              modeller. Flexflyeren m&aring; karakteriseres som at ligge midt i
              mellem, derfor v&aelig;lges en diameter som er 1,5 st&oslash;rre
              end stigningen.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>
                  Flexflyer: Propel APC E 9x6. <br />
                  Omdrejninger for 100&nbsp;W = 7997 omdr/min
                </p>
              </Grid>
            </Grid>
            <p>
              Propellens virkningsgrad er bestemt af en r&aelig;kke faktorer
              s&aring; som udformning af propelbladet, antal blade, hvor stor en
              effekt der tilf&oslash;res i forhold til propellens diameter m.m.
              Max virkningsgrad optr&aelig;der kun ved en bestemt kombination af
              diameter, stigning, omdrejningstal og flyvefart. Hvis vi arbejder
              med 10000 omdrejninger pr minut, skal man forvente en
              virkningsgrad p&aring; omkring 50&nbsp;%.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>Flexflyer: Propellens virkningsgrad = 50&nbsp;%</p>
              </Grid>
            </Grid>
            <h3>9. Beregning af motorens mekaniske ydelse p&aring; akslen</h3>
            <p>
              Idet propellens virkningsgrad kun er 50&nbsp;% bliver motoren
              n&oslash;dt til at yde mere end den dobbelte effekt p&aring;
              akslen. Motorens mekaniske effekt er omdrejninger x drejmoment og
              m&aring;les i watt eller hestekr&aelig;fter. 1hk = 745&nbsp;watt.
            </p>

            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>
                  Flexflyer: Motorens mekaniske udgangseffekt p&aring; akslen
                  =(Effekt p&aring; propellen)/(Propellens Virkningsgraden) =
                  120&nbsp;W/0,50 = 240&nbsp;W
                </p>
              </Grid>
            </Grid>
            <h3>10. Bestemmelse af motorens virkningsgrad</h3>
            <p>
              En b&oslash;rstel&oslash;s motor har en typisk virkningsgrad
              p&aring; 85 til 75&nbsp;% ved h&oslash;j udgangseffekt. Se fig. 5.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>Flexflyer: Motorens virkningsgrad = 77&nbsp;%</p>
              </Grid>
            </Grid>
            <h3>11. Beregning af motorens elektriske indgangseffekt</h3>
            <p>
              Vi er nu n&aring;et s&aring; langt, at vi kan bestemme den
              elektriske effekt, motoren skal have tilf&oslash;rt.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>
                  Den effekt regulatoren skal afgive=(Motorens mekaniske
                  udgangseffekt p&aring; akslen)/(Motorens virkningsgrad)
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>
                  Flexflyer: Regulator skal afgive=240&nbsp;W/0,77=311&nbsp;W
                </p>
              </Grid>
            </Grid>
            <h3>12. Valg af motor</h3>
            <p>
              Motorens fysiske st&oslash;rrelse bestemmes ud fra den maximale
              effekt, den skal kunne oms&aelig;tte, alts&aring; 400&nbsp;W.
              Micro elfly flyver med en Li-Po celle, mens de st&oslash;rste
              elfly anvender op til 12 serieforbundne Li-Po celler, ogs&aring;
              ben&aelig;vnt 12S. Udfra at Flexflyet er et mellemstort elfly,
              bestemmes at der anvendes 3 S d.v.s. at batterisp&aelig;ndingen
              ligger mellem 12,6&nbsp;V og 9,9&nbsp;V Nominelt 11.1&nbsp;V. Den
              valgte motor er en Mega ACn 22/30/3. Et meget vigtigt data
              p&aring; motoren er Kv v&aelig;rdien, som oplyser, om hvor mange
              omdrejninger motoren k&oslash;rer pr. volt. Motoren findes i flere
              varianter med forskellig Kv v&aelig;rdi. P&aring; den anvendte
              motor er Kv = 1287omdr pr. volt. D. v. s. at motoren k&oslash;rer
              11,1&nbsp;V x 1287rpm = 14285 rpm i ubelastet tilstand. P&aring;
              fig. 3 vises en computersimulation af hvordan den valgte motors
              omdrejninger, udgangseffekt og virkningsgrad &aelig;ndres ved en
              str&oslash;m fra 0&nbsp;A til 45&nbsp;A ved 10,6&nbsp;V. Belastet
              med en 9x6 propel tr&aelig;kkes 31,8&nbsp;A vist som gr&oslash;nne
              dots. Omdrejningstallet falder en del som f&oslash;lge af
              belastning af motoren (Bl&aring; kurve) Bem&aelig;rk at
              brugsomr&aring;det er fra ca. 7,5&nbsp;A til max 40&nbsp;A. Den
              st&oslash;rste virkningsgrad er 83&nbsp;% som opn&aring;s ved
              13&nbsp;A, mens virkningsgraden er 77&nbsp;% ved 32&nbsp;A, som er
              den st&oslash;rste str&oslash;m, vi bruger. Omdrejningerne er
              afh&aelig;ngige af batterisp&aelig;ndingen, mens drejmomentet er
              bestemt af str&oslash;mmen. Produktet af sp&aelig;nding gange
              str&oslash;m giver effekten.
            </p>
            <p>
              <img src={fig9} />
              <br />
              Fig. 5
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>Flexflyer: Valgt motor = Mega 22/30/3</p>
              </Grid>
            </Grid>
            <h3>
              13. Bestemmelse af regulatorens indgangs effekt og
              str&oslash;mforbrug
            </h3>
            <p>
              Regulatorens opgave er at omdanne j&aelig;vnsp&aelig;ndingen fra
              batteriet til en 3-faset vekselsp&aelig;nding. Dette foreg&aring;r
              ved at sende str&oslash;m igennem motorens stator spoler p&aring;
              de rigtige tidspunkter. Til dette form&aring; anvendes MOSFET
              transistorer som kontakter. Da disse ikke er ideelle,
              afs&aelig;ttes effekt. Dette betyder at virkningsgraden ikke er
              100&nbsp;%, men s&aring; h&oslash;j som 97&nbsp;%, resten bliver
              til varme. Der er yderligere et tab i ledninger og stik som ved
              h&oslash;je str&oslash;mme har en betydning. Der er s&aring;ledes
              et samlet tab p&aring; 5&nbsp;%.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>
                  Flexflyer: Grundet de beskrevne tab m&aring; ydelsen
                  &oslash;ges med 5&nbsp;% til 328&nbsp;W
                </p>
              </Grid>
            </Grid>
            <p>
              Vi v&aelig;lger som beskrevet 3S = 11,1&nbsp;V. Man skal her
              v&aelig;re opm&aelig;rksom p&aring;, at denne sp&aelig;nding er
              den ubelastede sp&aelig;nding p&aring; batteriet. S&aring;
              n&aring;r batteriet bliver belastet med en h&oslash;j
              str&oslash;m, vil man sammen med sp&aelig;ndingsfald i ledninger
              og stik realistisk kun have 10,6&nbsp;V til r&aring;dighed
              p&aring; regulatorens terminaler. Idet effekt = sp&aelig;nding x
              str&oslash;m kan man nu bestemme batteristr&oslash;mmen.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.box}>
                <p>
                  Flexflyer:
                  Batteristr&oslash;m=328&nbsp;W/10,6&nbsp;V=30,9&nbsp;A
                </p>
              </Grid>
            </Grid>
            <p>
              Bem&aelig;rk: Regulatoren skal mindst kunne t&aring;le antallet af
              valgte Li-Po celler, samt den max. str&oslash;m den uds&aelig;ttes
              for. Regulatoren skal ogs&aring; beskytte Li-Po batteriet mod for
              stor afladning. Nogle regulatorer indeholder et BEC
              kredsl&oslash;b, som forsyner modtageren og servoer med
              sp&aelig;nding fra motorbatteriet.
            </p>
            <h3>14. Valg af Li-Po Batteri</h3>
            <p>
              F&oslash;rst blev batteriet valgt til at have 3 celler. Derefter
              v&aelig;lges kapaciteten p&aring; batteriet til at v&aelig;re
              3700mA/timer. Idet 1&nbsp;C = 3,7&nbsp;A vil 34&nbsp;A v&aelig;re
              9,18&nbsp;C. Idet batteriet kan belastes med max 25&nbsp;C, og kun
              max bliver belastet med 9&nbsp;C, er det ikke overbelastet.
            </p>
            <h3>15. Beregning af max k&oslash;retid ved fulde omdrejninger</h3>
            <p>
              Det, at batteriet er p&aring;stemplet 3700mA/timer, er
              n&oslash;dvendigvis ikke sandheden. Det nye batteri kan vise sig
              kun at indeholde f.eks. 3600mA/timer. Dertil kommer, at du kun
              b&oslash;r bruge 70&nbsp;% af den faktiske kapacitet.
              &Aring;rsagen er, at regulatoren kun m&aring;ler summen af
              cellerne, s&aring; p&aring; grund af cellespredning kan en af
              cellerne komme under den kritiske gr&aelig;nse p&aring;
              3,0&nbsp;V. S&aring; nu er der kun 2500 mA/timer til
              r&aring;dighed.
              <br />
              Nu kan k&oslash;retiden ved fulde omdrejninger beregnes.
            </p>
            <p>
              <img src={fig10} />
            </p>
            <p>
              Eftersom motoren kan k&oslash;re i 291 sekunder, og flyet stiger
              med 6m/sek, kan toph&oslash;jden nu bestemmes til at v&aelig;re
              1746meter. Dette data kan og m&aring; ikke afpr&oslash;ves, idet
              den maximale tilladelige toph&oslash;jde er 100m! Hvis en
              &aelig;ndret k&oslash;retid &oslash;nskes, kan naturligvis
              v&aelig;lges en anden kapacitet p&aring; batteriet med
              tilh&oslash;rende v&aelig;gt&aelig;ndringer.
            </p>
            <h3>16. Bestemmelse af cruise speed</h3>
            <p>
              Vi vil nu pr&oslash;ve at se p&aring;, hvad der sker, hvis man vil
              pr&oslash;ve at flyve s&aring; &oslash;konomisk som muligt. Farten
              s&aelig;ttes ned til 13m/sek. Her er det bedst mulige forhold
              mellem opdrift og modstand p&aring; vingen.
              <br />
              Man f&aring;r den b&oslash;rstel&oslash;se motor til at
              k&oslash;re langsommere ved at hakke den 3 fasede str&oslash;m itu
              p&aring; en s&aring;dan m&aring;de, at man kan &aelig;ndre tiden
              mellem at der flyder str&oslash;m, og at der ikke flyder
              str&oslash;m. Derved falder den tilf&oslash;rte effekt. Da
              stighastigheden er 0 m/s skal der blot tilf&oslash;res s&aring;
              meget effekt, nemlig 30&nbsp;W, for at flyveren kan holde sig
              flyvende med konstant hastighed.
            </p>
            <h3>17. Beregninger af max. flyvetid, distance m.m.</h3>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Vandret flyvning med cruise-speed 13m/sek
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Fastlæggelse af stig hastighed</TableCell>
                  <TableCell>0m/s</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sum af stige- og synkehastighed</TableCell>
                  <TableCell>2m/s</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Beregning af propellens nødvendige effekt
                  </TableCell>
                  <TableCell>30&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bestemmelse af propellens virkningsgrad</TableCell>
                  <TableCell>50&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beregning af motorens mekaniske ydelse</TableCell>
                  <TableCell>60&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Fastlæggelse af motorens virkningsgrad</TableCell>
                  <TableCell>83&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beregning af motorens indgangseffekt</TableCell>
                  <TableCell>72&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Bestemmelse af regulatorens virkningsgrad
                  </TableCell>
                  <TableCell>95&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bestemmelse af regulatorens ydelse</TableCell>
                  <TableCell>76&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beregning af batteri strøm</TableCell>
                  <TableCell>6,9&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beregning af motorens køretid</TableCell>
                  <TableCell>21min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beregning af distancen </TableCell>
                  <TableCell>16,4km</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Watttime forbrug pr. km</TableCell>
                  <TableCell>1,6&nbsp;W/time pr. km</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Flyvetiden kan nu bestemmes til 21minutter under
              foruds&aelig;tning, at der ikke stiges, og at der flyves lige ud!
              Da flyet flyver med 13m pr sekund og kan flyve i 21minutter,
              bliver distancen 16,4km.
              <br />
              Ved konstruktion af fly i dag er br&aelig;ndstof forbruget pr.
              passagerkilometer helt afg&oslash;rende, t&aelig;nk p&aring;
              f.eks. Boeings Dreamliner. Br&aelig;ndstof &oslash;konomien
              p&aring; elflyet interesserer ikke modelflyveren, men den kan godt
              fort&aelig;lle noget om konstruktionen og flyvestilen. Flexflyeren
              kan flyve 16,4 km ved et forbrug p&aring; 2,5&nbsp;A/t x
              10,6&nbsp;V =26,5&nbsp;W/timer. F&oslash;lgelig bruger den
              1,6&nbsp;W/time pr. km. Til sammenligning har jeg et ducted fan
              fly som bruger 2,55&nbsp;W/time pr.km. Du kan se er propelflyet en
              mere energi&oslash;konomisk konstruktion.
            </p>
            <h2>Flyvesekvenser</h2>
            <p>
              En foruds&aelig;tning for alle beregningerne, var at flyet befandt
              sig i nogle bestemte tilstande. I praksis vil flyvningen
              best&aring; af en start, og derefter en blanding af varierende
              stigninger og hastigheder. Afh&aelig;ngig af flytypen b&oslash;r
              flyet v&aelig;re dimensioneret til den fremherskende flyvestil.
            </p>
            <h2>Temperatur</h2>
            <p>
              Alle de effekttab som forekommer, giver anledning til temperatur
              stigninger i komponenterne. S&aring; en sidegevinst ved at have et
              s&aring; optimeret fly som muligt vil du ogs&aring; have den
              laveste varmeudvikling, hvis du anvender h&oslash;je
              virkningsgrader. Mens det er vanskeligt at beregne
              tempeturstigningerne p&aring; grund af k&oslash;leluften omkring
              og i flyet, er det til geng&aelig;ld nemt at m&aring;le disse,
              s&aring; det vil jeg vende tilbage til i den 3. artikel.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
