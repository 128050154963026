import React, { Component } from "react";
import { withStyles, Fab } from "@material-ui/core";
import styles from "./ArtikelStyles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Print from "@material-ui/icons/Print";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
}

class Fabs extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToTop() {
    window.scrollTo(0, this.props.rootRef.current.offsetTop);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.scrollToTop}
          onClick={this.scrollToTop.bind(this)}
        >
          <ArrowUpward />
        </Fab>
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.print}
          onClick={window.print}
        >
          <Print />
        </Fab>
      </>
    );
  }
}

export default withStyles(styles)(Fabs);
