import React, { Component } from "react";
import { Grid, withStyles, Typography, Link } from "@material-ui/core";
import frontPage from "../images/forside.png";
import Cake from "@material-ui/icons/Cake";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

const styles = theme => ({
  root: {
    marginBottom: "60px",
    transition: "opacity 0.5s",
    opacity: 1
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center" as "center"
  },
  content: {
    textAlign: "left" as "left",
    padding: theme.spacing.unit * 3
  },
  image: {
    maxWidth: "100%"
  }
});

interface IState {}

interface IProps {
  classes: any;
  canonical: string;
}

class FrontPage extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Elflytec.dk - Viden om elfly teknik</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <Grid container className={`${classes.center} ${classes.root}`}>
          <Grid item lg={3} />
          <Grid item xs={12} md={6} lg={3} className={classes.content}>
            <Typography variant="h2">Ny hjemmeside</Typography>
            <br />
            <Typography variant="body1">
              Jørgen Bjørn fylder 80 år <Cake /> og i den forbindelse relanceres
              elflytec.dk
            </Typography>
            <br />
            <Typography variant="body1">
              Elflytec.dk virker nu optimalt på mobiler, tablets, bærbare og
              stationære computere. Alt kommunikation med siden sker nu også på
              en sikker linje. (HTTPS)
            </Typography>
            <br />
            <Typography variant="body1">
              Ny artikel <Link href="/FlyByWire/VTOL-teknik">VTOL Teknik</Link>{" "}
              kan findes under "Fly by wire controllers"
            </Typography>
            <hr />
            <Typography variant="body1">
              Velkommen til Elflytec.dk, som indeholder tekniske artikler om den
              teknologi der anvendes i elfly.
            </Typography>
            <Typography variant="body1">
              Billederne kan være til inspiration og vise den mangfoldighed
              elflyvning frembyder.
            </Typography>
            <Typography variant="body1">
              Du kan frit anvende artiklerne, men med reference til elflytec.dk
            </Typography>
            <Typography variant="body1">
              Nogle af artiklerne kan også findes i bladet Modelflyvenyt.
            </Typography>
            <br />

            <Typography variant="body1">
              Siden er besøgt {Cookies.get("visitorNumber")} gange
            </Typography>
            <Typography variant="body1">
              Siden er sidst opdateret 27/04/2019
            </Typography>
            <br />
            <Typography variant="body1">Copyright &copy; 2011</Typography>
            <Typography variant="body1">
              Udviklet af{" "}
              <Link href="https://jdu.dk" target="_blank">
                JD Udvikling
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3} className={classes.content}>
            <img src={frontPage} className={classes.image} />
          </Grid>
          <Grid item lg={3} />
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(FrontPage);
