import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig1.jpg";
import fig2 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig2.jpg";
import fig3 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig3.jpg";
import fig4 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig4.jpg";
import fig5 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig5.jpg";
import fig6 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig6.jpg";
import fig7 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig7.jpg";
import fig8 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig8.jpg";
import fig9 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig9.jpg";
import fig10 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig10.jpg";
import fig11 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig11.jpg";
import fig12 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig12.jpg";
import fig13 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig13.jpg";
import fig14 from "../../../images/Grundviden/Grundlaeggende24GHzTeknik/fig14.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Grundlæggende 2,4 GHz teknik</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Grundl&aelig;ggende 2,4 GHz teknik</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret marts 2010.</p>
            <p>
              Den teknologiske udvikling kommer i spring, senest har 2,4GHz
              teknikken i voldsom grad t&oslash;mt modelflyvernes pengepung,
              samtidig med at der er opst&aring;et en n&aelig;rmest
              religi&oslash;s diskussion om hvilke anl&aelig;g der er de bedste.
              Det kan denne artikel ikke svare p&aring;, men jeg h&aring;ber du
              kan f&aring; et lille indblik i, hvad det er der
              foreg&aring;r.&nbsp;
            </p>
            <h2>Frekvenser og st&oslash;j</h2>
            <p>
              Indledningsvis vil jeg pr&oslash;ve at give dig en ide om, hvor
              h&oslash;j en frekvens 2,4GHz egentlig er. Hvis du ser p&aring;
              fig.1 er den vandrette akse en frekvensakse, hvor du ude til
              venstre starter med det gammel kendte lang-, mellem- og
              kortb&oslash;lgeb&aring;nd, som bliver brugt til AM radio. Lige
              over kortb&oslash;lge b&aring;ndet ligger vores velkendte
              kanalopdelte 35MHz fjernstyringsb&aring;nd i VHF b&aring;ndet.
              Dette g&aring;r fra 30 MHz til 300 MHz. I dette omr&aring;de
              m&oslash;der vi ogs&aring; FM-radioen, der ligger omkring 100MHz.
              24 gange h&oslash;jere er s&aring; vores nye 2,4GHz b&aring;nd
              placeret, idet der g&aring;r 1000MHz p&aring; en 1GHz. 2,4 GHz
              ligger alts&aring; i UHF b&aring;ndet, der g&aring;r fra 300 MHz
              til 3 GHz. Her ligger ogs&aring; digitalt TV. Nu er det
              s&aring;ledes at b&oslash;lgel&aelig;ngden er omvendt proportional
              med frekvensen, s&aring; mens 35MHz senderen har en
              b&oslash;lgel&aelig;ngde p&aring; ca. 11 meter, bliver
              b&oslash;lgel&aelig;ngden kun 12,5&nbsp;cm ved 2,4GHz. Ved en lidt
              h&oslash;jere frekvens kan du m&oslash;de lufthavnsradaren med
              10&nbsp;cm b&oslash;lgel&aelig;ngde og vejrradaren med 3&nbsp;cm
              b&oslash;lgel&aelig;ngde. Frekvenser over 30 MHz g&aring;r i rette
              linjer, hvorfor antennerne skal kunne se hinanden da radio
              b&oslash;lger taber effekt ved at g&aring; igennem fast stof.
              Dette kan defineres s&aring;ledes: jo h&oslash;jere frekvensen er,
              jo mindre gennemtr&aelig;nger radio b&oslash;lger fast stof.
              Konklusionen er simpelt, ved 2,4 GHz m&aring; der kun v&aelig;re
              luft mellem antennerne, og antennerne skal kunne se hinanden.
            </p>
            <p>
              <img src={fig1} />
            </p>
            <p>
              Meget vigtigt er det at l&aelig;gge m&aelig;rke til at elektrisk
              radiost&oslash;j, som stammer fra elektromotorer, fartregulatorer
              og t&aelig;ndingsst&oslash;j, aftager ved stigende frekvens. Denne
              st&oslash;j er s&aring;ledes n&aelig;sten ikke tilstede ved 2,4GHz
              og kan derfor ikke finde ind i 2,4GHz modtageren, hvilket er en
              k&aelig;mpefordel for is&aelig;r el-fly og fly med motor og
              t&aelig;ndings anl&aelig;g.
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Fig. 2 viser nu selve 2,4GHz b&aring;ndet, som har en bredde
              p&aring; 83,5MHz. Det er et s&aring;kaldt ISM b&aring;nd, som
              bliver brugt til alle mulige form&aring;l, s&aring; som
              mikrob&oslash;lgeovne, industrielle og medicinske apparater og
              ikke mindst tr&aring;dl&oslash;se netv&aelig;rk til computere
              (WLAN) og Bluetooth. S&aring; du skal regne med, at der er et hav
              af andre brugere, i mods&aelig;tning til 35MHz, som jo er
              reserveret til vores fjernstyrede fly. En vigtig ting er dog at
              den maximale udgangseffekt ikke m&aring; overstige 100mW, hvilket
              giver en begr&aelig;nset r&aelig;kkevidde.
            </p>
            <h2>Udbredelsen af signalet</h2>
            <p>
              Som bruger af 2,4GHz anl&aelig;g b&oslash;r du vide hvordan
              signalet udbredes. Som grundregel skal senderens antenne kunne se
              flyverens antenne, som forklaret tidligere. Du m&aring;
              s&aring;ledes ikke flyve om bag et klubhus. Se fig. 3. Du skal
              regne med, at hvis modtageren taber signalet kan der medg&aring;
              en betydelig tid f&oslash;r den digitale signal behandling har
              genskabt et nyt brugbart signal. Skyggevirkningen kunne ogs&aring;
              fremkomme ved at modtageren l&aring; bag en stor br&aelig;ndstof
              motor eller kulfiber i kroppen.
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              N&aelig;ste problem kunne v&aelig;re en udfasning af signalet. Se
              fig. 4. Den opst&aring;r ved at flyveren rammes af et direkte
              signal a og et reflekteret signal b, sidstn&aelig;vnte har en
              l&aelig;ngere signalvej og bliver derfor forsinket en halv
              b&oslash;lgel&aelig;ngde. De to signaler er nu i modfase og
              oph&aelig;ver hinanden. Man b&oslash;r derfor ikke flyve langt
              v&aelig;k fra senderen med modellen i lav h&oslash;jde.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              En sidste ting er at mikrob&oslash;lgerne absorberes af f. eks. en
              s&oslash; eller, v&aring;d beplantning og fugtig luft. Se fig. 5.
              2,4MHz er s&aring;ledes uanvendelig til en fjernstyret Ub&aring;d,
              hvis du kunne finde p&aring; at bygge s&aring;dan en.&nbsp;
            </p>
            <p>
              <img src={fig5} />
            </p>
            <p>
              Viden om antennerne. Som f&oslash;lge af ovenn&aelig;vnte er
              antenne forholdene mere komplicerede. Som ved 35MHz anvender man
              en antenne l&aelig;ngde p&aring; &frac14;
              b&oslash;lgel&aelig;ngde, som nu bliver 12,5&nbsp;cm delt med 4,
              hvilket giver en antennel&aelig;nge p&aring; ca. 3&nbsp;cm. Som
              bruger b&oslash;r du vide at udstr&aring;lingen omkring antennen
              finder sted, som vist p&aring; 3-D tegningen fig. 6. P&aring; fig.
              7 er antennen set ovenfra, hvoraf det fremg&aring;r at
              udstr&aring;lingen er lige kraftig hele vejen rundt. Fig. 8 er et
              snit af fig. 6 set fra siden. Bem&aelig;rk at der her er 2
              d&aring;rlige vinkler i forl&aelig;ngelse af antennen, hvor der
              n&aelig;sten ikke udstr&aring;les energi. Som f&oslash;lge heraf
              m&aring; du ikke pege med sender antennen mod flyet og det er
              derfor 2,4GHz sender antennen kan drejes og vippes. N&aring;r man
              kommer til antennen i flyet har man naturligvis det problem, at
              antennen ligger i alle mulige stillinger under flyvningen. Dette
              kan im&oslash;dekommes ved at man har flere modtagere hvor
              antennerne kan st&aring; vinkelret p&aring; hinanden i det ene
              eller andet plan. Se fig. 9 og 10. F&oslash;lg omhyggeligt
              brugsanvisningen for det p&aring;g&aelig;ldende anl&aelig;g.&nbsp;
            </p>
            <p>
              <img src={fig6} />
            </p>
            <h2>Opbygningen af nyttedata</h2>
            <p>
              Nyttedata er det system som holder rede p&aring; de enkelte
              servoers stillinger i de enkelte af senderens og modtagerens
              kanaler. Ved fremkomsten af Futaba 1024 PCM systemet s&aring; man
              for f&oslash;rste gang en sender, som var baseret p&aring; digital
              signalbehandlig. 2,4GHz anl&aelig;gget anvender samme princip i
              sine nyttedata.
            </p>
            <p>
              <img src={fig7} />
            </p>
            <p>
              Al digital teknik er baseret p&aring; to tilstande, som kunne
              v&aelig;re 1 eller 0, 5&nbsp;volt eller 0&nbsp;volt, ja eller nej,
              on eller off, lys eller m&oslash;rke. Vi holder os her til 1 eller
              0, som ogs&aring; ben&aelig;vnes et BIT. (Binary digIT) der er det
              mindste element i digitalteknikken. Hvis vi kun havde et bit til
              en servo, kunne vi v&aelig;lge at flyve til h&oslash;jre eller til
              venstre. Med kombinationen 2 bit vil vi f&aring; 4 stillinger. Se
              fig. 11. Stadig ikke nok til at flyve lige ud, men hvis vi bruger
              10bit hj&aelig;lper det, vi vil nu kunne f&aring; 1024 stillinger
              p&aring; servoen. Hvis det nu er en 8 kanal sender, skal der
              yderligere anvendes 3 bit til at kunne holde rede p&aring; de
              enkelte kanaler. Med et kontrolbit ser vi s&aring;, at der skal
              anvendes i alt 112 bit for at kunne fort&aelig;lle 8 servoers
              individuelle stillinger. Nu er det jo ikke nok, at man
              fort&aelig;ller det en gang for alle (en frame), idet vi jo under
              flyvningen &oslash;nsker at kunne &aelig;ndre servoerne hurtigt,
              s&aring; derfor v&aelig;lger vi at opdatere servoerne 50 gange i
              sekundet. Dette giver en uafbrudt bit str&oslash;m af nyttedata
              p&aring; 5600bit pr sekund mellem senderen og modtageren.
            </p>
            <h2>Princippet i spredt spektrum og binding</h2>
            <p>
              Hele denne forklaring henviser til Fig. 12. I venstre side af
              blokdiagrammet tilf&oslash;res nyttedataet en kodekreds, som
              samtidig modtager en s&aring;kaldt psydo random kode. Oversat til
              dansk betyder det at senderen frembringer en tilsyneladende
              tilf&aelig;ldig kode, se lige under blokdiagrammet til venstre. Du
              ser her en bit str&oslash;m af 40 tilsyneladende tilf&aelig;ldige
              bit. Men ved n&aelig;rmere eftersyn gentager koden sig efter hver
              20bit, hvis du ser godt efter. Frekvensen p&aring; denne kode er
              1MHz, hvilket er meget h&oslash;jere end nytte frekvensen.
              Nyttedata og psydo random koden s&aelig;ttes nu sammen efter den
              simple regel, at hvis nyttedatabittet og kodebittet er
              forskellige, f&aring;s et kodet data bit 1 som resultat, ellers
              f&aring;s 0. Denne kodede h&oslash;je frekvens af bit
              tilf&oslash;res nu modulatoren og moduleres ind p&aring; 2,4GHz
              b&aelig;reb&oslash;lgen, hvilket medf&oslash;rer at senderen
              spreder sit spektrum ud over ca. 1MHz, deraf betegnelsen spread
              spectrum. Senderen spreder sig alts&aring; meget i
              mods&aelig;tning til 35MHz senderen, hvor man absolut ikke
              &oslash;nsker en spredning af hensyn til forstyrelse af
              nabokanalen. Hvis man lytter til 2,4GHz senderen danner det ikke
              nogen mening, idet senderen er kodet, ogs&aring; kaldet scramblet.
              Disse teknikker er udviklet til milit&aelig;r kommunikation, da
              teknologien vanskeligg&oslash;r aflytning, og er senere blevet
              frigivet til kommerciel brug. Det er i &oslash;vrigt
              chipteknologien fra de tr&aring;dl&oslash;se netv&aelig;rk og
              telefoner som har muliggjort vores 2,4GHz anl&aelig;g.
            </p>
            <p>
              <img src={fig8} />
            </p>
            <p>
              En betingelse for at danne en brugbar kopi af nyttedataet i
              modtageren er, at denne kender psydo random koden. Dette
              g&oslash;res ved at overf&oslash;re den unikke kode fra senderen
              til modtageren inden anl&aelig;gget tages i brug. Processen kaldes
              p&aring; engelsk binding, du binder nu s&aring;ledes din sender
              til netop din modtager med en kode som indeholder over 1 million
              kombinationer svarende til 20bit. Hver enkelt sender er af
              fabrikanten blevet forsynet med sin helt egen unikke kode og
              muligheden for at din kammerat, som ogs&aring; flyver, har den
              samme kode er usandsynlig. Afkodningen i modtageren f&oslash;lger
              samme regel som i senderen og du ser nu at modtageren bit for bit
              kan gendanne det oprindelige nyttedata. I den gamle 35 MHz
              modtager teknik opn&aring;ede man at ens egen information, ikke
              blev forstyrret af nabokanalen v. hj. af skarp udskillelse i et
              filter, er det er noget anderledes i 2,4GHz systemet. Her kan
              modtageren s&aring;ledes genkende sin egen unikke senders signal
              og ikke alt muligt andet skrammel. Fagfolk taler nu om proces gain
              i stedet for nabokanal selektivitet. I det viste eksempel vil
              undertrykkelsen af det u&oslash;nskede signal v&aelig;re 178
              gange. Det kan n&aelig;vnes at en af 2,4GHz fabrikanterne ved en
              fejl kom til at sende en r&aelig;kke sendere p&aring; markedet med
              samme kode, du kan nu forst&aring; at dette m&aring; betegnes som
              yderst uheldigt.
            </p>
            <h2>Forenelighed mellem forskellige fabrikater</h2>
            <p>
              Fra 35MHz anl&aelig;ggene har du v&aelig;ret vant til at kunne
              anvende forskellige fabrikater af modtagere sammen med forskellige
              sendere, stort set uden problemer. S&aring;dan er det ikke med
              2,4GHz anl&aelig;ggene, idet de forskellige producenter har valgt
              vidt forskellige tekniske l&oslash;sninger, som du vil se
              eksempler p&aring; i nedenst&aring;ende afsnit. Du skal dog
              v&aelig;re opm&aelig;rksom p&aring;, at det er muligt at
              k&oslash;be forskellige sender indstiksmoduler med
              tilh&oslash;rende modtagere, som muligg&oslash;r, at du f.eks. med
              en Futaba sender type T9CP kan udskifte 35MHz modulet med enten et
              Futaba, Spectrum eller Graupner 2,4GHz modul med tilh&oslash;rende
              modtagere.
            </p>
            <h3>Spectrum DSM2 og JR</h3>
            <p>
              Spectrum var det fabrikat som f&oslash;rst dukkede op p&aring;
              markedet, i f&oslash;rste omgang kun til indend&oslash;rs fly med
              begr&aelig;nset r&aelig;kkevidde. Efterf&oslash;lgende
              introducerede man anl&aelig;g med fuld r&aelig;kkevidde. N&aring;r
              man starter en Spectrum sender, g&aring;r den f&oslash;rst i gang
              med at skanne frekvensb&aring;ndet for at finde ud af, hvor der er
              en ledig plads. Derefter scanner senderen videre til den finder
              endnu en ledig frekvens, hvor den kan sende. Princippet kaldes
              &rdquo;listen before talk&rdquo;. Senderen A sender alts&aring;
              p&aring; to forskellige frekvenser samtidig og undg&aring;r
              forstyrrelsen F. Begge disse frekvenser modtages uafh&aelig;ngigt
              af hinanden, hvilket sammen med princippet i spread spectrum giver
              en stor sikkerhed mod forstyrrelser. Bem&aelig;rk hvad der sker
              n&aring;r jeg t&aelig;nder sender B. Den placerer sig elegant hvor
              der er plads. Se fig. 13. Systemet har 40 kanaler hvoraf der er 20
              brugbare, da hver sender optager 2 kanaler.&nbsp;
            </p>
            <p>
              <img src={fig9} />
            </p>
            <p>
              Det skal videre bem&aelig;rkes at modtageren kan forsynes med
              hj&aelig;lpemodtagere med selvst&aelig;ndige antenner, som kan
              placeres forskellige steder i modellen vinkelret p&aring;
              hinanden. Dette l&oslash;ser effektivt de problemer, som jeg har
              beskrevet under antennerne. Spectrum har endvidere en s&aelig;rlig
              binding funktion, som g&oslash;r at det ikke er muligt at f&aring;
              forbindelse til et forkert fly, hvis man har flere modeller
              liggende i senderens hukommelse. Specielt til inded&oslash;rsbrug
              er Spectrum anl&aelig;ggene velegnede p&aring; grund af deres
              meget lave v&aelig;gt, helt ned til nogle f&aring; gram, ligesom
              Spectrum teknologien anvendes indbygget i en r&aelig;kke
              f&aelig;rdigbyggede helikoptere og fly i micro
              st&oslash;rrelse.&nbsp;
            </p>
            <p>
              <img src={fig10} />
              <br />
              Spectrum modtager med hj&aelig;lpemodtager. Bem&aelig;rk
              hj&aelig;pemodtageren m&aring; ikke monteres i den stilling, som
              er vist p&aring; billedet p.gr. af antennernes
              udtr&aring;lingskarakteristik.
              <br />
            </p>
            <h3>Futaba FASST</h3>
            <p>
              Futaba/Robbe har valgt en ganske anden l&oslash;sning. N&aring;r
              man t&aelig;nder Futaba senderen, starter den med at udsende en
              komplet datapakke i l&oslash;bet af 2 millisekunder. I de
              n&aelig;ste 8 millisekunder sender den overhovedet ingen ting, men
              klarg&oslash;r sig til at sende p&aring; en ny tilf&aelig;ldig
              kanal ud af 36 mulige kanaler, som den selv har opdelt. Foruden at
              sende i et spread spectrum hopper den rundt i en tilsyneladende
              tilf&aelig;ldig r&aelig;kkef&oslash;lge. Koden p&aring;
              frekvenshoppet kendes naturligvis af modtageren, da man bindede
              den, s&aring; den hopper med i samme r&aelig;kkef&oslash;lge. Se
              fig. 14.
            </p>
            <p>
              <img src={fig11} />
            </p>
            <p>
              Sender A er en Futaba sender, som st&aring;r ved siden af en
              Spectrum sender. Hvis Futaba senderen rammer den frekvens Spectrum
              senderen ligger p&aring; bliver datapakken af nyttedata muligvis
              &oslash;delagt. Dette betyder ikke noget videre idet modtagerens
              microprocessor, dels fejlkorrigerer datapakken og regner ud
              hvordan den kunne have set ud, dels modtager en nye datapakke
              p&aring; en helt ny frekvens i&nbsp;l&oslash;bet af 1/100 sekund,
              uden at piloten opdager noget som helst. Det at senderen hele
              tiden hopper i mellem forskellige frekvenser neds&aelig;tter i
              &oslash;vrigt muligheden for den udfasning af signalet, som er
              beskrevet tidligere.
            </p>
            <p>
              <img src={fig12} />
            </p>
            <p>
              Modtageren benytter sig af to deversity antenner, hvor princippet
              er at den hele tiden anvender den antenne med det bedste signal.
              De b&oslash;r st&aring; vinkelret p&aring; hinanden, som tidligere
              beskrevet. Bem&aelig;rk at der er tale om to coaxial kabler, hvor
              det kun er de yderste 3&nbsp;cm, der er de aktive antenner.
            </p>
            <h3>Graupner IFS</h3>
            <p>
              Sidst vil vi se p&aring; Graupners Intelligent Frekvency Select
              system. Her starter senderen med at pr&oslash;ve at sende en
              nyttedatapakke til modtageren p&aring; en tilf&aelig;ldig frekvens
              i spread spectrum. Hvis modtageren godtager datapakken, sker der
              ikke mere, systemet virker. Hvis modtageren ikke godtager data,
              beder modtageren via en indbygget sender, senderen om at skifte
              til en ny frekvens. Dette kan kun lade sig g&oslash;re, fordi
              senderen p&aring; jorden ogs&aring; indeholder en modtager, som
              kan &aelig;ndre senderens frekvens. Der er alts&aring; tale om
              2-vejs kommunikation mellem de to enheder. Man kan sige at
              senderen automatisk skifter frekvens indtil der modtages brugbare
              datapakker. Se fig. 15.&nbsp;
            </p>
            <p>
              <img src={fig13} />
            </p>
            <p>
              Systemet led imidlertid af den svaghed at modtageren p&aring;
              jorden ofte var omgivet af andre 2,4GHz sendere i f&aring; meters
              afstand, som i visse tilf&aelig;lde forhindrede det
              n&oslash;dvendige frekvensskift. Firmaet blev derfor n&oslash;dt
              til at tilbagekalde anl&aelig;ggene i efter&aring;ret 2008, for at
              f&aring; l&oslash;st problemet og indf&oslash;rte ved denne
              lejlighed frekvens hop over 16 kanaler. Som en sidegevinst er man
              i stand til at lave telemetri, d.v.s. at man kan overf&oslash;re
              data, s&aring; som batterisp&aelig;nding og feltstyrke p&aring;
              modtageren, fra flyet til jorden under flyvningen. Anl&aelig;gget
              har en meget h&oslash;j servo opl&oslash;sning p&aring; 0,003grad,
              ligesom reaktions forsinkelsen til servoerne er meget lav.
            </p>
            <p>
              <img src={fig14} />
              <br />
              Antennen afviger fra de &oslash;vrige anl&aelig;g ved at
              v&aelig;re en fast antenne som er monteret p&aring; modtageren.
              <br />
              Denne antenne har kugle karakteristik d.v.s. at den modtager lige
              godt i alle retninger.
            </p>
            <h2>Afslutning</h2>
            <p>
              Du har nu f&aring;et et lille indblik i at 2,4GHz teknologien er
              kompliceret, specielt er det vanskeligt at give et bud p&aring;
              hvilket af de beskrevne anl&aelig;g, som vil klare sig bedst, hvis
              rigtig mange 2,4GHz brugere med forskellige fabrikater er i gang
              samtidig. Jeg t&oslash;r ikke komme med et bud p&aring;, hvilket
              fabrikat der er bedst, men jeg kan n&aelig;vne at jeg har
              udf&oslash;rt r&aelig;kkevidde test af alle tre systemer med
              fremragende resultater i praksis. Der er ingen tvivl om at du
              f&aring;r en langt mere robust radioforbindelse end ved 35MHz,
              samtidig med at du ikke beh&oslash;ver frekvensflag, har korte
              antenner og kan undg&aring; elektrisk st&oslash;j. En sideeffekt
              er, at der bliver bedre plads i 35MHz b&aring;ndet, hvilket
              ogs&aring; &oslash;ger sikkerheden. Bem&aelig;rk til sidst at din
              forsyningssp&aelig;nding i s&aelig;rlig grad skal v&aelig;re i
              orden, fordi der kan medg&aring; rigtig lang tid f&oslash;r
              modtageren igen har f&aring;et styr p&aring; databehandlingen
              efter en str&oslash;mafbrydelse f.eks. for&aring;rsaget af en
              overbelastet BEC. Jeg har m&oslash;dt piloter som nu tror, at det
              er umuligt at deres 2,4GHz kan svigte, det kan vist ikke
              v&aelig;re rigtigt og der jo stadig det med piloten.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
