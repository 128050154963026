import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig1.jpg";
import fig2 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig2.jpg";
import fig3 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig3.jpg";
import fig4 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig4.jpg";
import fig5 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig5.png";
import fig6 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig6.jpg";
import fig7 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig7.jpg";
import fig8 from "../../../images/FlyByWire/BlendedWingBodyFremtidensFly/fig8.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Blended Wing Body – Fremtidens fly</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Blended Wing Body &ndash; Fremtidens fly</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn.</p>
            <p>Publiceret Dec. 2014.</p>
            <h2>Inspirationen</h2>
            <p>
              Inspirationen til dette projekt kommer fra Boeings og NASAs
              arbejde med at konstruere fly, som er mere effektive og
              &oslash;konomiske. I stedet for at et fly best&aring;r af en
              vinge, en krop og et haleplan, integreres hele konstruktionen i en
              helhed, en blanding af vinge og krop (Blended Wing Body). Det
              endelige m&aring;l kunne v&aelig;re, at BWBen&nbsp;bruger 1/3
              mindre br&aelig;ndstof end et normalt trafikfly, transporterer 800
              passagerer over 7000miles og lander p&aring; en normal bane som en
              Boeing 747. Udviklingsomkostninger er enorme, s&aring;
              forel&oslash;big arbejder man p&aring; store fjernstyrede modeller
              som X-48, se billed 1.
            </p>
            <p>
              Sidst i artiklen er der en r&aelig;kke henvisninger, som refererer
              til W.W.W., hvis du vil vide mere. Den f&oslash;rste er LINK 1.
            </p>
            <p>
              <img src={fig1} />
            </p>
            <p>
              Billede 1. Boeings stormodel har en sp&aelig;ndvidde p&aring;
              6,4m, vejer 226 kg og drives af 3 turbojet motorer. Den anvendes
              til at unders&oslash;ge Lift/drag forhold, aerodynamisk stabilitet
              og kontrol samt st&oslash;j. Den skal give erfaring til at
              definere et fortsat udviklingsprogram.
            </p>
            <p>&nbsp;En BWB model har f&oslash;lgende fordele:</p>
            <ul>
              <li>Stort reelt lastrum til elektronik, batterier m.m.</li>
              <li>
                Stor byggeh&oslash;jde i bodydelen giver nem montage af et
                optr&aelig;kkeligt understel.
              </li>
              <li>
                Stor byggeh&oslash;jde muligg&oslash;r en s&aelig;rdeles
                st&aelig;rk konstruktion.
              </li>
              <li>Er kompakt og transportvenlig.</li>
              <li>
                Motoren er beskyttet, fordi den sidder p&aring; oversiden.
              </li>
              <li>Kan b&aring;de flyve hurtigt og langsomt.</li>
            </ul>
            <p>Jeg vil nu beskrive processen af mit BWB projekt.&nbsp;</p>
            <h2>Min f&oslash;rste BWB</h2>
            <p>
              Der blev f&oslash;rst fremstillet en lille simpel model. Se
              billede 2.
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Billede 2 viser den lille testmodel. som blev anvendt til
              forunders&oslash;gelser af: tyngdepunktets placering. (Se LINK 2),
              stabilitet over de 3 akser, man&oslash;vredygtighed, samt
              m&aring;ling af flyvefart og glidetal.
            </p>
            <h3>Konstruktionen</h3>
            <p>
              Efter at have godkendt pr&oslash;veflyvningerne af den lille
              model, begyndte jeg at tegne en ny BWB, samtidig med at
              v&aelig;gtberegningen fandt sted. F&oslash;lgende data blev
              bestemt: Sp&aelig;ndvidde130cm, Planareal 43dm2, V&aelig;gt ca.
              1800g. Til tegning af flyet blev anvendt programmet Micrografx
              Designer Ver. 9.0, som er glimrende egnet. Tegneprogrammet
              m&aring; p&aring; den ene side ikke v&aelig;re for tungt, p&aring;
              den anden side skal det kunne l&oslash;se de opgaver, som
              kr&aelig;ves for at kunne tegne et modelfly. Tegningerne blev
              derefter p&aring;h&aelig;ftes materialerne, som s&aring; kunne
              saves ud med en Dremmelsav.{" "}
            </p>
            <p>
              Tegneprocessen kr&aelig;ver nogen tilv&aelig;nning og
              f&oslash;lgende gode r&aring;d kan gives.
            </p>
            <ul>
              <li>
                Tegn omrids, alts&aring; alle yderm&aring;l f&oslash;rst. Det er
                her, man kan bestemme den endelige st&oslash;rrelse af flyet,
                ved at tr&aelig;kke i en hj&oslash;rnepil for zoom p&aring;
                tegningen.&nbsp;
              </li>
              <li>Opbyg derefter strukturen inde i konstruktionen.</li>
              <li>Anvend flere lag i tegningen.</li>
              <li>Anvend kopifunktionen s&aring; meget som muligt.</li>
              <li>
                Opret et bibliotek af komponenter, som kan hentes med et
                museklik.
              </li>
              <li>
                Anvend symmetrilinjer og tegn f.eks. kun venstre halvdel,
                hvorefter h&oslash;jre tegnes ved at spejlvende tegningen.
              </li>
            </ul>
            <p>
              Om konstruktionen kan siges, at man &oslash;nsker at anvende de
              enkelte materialer s&aring; optimalt som muligt, med henblik
              p&aring; at optage og fordele de kr&aelig;fter, som p&aring;virker
              flyet. Se billede 3. Hver enkelt stump er vendt og drejet med
              hensyn til funktion, styrke og v&aelig;gt. Der kunne dog godt
              v&aelig;re flere letningshuller.
            </p>
            <p>F&oslash;lgende overvejelser blev gjort omkring understellet:</p>
            <ul>
              <li>Understellet skal v&aelig;re funktionssikkert.</li>
              <li>
                M&aring; ikke deformeres eller &oslash;del&aelig;gges selv ved
                h&oslash;je belastninger.
              </li>
              <li>
                Skal kunne kobles sammen med flyets b&aelig;rende konstruktion,
                som derefter kan optage og fordele kr&aelig;fterne selv ved
                meget d&aring;rlige landinger.
              </li>
              <li>
                V&aelig;gten m&aring;tte stort set kun for&oslash;ges med
                understel servoens v&aelig;gt.{" "}
              </li>
              <li>
                Skal v&aelig;re effektivt l&aring;st af den m&aring;de,
                understellet er konstrueret p&aring;.
              </li>
              <li>
                Hvis der opst&aring;r fejl, som medf&oslash;rer en blokeret
                understels servo, m&aring; modtagersystemet med servoer ikke
                kunne g&aring; ned p.gr. af for lav batterisp&aelig;nding.
              </li>
              <li>N&aelig;sehjulet skal v&aelig;re styrbart.</li>
              <li>
                V&aelig;re konstrueret som en eller flere komponenter, der kan
                demonteres flyet med henblik p&aring; modifikation, reparation
                og vedligeholdelse. Tingene skal kunne skilles ad med
                nylonskruer.
              </li>
            </ul>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Billede 3 eksempel p&aring; at de kr&aelig;fter, understellet
              bliver udsat for, bliver overf&oslash;rt og fordelt til
              kulr&oslash;ret og rippen. Man ser desv&aelig;rre p&aring; mange
              f&aelig;rdigfremstillede modeller, at understellet bliver revet af
              under landinger. Pianotr&aring;den virker udefra hjulet og ind til
              midten af kroppen som torsionsstang. Den er her bukket op i
              90grader og g&aring;r imod en 5mm kulstang som stop. Dette
              forhindrer, at kr&aelig;fterne overf&oslash;res til servoen.
              Konstruktionen er kun mulig, fordi hjulene svinges fremad og op i
              kroppen. Det samme g&aelig;lder n&aelig;sehjulet som tr&aelig;kkes
              af en kulstang.
            </p>
            <p>
              Til udtegning at profilerne anvendes profilprogrammet
              Profili2.com. (LINK 3) Som vingeprofil er anvendt Eppler 186, som
              er udviklet til flyvende vinger. Profilet besidder en lav
              &aelig;ndring af drejmoment og ringe &aelig;ndringer af
              opdriftcenteret ved forskellige indfaldsvinkler. Ved hj&aelig;lp
              af profilprogrammet er det nemt at &aelig;ndre tykkelsen.{" "}
            </p>
            <p>
              For at f&aring; styr p&aring; flyets pr&aelig;stationer inden det
              blev bygget, er der udf&oslash;rt beregninger (LINK 4), simulation
              (LINK 5) samt bestemmelse af BWBens tyngdepunkt (LINK 2) efter
              metoder, jeg tidligere har omtalt i Modelflyvenyt eller p&aring;
              Elflytec.dk
            </p>
            <h3>Modellen</h3>
            <p>
              Modellen er opbygget i forskellige materialer s&aring; som:
              Aluminium (Monarflex folie), balsa, kulr&oslash;r og
              st&aelig;nger, depronplader, bl&aring;t skum samt X-finer. Se
              billede 4. Som noget us&aelig;dvanligt er der 10 depronpaneler
              bekl&aelig;dt med Monarflexfolie, som er skruet fast med et stort
              antal 4mm unders&aelig;nkede nylonskruer. En skrue vejer 0,16g.
              Dette muligg&oslash;r, at man kan let komme til at lave
              &aelig;ndringer og service p&aring; elektronik, understel, servoer
              med mere. Det &oslash;verste lastrum, som indeholder 2,4GHz
              modtageren, GPS modtageren samt Eagle Tree enheden, er let
              tilg&aelig;ngeligt gennem en aftagelig plade med henblik p&aring;
              batteriskift. Ydervingerne er aftagelige og holdes p&aring; plads
              af kraftige magneter. Vinglets er monteret med nylonskruer.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>Billede 4 halvf&aelig;rdig model som viser opbygningen.</p>
            <h3>BWB Hardware&nbsp;</h3>
            <p>
              Som et fremtidsrettet fly indeholder mit BWB fly en hel del
              avanceret elektronik. Udover de velkendte ting som en 2,4GHz
              modtager og en Elektronisk Speed Kontroller skal n&aelig;vnes, at
              flyet anvender 7 m&aring;lesensorer til telemetri (Link 6) samt et
              elektronisk stabiliserings system. (LINK 7) Flyet logger endvidere
              automatisk al flyvning til en blackbox recorder (Link 8), som
              endda befinder sig p&aring; jorden. I tilf&aelig;lde af
              bortflyvninger skal man alts&aring; ikke ud at lede efter den
              sorte box, en problematik som kendes f.eks. fra Malasian Airways
              MH370. Fig.1 giver dig et detaljeret indblik i, hvordan tingene er
              koblet op.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig. 1
            </p>
            <p>
              Vi vil nu se p&aring; en beskrivelse af BWB hardwaren.
              F&oslash;lgende skal understreges:
            </p>
            <ul>
              <li>
                De sorte kasser og forbindelser er det, der anvendes i et
                standard 2,4GHz RC anl&aelig;g.
              </li>
              <li>
                De r&oslash;de kasser indeholder telemetrim&aring;leenhederne.
                De r&oslash;de ledninger er telemetriens serielle databus. De
                gr&oslash;nne ledninger giver fysiske input til
                telemetrienhederne.
              </li>
              <li>Den bl&aring; box er Fly By Wire stabilisatoren.</li>
            </ul>
            <p>
              En detaljeret beskrivelse skal starte med betragtninger omkring
              2,4GHz antennerne. Idet BWB&rsquo;en er bekl&aelig;dt med metal
              (Monarflex folien er p&aring;dampet aluminium), er det
              n&oslash;dvendigt at anbringe antennerne udenfor modellen.
              Jetimodtagere findes heldigvis i mange forskellige udgaver
              herunder en med 40 cm lange antenne coaxialkabler. Dette
              muligg&oslash;r at den ene antenne kan anbringes p&aring;
              undersiden af BWBen, mens den anden monteres p&aring; oversiden i
              et 15cm langt plastr&oslash;r. De to 3cm antenner st&aring;r
              vinkelret p&aring; hinanden, som de skal. 5,5V
              forsyningssp&aelig;ndingen til hele systemet frembringes af en
              Dualsky switchmode 3 ampere regulator. (LINK 9) Styrefladerne
              kontrolleres af 4 digitale high speed servoer (Link10).
              Understelsservoen drejer180 grader og tr&aelig;kker alle 3 hjul.
              N&aring;r understellet er nede, slutter det microswitch 2. Hvis
              piloten samtidig slutter den udvendige switch 3 vil LED
              landingslyset i n&aelig;sen blive t&aelig;ndt. Piloten husker
              m&aring;ske s&aring; at sl&aring; understellet ud ved landinger!
              Switch 1 er en 2 polet switch, som sikrer, at motoren ikke kan
              startes, og at understellet ikke kan tr&aelig;kkes op ved uheldig
              betjening af senderen. Dette er en rigtig god ting til
              forebyggelse af uheldige h&aelig;ndelser. Du afbryder den gule
              eller orange signalledning, som overf&oslash;rer styrepulsen og
              lodder ledninger p&aring; til switchen.
            </p>
            <p>
              Drivlinien er et 3S 35C 2200mA/h LiPo batteri, en Jeti Spin 70Amp
              regulator, en 16 polet Turginy motor D3536/5 med en Kv v&aelig;rdi
              p&aring; 1450omdr. pr. volt som tr&aelig;kker en 4 bladet
              4&rdquo;x7,9&rdquo;propel med variabel stigning og udskiftelige
              blade, system Ramoser Varioprop. (LINK 11) Stigningen kan
              &aelig;ndres fra 4&rdquo; til 8&rdquo; Ved
              stignings&aelig;ndringen kan den tilf&oslash;rte effekt til
              modellen reguleres indenfor vide rammer. F&oslash;lgende kan
              m&aring;les p&aring; drivlinien: Motorsp&aelig;nding 10,5V,
              Motorstr&oslash;m 34,9A, Omdrejninger pr. min. 13100, Statisktryk
              1140g.&nbsp;
            </p>
            <p>
              Som det fremg&aring;r at Fig. 1, er der 7 telemetrienheder, som
              kan levere data i en datastr&oslash;m ned til Jetiboxen p&aring;
              jorden. Idet Jeti systemet er beskrevet omhyggeligt p&aring;&nbsp;
              <Link href="https://elflytec.dk">Elflytec.dk</Link>&nbsp;(LINK
              12), skal jeg n&oslash;jes med at omtale den nye h&oslash;jde- og
              vario m&aring;ler MVario2 &oslash;verst p&aring; figuren.
              M&aring;leren er yderst n&oslash;jagtig, den er udviklet til
              forskellig konkurrencebrug og autocalibrerer p&aring;
              barometertrykket, n&aring;r den t&aelig;ndes. F&oslash;lsomheden
              er omkring 10cm h&oslash;jde&aelig;ndring, utroligt at
              m&aring;lechippen, som er produceret i Schweiz, kan registrere
              s&aring; sm&aring; tryk&aelig;ndringer! M&aring;leren indeholder
              endvidere to programmerbare porte, som i min anvendelse kan bruges
              som multiplexer for tilslutning af real airspeed- og
              omdrejningsm&aring;leren. En multiplexer er en omskifer, som
              samler flere databus ledninger til en enkel. S&aring;ledes samler
              Mux`en midt i diagrammet de 4 indkommende data til en seriel
              databus, som tilsluttes Jeti modtagerens telemetri sender, som
              s&aring; kan sende en datastr&oslash;m til jorden.
            </p>
            <p>
              Den m&aring;ske opm&aelig;rksomme l&aelig;ser kan undre sig over,
              at der g&aring;r en gr&oslash;n forbindelse fra h&oslash;lderors
              servoerne til GPS modtageren. Grunden er, at en Jeti GPS modtager
              har den facilitet, at den kan m&aring;le impulsbredden p&aring; en
              servo kanal alts&aring; 1,5mS+/-0,5mS. Den gr&oslash;nne ledning
              fra de 2 h&oslash;jderorsservoer forsyner derfor GPS modtageren
              med et signal, som m&aring;ler, hvilken udslagsvinkel
              h&oslash;jderoret ret faktisk har under flyvningen, efter at
              impulsen er behandlet af fly by wire computeren. Denne facilitet
              kan anvendes til analyse efter flyvninger i flight recorderen.
            </p>
            <p>
              Til sidst skal omtales den bl&aring; box. Form&aring;let med den
              er automatisk at holde flyet stabilt omkring alle 3 akser. Som det
              ses modtager den information fra alle servoudgangene p&aring;
              modtageren med undtagelse af understelsservoen. Ved hj&aelig;lp af
              kanal 8 stabiliseringsgain bestemmes, hvor stor indflydelse fly by
              wire systemet skal have. USB indgangen anvendes til at
              ops&aelig;tte softwaren via en Windows computer. (LINK 7)
            </p>
            <p>
              <img src={fig6} />
            </p>
            <p>
              Billede 5 underste lastrum er fyldt med elektronik, noget af en
              rodebutik. Desv&aelig;rre udg&oslash;r ledningerne en
              un&oslash;dvendig merv&aelig;gt, men da jeg bruger
              telemetrimodulerne i andre modeller, har jeg ikke lyst til at
              forkorte dem. Bem&aelig;rk den ene 2,4GHz antenne i det gule
              plastr&oslash;r.
            </p>
            <h3>Flyvningen</h3>
            <p>
              F&oslash;r f&oslash;rste flyvning er BWBen blevet underkastet en
              r&aelig;kke test p&aring; jorden s&aring; som:
            </p>
            <ul>
              <li>
                Kontrol af tyngdepunkt med en v&aelig;gt. Der m&aring;les
                p&aring; n&aelig;sehjul og hovedhjul, hvorefter beregning
                foretages{" "}
              </li>
              <li>Kontrol af telemetrisystemets alarmer.</li>
              <li>
                Drivliniens ydeevne herunder indstilling af den variable
                stigning p&aring; propellen.
              </li>
              <li>2,4GHz antennernes funktion. (LINK 13)</li>
              <li>Temperatur m&aring;linger p&aring; motor og ESC.</li>
              <li>
                Robusthed af det optr&aelig;kkelige understel ved fald test.
              </li>
              <li>Taxik&oslash;rsel.</li>
              <li>Fysiske bev&aelig;gelser af BWBen omkring alle 3 akser.</li>
              <li>Kontrol af dataopsamlingssystemet.</li>
              <li>
                Udarbejdelse af nedenst&aring;ende check liste p&aring; en
                Android Nexus 7 tablet computer. Det er en god ide at anvende en
                tjekliste, is&aelig;r hvis der g&aring;r lang tid mellem du er
                ude at flyve.(Appen hedder CheckList) Udover tjeklisten findes
                ogs&aring; dokumentation af programmerings- ops&aelig;tningen,
                alarmv&aelig;rdier, brugsanvisninger m.m. Anvendelsen af tablet
                computeren muligg&oslash;r, at data hurtigt kan rettes og altid
                er ved h&aring;nden. I det professionelle cockpit erstatter
                tablet computeren kilovis af papir manualer m.m., s&aring;
                hvorfor ikke bruge den til modelflyvning.
              </li>
            </ul>
            <p>F&oslash;r flyvningen.</p>
            <ol>
              <li>Sender t&aelig;ndes.</li>
              <li>Rigtig model i sender.</li>
              <li>Origin i GPS set.</li>
              <li>Motor og gear safety Switch OFF</li>
              <li>Sender gaspin low.</li>
              <li>Gear Switch G Down p&aring; sender.</li>
              <li>Jetibox on. Data recorder starter automatisk.</li>
              <li>Sk&aelig;rm 1 v&aelig;lges som vist:</li>
            </ol>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>mA/h</TableCell>
                  <TableCell>Vario</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Indicated Air Speed</TableCell>
                  <TableCell>Motor str&oslash;m</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <ol start={9 as number}>
              <li>Den faktiske kapacitet huskes.&nbsp;(LINK 14)</li>
              <li>Tilslutning af batteri.</li>
              <li>Motor og gear safety Switch ON</li>
              <li>FBW mode 100 % v&aelig;lges p&aring; sender. </li>
              <li>Test af Elevator og balance klapper.</li>
              <li>Landingslys t&aelig;ndes.</li>
            </ol>
            <p>
              Der er i alt til dato udf&oslash;rt 23 flyvninger, hvor der dels
              er udf&oslash;rt en r&aelig;kke indledende test, dels er
              m&aring;lt f&oslash;lgende specifikationer v.h.af Jeti
              telemetrisystemet og Jeti Flight Recorderen.
            </p>
            <p>
              Climb rate 9m/sek. med 38A motorstr&oslash;m.
              <br />
              Decent rate 2m/sek med lukket motor.
              <br />
              Glidetal med slukket motor 1 til 4,5.
              <br />
              Stall speed 9m/sek med lukket motor.
              <br />
              Cruise speed 13m/sek med 7A motorstr&oslash;m.
              <br />
              Max speed 31m/sek = 105km/t ved vandret flyvning.
              <br />
              Varighed 11min og Distance 8,8km ved et forbrug p&aring; 1575mA/h.
            </p>
            <p>
              Det skal n&aelig;vnes, at jomfruflyvningen forl&oslash;b uden
              problemer, hvor det eneste m&aring;l var at f&aring; BWBen sikkert
              ned. Flyvningen viste imidlertid, at flyet havde sv&aelig;rt ved
              at dreje. Ved flyvning nr.2 var stabiliseringssoftwaren
              &aelig;ndret radikal. Efter at BWBen var kommet i luften, svingede
              det med det samme ud til h&oslash;jre og gik i jorden. Det viste
              sig, at softwaren var sat op p&aring; en s&aring;dan m&aring;de,
              at balanceklapperne destabiliserede i stedet for at stabilisere.
              Flyveren var naturligvis umulig at styre. Hvorfor sker det? Ja,
              der er jo stadigv&aelig;k det med menneskelige fejl. (Link 15)
              Efter en mindre reparation fortsatte flyvningerne. De
              efterf&oslash;lgende flyvninger forl&oslash;ber uden dramatik med
              undtagelse af en flyvning, hvor jeg var p&aring; vej for langt
              v&aelig;k. I en afstand af 270m bringes BWBen uden for sin flight
              envelope, fordi jeg intuitivt tager gassen af motoren, Det
              betyder, at jeg flyver s&aring; langsomt, at
              stabiliseringssystemet ikke kan holde flyet i luften, hvorved det
              g&aring;r ind i et spiraldyk. Det medf&oslash;rte h&oslash;jdetab
              var 60 m, inden flyet rettede sig selv op. N&aring;r modellen er
              langt v&aelig;k, brydes tilbagemeldingen til pilotens hjerne,
              BWBen flyver autonomt, alts&aring; selvstabiliserende. Dette er en
              klar fordel, men kan ogs&aring; v&aelig;re en ulempe, noget man
              skal v&aelig;nne sig til. Under alle omst&aelig;ndigheder er
              piloten i en ny situation, idet flyvningen b&aring;de bestemmes af
              hvad piloten giver af input og Fly By Wire computerens behandling
              af de data den registrerer af flyets stilling og fart.
            </p>
            <h3>Flight Recorderen</h3>
            <p>
              Alle flyvningers telemetridata bliver konsekvent opsamlet i Flight
              Recorderen, idet &oslash;jeblik systemet t&aelig;ndes. Det er sat
              op p&aring; denne m&aring;de, fordi man aldrig kan forudse, hvad
              der kan ske under en flyvning. Man kan s&aring;
              efterf&oslash;lgende analysere h&aelig;ndelser sekund for sekund.
              Der er p&aring; de 23 flyvninger opsamlet omkring 28 Mbyte data,
              og det er naturligvis kun f&aring; h&aelig;ndelser som er
              interessante.
            </p>
            <p>
              Billede 6 viser 15 sekunder af en stalltest. De viste data er
              optaget kl. 11,32. Motoren er lukket, h&oslash;jden er 58m, der er
              max. opad h&oslash;jderor, dette kan udl&aelig;ses af 1,988mS
              pulsbredde m&aring;lt p&aring; h&oslash;jderoret, den indikerede
              air speed IAS er 0m/sek, fordi flyet flyver s&aring; langsomt, at
              pitotr&oslash;ret ikke l&aelig;ngere kan m&aring;le flyvefarten, 4
              sekunder senere kl. 11,36 falder flyet med n&aelig;sten 10m/sek
              (r&oslash;d kurve), og indikeret flyvefart m&aring;lt med
              pitotr&oslash;ret er steget til 23m/sek (gr&aring; kurve)med et
              h&oslash;jdetab p&aring; 30m (bl&aring; kurve) L&aelig;ngere
              fremme gives det fuld gas og der vindes h&oslash;jde.&nbsp;
            </p>
            <p>
              <img src={fig7} />
            </p>
            <p>
              Billede 6 man kan kun vise et &oslash;jebliksbillede p&aring;
              papir. Ved hj&aelig;lp af den lille flyver kan man flytte curleren
              igennem hele flyvningen, ligesom man selv kan bestemme, hvilke
              data der skal vises p&aring; de 3 grafer.
            </p>
            <p>
              <img src={fig8} />
            </p>
            <p>
              Billede 7. Klar til start. Efter en del eksperimenter har jeg
              valgt ikke at lade n&aelig;sehjulsstyringen g&aring; igennem fly
              by wire computeren, men styre den direkte fra siderors stikken.
            </p>
            <h2>S&aring; hvad kunne det n&aelig;ste s&aring; v&aelig;re?</h2>
            <p>
              Hvis en yderligere grad af autonomi &oslash;nskes, kan man
              t&aelig;nke p&aring; en autopilot, som den findes i flyindustrien.
              Her er det muligt, at f&aring; flyet til at holde en konstant
              flyvefart i forhold til luften (TAS), en konstant h&oslash;jde og
              en konstant kompaskurs, indtil autopiloten f&aring;r nye input.
              Det ville ogs&aring; v&aelig;re en ide, om man kunne hjemkalde
              modelflyet, hvis det var ved at flyve v&aelig;k. Disse funktioner
              findes allerede i en r&aelig;kke nye produkter, som jeg kort skal
              omtale.
            </p>
            <p>
              Det amerikanske firma Eagle Tree har lanceret en Flight
              Controller, som hedder Vector. Den er egentlig tilt&aelig;nkt
              First Personal View flyvning. FPV flyvning foreg&aring;r ved, at
              piloten ser flyet igennem et par videobriller, som modtager
              billeder og data fra flyets videokamera. Kontrolenheden kan dels
              stabilisere flyet som forfatterens BWB, dels modtage data fra den
              tilh&oslash;rende GPS modtager, som ogs&aring; har et
              magnetometer, dels modtage ordre fra piloten via senderen. Det
              sidste er n&oslash;dvendigt, idet der jo ikke sidder en pilot oppe
              i modelflyet, som kan indtaste nye data til autopiloten. Firmaet
              har udviklet en s&aelig;rdeles forst&aring;elig brugerflade til en
              windows computer og en manual p&aring; 88 sider. Produktet
              underst&oslash;tter b&aring;de fastvinge fly og multi-rotor fly.
              (LINK 16)
            </p>
            <ul>
              <li>
                En anden mulighed er et lille printkort som
                hedder&nbsp;OpenPilot CC3D Open Source Flight Controller fra
                firmaet EMax. Printkortet indeholder en 32 Bits Processor samt
                et&nbsp;3-akset Gyroscope og Accelerometer, en 4 Mbit onboard
                memory og en r&aelig;kke porte. Kortet kan anvendes til
                multi-rotor fly, helikoptere og fastvingefly. Prisen p&aring;
                kun 240 kr, viser tendensen til, at hardware bliver billigere og
                billigere. (LINK 17) Det er s&aelig;rlig interessant, at kortet
                og softwaren er udviklet i Open Source af en gruppe passionerede
                udviklere spredt over hele jorden. (Link 18) Hvis du
                v&aelig;lger dette produkt, skal du have en god teknisk
                baggrund.&nbsp;
              </li>
            </ul>
            <p>
              Til sidst skal n&aelig;vnes Jeti, som i januar 2014 p&aring;
              Nurnbergmessen introducerede to stabiliserings produkter, som
              skulle v&aelig;re klar til sommeren. (Link19) Produkterne er ikke
              dukket op her i december 2014. Jeti har den politik, at det altid
              melder sine nyheder tidligt ud, g&oslash;r sig stor umage med at
              g&oslash;re softwaren s&aring; fejlfri som mulig, og lader
              s&aring; kunderne vente t&aring;lmodigt. Principielt l&oslash;ber
              man den risiko, at udviklingen l&oslash;ber fra dem p&aring; grund
              af den eksponentielle digitale ekspantion. (Link 20)
            </p>
            <p>
              Med den omsiggribende m&aelig;ngde software der anvendes i
              modelflyvningen, b&oslash;r modelflyveren v&aelig;re
              opm&aelig;rksom p&aring;, at der er en stor risiko for fejl, dels
              i programmeringskoden i produkterne, dels i ops&aelig;tningen, som
              forfatteren allerede har omtalt f&oslash;rst i denne artikkel. I
              mods&aelig;tning til tog og de selvk&oslash;rende biler, som snart
              kommer, har et fly ingen sikker tilstand, det kan ikke holde
              stille i luften! Derfor er fejl i softwaren dels katastrofal, dels
              sv&aelig;r at finde. Til sidst stiller jeg dig
              sp&oslash;rgsm&aring;let: &Oslash;nsker du, at modelflyet skal
              ende med at kunne flyve helt af sig selv? Uanset hvad du svarer,
              vil en &oslash;get anvendelse af forskellige digitale teknologier
              brede sig ind i modelflyvningen med fordele og udfordringer.&nbsp;
            </p>
            <h2>Hvis du vil vide mere</h2>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Link</TableCell>
                  <TableCell>Emne</TableCell>
                  <TableCell>Modelflyvenyt</TableCell>
                  <TableCell>WWW</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>NASA</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="http://en.wikipedia.org/wiki/Boeing_X-48"
                      target="_blank"
                    >
                      http://en.wikipedia.org/wiki/Boeing_X-48
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2</TableCell>
                  <TableCell>Beregning af tyngdepunkt</TableCell>
                  <TableCell>Nr 4 2014</TableCell>
                  <TableCell>
                    <Link
                      href="/Grundviden/Bestemmelse-af-tyngdepunkt"
                      target="_blank"
                    >
                      https://elflytec.dk/Grundviden/Bestemmelse-af-tyngdepunkt
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>3</TableCell>
                  <TableCell>Tegning af profiler</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link href="http://www.profili2.com" target="_blank">
                      http://www.profili2.com
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>4</TableCell>
                  <TableCell>Beregninger af flydata</TableCell>
                  <TableCell>Nr 4 2013</TableCell>
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Grundviden/HvordanElflyetVirker/Beregninger-af-et-elflys-data"
                      target="_blank"
                    >
                      https://elflytec.dk/Grundviden/HvordanElflyetVirker/Beregninger-af-et-elflys-data
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5</TableCell>
                  <TableCell>Simulation af flydata</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Grundviden/HvordanElflyetVirker/Simulation-af-et-elflys-data"
                      target="_blank"
                    >
                      https://elflytec.dk/Grundviden/HvordanElflyetVirker/Simulation-af-et-elflys-data
                    </Link>
                    <br />
                    <Link href="http://www.motocalc.com/" target="_blank">
                      http://www.motocalc.com/
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>6</TableCell>
                  <TableCell>Telemetri</TableCell>
                  <TableCell>Nr 1 og 5 2010</TableCell>
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Jeti/Jeti-Duplex-2.4-GHz-teknik"
                      target="_blank"
                    >
                      https://elflytec.dk/Jeti/Jeti-Duplex-2.4-GHz-teknik
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>7</TableCell>
                  <TableCell>Fly by wire</TableCell>
                  <TableCell>Nr 4 2013</TableCell>
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/FlyByWire/Fly-by-Wire"
                      target="_blank"
                    >
                      https://elflytec.dk/FlyByWire/Fly-by-Wire
                    </Link>
                    <br />
                    <Link
                      href="http://www.rc-netbutik.dk/product.asp?product=2262"
                      target="_blank"
                    >
                      http://www.rc-netbutik.dk/product.asp?product=2262
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>8</TableCell>
                  <TableCell>Blackbox Recorder</TableCell>
                  <TableCell>Nr 2 og 3. 2012</TableCell>
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Jeti/Jeti-Box-Profi-EX-Del-1"
                      target="_blank"
                    >
                      https://elflytec.dk/Jeti/Jeti-Box-Profi-EX-Del-1
                    </Link>
                    <br />
                    <Link
                      href="https://elflytec.dk/Jeti/Jeti-Box-Profi-EX-Del-2"
                      target="_blank"
                    >
                      https://elflytec.dk/Jeti/Jeti-Box-Profi-EX-Del-2
                    </Link>
                    <br />
                    <Link
                      href="https://elflytec.dk/Jeti/Flight-Monitor-maalinger-paa-flyvninger"
                      target="_blank"
                    >
                      https://elflytec.dk/Jeti/Flight-Monitor-maalinger-paa-flyvninger
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>9</TableCell>
                  <TableCell>Målinger på Dualsky regulator</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Jeti/Flight-Monitor-maaling-paa-komponenter"
                      target="_blank"
                    >
                      https://elflytec.dk/Jeti/Flight-Monitor-maaling-paa-komponenter
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10</TableCell>
                  <TableCell>Digitale servoer</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Grundviden/Digitale-servoer-og-BEC-stroemforsyninger"
                      target="_blank"
                    >
                      https://elflytec.dk/Grundviden/Digitale-servoer-og-BEC-stroemforsyninger
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>11</TableCell>
                  <TableCell>Propellen Varioprop</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="http://www.ramoser.de/home_e/varioprop_e/varioprop_e.html"
                      target="_blank"
                    >
                      http://www.ramoser.de/home_e/varioprop_e/varioprop_e.html
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>12</TableCell>
                  <TableCell>Jeti</TableCell>
                  <TableCell>Nr 1 2010</TableCell>
                  <TableCell>
                    <Link href="http://www.jetimodel.com/en/" target="_blank">
                      http://www.jetimodel.com/en/
                    </Link>
                    <br />
                    <Link
                      href="https://elflytec.dk/Jeti/Jeti-Duplex-2.4-GHz-teknik"
                      target="_blank"
                    >
                      https://elflytec.dk/Jeti/Jeti-Duplex-2.4-GHz-teknik
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>13</TableCell>
                  <TableCell>Målinger på 2,4GHz antenner</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Jeti/Flight-Monitor-maaling-paa-2.4GHz-signaler"
                      target="_blank"
                    >
                      https://elflytec.dk/Jeti/Flight-Monitor-maaling-paa-2.4GHz-signaler
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>14</TableCell>
                  <TableCell>Faktisk kapacitet på Li-Po celler</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Lipo/Vaerd-at-vide-om-LiPo"
                      target="_blank"
                    >
                      https://elflytec.dk/Lipo/Vaerd-at-vide-om-LiPo
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>15</TableCell>
                  <TableCell>Den menneskelige faktor</TableCell>
                  <TableCell>Nr 6 2012</TableCell>
                  <TableCell>
                    <Link
                      href="https://elflytec.dk/Andet/Den-menneskelige-faktor"
                      target="_blank"
                    >
                      https://elflytec.dk/Andet/Den-menneskelige-faktor
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>16</TableCell>
                  <TableCell>Eagle tree Vektor</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="http://www.rc-netbutik.dk/product.asp?product=3962"
                      target="_blank"
                    >
                      http://www.rc-netbutik.dk/product.asp?product=3962
                    </Link>
                    <br />
                    <Link
                      href="http://eagletreesystems.com/Manuals/vector.pdf"
                      target="_blank"
                    >
                      http://eagletreesystems.com/Manuals/vector.pdf
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>17</TableCell>
                  <TableCell>OpenPilot CC3D Controller</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="http://www.rc-netbutik.dk/product.asp?product=3846"
                      target="_blank"
                    >
                      http://www.rc-netbutik.dk/product.asp?product=3846
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>18</TableCell>
                  <TableCell>Open Pilot software</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link href="https://www.librepilot.org" target="_blank">
                      https://www.librepilot.org
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>19</TableCell>
                  <TableCell>Jeti Gyrosystemer</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="https://www.edf-jets.de/news/274-jeti-ex-messe-news.html"
                      target="_blank"
                    >
                      https://www.edf-jets.de/news/274-jeti-ex-messe-news.html
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>20</TableCell>
                  <TableCell>Digital udvikling</TableCell>
                  <TableCell />
                  <TableCell>
                    <Link
                      href="http://www.forstaafremtiden.dk/"
                      target="_blank"
                    >
                      http://www.forstaafremtiden.dk/
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
