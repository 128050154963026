import React, { Component } from "react";
import {
  withStyles,
  Menu,
  MenuItem,
  IconButton,
  Button
} from "@material-ui/core";
import MenuData from "./MenuData";
import { Link } from "react-router-dom";

const styles = theme => ({
  menuButtons: {
    textAlign: "center" as "center"
  },
  menuButton: {
    color: "#ffffff",
    borderRadius: 0,
    "&:not(:last-child)": {
      borderRight: 0
    }
  },
  "@media print": {
    menuButton: {
      display: "none"
    }
  }
});

interface IState {
  anchorEl: any;
  menuId: number;
}

interface IProps {
  classes: any;
}

class MenuDesktop extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      menuId: 1
    };
  }

  handleClick = id => event => {
    this.setState({ anchorEl: event.currentTarget, menuId: id });
  };

  handleClose = (url?: string) => {
    if (url && url === "#") {
      // Ignore
    } else {
      this.setState({ anchorEl: null });
    }
  };

  render() {
    const { classes } = this.props;

    let selectedMenu = MenuData.find(
      topMenu => topMenu.id === this.state.menuId
    );

    if (!selectedMenu) {
      selectedMenu = {
        id: 0,
        name: "",
        url: "",
        children: []
      };
    }

    return (
      <div className={classes.menuButtons}>
        {MenuData.map(topMenu => {
          return (
            <Button
              key={topMenu.id}
              variant="outlined"
              className={classes.menuButton}
              onClick={this.handleClick(topMenu.id)}
            >
              {topMenu.name}
            </Button>
          );
        })}

        <Menu
          id="menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.handleClose();
          }}
        >
          {selectedMenu.children.map(child => {
            return (
              <MenuItem
                key={child.name}
                onClick={() => {
                  this.handleClose(child.url);
                }}
                component={({ innerRef, ...props }) => (
                  <Link {...props} to={child.url} />
                )}
              >
                {child.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(MenuDesktop);
