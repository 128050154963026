import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Grundviden/SimulationAfEtElflysData/fig1.jpg";
import fig2 from "../../../images/Grundviden/SimulationAfEtElflysData/fig2.jpg";
import fig3 from "../../../images/Grundviden/SimulationAfEtElflysData/fig3.jpg";
import fig4 from "../../../images/Grundviden/SimulationAfEtElflysData/fig4.jpg";
import fig5 from "../../../images/Grundviden/SimulationAfEtElflysData/fig5.jpg";
import fig6 from "../../../images/Grundviden/SimulationAfEtElflysData/fig6.jpg";
import fig7 from "../../../images/Grundviden/SimulationAfEtElflysData/fig7.jpg";
import fig8 from "../../../images/Grundviden/SimulationAfEtElflysData/fig8.jpg";
import fig9 from "../../../images/Grundviden/SimulationAfEtElflysData/fig9.jpg";
import fig10 from "../../../images/Grundviden/SimulationAfEtElflysData/fig10.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>
            Hvordan elflyet virker - Del 2. Simulation af et elflys data
          </title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Del 2. Simulation af et elflys data</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret juli 2013.</p>
            <p>
              <Link href="/Grundviden/HvordanElflyetVirker/Beregninger-af-et-elflys-data">
                I del 1 bliver opstillet en fremgangsm&aring;de, s&aring;ledes
                du kunne beregne et elflys data.
              </Link>
              <br />
              I del 2 foretages en computer simulation af data ved hj&aelig;lp
              af programmet MotoCalc.
              <br />
              <Link href="/Grundviden/HvordanElflyetVirker/Maalinger-af-et-elflys-data">
                I del 3 m&aring;les flyets data i praksis, ved at telemetridata
                recordes og efterf&oslash;lgende analyseres.
              </Link>
              <br />
              M&aring;let med de tre artikler er, at modelflyveren f&aring;r en
              bedre forst&aring;else af, hvordan elflyet virker.
            </p>
            <p>
              <img src={fig1} />
            </p>
            <p>
              I denne 2 del foretages nu en simulation af Flexflyeren, hvis
              ydeevne blev beregnet i del 1. Til dette form&aring;l anvendes
              programmet MotoCalc. Det vil v&aelig;re en fordel at l&aelig;se
              del 1 for at forst&aring; samspillet i mellem flyet, propellen,
              motoren, regulatoren og batteriet.
            </p>
            <h2>Input til MotoCalc</h2>
            <p>
              Ved f&oslash;rste opstart af programmet ops&aelig;ttes under
              Options til metriske m&aring;l og propeller i tommer, derudover
              til v&aelig;lges alle display oplysninger.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Sk&aelig;rmbillede 1
            </p>
            <p>
              Programmet startes nu, ved at du under Project v&aelig;lger New,
              derved &aring;bnes sk&aelig;rmbillede 1 med tomme felter. Du kan
              nu v&aelig;lge Motor ved at taste Open og der finde den
              &oslash;nskede motor i programmets database. P&aring; tilsvarende
              m&aring;de findes Batteri, Driv system og Speed controllen. Din
              model oprettes under Airframe ved at indtaste sp&aelig;ndvidde,
              planareal og tomv&aelig;gt. Under tomv&aelig;gten har programmet
              allerede registreret v&aelig;gten af motor, batteri og
              fartregulator, s&aring; det der indtastes af v&aelig;gt p&aring;
              sk&aelig;rmbillede 1, er resten af flyets v&aelig;gt med
              tilh&oslash;rende radioanl&aelig;g m.m. Du skal kontrollere ved
              f&oslash;rste k&oslash;rsel af programmet, at flyets totale
              v&aelig;gt stemmer med virkeligheden. &Aring;rsagen til at
              programmet er bygget op p&aring; denne m&aring;de er naturligvis,
              at hvis man skifter komponenter ud i drivlinien, kompenserer
              programmet selv for v&aelig;gten. Derefter v&aelig;lges
              Coeff&hellip; knappen, hvorved sk&aelig;rmbillede 2
              &aring;bnes.&nbsp;
            </p>
            <p>
              <img src={fig3} />
              <br />
              Sk&aelig;rmbillede 2
            </p>
            <p>
              Sk&aelig;rmbillede 2 giver mulighed for at v&aelig;lge oplysninger
              om flyets aerodynamiske data. Fra knappen Choose From List
              v&aelig;lges E474 profilet fra profil databasen. Indfaldsvinklen
              s&aelig;ttes til 4 grader, idet man skal huske, at et
              helsymmetrisk profil ikke har l&oslash;ft ved 0 grader. I de fire
              bokse til h&oslash;jre v&aelig;lges de data, som passer til
              modellen. Derefter afsluttes med Apply og OK.
            </p>
            <h2>K&oslash;rsel af programmet og det beregnede output</h2>
            <p>
              Programmets har nu f&aring;et alle de data, som er
              n&oslash;dvendige for at kunne gennemf&oslash;re en beregning af
              flyveren. Den f&oslash;rste beregning kan nu udf&oslash;res ved at
              taste Compute Report p&aring; sk&aelig;rmbillede 1.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Sk&aelig;rmbillede 3
            </p>
            <p>
              Der fremkommer nu p&aring; sk&aelig;rmbillede 3 en m&aelig;ngde
              tal, som vi skal se n&aelig;rmere p&aring;. &Oslash;verst er der 4
              linjer, som blot beskriver de anvendte komponenter i drivlinien,
              du har anvendt. Derefter beskrives modellen, og her skal du
              kontrollere, at flyets totale v&aelig;gt stemmer med
              virkeligheden. I den sidste linje begynder der s&aring; at komme
              beregnede data i f&oslash;lgende r&aelig;kkef&oslash;lge:
            </p>
            <ul>
              <li>Tilf&oslash;rt effekt pr. kg. Modelv&aelig;gt.</li>
              <li>Nyttiggjort effekt til propellen pr. kg modelv&aelig;gt.</li>
              <li>Stall speed.</li>
              <li>Cruise speed</li>
              <li>Max flyvetid ved cruise speed.</li>
              <li>Speed ved vandret flyvning med 4 graders indfaldsvinkel.</li>
              <li>Max stigeevne og stigevinkel.</li>
              <li>Synkehastighed og glide vinkel.</li>
            </ul>
            <p>
              Vi vil derefter se p&aring; indholdet i den store tabels 17
              kolonner:
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kolonne</TableCell>
                  <TableCell>Forklaring og bemærkninger</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>AirSpeed</TableCell>
                  <TableCell>
                    Den flyvefart beregningen gælder for. Fra 0m/sek til 9m/sek
                    kan flyet overhovedet ikke flyve, det er alle de felter, som
                    er mærket gult. Stall speed bliver så 9m/sek. Max speed er
                    22,5 m/sek
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Drag og lift</TableCell>
                  <TableCell>
                    De to næste kolonner viser hvorledes luftmodstand og opdrift
                    stiger med stigende fart.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Bat amps og Motor amps</TableCell>
                  <TableCell>
                    Disse kolonner viser strømforbruget fra start og op til max
                    flyvefart ved max. throttle.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Motor volts</TableCell>
                  <TableCell>
                    Derefter vises den jævnspænding, som forventes at være
                    tilstede på regulatoren, når den belastes ved max throttle.
                    Den er lidt mindre end den belastede batterispænding.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Input effekt</TableCell>
                  <TableCell>
                    Er produktet af de to foregående størrelse.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Loss</TableCell>
                  <TableCell>
                    Er effekttabet i motoren. Det er dette tab, som giver
                    anledning til opvarmningen af motoren, og dermed sætter den
                    reelle grænse for max output fra motoren, idet der er en
                    grænse for hvor varm motoren, specielt magneterne kan tåle
                    at blive. Størrelsen er nem at måle, men svær at beregne
                    eller simulere, idet motoren normalt er luftkølet.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Motor Gb out</TableCell>
                  <TableCell>
                    Er forskellen mellem indgangseffekten på motoren og effekten
                    på propelakslen. D.v.s. at tabet i regulatoren er medregnet.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Motor Gb effektivitet</TableCell>
                  <TableCell>Er virkningsgraden på motoren.</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Shaft effektivitet</TableCell>
                  <TableCell>
                    Samlet virkningsgrad på ledninger stik, regulator og motor
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Prop RPM</TableCell>
                  <TableCell>Omdrejninger på propellen.</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Thrust</TableCell>
                  <TableCell>Det tryk propellen leverer.</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Pspeed</TableCell>
                  <TableCell>
                    Den lufthastigheds forøgelse som findes lige bag propellen,
                    i forhold til flyvefarten.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Propel effektivitet</TableCell>
                  <TableCell>Propellens virkningsgrad.</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Total effektivitet</TableCell>
                  <TableCell>
                    Effektivitet af propel, motor og regulator.
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Tid</TableCell>
                  <TableCell>
                    Beregnet flyvetid ud fra at du anvender hele kapaciteten på
                    batteriet, altså 3700mA/t.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Bl&aring; linje: Clopt er der, hvor bedste forhold er mellem lift
              og drag = bedste glidetal.
              <br />
              Gr&oslash;n linje:&nbsp;Cl er styret af indfaldsvinklen.
              Pr&oslash;v at &aelig;ndre indfaldsvinklen. Her er opdrift =
              modellens v&aelig;gt.
            </p>
            <h2>Skriftligt udsagn</h2>
            <p>
              I stedet for de mange tal kan man ogs&aring; f&aring; et
              skriftligt udsagn ved at taste p&aring; opinion p&aring;
              sk&aelig;rmbillede 3.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <h2>Grafer</h2>
            <p>
              Programmet kan ogs&aring; frembringe grafer ved at taste Graph
              p&aring; sk&aelig;rmbillede 3. Disse giver ofte et bedre overblik
              end de mange tal.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Sk&aelig;rmbillede 4
            </p>
            <p>
              Sk&aelig;rmbillede 4 viser, hvorledes motoromdrejningerne
              (r&oslash;d kurve) og propellens virkningsgrad (bl&aring; kurve)
              &aelig;ndrer sig ved forskellige lufthastigheder.
            </p>
            <p>
              <img src={fig7} />
              <br />
              Sk&aelig;rmbillede 5
            </p>
            <p>
              Sk&aelig;rmbillede 5 viser, hvorledes modellens luftmodstand
              stiger (r&oslash;d kurve), og propellens tr&aelig;k falder
              (bl&aring; kurve) ved stigende flyvefart. N&aring;r de er lige
              store, kan modellen ikke flyve hurtigere (Ved vandret flyvning).
            </p>
            <h2>Cruise speed</h2>
            <p>
              &Oslash;verst til h&oslash;jre p&aring; sk&aelig;rmbillede 6
              findes en skyder som svarer til gaspinden p&aring; senderen
            </p>
            <p>
              <img src={fig8} />
              <br />
              Sk&aelig;rmbillede 6
            </p>
            <p>
              Hvis man reducerer gassen s&aring; meget (58&nbsp;%), at flyet
              hverken stiger eller falder haves cruise speed.
              <br />
              Programmet viser med den turkisfarvede linje den flyvefart, hvor
              det bedste forhold mellem opdrift og modstand opn&aring;s, her
              haves den mest &oslash;konomiske flyvning.
            </p>
            <h2>Forslag til forskellige propel stigninger</h2>
            <p>
              I sk&aelig;rmbillede 1 kan man under drivline indtaste forslag til
              at &aelig;ndre propelst&oslash;rrelserne som vist i
              sk&aelig;rmbillede 7, derved fremkommer sk&aelig;rmbillede 8. Man
              kan nu se, hvorledes de statiske v&aelig;rdier &aelig;ndrer sig.
              L&aelig;g is&aelig;r m&aelig;rke til motorstr&oslash;mmen! De
              r&oslash;de tal viser at de 3 af de valgte propeller, er stallede
              under starten af flyet, fordi stigningen er blevet for stor. Man
              kan skifte propeller med andre stigninger og diametre og foretage
              simulation af flyvningen for at finde den optimale propel i
              forhold til de flyvem&oslash;nster, som &oslash;nskes.
            </p>
            <p>
              <img src={fig9} />
              <br />
              Sk&aelig;rmbillede 7
            </p>
            <p>
              <img src={fig10} />
              <br />
              Sk&aelig;rmbillede 8
            </p>
            <h2>Sammenligning</h2>
            <p>
              Der foretages nu en sammenligning mellem de beregnede og de
              simulerede resultater.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sekvens</TableCell>
                  <TableCell>Resultatet af beregninger fra del 1</TableCell>
                  <TableCell>Resultatet af MotoCalcs simulationen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Stigning</TableCell>
                  <TableCell>6 m/sek</TableCell>
                  <TableCell>6,6 m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max. speed</TableCell>
                  <TableCell>20 m/sek</TableCell>
                  <TableCell>22,5 m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cruise speed</TableCell>
                  <TableCell>13 m/sek</TableCell>
                  <TableCell>12,5 m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Minimum speed</TableCell>
                  <TableCell>9 m/sek</TableCell>
                  <TableCell>9 m/sek</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Ud fra flyvesekvenserne kan yderligere udledes en r&aelig;kke
              data, som beskriver flyets ydeevne.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Resultatet af artiklens beregninger</TableCell>
                  <TableCell>Resultatet af simulationen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Max. tophøjde</TableCell>
                  <TableCell>1746 meter</TableCell>
                  <TableCell>
                    6,6 m/sek x 6 min x 60sek = 2376 meter <br />
                    (1* Korrigeret til 1615 meter)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Synkehastighed</TableCell>
                  <TableCell>2m pr.sek</TableCell>
                  <TableCell>2,13m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max. flyvetid</TableCell>
                  <TableCell>21min</TableCell>
                  <TableCell>
                    32min <br />
                    (1* Korrigeret til 21,6 min)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Glidetal</TableCell>
                  <TableCell>5 til 1</TableCell>
                  <TableCell>12,5m/sek / 2,13m/sek = 5,86 til 1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max rækkevidde</TableCell>
                  <TableCell>16,4 km</TableCell>
                  <TableCell>
                    12,5 m/sek x 32 min x 60 sek = 24 km <br />
                    (1* Korrigeret til 16,32km)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Speed-range</TableCell>
                  <TableCell>2x</TableCell>
                  <TableCell>2,5x</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              (1* MotoCalc g&aring;r ud fra, at man kan anvende alle
              3700mA/timer, som er p&aring; stemplet Li-Po batteriet.
              Beregningerne g&aring;r ud fra, at man kun anvender 70&nbsp;% af
              batteriets faktiske kapacitet, som blev bestemt til at v&aelig;re
              2500mA/time. Dermed ses at der bliver stor overensstemmelse mellem
              de beregnede og simulerede data.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Beregnet max</TableCell>
                  <TableCell>MotoCalc max</TableCell>
                  <TableCell>Beregnet cruise</TableCell>
                  <TableCell>MotoCalc cruise</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Omdrejninger</TableCell>
                  <TableCell>10236 omdr/min</TableCell>
                  <TableCell>12353 omdr/min</TableCell>
                  <TableCell>6397 omdr/min</TableCell>
                  <TableCell>7424 omdr/min</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Virkningsgrad på propellen</TableCell>
                  <TableCell>50&nbsp;%</TableCell>
                  <TableCell>51,8&nbsp;%</TableCell>
                  <TableCell>50&nbsp;%</TableCell>
                  <TableCell>49,9&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Udgangseffekt på akslen</TableCell>
                  <TableCell>240&nbsp;W</TableCell>
                  <TableCell>257&nbsp;W</TableCell>
                  <TableCell>60&nbsp;W</TableCell>
                  <TableCell>64&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motorens virkningsgrad</TableCell>
                  <TableCell>77&nbsp;%</TableCell>
                  <TableCell>84,4&nbsp;%</TableCell>
                  <TableCell>83&nbsp;%</TableCell>
                  <TableCell>86&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tilført effekt</TableCell>
                  <TableCell>328&nbsp;W</TableCell>
                  <TableCell>292&nbsp;W</TableCell>
                  <TableCell>76&nbsp;W</TableCell>
                  <TableCell>74&nbsp;W</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Batteristrøm</TableCell>
                  <TableCell>30,9&nbsp;A</TableCell>
                  <TableCell>27,4&nbsp;A</TableCell>
                  <TableCell>6,9&nbsp;A</TableCell>
                  <TableCell>6,9&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Køretid</TableCell>
                  <TableCell>4 min og 51sek</TableCell>
                  <TableCell>
                    8 min <br />
                    (1* 5,44 min)
                  </TableCell>
                  <TableCell>21 min</TableCell>
                  <TableCell>
                    32 min <br />
                    (1* 21,6min)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Som det ses er der rimelig overensstemmelse mellem de beregnede og
              de simulerede data. Forfatteren har anvendt MotoCalc p&aring;
              mange forskellige modeller, og programmet giver et glimrende
              overslag over elflys data. Det er ligeledes et godt
              hj&aelig;lpemiddel til at anskueligg&oslash;re, hvordan tingene
              virker. MotoCalc kan ogs&aring; anvendes p&aring; ducted fan fly,
              fly med flere motorer, ligesom man har muligheder for at skaffe
              manglende data p&aring; elmotorer ud fra m&aring;linger.
            </p>
            <h2>MotoCalcs anskaffelse</h2>
            <p>
              MotoCalc hentes p&aring; nettet p&aring; adressen:&nbsp;
              <Link href="http://www.motocalc.com/" target="_blank">
                http://www.motocalc.com
              </Link>{" "}
              Prisen er 39 US dollar. Du kan pr&oslash;ve programmet frit i en
              m&aring;ned, og eventuelt down-loade manualen til n&aelig;rmere
              l&aelig;sning.&nbsp;
              <br />
              &nbsp;
              <br />I den 3. og sidste artikel skal unders&oslash;ges, hvordan
              flexflyerens data ser ud i virkeligheden, n&aring;r de m&aring;les
              og analyseres.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
