import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles, Link } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/JetiDuplex24GHzTeknik/fig1.jpg";
import fig2 from "../../../images/Jeti/JetiDuplex24GHzTeknik/fig2.jpg";
import fig3 from "../../../images/Jeti/JetiDuplex24GHzTeknik/fig3.jpg";
import fig4 from "../../../images/Jeti/JetiDuplex24GHzTeknik/fig4.jpg";
import fig5 from "../../../images/Jeti/JetiDuplex24GHzTeknik/fig5.jpg";
import fig6 from "../../../images/Jeti/JetiDuplex24GHzTeknik/fig6.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Jeti Duplex - 2,4GHz teknik med telemetri</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Jeti Duplex - 2,4GHz teknik med telemetri</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>
              Publiceret december 2009. <br /> <br /> Denne artikel er en
              forts&aelig;ttelse af emnet: &rdquo;
              <Link
                href="/Grundviden/Grundlaeggende-2.4-GHz-teknik"
                target="_blank"
              >
                Grundl&aelig;ggende 2,4GHz teknik
              </Link>
              &rdquo;, bragt i{" "}
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2009/modelflyvenyt%205-2009.pdf"
                target="_blank"
              >
                nummer 5-2009 af Modelflyvenyt
              </Link>
              . Det er en fordel, at du kender de grundl&aelig;ggende begreber,
              f&oslash;r du l&aelig;ser videre.&nbsp; <br />
            </p>
            <h2>Telemetri, dataopsamling og logning</h2>

            <p>
              2,4GHz teknikken udvikler sig fortsat i spring. Nyeste
              frembringelse er, at du kan sende data tilbage fra flyet til
              jorden under flyvningen i sand tid, begrebet kaldes telemetri, som
              kan overs&aelig;ttes til fjernm&aring;ling. En foruds&aelig;tning
              for at dette kan lade sig g&oslash;re er, at der kan oprettes en
              2-vejs data forbindelse mellem flyet og jorden. Dette er der
              allerede lagt op til, idet den kredsl&oslash;bsteknologi, som
              anvendes i vores 2,4GHz anl&aelig;g, grundl&aelig;ggende er
              udviklet til tr&aring;dl&oslash;se lokale datanetv&aelig;rk, som
              netop virker begge veje. En anden foruds&aelig;tning er, at flyet
              udstyres med de n&oslash;dvendige m&aring;lemoduler, som kan
              oms&aelig;tte elektriske og fysiske st&oslash;rrelser til
              m&aring;lbare data. Nu kan man ikke alene frembringe data under
              flyvningen, men ogs&aring; opsamle og gemme en r&aelig;kke data,
              s&aring; som minimum batterisp&aelig;nding p&aring; modtageren
              eller maksimal flyveh&oslash;jde og s&aring; udl&aelig;se disse
              data efter landingen. Endelig skal n&aelig;vnes, at man kan
              k&oslash;be egentlige loggere, som under flyvningen opsamler store
              m&aelig;ngder data, og som efter landingen kan tilsluttes en PC.
              Data oms&aelig;ttes s&aring; til kurver v.hj. af programmer, som
              f.eks. Logview, s&aring; man derefter kan analysere flyvningen. En
              parallel har vi over til flyindustriens sorte bokse, hvor
              problemet er at finde disse efter ulykker p&aring; f.eks. dybt
              vand. Airbus arbejder derfor intensivt med at overf&oslash;re
              flyets data via satellitter under flyvningen, alts&aring;
              telemetri i sand tid, s&aring;ledes at efters&oslash;gning af en
              savnet boks bliver un&oslash;dvendig.
            </p>
            <h2>Jeti Duplex systemet</h2>
            <p>
              Udviklingen af forfinet mikroelektronik i h&oslash;j kvalitet, er
              nu s&aring; langt fremme at det tjekkiske firma Jeti
              pr&aelig;senterer et f&aelig;rdigt 2,4GHz system, som indeholder
              telemetri og dataopsamling. P&aring; det viste blokdiagram vil jeg
              fors&oslash;ge at give dig et overblik over det samlede Jeti
              Duplexsystem, som det ser ud i udbygget form, samtidig med at der
              henvises til nedenst&aring;ende forklaring.&nbsp;
            </p>
            <p>
              <img src={fig1} />
            </p>
            <h2>2-vejs dataforbindelsen</h2>
            <p>
              Trafikken af data mellem sender og modtager ser s&aring;ledes ud:
            </p>
            <ol>
              <li>
                <p>
                  F&oslash;r systemet tages i brug skal der oploades
                  programmering af forskellige data, dels til modtageren, dels
                  til m&aring;lemodulerne, indeholdende blandt andet
                  alarmv&aelig;rdier.
                </p>
              </li>
              <li>
                <p>
                  Under flyvningen sendes der naturligvis en bitstr&oslash;m af
                  nyttedata (16 bit pr. kanal) op til flyet for styring af
                  servoer p&aring; normal vis.
                </p>
              </li>
              <li>
                <p>
                  Som noget nyt sendes der en r&aelig;kke data tilbage fra flyet
                  til jorden, samtidig med, at der opsamles en r&aelig;kke min
                  og max v&aelig;rdier, som kan udl&aelig;ses efter landingen.
                </p>
              </li>
            </ol>
            <p>
              Den m&aring;de der sendes p&aring;, er en kombination af Spektrum,
              (Listen before talk), Futaba FASST (frekvens-hopping) og Graupner
              IFS (Adaptivt med telemetri), hvorved maksimal sikkerhed og
              hastighed opn&aring;s. Forsinkelsen mellem styrepind og servo er
              mellem 2mS og en timeframe. Systemet starter med at
              unders&oslash;ge 2,4GHz b&aring;ndet for trafik, hvorefter det
              opretter 16 udvalgte kanaler, som der hoppes p&aring;. Data sendes
              i pakker af variabel l&aelig;ngde. Ved afslutning af en datapakke,
              overf&oslash;res data fra modellen til jorden, hvor systemet
              s&aring; tester styrke og kvalitet af datatransmissionen. Systemet
              kan maximalt h&aring;ndtere 120 t&aelig;ndte Jeti Duplex
              anl&aelig;g p&aring; en gang. Den anvendte IC-kreds fra Atmel har
              p&aring; de store modtagere en f&oslash;lsomhed p&aring; -106dBm,
              hvilket giver en r&aelig;kkevidde p&aring; ca. 3000m i et frit
              rum.
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              En spektrum analyse af senderen, hvor man tydeligt kan se at den
              hopper p&aring; 16 kanaler.&nbsp;
            </p>
            <h2>Jetibox</h2>
            <p>
              For at systemet kan tale sammen med piloten, bliver man
              n&oslash;dt til at have en box, som monteres p&aring; senderen.
              Denne box, Jetiboxen er allerede kendt af modelflyvere, som
              anvender Jeti Spin regulatorer. Den best&aring;r af 4 input
              taster, som anvendes til programmering og et 2 linjers LCD display
              til udl&aelig;sning. Den kan i &oslash;vrigt ogs&aring; bruges som
              servotester.
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Jetibox er tilsluttet det udskiftede sendermodul p&aring; bagsiden
              og monteret p&aring; 35MHz antennen.
            </p>
            <h2>Senderen</h2>
            <p>
              Jeti producerer ikke deres egen sender, men s&aelig;lger 7
              forskellige sender moduler, som d&aelig;kker en r&aelig;kke
              eksisterende fabrikater s&aring; som: Futaba, Hitec, Graupner,
              Multiplex m. fl. Dette muligg&oslash;r, at du kan beholde din
              eksisterende sender, hvad jeg personlig finder er en stor fordel.
              Jeg har monteret modulet i en Futaba T9CP. Man udtager blot 35MHz
              modulet, is&aelig;tter 2,4GHz modulet, husker modulationen
              p&aring; Futaba senderen skal v&aelig;re PPM og ikke PCM, beholder
              antennen til montage af Jetiboxen og forbinder sendermodulet til
              Jetiboxen med en ledning. Alt er ukompliceret og tager under 5min.
              Senderen bruger nu mindre str&oslash;m, fordi forbruget falder fra
              ca. 190mA til ca. 150mA. N&aring;r Jetiboxen forbindes til
              sendermodulet via datakabelet f&aring;s f&oslash;lgende
              funktioner: Udl&aelig;sning af binding kode og produktions numre,
              binding, mulighed for reduceret sendestyrke, (10mW med alarm.)
              udl&aelig;sning af modtagerens signalstyrke p&aring; de 2
              antenner, hver for sig, i en skala fra 0 til 9 samt
              udl&aelig;sning af senderens batterisp&aelig;nding. Sendermodulet
              indeholder en bipper, som underst&oslash;tter forskellige alarmer
              p&aring; displayet. Det skal ogs&aring; bem&aelig;rkes, at
              sendermodulet indeholder en modtager til brug for telemetrien.
              Denne modtager bindes automatisk til dit flys telemetri sender,
              s&aring;ledes at du ikke modtager data fra et andet Jeti Duplex
              system, som er i brug samtidig.&nbsp;
            </p>
            <h2>Modtageren</h2>
            <p>
              Der kan v&aelig;lges mellem 21 forskellige modtagere fra 4 til 16
              kanaler, v&aelig;gten er fra 4g til 30g. 16 kanal modtagere dannes
              af to 8 kanal modtagere, hvoraf den ene bringes i klonet tilstand,
              s&aring;ledes at de ikke begge kan udsende telemetri. Jeg har
              valgt en 8 kanal modtager med en v&aelig;gt p&aring; 15g.
              Modtageren er udstyret med 2 diversity antenner. Begrebet
              diversity d&aelig;kker over, at modtageren automatisk anvender det
              af de to signaler, som modtages bedst. Antenne kablerne er
              20&nbsp;cm lange, hvoraf det kun er de yderste 3&nbsp;cm, som er
              de aktive antenner. Denne konstruktion muligg&oslash;r, at
              antennerne kan komme uden for kroppen, hvis denne best&aring;r af
              kulfiber, kulr&oslash;r, eller metal. Modtagerne findes i
              &oslash;vrigt med forskellige l&aelig;ngder antennekabler,
              s&aring; de kan tilpasses st&oslash;rrelsen af kroppen. Modtageren
              har et datastik, for tilslutning af m&aring;lemoduler. Som noget
              us&aelig;dvanligt indeholder modtageren software, som kan supplere
              f&oslash;lgende funktioner i senderen, hvis de ikke findes i
              forvejen: Fail-safe, ATV, EXPO, MIX af kanaler og en
              servoforsinkelse. Denne facilitet g&oslash;r, at hvis du anvender
              Jeti Duplex i forbindelse med en simpel 4 kanal sender, f&aring;r
              du nu disse nye funktioner til r&aring;dighed. Hvis du har flere
              fly, kan du gemme det enkelte flys ops&aelig;tning i flyets
              modtager, og s&aring;ledes undg&aring; at starte med et forkert
              fly i senderen. Det kan n&aelig;vnes at den fail-safe funktion,
              jeg havde i det gamle Futaba 1024 system tabes, n&aring;r du
              skifter fra PCM til PPM, men at den kan genskabes af Jeti
              modtagerens software.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Modtager med bind plug. M&aring;lemodul er tilsluttet dataindgang
              modsat servotilslutninger.
            </p>
            <h2>Str&oslash;mforsyning</h2>
            <p>
              Vi vil nu se p&aring; Duplex modtagerens str&oslash;mforsyning,
              herunder forsyningen til servoer og evt. tilsluttede
              m&aring;lemoduler. Selve modtageren har et
              sp&aelig;ndingsomr&aring;de p&aring; 3,2&nbsp;V til 8,4&nbsp;V og
              et str&oslash;mforbrug p&aring; ca. 48mA, noget mere end den gamle
              35MHz modtager den afl&oslash;ser. Dette skyldes, at modtageren
              indeholder telemetrisenderen. Der er to grundl&aelig;ggende ting
              omkring str&oslash;mforsyningen, du b&oslash;r v&aelig;re
              opm&aelig;rksom p&aring;: Det f&oslash;rste er, at
              forsyningssp&aelig;ndingen falder, fordi belastningen er meget
              kraftig svingende p.gr.af servoernes varierende forbrug.
              Is&aelig;r er det et problem, hvis flere servoer starter samtidig.
              I det gamle 35MHz system fik servoerne styrepulserne efter
              hinanden, og de blev s&aring; gentaget efter ca. 20mS. S&aring;dan
              arbejder Jeti systemet heldigvis ogs&aring;. Nogle 2,4GHz
              anl&aelig;g er s&aring;dan konstrueret, at alle servoer f&aring;r
              impulserne p&aring; samme tid, p&aring; grund af den digitale
              signal behandling. Dette stiller s&aelig;rlig store krav til
              str&oslash;mforsyningens evne til at kunne holde sp&aelig;ndingen
              konstant og levere den n&oslash;dvendige str&oslash;m. Det andet
              problem er, at hvis sp&aelig;ndingen kommer ned under 2,4GHz
              modtagerens minimums sp&aelig;nding p&aring; 3,2&nbsp;V
              (M&aring;lt til 2,9&nbsp;V), bryder den digitale signalbehandling
              sammen, s&aring; de fordele, den robuste radioforbindelse byder
              p&aring;, bliver sat over styr. Jeti Dupex modtageren indeholder
              imidlertid en genial m&aring;lemulighed. Den kan nemlig gemme
              modtagerens minimum- og maximumv&aelig;rdier for
              forsyningssp&aelig;nding, samtidig med at den sender den aktuelle
              forsyningssp&aelig;nding tilbage til piloten. Hvis
              sp&aelig;ndingen bliver for lav, udl&oslash;ses en lydalarm i
              sendermodulet. Dette giver en utrolig god mulighed for dels at
              kontrollere kvaliteten af forsyningssp&aelig;ndingen, dels at
              sikre sig mod at modtager- batteriet ikke l&oslash;ber t&oslash;rt
              for str&oslash;m. Idet forsyningssp&aelig;ndingen skal ligge i et
              interval mellem 3,2&nbsp;V og 8,4&nbsp;V, vil der v&aelig;re
              forskellige muligheder for valg af
              modtagerstr&oslash;mforsyning.&nbsp;
            </p>
            <ol>
              <li>
                2 serieforbundne Li-Po celler med en Jeti BEC MAX
                sp&aelig;ndingsstabilisator.&nbsp;
              </li>
              <li>
                2 serieforbundne Hyperion LiFePO4 celler direkte. De findes i
                st&oslash;rrelserne fra 850&nbsp;mAh op til 3800&nbsp;mAh. De
                kan afgive den n&oslash;dvendige max str&oslash;m, har ringe
                selvafladning, kan oplades med op til 4&nbsp;C (15 minutter
                ladetid) og t&aring;ler betydelig dybdeafladning i
                mods&aelig;tning til LiPo. Man skal dog her v&aelig;re
                opm&aelig;rksom p&aring;, at servoerne skal kunne t&aring;le den
                h&oslash;jere sp&aelig;nding. Der er flere servoer p&aring;
                markedet, som kan dette, ligesom det skal bem&aelig;rkes, at
                Hyperion snart introducerer en High Voltage serie, som kan
                arbejde p&aring; den h&oslash;je sp&aelig;nding.&nbsp;
              </li>
              <li>
                Hvis servoerne ikke kan t&aring;le sp&aelig;ndingen anbefales,
                at man anvender Jeti BEC MAX sp&aelig;ndingsstabilisator
                modulet, som s&aring; forsynes fra LiFePO4 batteriets 2
                celler.&nbsp;
              </li>
              <li>
                En BEC indbygget i en b&oslash;rstel&oslash;s regulator, hvor
                man grundigt tester, at den ikke bliver overbelastet.&nbsp;
              </li>
              <li>
                Man kan naturligvis ogs&aring; anvende 4 celler NiMh (f.eks.
                Eneloop) batterier, som mange bruger, men skal s&aring;
                v&aelig;re opm&aelig;rksom p&aring;, at de ikke udl&oslash;ser
                undersp&aelig;ndings alarm, specielt hvis de er gamle, og
                temperaturen er lav.&nbsp;
                <br />
                Ved fremkomsten af 2,4GHz teknikken havde man visse problemer
                med, at modtageren gik i st&aring; ved udfald af
                forsyningssp&aelig;ndingen. Der kunne g&aring; op til 5 til 7
                sekunder, f&oslash;r modtageren var klar igen (havde bootet,
                ligesom n&aring;r du t&aelig;nder din PC). Forskellige grundige
                test af Jeti Duplex modtageren med servoer, udviser ingen
                forsinkelser af praktisk betydning, hvis
                forsyningsp&aelig;ndingen underskrides, og modtageren skal
                genstarte. Boot-tid er 1/4Sek.
              </li>
            </ol>
            <h2>M&aring;lemoduler</h2>
            <p>
              M&aring;lemodulerne tilsluttes modtageren via et datastik.
              Modtageren kan kun tilsluttes et m&aring;lemodul af gangen. Hvis
              flere moduler &oslash;nskes anvendt samtidigt, bruges
              expander-boxen med tilslutning af op til 4 m&aring;lemoduler
              p&aring; en gang. Hvis man anvender flere m&aring;lemoduler,
              udl&aelig;ses disse ved, at man stepper sig igennem
              udl&aelig;sningerne p&aring; Jetiboxen. Hvis man ikke vil
              overf&oslash;re data tr&aring;dl&oslash;st mellem sender og
              modtager, kan alle m&aring;lemoduler tilkobles Jetiboxen direkte.
              <br />
              <em>Batterim&aring;leren</em>&nbsp;ben&aelig;vnt MUI findes til
              max 30&nbsp;A, 50&nbsp;A, 75&nbsp;A, 150&nbsp;A og 200&nbsp;A. Den
              kan m&aring;le sp&aelig;nding, str&oslash;m og forbrugt
              batterikapacitet. M&aring;len&oslash;jagtigheden er opgivet til
              0,1&nbsp;% p&aring; sp&aelig;ndingen og 1&nbsp;% p&aring;
              str&oslash;mmen. Jeg har med min elektroniske
              konstantstr&oslash;msbelastning og et stop ur m&aring;lt
              n&oslash;jagtigheden af den forbrugte batteri kapacitet til at
              v&aelig;re 0,7&nbsp;%. Det er flot klaret af Jeti, n&aring;r man
              t&aelig;nker p&aring; de store str&oslash;mme. Den er s&aelig;rlig
              interessant som &rdquo;br&aelig;ndstofm&aring;ler&rdquo; (mA/h) i
              forbindelse med Li-Po batterier. Den indre modstand p&aring; MUI75
              er m&aring;lt til 0,83milliohm, det er n&oslash;dvendigt, at den
              er s&aring; lav, fordi den genneml&oslash;bes af de store
              str&oslash;mme. Oms&aelig;tningen af str&oslash;m til
              sp&aelig;nding foretages v. hj. af en hall transducer, som
              m&aring;ler magnetfeltet omkring ledningen.&nbsp;
              <br />
              <em>Omdrejningsm&aring;leren</em>&nbsp;ben&aelig;vnes MRPM og
              findes som en optisk m&aring;ler, som m&aring;ler p&aring;
              lysreflektioner fra propellen eller som en elektrisk m&aring;ler,
              som tilkobles 2 faser p&aring; en b&oslash;rstel&oslash;s motor.
              Omdrejningsm&aring;lerne kan ogs&aring; m&aring;le mekanisk effekt
              (watt/hk) p&aring; motorens aksel, ud fra kendte 100&nbsp;watts
              propeldata.
              <br />
              <em>Variometeret</em>&nbsp;ben&aelig;vnes MVARIO og afgiver til
              termiks&oslash;gning lydsignaler. Variometeret kan ogs&aring; vise
              den absolutte flyveh&oslash;jde og h&oslash;jden over jorden, max
              stig og synk pr. sek, min og max temperatur, lufttryk og max
              opn&aring;et flyveh&oslash;jde. Enheden skal resettes med
              Jetiboxen f&oslash;r hver flyvning, hvis min/max v&aelig;rdier
              &oslash;nsket slettet. Enheden er testet til at have en
              n&oslash;jagtighed p&aring; 1m i et kalibreringsudstyr for
              h&oslash;jdem&aring;lere fra full-size fly, ved h&oslash;jder fra
              0 til 3000m! H&oslash;jdem&aring;leren skal ogs&aring; kunne
              anvendes i bjergegne. Ved 100m reference m&aring;ltes 99m.
              M&aring;leren er med sin v&aelig;gt p&aring; kun 6 gram
              imponerende n&oslash;jagtig mikroelektronik.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <p>
              Variometer og h&oslash;jdem&aring;ler. V&aelig;gten er kun
              6gram!&nbsp;
            </p>
            <p>
              <em>Termometeret</em>&nbsp;ben&aelig;vnes MT og kan m&aring;le 2
              temperaturer p&aring; samme tid og findes i 2 udgaver. En som
              m&aring;ler -55&nbsp;C til 125&nbsp;C og en som m&aring;ler
              -40&nbsp;C til 300&nbsp;C grader. N&oslash;jagtighed 0,5&nbsp;C
              grad.&nbsp;
              <br />
              <em>GPS modulet</em>&nbsp;er under udvikling. Kan m&aring;le
              afstand fra start position, stig og synk, flyveh&oslash;jde og
              flyvefart. Forventes f&aelig;rdig jan. 2010.&nbsp;
            </p>
            <h2>Programmering</h2>
            <p>
              Programmeringen af Duplex systemet er naturligvis meget
              omfattende, men ogs&aring; meget logisk, fordi man grafisk
              p&aring; et programark kun kan bev&aelig;ge sig til venstre,
              h&oslash;jre, op eller ned v. hj. af tasterne p&aring; Jetiboxen.
              Der kan ops&aelig;ttes alle mulige t&aelig;nkelige
              alarmv&aelig;rdier, der b&aring;de vises p&aring; jetiboksens
              display og h&oslash;res som morsekoder p&aring; senderens
              h&oslash;jttaler.
            </p>
            <h2>Anvendelsen</h2>
            <p>
              Det er klart, at du stadig skal have din fulde opm&aelig;rksomhed
              rettet mod flyveren, og ikke st&aring; og betjene Jetiboxen under
              flyvningen! Min erfaring med systemet er, at du skal
              gennemt&aelig;nke, hvordan gr&aelig;nsefladen er i mellem dig og
              systemet, og den m&aring;de systemet pr&aelig;senterer sine
              oplysninger p&aring;. Du og din hjerne har kun en begr&aelig;nset
              simultankapasitet! Det tror du ikke, men det er rigtigt. Jeg vil
              nu efterf&oslash;lgende pr&oslash;ve at belyse forskellige
              eksempler p&aring; brug af systemet.
            </p>
            <ul>
              <li>
                <p>Al programmering finder sted p&aring; jorden!</p>
              </li>
              <li>
                <p>
                  Udl&aelig;sning af min/max v&aelig;rdier finder sted efter
                  flyvningen.
                </p>
              </li>
              <li>
                <p>
                  Hvis du anvender Jeti Duplex uden m&aring;lemoduler, men med
                  Jetibox, virker det som et hvert andet 2,4GHz anl&aelig;g. Men
                  du f&aring;r en for&oslash;get sikkerhed p&aring;
                  radioforbindelsen og modtagerens str&oslash;mforsyning, samt
                  de muligheder, som er n&aelig;vnt under modtageren og
                  senderen.
                </p>
              </li>
              <li>
                <p>
                  Hvis du tilv&aelig;lger m&aring;lemoduler, som skal anvendes
                  af dig selv under flyvningen, skal du have simultan kapacitet
                  i din hjerne til at modtage informationerne. Derfor er det nok
                  klogt kun at tilslutte et modul ad gangen. Jeg har f.eks.
                  valgt p&aring; mit store fanliner fly, kun at kunne
                  udl&aelig;se den brugte batteri kapacitet i mAh, alts&aring;
                  en &rdquo;br&aelig;ndstofm&aring;ler&rdquo;, i stedet for at
                  anvende et stopur til flyvetid. N&aring;r jeg n&aring;r
                  3000&nbsp;mAh ud af mulige 3700&nbsp;mAh, bipper der en alarm.
                  Smart, behageligt og meget betryggende, fordi enhver el-pilot
                  ved, at forbruget varierer afh&aelig;ngig af flyvesstil og
                  vind. N&aring;r der skiftes batteri starter
                  &rdquo;br&aelig;ndstofm&aring;leren&rdquo; forfra af sig selv.
                </p>
              </li>
              <li>
                <p>
                  Hvis mange data &oslash;nskes udl&aelig;st under flyvningen,
                  kan du naturligvis anvende en hj&aelig;lper.
                </p>
              </li>
              <li>
                <p>
                  Jeg har ogs&aring; et stort sv&aelig;vefly, hvor variometeret
                  og h&oslash;jdem&aring;leren kan anvendes. Du s&oslash;ger
                  s&aring; termik med biptoner fra variometeret og f&aring;r
                  s&aring; yderligere en bip alarm, hvis flyet overstiger din
                  programmerede flyveh&oslash;jde.
                </p>
              </li>
              <li>
                <p>
                  Da det er muligt at m&aring;le styrken af det modtagne signal
                  p&aring; hver af de to diversity antenner, kan man p&aring;
                  forh&aring;nd nede p&aring; jorden unders&oslash;ge den
                  optimale placering af antennerne, i fly, som f.eks. indeholder
                  metal eller kulfiber, ved at dreje flyet i alle mulige
                  stillinger.
                </p>
              </li>
              <li>
                <p>
                  Man kan ogs&aring; forestille sig, at man kan finde et fly der
                  er landet i en kornmark, idet det udfra
                  signalstyrkem&aring;lingen kan afg&oslash;res, om man
                  n&aelig;rmer sig eller fjerner sig fra landingsstedet.
                </p>
              </li>
              <li>
                <p>
                  Til slut et eksempel som viser systemets styrke som
                  udviklingsv&aelig;rkt&oslash;j, n&aring;r en mere kompliceret
                  opgave s&oslash;ges l&oslash;st. Det drejer sig om
                  m&aring;ling af virkningsgrader p&aring; en elmotor.
                  F&oslash;rst skal man vide, hvor mange omdrejninger en given
                  propel skal k&oslash;re, for n&oslash;jagtig at optage
                  100&nbsp;W. (N100&nbsp;w). For at finde
                  N100&nbsp;w&nbsp;p&aring; netop den propel, du anvender, er
                  det n&oslash;dvendigt at sl&aring; N100&nbsp;w&nbsp;op i en
                  liste, som du kan finde p&aring; firmaet Electric Flight
                  Equipments&nbsp;web-side under: Duplex/ Tilbeh&oslash;r/ MRPM/
                  Propel data, omdrejninger /100&nbsp;w.&nbsp;Her findes
                  N100&nbsp;w data for over 200 forskellige propeller. Nu er det
                  s&aring;dan, at hvis man fordobler omdrejningerne p&aring; en
                  motor for en given propel, kr&aelig;ver det en 8 dobling i den
                  tilf&oslash;rte effekt. Derfor kr&aelig;ver det blot, at man
                  kan m&aring;le omdrejningerne p&aring; propellen og lade en
                  microchip udf&oslash;re f&oslash;lgende regnestykke:
                  Pmek=100&nbsp;W x (RPMm&aring;lt opl&oslash;ftet i 3. potens
                  divideret med RPM100&nbsp;w opl&oslash;ftet i 3.potens.) Dette
                  er netop, hvad MRPM-AC modulet kan, n&aring;r det tilsluttes 2
                  faser p&aring; motorer, og man programmerer N100&nbsp;w
                  v&aelig;rdien ind via jetiboxen. Man f&aring;r nu m&aring;lt
                  omdrejninger og mekanisk effekt p&aring; motoren. Tilbage er
                  kun at dele den mekaniske udgangseffekt med den elektriske
                  indgangseffekt og gange med 100.
                </p>
              </li>
            </ul>
            <p>
              <img src={fig6} />
            </p>
            <p>
              <strong>Eksempel:</strong>&nbsp;Der m&aring;les p&aring; en
              b&oslash;rstel&oslash;s motor med en APC-E propel p&aring; 10x5
              tommer. Propellen findes i tabellen til 7727 RPM100&nbsp;w. Der
              indtastes 7730RPM og 14 poler via Jetibox. Derefter m&aring;les
              ved fuld gas et input p&aring; 12,5&nbsp;V og 16,4&nbsp;A hvilket
              giver 205&nbsp;W. Videre m&aring;les 9090 omdrejninger og
              162&nbsp;W. Virkningsgraden bliver s&aring;
              162&nbsp;W/205&nbsp;Wx100 = 79&nbsp;%. Dette er fint og
              bekr&aelig;fter den beregning, som p&aring; anden vis er
              foretaget. Ved halv gas ca. 8&nbsp;A er virkningsgraden faldet til
              ca. 62&nbsp;%.
            </p>
            <h2>Afslutning</h2>
            <p>
              Det ville v&aelig;re en logisk udvikling, at data kunne gemmes i
              en hukommelse i boxen og siden logges ud p&aring; en PC,
              s&aring;dan som det er omtalt i starten. Det kan Jeti Systemet
              ikke endnu, men man arbejder p&aring; at opsamle store
              datam&aelig;ngder i modtageren med henblik p&aring;
              udl&aelig;sning. Jeti Duplex er udviklet og produceret i Tjekkiet,
              med st&oslash;tte af den tjekkiske stat og med st&oslash;tte af
              EU's teknologifond, for at udvikle et h&oslash;jt innovativt
              produkt i konkurrence med tilsvarende produkter fra USA, Japan og
              Kina. Jeti Duplex er efter min menig et af de systemer, som p.t.
              er l&aelig;ngst fremme p&aring; det globale marked og udviser i
              mine unders&oslash;gelser af specielt m&aring;lemodulerne
              h&oslash;j kvalitet og forbavsende stor n&oslash;jagtighed. Til
              sidst vil jeg pr&oslash;ve at veje fordele og ulemper mod
              hinanden.
            </p>
            <h2>Fordele</h2>
            <ul>
              <li>
                <p>Alle de s&aelig;dvanlige for et 2,4GHz anl&aelig;g.</p>
              </li>
              <li>
                <p>
                  Fleksibel overgang fra mit eksisterende 35MHz anl&aelig;g. Jeg
                  kan beholde senderen og gradvis udfase alle de gamle 35MHz
                  modtagerne.
                </p>
              </li>
              <li>
                <p>
                  Prisen er fornuftig, afvejet mod den h&oslash;je kvalitet.
                </p>
              </li>
              <li>
                <p>
                  Systemet giver &oslash;get sikkerhed i forhold til andre
                  2,4GHz anl&aelig;g
                </p>
              </li>
              <li>
                <p>
                  M&aring;lemoduler kan tilk&oslash;bes efter behov.
                  M&aring;linger giver nye muligheder for sikkerhed og udvikling
                  af fly og systemer.
                </p>
              </li>
              <li>
                <p>
                  M&aring;lemodulerne kan sammen med Jetiboxen, uden senderen,
                  anvendes som et selvst&aelig;ndigt avanceret m&aring;lesystem.
                </p>
              </li>
              <li>
                <p>
                  Jeti har allerede bevist h&oslash;j kvalitet i deres tidligere
                  produkter.
                </p>
              </li>
              <li>
                <p>
                  Jeti dokumenterer deres produkter bedre f. eks. oplyser de, at
                  produktet arbejder indenfor -10&nbsp;C og +85&nbsp;C grader,
                  godt at vide en varm sommerdag.
                </p>
              </li>
            </ul>
            <h2>Ulemper</h2>
            <ul>
              <li>
                <p>
                  Det er en ulempe, at senderen ikke l&aelig;ngere kan ligge ned
                  p&aring; bagsiden, p&aring; gr. af 2,4GHz antennen. Denne
                  m&aring; ikke uds&aelig;ttes for mekaniske st&oslash;d.
                </p>
              </li>
              <li>
                <p>
                  De bip signaler, som udsendes fra senderboxen, er for svage i
                  visse situationer.
                </p>
              </li>
            </ul>
            <p>Happy Landing.</p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
