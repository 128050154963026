import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig1.jpg";
import fig2 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig2.jpg";
import fig3 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig3.jpg";
import fig4 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig4.jpg";
import fig5 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig5.jpg";
import fig6 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig6.jpg";
import fig7 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig7.jpg";
import fig8 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig8.jpg";
import fig9 from "../../../images/Lipo/LipoTeknikITeoriOgPraksis/fig9.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Li-Po teknik i teori og praksis</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Li-Po teknik i teori og praksis</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret juni 2007.</p>
            <p>
              De pr&aelig;stationer, som nu kan opn&aring;s med elfly, er
              s&aring; overbevisende at de stort set p&aring; alle omr&aring;der
              kan m&aring;le sig med modelfly, som anvender br&aelig;ndstof.
              &Aring;rsagen er, dels anvendelsen af den b&oslash;rstel&oslash;se
              motor, dels udviklingen af litium polymere batterierne. Li-Po
              teknikken er p&aring; kort tid g&aring;et fra et fors&oslash;gs
              stadie, hvor nogle f&aring; piloter heksede med at lodde p&aring;
              l&oslash;se celler og bygge balancere, til et fuldt udbygget
              kommercielt marked. Jeg skal efterf&oslash;lgende fors&oslash;ge
              at belyse nogle forhold omkring anvendelsen af Li-Po batterier.
            </p>
            <h2>Li-Po cellens sp&aelig;ndigsgr&aelig;nser</h2>
            <p>
              Fig. 1 viser hovedproblemet ved at anvende Li-Po teknik. Den
              enkelte celle skal altid holdes inde i brugsomr&aring;det,
              s&aring; simpelt kan det siges. Ved f.eks. 3 celler i serie findes
              sp&aelig;nding naturligvis ved at gange den enkelte celles
              sp&aelig;nding med 3.&nbsp;
            </p>
            <p>
              <img src={fig1} />
              <br />
              Fig. 1
            </p>
            <p>
              <img src={fig2} />
              <br />
              Fig. 2
            </p>
            <p>
              Fig. 2 viser hvorledes sp&aelig;ndingsgr&aelig;nserne trues af
              ubalance i cellerne. Til venstre vises et fuldt opladt 3S batteri
              i balance. Det n&aelig;ste batteri er i voldsom ubalance. Hvis
              dette batteri aflades, vil det v&aelig;re den nederste celle, som
              f&oslash;rst kommer under 2,5&nbsp;V og derved bliver
              &oslash;delagt. Igen tager vi det batteri som er i ubalance. Denne
              gang oplader vi batteriet og som det ses l&aelig;ngst til
              h&oslash;jre, vil den &oslash;verste celle f&oslash;rst opn&aring;
              fuld opladning og derved risikere at blive &oslash;delagt.
              Batteriets sp&aelig;ndingsgr&aelig;nser og ubalance problemer
              stiller derfor krav om, at der anvendes s&aelig;rlige ladere og
              balancerings kredsl&oslash;b til Li-Po batterierne. Linie 16, 21
              og 22: Hvis der skal bruges store str&oslash;mme er Li-Po
              teknikken langt den bedste.
            </p>
            <h2>Li-Po cellens str&oslash;mgr&aelig;nser</h2>
            <p>
              Traditionelt g&aelig;lder at Li-Po batterier skal oplades med max
              1&nbsp;C, d.v.s. at et batteri med en kapacitet p&aring; f. eks.
              3700&nbsp;mA/h skal oplades med en str&oslash;m p&aring;
              3,7&nbsp;Amp. Opladningen vil fra fuldt afladt vare godt 1 time.
              Da dette er temmelig l&aelig;nge, har man p&aring; det senere
              &oslash;get opladestr&oslash;mmen til max 2&nbsp;C. Dette
              foresl&aring;s bl. andet af Hyperion. Der foreligger mig bekendt
              ikke p&aring;lidelige oplysninger om, hvilken effekt dette kan
              have p&aring; levetiden, men en medvirkende grund til at det kan
              g&oslash;res, er at de bedste lade- og balancerings systemer er
              blevet meget velvirkende og pr&aelig;cise. For afladningen
              g&aelig;lder at batteri pakkerne er p&aring;trykt oplysninger som
              f. eks. 15&nbsp;C eller 20&nbsp;C og m&aring;ske 30&nbsp;C i
              burst. Disse oplysninger fort&aelig;ller, hvor store
              str&oslash;mme der kan tr&aelig;kkes ud af cellerne. Det er
              vigtigt for cellernes levetid at du ikke belaster dem for meget og
              for l&aelig;nge. Eksempel se fig. 3. Hvis man &oslash;nsker at se
              h&oslash;jstr&oslash;ms belastningskurver for forskellige
              batterier kan anbefales&nbsp;
              <Link
                href="https://www.elektromodellflug.de/oldpage/datenbank.htm"
                target="_blank"
              >
                https://www.elektromodellflug.de/oldpage/datenbank.htm
              </Link>
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig. 3
            </p>
            <p>
              Fig. 3 Min Fanliner drives nu af 6S Hyperion VX 3700&nbsp;mA/h
              20&nbsp;C (20&nbsp;C = 74&nbsp;A) i stedet for 20celler Sanoy 3300
              Ni-MH. Ved take-off bruger de 2 fans 64&nbsp;A (17,3&nbsp;C)
              tilsammen, men kun ganske kortvarigt. Efter 15sek er flyet i ca.
              40 meters h&oslash;jde og der kan nu reguleres ned til at anvende
              ca. 30&nbsp;Amp (ca. 8&nbsp;C) med en ganske ringe temperatur
              for&oslash;gelse p&aring; cellerne.
            </p>
            <h2>Li-Po cellens kapacitet</h2>
            <p>
              Ved overgangen til Li-Po teknik besluttede jeg at f&oslash;lge
              hver enkelt batteripakke systematisk, ved at m&aring;le pakkens
              kapacitet. Jeg konstaterede her, at den p&aring; pakkerne
              p&aring;stemplede kapacitet var rigelig optimistisk, som
              s&aelig;dvanlig. S&aring;ledes ligger Hyperion VX 3700&nbsp;mA/h
              mellem 3260&nbsp;mA/h og 3450&nbsp;mA/h m&aring;lt p&aring; 4 stk.
              Der aflades med kun 1&nbsp;Amp, indtil den f&oslash;rste celle
              n&aring;r 3,0&nbsp;Volt. En af pakkerne m&aring;lte fra starten
              3310&nbsp;mA/h og m&aring;ler nu efter 20 op- og afladninger
              3150&nbsp;mA/h. I praksis kan du regne med kun at anvende
              75&nbsp;% af den p&aring;stemplede kapacitet: 3,7&nbsp;A/h x
              0,75=2775&nbsp;mA/h brugbar kapacitet. Kapaciteten er ikke ene
              afg&oslash;rende, langt vigtigere er det, at batteriet kan holde
              sp&aelig;ndingen under stor belastning.
            </p>
            <h2>Li-Po cellens levetid</h2>
            <p>
              Li-Po batterierne taber deres kapacitet p&aring; grund af celle
              oxidation, som opst&aring;r dels p&aring; grund af brug, dels
              p&aring; grund af &aelig;ldning. Efterh&aring;nden vil ogs&aring;
              den indre modstand stige med alderen. Dette er et s&aelig;rligt
              problem ved h&oslash;jstr&oslash;ms brug, idet man hurtigt ved den
              h&aring;rde belastning n&aelig;rmer sig cellens nedre
              sp&aelig;ndingsgr&aelig;nse, samtidig med at temperaturen i cellen
              stiger. Cellerne m&aring; ikke blive mere end 60&nbsp;C varme.
              Cellernes selvafladning stiger ogs&aring; med alderen. Den er
              ellers meget lille. (ca. 5&nbsp;% p&aring; et &frac12; &aring;r
              ved 20&nbsp;C grader) i mods&aelig;tning til Ni-MH batterier, som
              har en meget h&oslash;j selvafladning. Alle disse forhold er med
              til at begr&aelig;nse levetiden, men kan modvirkes ved at cellerne
              opvares koldt og opladt til sp&aelig;ndingen 3,8&nbsp;V pr celle.
              Levetiden defineres, af nogle fabrikanter, ved at du er nede
              p&aring; 70&nbsp;% af den oprindelige ydeevne. Du finder det
              m&aring;ske ikke s&aring; praktisk, at dine batterier skal tages
              ud af k&oslash;leskabet og oplades inden du vil flyve. Det er da
              heller ikke s&aring; relevant, hvis du flyver regelm&aelig;ssigt i
              s&aelig;sonen. Men er der perioder, hvor du ligger stille, f.eks.
              hele vinteren, er det en god ide, at f&oslash;lge dette
              opbevarings r&aring;d. Kunne prisen p&aring; en flyvning med et
              batteri, som f.eks. koster 950kr, komme ned p&aring; 9,50 kr. pr.
              flyvning, ville det kr&aelig;ve 100 op- og afladninger (100
              cykles). Man kunne s&aring; fjerne det argument, som metanol
              piloterne har, nemlig at elektroflyvning er for dyr. Hele denne
              problemstilling er ogs&aring; aktuel i udviklingen af elbilen,
              hvor der ogs&aring; anvendes Litium batterier og
              b&oslash;rstel&oslash;s motor teknologi.
            </p>
            <h2>Ibrugtagning af nyt batteri</h2>
            <p>
              Det kan anbefales at du starter dit helt nye batteri med et
              ibrugtagnings program, som langsomt &oslash;ger belastningen. Det
              best&aring;r af 4 cykles, hvor opladningen holdes p&aring;
              &frac12;C og hvor afladning &oslash;ges gradvis 1&nbsp;C,
              2&nbsp;C, 4&nbsp;C og 8&nbsp;C med tilh&oslash;rende balancering
              af cellerne. I praksis gennemf&oslash;rer jeg 1&nbsp;C og 2&nbsp;C
              p&aring; laderen og 4&nbsp;C og 8&nbsp;C p&aring; en flyver, hvor
              jeg med et tangamperemeter kontrollerer str&oslash;mmen. Der efter
              kan batteriet tages i brug.
            </p>
            <h2>Balancering</h2>
            <p>
              Der har v&aelig;ret mange meninger om n&oslash;dvendigheden af, at
              foretage hyppige balanceringer. Med den teknik som nu findes,
              mener jeg at balanceringen af cellerne er en del af oplade
              processen og som derfor b&oslash;r foretages l&oslash;bende.
            </p>
            <h2>Valg af lader og balanceringskredsl&oslash;b</h2>
            <p>
              Inden du v&aelig;lger Li-Po laderen er der en lang r&aelig;kke
              ting du b&oslash;r tage stilling til. Der kan n&aelig;vnes
              f&oslash;lgende:
            </p>
            <ol>
              <li>
                <p>
                  Hvor mange serieforbundne celler kommer du ud for at anvende?
                </p>
              </li>
              <li>
                <p>
                  Hvor mange watt kan laderen h&aring;ndtere maximalt under
                  opladning?
                </p>
              </li>
              <li>
                <p>
                  Hvilken ladestr&oslash;m har du brug for? Har du brug for en
                  kort ladetid s&aring; du hurtigt kan komme i luften igen, kan
                  det overvejes om du skal lade med mellem 1&nbsp;C og op til
                  2&nbsp;C.
                </p>
              </li>
              <li>
                <p>
                  Hvilke batteri typer ud over Li-Po skal den kunne bruges til?
                  Der kan n&aelig;vnes Li-Io, Li-Fe, Ni-Ca, Ni-Mh og Bly.
                </p>
              </li>
              <li>
                <p>
                  Kan laderens soft-ware opgraderes til nye ikke opfundne
                  batteri typer?
                </p>
              </li>
              <li>
                <p>
                  Hvordan str&oslash;mforsynes laderen? Er det vigtigt at
                  laderen direkte kan tilsluttes 230&nbsp;V?
                </p>
              </li>
              <li>
                <p>Kan laderen aflade batterierne og med hvor mange watt?</p>
              </li>
              <li>
                <p>
                  Hvilken sikkerhed er der indbygget i laderen. Er der en
                  temperatur m&aring;ler?
                </p>
              </li>
              <li>
                <p>Er der indbygget en balancer i laderen?</p>
              </li>
              <li>
                <p>Vil du anvende en l&oslash;st balancer?</p>
              </li>
              <li>
                <p>
                  Kan laderen af- eller oplade automatisk til Li-Po pakkens
                  opbevarings sp&aelig;nding?
                </p>
              </li>
              <li>
                <p>
                  Har laderen en hukommelse, s&aring; den nemt kan huske
                  ops&aelig;tningen af forskellige batterier?
                </p>
              </li>
              <li>
                <p>
                  Kan ladere levere data til en PC, s&aring;ledes at der kan
                  optages op- og afladekurver?
                </p>
              </li>
              <li>
                <p>
                  &Oslash;konomien. Herunder sammenholdes prisen for Li-Po
                  batteriets pris kontra kort ladetid, alts&aring; skal du have
                  flere batterier, som du kan udskifte, eller et enkelt, som du
                  kan oplade hurtigt.
                </p>
              </li>
            </ol>
            <p>
              Specielt ligger der en faldgruppe i sp&oslash;rgsm&aring;l 2 og 3.
              Der reklameres tydeligt med hvor stor en opladestr&oslash;m
              laderen kan klare. Denne oplade str&oslash;m holder ikke ved mange
              celler i serie, men begr&aelig;nses af effekten alts&aring; max
              watt. Efterf&oslash;lgende vil jeg se p&aring; et eksempel
              p&aring; en lader og de betragtninger jeg har gjort mig da jeg
              valgte den.
            </p>
            <p>
              <strong>Eksempel p&aring; en lader: Bantam e-Station BC8</strong>
              &nbsp;
              <br />
              Den valgte lader er et eksempel p&aring; en lader i nyeste
              teknologi. Se fig. 4. Udbudet p&aring; markedet er stort og der
              finder en hastig l&oslash;bende udvikling sted. S&aring;
              k&oslash;b f&oslash;rst n&aring;r du virkelig er tvunget til det.
              Det andet gode r&aring;d er at downloade manualerne, s&aring; du i
              detaljer kan se hvad produktet kan.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig. 4&nbsp;Bem&aelig;rk temperatur sensor ledning med sort klat.
              Den firkantede r&oslash;de dims er hardware som omdanner de
              serielle data til USB. Balancer stik nederst til h&oslash;jre
              kommer ud med 9 ledninger, som s&aring; er koblet op til at kunne
              balancere 3S eller 6S.
            </p>
            <p>
              BC8 kan tilsluttes fra 1 til 8 Li-Po celler. Jeg har brug for 6S.
              BC8 kan h&aring;ndtere maximalt 150&nbsp;W under opladning.
              Hvilken ladestr&oslash;m har du brug for? Har du brug for en kort
              ladetid, s&aring; du hurtigt kan komme i luften igen, kan det
              overvejes om du skal lade med mellem 1&nbsp;C og op til 2&nbsp;C.
              BC8 kan programmeres til en ladestr&oslash;m p&aring; 0,1&nbsp;A
              til 7&nbsp;A i trin p&aring; 0,1&nbsp;A. Jeg har brug for at kunne
              oplade 6S 3700&nbsp;mA/h s&aring; hurtigt som muligt, alts&aring;
              med 2&nbsp;C=7,4&nbsp;Amp. Kan jeg s&aring; det? 6S =
              4,2&nbsp;Volt x 6S=25,2&nbsp;Vx7,4&nbsp;Amp = 186&nbsp;W, s&aring;
              det kan laderen ikke helt klare. Da P=UxI kan findes at I=P/U
              alts&aring; 150&nbsp;W divideret med 25,2&nbsp;Volt =
              5,95&nbsp;Amp. Dette er kun 1,24&nbsp;C S&aring; selv om laderen
              opgives til max 7&nbsp;Amp g&aelig;lder dette ikke ved 6S. Det kan
              stadig diskuteres om der kan lades med mere end 1&nbsp;C, uden at
              det g&aring;r udover levetiden p&aring; Li-Po batteriet. Men jeg
              v&aelig;lger at g&oslash;re det n&aring;r det er n&oslash;dvendigt
              at komme i luften i en fart. Nedenst&aring;ende tabel viser den
              maximale batteri kapacitet laderen kan klare teoretisk. Med til
              svaret h&oslash;rer, at laderen ogs&aring; har en fast charge mode
              som virker p&aring; den m&aring;de, at opladningen stopper ved
              1/5&nbsp;af ladestr&oslash;mmen i stedet for 1/10. Dette afkorter
              opladetiden i den sidste del af opladningen, hvor det g&aring;r
              langsomt, med omkring 4 minutter, uden at det g&aring;r ud over
              opladning i praksis. Se fig. 6.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Antal celler og max sp&aelig;nding</TableCell>
                  <TableCell>Max kapacitet ved 1&nbsp;C</TableCell>
                  <TableCell>Max kapacitet ved 2&nbsp;C</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1S 4,2&nbsp;V</TableCell>
                  <TableCell>7000&nbsp;mA/h</TableCell>
                  <TableCell>3500&nbsp;mA/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2S 8,4&nbsp;V</TableCell>
                  <TableCell>7000&nbsp;mA/h</TableCell>
                  <TableCell>3500&nbsp;mA/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>3S 12,6&nbsp;V</TableCell>
                  <TableCell>7000&nbsp;mA/h</TableCell>
                  <TableCell>3500&nbsp;mA/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>4S 16,8&nbsp;V</TableCell>
                  <TableCell>7000&nbsp;mA/h</TableCell>
                  <TableCell>3500&nbsp;mA/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5S 21&nbsp;V</TableCell>
                  <TableCell>7000&nbsp;mA/h</TableCell>
                  <TableCell>3500&nbsp;mA/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>6S 25,2&nbsp;V</TableCell>
                  <TableCell>5952&nbsp;mA/h</TableCell>
                  <TableCell>2626&nbsp;mA/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>7S 29,4&nbsp;V</TableCell>
                  <TableCell>5102&nbsp;mA/h</TableCell>
                  <TableCell>2551&nbsp;mA/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>8S 33,6&nbsp;V</TableCell>
                  <TableCell>4460&nbsp;mA/h</TableCell>
                  <TableCell>2230&nbsp;mA/h</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              BC8 kan oplade Li-Po, Li-Io, Li-Fe, Ni-Ca, Ni-Mh og Bly og er
              s&aring;ledes d&aelig;kket rimeligt godt ind overfor kendte
              batterityper. BC 8 laderens soft-ware kan ikke opgraderes til nye
              ikke opfundne batteri typer. Dette v&aelig;lger jeg at leve med
              fordi laderen kan klare de ovenfor n&aelig;vnte batteri typer.
              Hvordan str&oslash;mforsynes laderen? Her er 3 muligheder:
            </p>
            <ol>
              <li>
                <p>
                  Laderen tilsluttes 12&nbsp;Volt j&aelig;vnsp&aelig;nding,
                  alts&aring; en blyakkumulator med en kapacitet p&aring; min.
                  45&nbsp;Amp/h for BC8s vedkommende.
                </p>
              </li>
              <li>
                <p>
                  Eller en reguleret str&oslash;mforsyning enten line&aelig;r
                  eller swich-mode med en sp&aelig;nding mellem 11&nbsp;Volt og
                  18&nbsp;Volt. Skal kunne levere 20&nbsp;Amp.
                </p>
              </li>
              <li>
                <p>
                  Nogle af de mindre ladere kan direkte tilsluttes 230&nbsp;V.
                  Det kan BC8 ikke.
                </p>
              </li>
            </ol>
            <p>
              BC8 kan aflade batterierne og med max 25&nbsp;watt ved en
              afladestr&oslash;m mellem 0,1&nbsp;Amp og 5&nbsp;A. Her
              g&aelig;lder de samme watt m&aelig;ssige begr&aelig;nsninger, som
              beskrevet under opladningen. S&aring; ved 3S har du en aflade
              str&oslash;m p&aring; ca. 2&nbsp;Amp. BC8 har en meget h&oslash;j
              grad af sikkerhed indbygget i laderen. Der kan n&aelig;vnes, at
              hvis der er uoverensstemmelse mellem det programmerede og det
              tilsluttede celleantal, vil laderen g&oslash;re opm&aelig;rksom
              p&aring; dette. Hvis der er for stor ubalance vil der ogs&aring;
              komme en fejlmelding. Man er ogs&aring; i stand til l&aelig;gge en
              max ladetid, en max kapacitet og eller en max temperatur ind i
              laderen. N&aring;r max n&aring;s, p&aring; en af disse parameter,
              stopper laderen. Jeg anvender s&aring;ledes temperatur
              m&aring;lingen p&aring; de nye Sanoy Eneloop Ni-Mh batterier, som
              jeg anvender til modtager og sender. Bem&aelig;rk at disse
              batterier er n&aelig;sten uden selvafladning. Ca. 10&nbsp;%
              p&aring; et halvt &aring;r. BC8 har en indbygget en balancer i
              laderen. Dette er en konsekvens af udviklingen og kendetegnet for
              sidste generation af ladeteknik. Man er s&aring;ledes i stand til
              at udl&aelig;se sp&aelig;ndingen p&aring; de enkelte celler
              p&aring; laderen Se fig. 5. Hvis du v&aelig;lger en lader uden
              indbygget balancer vil Hyperion LBA6 eller LBA10 v&aelig;re et
              godt valg.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig. 5 Her udl&aelig;ses sp&aelig;ndingen p&aring; de enkelte
              celler.
            </p>
            <p>
              BC8 kan automatisk op- eller aflade til Li-Po batteriets
              opbevaringssp&aelig;nding og har endvidere en hukommelse, hvor du
              kan huske ladedata p&aring; op til 10 forskellige batteri pakker,
              hvilket letter ops&aelig;tningen. BC8 kan levere data til en PC,
              via et USB stik, s&aring;ledes at der kan optages op- og
              afladekurver. Det skal her bem&aelig;rkes at jeg ikke fik det
              tilh&oslash;rende program fra Bantam til at virke. Problemet er
              imidlertid l&oslash;st ved at anvende Logwiev programmet, som er
              beskrevet af Troels Lund i Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt.dk/modelflyvenyt/2007/Modelflyvenyt%202%202007.pdf"
              >
                nr 2 2007
              </Link>
              . Bantam USB driveren er stadig anvendt. Som eksempel vises et
              fast charge forl&oslash;b af et Hyperion 3700 VX. Se fig. 6.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig. 6
            </p>
            <p>
              2&nbsp;C Fast charge forl&oslash;b af et 3S Hyperion 3700 VX .
              Opladetid 31 minutter. Der er sparet 4&frac12;minut og der mangler
              kun 64&nbsp;mA/h i forhold til normal opladning, fordi opladningen
              stopper ved 1/5&nbsp;C i stedet for 1/10&nbsp;C. Ved 1&nbsp;C vil
              opladetiden v&aelig;re omkring 1 time. Bem&aelig;rk at
              sp&aelig;ndingen p&aring; hver enkelt celle ogs&aring; vises.
              Normalt er &oslash;konomi ikke noget som har modelflyverens helt
              store interesse, men derfor kan man jo godt t&aelig;nke lidt over
              hvad der kan betales sig.
            </p>
            <p>
              1. Da jeg skal vedligeholde batterier til en pris, som langt
              overstiger hvad laderen koster, spiller laderens pris en relativ
              mindre rolle. BC8 koster komplet 1780kr hos RC-Netbutik.
            </p>
            <p>
              2. Man m&aring; sammenholde prisen for Li-Po batteriet kontra
              ladetiden. Alts&aring; skal du have flere batterier, som du kan
              udskifte, eller et enkelt, som du kan oplade hurtigt. Som det
              fremg&aring;r af det efterf&oslash;lgende anvender jeg en samlet
              strategi for de fleste af mine fly.
            </p>
            <h2>Organiseringen af batterierne</h2>
            <p>
              Som udgangspunkt anvender jeg 4 stk. Hyperion 3700&nbsp;mA/h VX 3S
              til 5 forskellige slags fly, omfattende 2 motoriserede
              sv&aelig;vefly, 2 kunstflyvnings fly, og et 2 motoret fly. Dette
              er gjort af &oslash;konomiske og praktiske grunde, for ikke at
              have alle mulige forskellige batterier. Det skal ogs&aring;
              n&aelig;vnes at de forskellige fabrikanter, desv&aelig;rre
              anvender ikke de samme typer balanceringsstik, hvilket er
              upraktisk. Til Bantam laderen kan der imidlertid k&oslash;bes
              f&aelig;rdige mellemstik til balancering, som d&aelig;kker de
              fleste batterityper, s&aring;ledes at du hverken skal t&aelig;nke
              eller lodde. Mine 4 batteripakker danner 2 par, idet jeg i det
              sjette fly, Fanlineren bruger 2 pakker i serie til at give 6S, som
              s&aring; kobles parallelt til begge controlere via et print. Se
              fig. 7.
            </p>
            <p>
              <img src={fig7} />
              <br />
              Fig. 7. De 2 serieforbundne batterier tilsluttes venstre side. De
              2 parallelforbundne controllere tilsluttes h&oslash;jre side.
            </p>
            <p>
              Batterierne er monteret med 4mm guldstik. Disse har en
              kontaktmodstand p&aring; 0,2 milli-Ohm og kan b&aelig;re
              80&nbsp;Amp. Bem&aelig;rk at plus og minus ledningen ikke er lige
              lange. Dette er gjort for at minimere faren for kortslutning.
              Batteriet er nummereret og bliver logget for hver op- og
              afladning, s&aring;ledes at ydeevnen over tid kan bestemmes. For
              ikke ude i marken at tage fejl mellem opladte og afladte pakker,
              er den opladte forsynet med et gummib&aring;nd. Dette er
              s&aelig;rlig vigtigt n&aring;r to pakker danner par! Se fig. 8.
              Foran batteriet b&oslash;r du have en beskyttelse af hvid eller
              bl&aring; skum, i tilf&aelig;lde af en nedstyrtning! Batteriet
              m&aring; ikke pakkes ind p&aring; grund af varmeudviklingen.
            </p>
            <p>
              <img src={fig8} />
              <br />
              Fig. 8. 2stk 3S forbundet som 6S.
            </p>
            <h2>Afladning</h2>
            <p>
              Afladningen foreg&aring;r af gode grunde normalt oppe i luften og
              kan derfor ikke overv&aring;ges s&aring; nemt, men f&oslash;lgende
              muligheder kan bruges:
            </p>
            <p>
              1. Mest simpelt b&oslash;r du m&aring;le din motortid med et
              stopur og lande i god tid.
            </p>
            <p>
              2. En mere avanceret form for styring af tiden kan udf&oslash;res,
              hvis du har en moderne sender. F.eks. kan Futaba T9CP p&aring;
              motorkanalen programmeres, s&aring;ledes at du kan starte en timer
              ved en bestemt stilling n&aring;r du f&oslash;rer gaspinden frem.
              Se fig. 9.
            </p>
            <p>
              <img src={fig9} />
              <br />
              Fig 9.
            </p>
            <p>
              S1 er h&oslash;jstr&oslash;mstimer og er sat til 1minut. Er aktiv
              mellem 90&nbsp;% og 100&nbsp;% gas. S2 er str&oslash;mtimer og
              startes n&aring;r gassen er over 5&nbsp;%. Du glemmer ikke at
              starte tidtagningen og f&aring;r et bedre billede af dit
              str&oslash;mforbrug i luften samtidig med at du modtager alarmer.
            </p>
            <p>
              3. Anvende en programmerbar motorcontroller med indstillelig
              cut-off sp&aelig;nding. Eksempel: Et Hyperion batteri
              3700&nbsp;mA/h 3 celler aflades med 15&nbsp;A (4&nbsp;C) med en
              TMM expert controller. Hver enkelt celle blev overv&aring;get med
              hver sit digitalmultimeter. TMM expert controlleren blev sat til
              en cut-off sp&aelig;nding p&aring; 3,4&nbsp;V. I praksis blev
              minimum sp&aelig;ndingen p&aring; cellerne 3,55&nbsp;V 3,18&nbsp;V
              og 3,12&nbsp;V da controlleren lukkede ned. Controllerens software
              ser summen af sp&aelig;ndingen alts&aring; 9,85&nbsp;V Dette
              svarer 3,28&nbsp;V pr celle. M&aring;lingerne viser at det er
              n&oslash;dvendigt s&aelig;tte cut-off sp&aelig;ndingen
              h&oslash;jere end man skulle tro. Det kan generelt konstateres at
              der opst&aring;r stigende ubalance, n&aring;r man n&aelig;rmer sig
              slutningen af afladeforl&oslash;bet.
            </p>
            <p>
              4. En bedre l&oslash;sning kunne v&aelig;re at man
              overv&aring;gede hver enkelt celle og dermed lod den celle som
              f&oslash;rst n&aring;ede 3&nbsp;V bestemme cut-off. Dette princip
              anvendes i Schulzes LiPo-Discharge Monitoring And Throttle
              InteraCtion modul. Her kobler man en enhed ind i motorkanalen og
              tilslutter samtidig enheden til batteriets balancer stik og lader
              dermed den celle, som har den laveste sp&aelig;nding bestemme. Der
              er ogs&aring; begyndt at dukke controlere op p&aring; markedet,
              hvor man tilslutter batteriets balancerstik direkte til
              controleren, s&aring;ledes at hver enkelt celle overv&aring;ges
              under flyvningen.
            </p>
            <p>
              Samme princip anvendes ogs&aring; af Hyperions LBA6 balancerings
              enhed, hvilket muligg&oslash;r at du p&aring; en simpel og sikker
              m&aring;de kan m&aring;le kapaciteten p&aring; dine Li-Po
              batterier som beskrevet tidligere. LBA 6 tilsluttes en aflader,
              som kan m&aring;le kapacitet. Batteriet tilsluttes gennem LBA6 som
              aktiveres. Afladningen stopper nu n&aring;r den f&oslash;rste
              celle &rdquo;rammer&rdquo; 3&nbsp;V og kapaciteten kan derefter
              afl&aelig;ses. Generelt b&oslash;r man ikke flyve cellerne tomme,
              men have ca. &frac14; tilbage ved landing.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
