import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/BeskrivelseAfJetisDataSystem/fig1.jpg";
import fig2 from "../../../images/Jeti/BeskrivelseAfJetisDataSystem/fig2.jpg";
import fig3 from "../../../images/Jeti/BeskrivelseAfJetisDataSystem/fig3.jpg";
import fig4 from "../../../images/Jeti/BeskrivelseAfJetisDataSystem/fig4.jpg";
import fig5 from "../../../images/Jeti/BeskrivelseAfJetisDataSystem/fig5.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Beskrivelse af Jetis datasystem</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Beskrivelse af Jetis datasystem</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret november 2012</p>
            <h2>Indledning</h2>
            <p>
              Denne beskrivelse g&aring;r i dybden med den teknik, der anvendes
              i Jetis flight recorder system til modelfly. Data opsamles via
              telemetri i en Jeti Profi Box eller i den nye Jeti DC16/DS16
              sender, med henblik p&aring; senere analyse i Windows programmet
              Jeti Flight Monitor. Systemet virker p&aring; samme m&aring;de som
              den sorte box, man altid leder efter ved flyveulykker.
            </p>
            <h2>System beskrivelse</h2>
            <p>
              For n&aelig;rmere at kunne vurdere&nbsp;systemes&nbsp;evne til at
              levere pr&aelig;cise oplysninger i sand tid, skal foretages en
              kort gennemgang af, hvordan systemet arbejder. Opsamlingen af data
              i flyet foretages af forskellige transducere, som kan
              oms&aelig;tte lufttryk, temperatur, sp&aelig;ndinger og
              magnetfelter (Hall sensoren i&nbsp;MUIen) til analoge
              sp&aelig;ndinger. Disse sp&aelig;ndinger konverteres derefter til
              digitale v&aelig;rdier i en A/D konverter. (En undtagelse er GPS
              modtageren, som afgiver digitale v&aelig;rdier opsamlet fra 5 til
              12 satellitter.) De digitale signaler bearbejdes i en
              mikroprocessor med tilh&oslash;rende beregnings- programmer, som
              derefter leverer data til en UART (Universal Asynkron Receiver
              Transmitter). Hele denne proces g&aring;r hurtigt, i forhold til
              de tider som &oslash;nskes m&aring;lt p&aring; flyet. Datapakker
              med m&aring;ledata sendes derefter tr&aring;dl&oslash;st p&aring;
              2,4GHz, ved hj&aelig;lp af en seriel asynkron half-duplex
              forbindelse, tilbage til datarecorderens hukommelse i Jeti Profi
              Boxen eller Jeti senderen. Transmissionshastigheden er 9600 Baud.
              Denne hastighed er relativ langsom i forhold til de
              datam&aelig;ngder, som &oslash;nskes overf&oslash;rt, specielt
              hvis man har sammenkoblet op til 4 m&aring;lemoduler
              via&nbsp;expandermodulet. Dette virker som
              en&nbsp;multiplexer(data omskifter). Yderligere moduler kan
              sammenkobles ved at daisy chaine flere expandere, se tegning.
              Endvidere vil man kunne tilkobles enheder, s&aring; som Jeti EX
              str&oslash;mforsyninger og den nye regulator serie Mezon EX til
              brug for store b&oslash;rstel&oslash;se motorer.
            </p>
            <p>
              Databussen har en &rdquo;point to point&rdquo; topologi.
              Princippet er, at det er de enkelte sensorer, der p&aring;begynder
              kommunikationen som mastere, og at Jeti boxen er slave. Der er kun
              en aktiv sensor p&aring; netv&aelig;rket af gangen, som sender en
              datapakke. N&aring;r pakken er afsendt, frig&oslash;res bussen i
              mindst 20msek. I dette tidsrum lytter Jeti boxen. Derefter
              &aelig;ndres transmissionsvejen, s&aring;ledes at man kan sende
              kommandoerne&nbsp;left/down/up/right p&aring; de 4 piletaster med
              en aktiv bin&aelig;r 0 v&aelig;rdi. N&aring;r time out tiden er
              udl&oslash;bet, skifter bussen retning og er klar til et nyt
              opkald fra en sensor. Bem&aelig;rk der er ingen
              programmeringsparametre i Jeti boxen, men at Jeti boxen kan
              programmere modtageren og de enkelte tilkoblede enheder, s&aring;
              som m&aring;lemoduler, str&oslash;mforsyninger eller Mezon
              regulatorer.
            </p>
            <p>
              <img src={fig1} />
            </p>
            <h2>Datas forsinkelse og n&oslash;jagtighed</h2>
            <p>
              Nedenst&aring;ende tal viser nogle af de opsamlede data over 4
              sekunder, nemlig sekunderne 35 til 38. Der er ved hurtige
              forl&oslash;b en ikke ubetydelig forsinkelse, flere sekunder, i
              forhold til sand tid p&aring; grund af &rdquo;flaskehalsen&rdquo;
              i den asynkrone serielle dataforbindelse. Dette medf&oslash;rer,
              at data kommer forsinket ind i forhold til flyvningen.
              N&oslash;jagtigheden af m&aring;lingerne bestemmes af de enkelte
              m&aring;lemodulers n&oslash;jagtighed, som er opgivet af Jeti.
              Satellitmodtagerens n&oslash;jagtighed er svingende, men
              hovedsagelig bestemt af antallet af modtagne satellitter, som skal
              v&aelig;re over 5.&nbsp;
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Det ses her at sampleraten svinger mellem 2 og 5 registreringer
              pr. sekund. Tiden er l&aelig;ngst til h&oslash;jre. Dette er
              dermed den data opsamlingshastighed systemet kan
              pr&aelig;stere.&nbsp;GPSen&nbsp;er s&aelig;rlig kr&aelig;vende
              idet der er ca. 80 cifre som skal overf&oslash;res.
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Sampelrate&nbsp;synes at v&aelig;re ca. &frac14; sekund i
              data&nbsp;recorderen, idet der kan konstateres ca. 4
              &aelig;ndringer inden for 1 sek.
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Endelig vises en m&aring;ling, som er opst&aring;et p&aring; den
              m&aring;de at satellitmodtageren har mulighed for at m&aring;le
              pulsbredden p&aring; en vilk&aring;rlig&nbsp;servo&nbsp;kanal. I
              dette tilf&aelig;lde m&aring;les p&aring; en switch (ch8), som
              hurtigt vippes frem og tilbage. Kurven burde v&aelig;re en
              firkant, idet pulsen &aelig;ndres fra 1,000mS til 1,890mS uden
              nogen tidsforsinkelse. Man har alts&aring; en stigtid/faldtid
              p&aring; godt 1sek p&aring; grafen. Registreringen kan dels bruges
              som en tidsmark&oslash;r under flyvningen, dels vil man kunne
              m&aring;le pulsbredden p&aring; f.eks. et h&oslash;jderor, spoiler
              eller flaps og dermed bestemme stillingen under flyvningen. Alle
              Jeti modtagere kan duplikere kanaler, s&aring;ledes at man ikke
              beh&oslash;ver at anvende Y-ledninger.&nbsp;
            </p>
            <h2>Praktisk brug</h2>
            <p>
              Jeti Profi Boxen s&aelig;ttes op til at optage al aktivitet,
              s&aring; snart den er t&aelig;ndt, s&aring;ledes at man ikke kan
              glemme at starte optagelserne. N&aring;r data flyttes over
              i&nbsp;PC&acute;en, kan de organiseres i mapper, som knytter sig
              til hvert enkelt fly.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <p>
              Hvis der anvendes GPS modul, skal det s&aelig;ttes til maksimal
              optagehastighed 0,2 optagelser&nbsp;pr.sek. s&aring;ledes at det
              kan f&oslash;lge med i de hurtige forl&oslash;b, som findes under
              flyvning.
            </p>
            <p>
              Hvis GPS modulets hukommelse bliver fyldt overskrives det blot.
              Med MUI modul f&aring;s et dataforbrug p&aring; godt 1kbyte pr
              sekund. Med MUI og GPS modul f&aring;s 1,3 til 2kbyte pr. sekund.
              Filformatet der anvendes, er Java&nbsp;Modeling&nbsp;Language med
              fil&nbsp;extention&nbsp;JML. Flight Monitoren
              kan&nbsp;exportere&nbsp;data til formatet CSV, som kan l&aelig;ses
              af Excel.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
