import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles, Link } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/JetiBoxProfiExDel1/fig1.jpg";
import fig2 from "../../../images/Jeti/JetiBoxProfiExDel1/fig2.jpg";
import fig3 from "../../../images/Jeti/JetiBoxProfiExDel1/fig3.jpg";
import fig4 from "../../../images/Jeti/JetiBoxProfiExDel1/fig4.jpg";
import fig5 from "../../../images/Jeti/JetiBoxProfiExDel1/fig5.jpg";
import fig6 from "../../../images/Jeti/JetiBoxProfiExDel1/fig6.jpg";
import fig7 from "../../../images/Jeti/JetiBoxProfiExDel1/fig7.jpg";
import fig8 from "../../../images/Jeti/JetiBoxProfiExDel1/fig8.jpg";
import fig9 from "../../../images/Jeti/JetiBoxProfiExDel1/fig9.jpg";
import fig10 from "../../../images/Jeti/JetiBoxProfiExDel1/fig10.jpg";
import fig11 from "../../../images/Jeti/JetiBoxProfiExDel1/fig11.jpg";
import fig12 from "../../../images/Jeti/JetiBoxProfiExDel1/fig12.jpg";
import fig13 from "../../../images/Jeti/JetiBoxProfiExDel1/fig13.jpg";
import fig14 from "../../../images/Jeti/JetiBoxProfiExDel1/fig14.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>
            Jeti Box Profi EX - en trådløs terminal med data recorder og 2,4GHz
            sender modul. Del 1
          </title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>
              Jeti Box Profi EX &ndash; en tr&aring;dl&oslash;s terminal med
              data recorder og 2,4GHz sender modul. Del 1
            </h1>
            <p>
              Af J&oslash;rgen Bj&oslash;rn&nbsp;
              <br />
              <br />
              Publiceret Marts 2012
            </p>
            <p>
              Denne artikel er en forts&aelig;ttelse af emnet: &rdquo;
              <Link href="/Jeti/Jeti-Duplex-2.4-GHz-teknik" target="_blank">
                Jeti Duplex 2,4GHz teknik
              </Link>
              &rdquo;, bragt i{" "}
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2010/modelflyvenyt%201-2010.pdf"
                target="_blank"
              >
                nummer 1-2010 af Modelflyvenyt
              </Link>{" "}
              Det er en fordel, at du kender principperne i Jeti Duplex
              systemet.
            </p>
            <h2>Overblik over Jeti Duplex systemerne</h2>
            <p>
              Jeti Duplex produkterne udg&oslash;r et omfattende
              fjernstyringssystem p&aring; 2,4GHz, som best&aring;r af 6
              forskellige moduler til indbygning i eksisterende fremmede sendere
              s&aring;som Futaba, Graupner Multiplex med flere, 14 forskellige
              modtagere fra 4 til 18 kanaler, 4 forskellige
              str&oslash;mforsyninger, 2 Jeti boxe, 14 forskellige
              m&aring;lemoduler til telemetri samt Jeti Box Profi EX, som denne
              artikel beskriver herefter ben&aelig;vnt Profi Box. Endvidere
              introduceres 2 nye sendere DS16 EX og DC16 EX i for&aring;ret
              2012.&nbsp;
              <br />
              Jeti Duplex er udviklet og produceret i Tjekkiet, med
              st&oslash;tte af EU's teknologifond, for at udvikle et h&oslash;jt
              innovativt produkt i konkurrence med tilsvarende produkter. Jeti
              Duplex EX, er efter min menig et af de systemer, som p.t. er
              l&aelig;ngst fremme p&aring; det globale marked og udviser i mine
              unders&oslash;gelser af specielt m&aring;lemodulerne h&oslash;j
              kvalitet og forbavsende stor n&oslash;jagtighed.
              Pris/ydelsesforholdet p&aring; Jeti EX systemet er v&aelig;sentlig
              bedre end tilsvarende sammenlignelige anl&aelig;g p&aring;
              markedet.
              <br />
              Jeti Duplex EX er efterf&oslash;lgeren af Duplex. De to
              generationer er indbyrdes kompatible.
              <br />
              EX generationen frembyder forbedringer p&aring; f&oslash;lgende
              omr&aring;der:
            </p>
            <ul>
              <li>
                Visning af telemetridata p&aring; et programmerbart grafisk LCD
                display.
              </li>
              <li>
                Stemme alarmer p&aring; indbygget h&oslash;jttaler eller
                hovedtelefon.
              </li>
              <li>
                Data Flight Recorder som kan opsamle og gemme str&oslash;mmen af
                telemetri data under flyvningen.
              </li>
              <li>
                Windows programmet Flight Monitor til behandling og monitorering
                af telemetri data under eller efter flyvningen.
              </li>
            </ul>
            <p>
              <img src={fig1} />
              <br />
              Profi Box vist i hovedmenuen med den tilh&oslash;rende
              telemetrisender Rsat2 EX
              <br />
              Bem&aelig;rk at alle de gamle Duplex produkter er bl&aring; som
              nederst til venstre. EX produkter er sorte.
            </p>
            <h2>Profi box 2.4GHz EX Duplex tr&aring;dl&oslash;s terminal</h2>
            <p>
              Jeti box profi er en tr&aring;dl&oslash;s terminal, som indeholder
              et 2,4GHz sendermodul, og som samtidig kan programmere, modtage,
              sende, vise, udtale, behandle og gemme telemetridata. N&aring;r
              jeg anvender udtrykket terminal, er det fordi alle de omkring
              liggende enheder kan m&oslash;des i Profi Boxen p&aring; mange
              forskellige m&aring;der, ligesom en terminals funktion i en
              lufthavn.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Systemdiagram
            </p>
            <h2>6 forskellige modes:</h2>
            <p>
              EX systemet kan anvendes p&aring; 6 forskellige m&aring;der.
              Bem&aelig;rk personernes opgaver i de forskellige modes. Data fra
              Duplex enheder kan streames live til en USB forbundet Windows PC.
              I 4 modes kan data optages i Profi Boxens hukommelse for senere
              afspilning.
            </p>
            <p>
              <strong>
                Mode 1. Ledningsforbundet duplex terminal med data recorder som
                m&aring;lev&aelig;rkt&oslash;j p&aring; v&aelig;rksted
              </strong>
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Her har modelflyveren et m&aring;lev&aelig;rkt&oslash;j p&aring;
              sit v&aelig;rksted til alle de elektriske og fysiske
              st&oslash;rrelser, som m&aring;lemodulerne kan omforme til
              digitale data. Jeti Duplex EX m&aring;lemoduler
              str&oslash;mforsynes fra boxen.&nbsp;
              <br />
              Eksempler p&aring; m&aring;ling kan v&aelig;re: Batterikapacitet,
              str&oslash;m, temperatur, omdrejninger, virkningsgrader p&aring;
              elmotorer, GPS m&aring;linger, true airspeed m.m.&nbsp;
            </p>
            <p>
              <strong>
                Mode 2. Tr&aring;dl&oslash;s duplex terminal med data recorder
                til monitorering af fremmede systemer
              </strong>
            </p>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Her kan modelflyveren ved hj&aelig;lp af den tilh&oslash;rende
              telemetrisender Rsat2 tr&aring;dl&oslash;st overf&oslash;re alle
              de samme st&oslash;rrelser som n&aelig;vnt i mode 1. Modellen
              styres af et hvilket som helst RC-anl&aelig;g f.eks. 35MHz eller
              Futaba FAAST. Rsat2 modtageren kan str&oslash;mforsynes fra den
              fremmede modtager.
            </p>
            <p>
              <strong>
                Mode 3. Fremmed sender monteret med 2,4GHz Jeti sender modul
                samt tr&aring;dl&oslash;s Profi Box med data recorder
              </strong>
            </p>
            <p>
              Denne mode best&aring;r af en programmeringsmode og en
              flyvemode.&nbsp;
            </p>
            <p>
              <img src={fig5} />
              <br />
              Programmering
            </p>
            <p>
              F&oslash;rst bringes Profi Box i TX mode i menuen Duplex, derefter
              bindes Profi Box til flyets modtager. Nu kan en programmering af
              alarmv&aelig;rdier m.m. foretages via Profi Box. Det
              bem&aelig;rkes, at den r&oslash;de fjernstyrings sender er
              slukket. Inden der forts&aelig;ttes, bringes Profi Box i monitor
              mode. Dette betyder, at senderen i Profi Box stoppes.
              Telemetrimodtageren i Profi Box er stadig bindet til modtagerens
              telemetri sender.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Flyvning
            </p>
            <p>
              Fjernstyringssenderen t&aelig;ndes og bindes derefter til flyets
              modtager. Ovenst&aring;ende procedure skal kun foretages en gang.
              Nu kan der flyves, og piloten og hans hj&aelig;lper har nu en
              tr&aring;dl&oslash;s Profi Box til r&aring;dighed med alle dens
              faciliteter.
            </p>
            <p>
              <br />
              <strong>
                Mode 4. Fremmed sender monteret med Profi Box som duplex
                terminal med data recorder dermed f&aring;r man et opdateret 2,4
                GHz RC-anl&aelig;g
              </strong>
            </p>
            <p>
              <img src={fig7} />
            </p>
            <p>
              Her tilkobles senderen (f.eks. Futaba) til Profi Box via et PPM
              signal, hermed erstattes det gamle 35MHz sendermodul, og man har
              et opdateret Jeti Duplex EX RC-anl&aelig;g.&nbsp;
              <br />
              Du skal her v&aelig;re opm&aelig;rksom p&aring;, at alle Jeti
              modtagere har indbygget software til EXP, ATV, MIX, Fail Safe ja
              endog servoforsinkelse, det skal blot programmeres via Profi
              Boxen.
            </p>
            <p>
              <img src={fig8} />
              <br />
              En meget gammel og en helt ny generation teknik m&oslash;des.
            </p>
            <p>
              Et kabel fra tr&aelig;nerstikket overf&oslash;rer PPM signalet til
              Profi Boxen. Indmaden af det gamle 35MHz modul fjernes, og den
              gamle senderkasse is&aelig;ttes tom, for ikke at bruge
              str&oslash;m og for ikke at efterlade et hul bag p&aring;
              senderen. Se ogs&aring; under Power management.
            </p>
            <p>
              <strong>
                Mode 5. Analyse af optagne data fra data recorder efter flyvning
              </strong>
            </p>
            <p>
              <img src={fig9} />
            </p>
            <p>
              Her kan piloten analysere flyvningen efter landingen. Vil blive
              beskrevet i del 2, n&aring;r Jeti har frigivet Flight Monitor
              software til Windows PCen.
            </p>
            <p>
              <strong>
                Mode 6. Firmware opdateringer fra Jetis server via USB
                adaptoren, se systemdiagram
              </strong>
            </p>
            <p>
              Det forventes, at systemets software stadig forbedres. Du kan
              derfor via Jetis USB adaptor hente nye versioner af softwaren. Den
              nuv&aelig;rende version er 1.13. Versions nummeret kan
              afl&aelig;ses i menuen <br />
              Update. Det er ogs&aring; her, du kan afl&aelig;se serie nummeret
              p&aring; Profi Boxen, s&aring;ledes at produktet er sporbart.
            </p>
            <h2>Senderen og antennen</h2>
            <p>
              Sende effekten kan &aelig;ndres mellem 10mW og 100mW, ligesom der
              er en rangetest i menuen Duplex. Det er ogs&aring; her man kan
              stoppe Profi Box senderen og kun anvende telemetri modtageren som
              vist i mode 3. &Oslash;verst i kanten af Profi Box er der en
              indbygget integreret antenne til sender og telemetrimodtager, som
              p&aring; mobiltelefoner. Dette kan ikke ses, men det er godt at
              vide! Man b&oslash;r ikke holde Profi Boxen her, fordi det giver
              en reduceret sendeeffekt. Hvis Profi Box anvendes som sender modul
              (mode 4), g&aelig;tter jeg p&aring; problemet er l&oslash;st,
              fordi piloten holder h&aelig;nderne p&aring; styrepindene, mens
              han flyver!&nbsp;
            </p>
            <p>
              <img src={fig10} />
              <br />
              Billedet viser 2,4GHz modulet med den indbyggede dipol antenne
              &oslash;verst til venstre.
              <br />I midten ses det indbyggede 2GB micro SD hukommelses kort og
              til h&oslash;jre Li-Po cellen.
            </p>
            <h2>Display</h2>
            <p>
              Visning af telemetridata finder sted p&aring; et programmerbar
              baggrundsbelyst 7&nbsp;cm 128x64 pixel LCD display. Displayet er
              delt op i fire felter, som valgfrit kan vise data numerisk, som
              bars eller grafer.
              <br />
              Der kan v&aelig;lges mellem fem forskellige sk&aelig;rmsider,
              s&aring;ledes at man samlet kan vise 20 indkomne telemetridata.
              Udover dette er der to faste sk&aelig;rmsider, som kan vise
              styrespindenes stillinger op til 16 kanaler. Nu kommer piloter med
              den indvending, at jeg ikke kan st&aring; og se p&aring; alle de
              oplysninger, mens jeg flyver. Dette er ganske rigtigt. Hold
              &oslash;jnene p&aring; modellen!&nbsp;
              <br />
              Ny teknologi kr&aelig;ver nyt&aelig;nkning:
            </p>
            <ul>
              <li>
                Du kan godt afl&aelig;se den forbrugte batteri kapacitet under
                flyvningen ved at &oslash;ve dig.
              </li>
              <li>
                Det er meget n&aelig;rliggende at anvende en hj&aelig;lper under
                flyvningen. Da Profi Boxen er en tr&aring;dl&oslash;s terminal i
                mode 2 og 3, kan hj&aelig;lperen iagttage data p&aring;
                sk&aelig;rmen og hj&aelig;lpe piloten.
              </li>
              <li>
                Du m&aring; lave en programmering af displayet og v&aelig;lge en
                &oslash;nsket opgave, som beskrevet neden for.
              </li>
            </ul>
            <p>
              <img src={fig11} />
            </p>
            <p>
              L&aelig;g m&aelig;rke til sidenummeret nederst i venstre
              hj&oslash;rne. Dette er min brugsside nr. 1, n&aring;r jeg flyver
              alene. Jeg har valgt at programmere displayet med oplysninger om
              flybatteriets sp&aelig;nding og forbrugt kapacitet med
              tilh&oslash;rende stemme alarmer. Endvidere vises modtagerens
              forsyningssp&aelig;nding og nederst flyveh&oslash;jden, som
              modtages fra GPSen. Jeg kunne v&aelig;lge alt muligt andet i det
              sidste felt, men alle moduler skal v&aelig;re EX version.
            </p>
            <p>
              <img src={fig12} />
            </p>
            <p>
              P&aring; side 2 kan min iagttager/copilot st&aring; med Profi
              boxen og udl&aelig;se ground speed, flyveh&oslash;jde, afstand ud
              til flyet samt den gennemfl&oslash;jne str&aelig;kning. Hvis True
              Air Speed (TAS) &oslash;nskes m&aring;lt, findes der ogs&aring; et
              m&aring;lemodul til dette.
            </p>
            <p>
              <img src={fig13} />
            </p>
            <p>
              P&aring; side 3 vises antennesp&aelig;ndingerne og modtagerens
              forsyningssp&aelig;nding. Disse oplysninger afgiver alle Jeti
              modtagere altid uden brug af m&aring;lemoduler. Det er ganske
              interessant at f&oslash;lge modtagerens antennesp&aelig;ndinger,
              mens du flyver rundt. Jeg anvender denne oplysning i min Fanliner,
              som har aluminiums bekl&aelig;dning og indeholder kulr&oslash;r.
              Husk de to antenner skal st&aring; vinkelret p&aring; hinanden
              p&aring; grund af udstr&aring;lingskarakteristikken.
            </p>
            <p>
              <img src={fig14} />
            </p>
            <p>
              P&aring; side 4 vises GPS koordinater som med stor sikkerhed
              indenfor 4 til 10 meter g&oslash;r, at jeg kan finde modellen i en
              kornmark ved en bortflyvning. Koordinaterne kan nu tastes ind i en
              bil eller geotracker GPS, og s&aring; kan man pr&oslash;ve. Et
              andet bortflyvningsv&aelig;rkt&oslash;j er den nederste oplysning
              som fort&aelig;ller at modellen befinder sig p&aring; kurs 210
              grader og i en afstand af 15 meter.
            </p>
            <h2>Stemme alarm system</h2>
            <p>
              Efter fremkomsten af 1. generation af Duplex var det et problem,
              at der kun kom morsekoder ud af alarmsystemet. Dette problem er nu
              l&oslash;st perfekt. Til hver enkelt morsekode er der nu
              tilknyttet en stemmealarm, som kan h&oslash;res p&aring; den
              indbyggede h&oslash;jttaler eller p&aring; en &oslash;reprop.
              Sidst n&aelig;vnte l&oslash;sning er sikkert fornuftigt, hvis der
              st&aring;r flere Jeti piloter samlet. Styrken kan nu ogs&aring;
              reguleres. Profi Box leveres med 19 forud indspillede lydfiler
              p&aring; engelsk, tysk eller tjekkisk. Jeg tror, jeg
              foretr&aelig;kker engelsk, som er flyvningens sprog.
              <br />
              Under normal flyvning er systemet tavst, men aktiveres hvis den
              p&aring;g&aelig;ldende h&aelig;ndelse opst&aring;r. Uden
              anvendelse af m&aring;lemoduler kan alle EX modtagere afgive
              f&oslash;lgende: &rdquo;Binding&rdquo; &ndash; &rdquo;PPM
              error&rdquo; &ndash; &rdquo;Jeti box low voltage&rdquo; &ndash;
              &rdquo;Receiver voltage decrease&rdquo;-&rdquo;Signal
              loss&rdquo;-&rdquo;Low signal&rdquo;- &rdquo;Range test&rdquo;.
              Hvis der anvendes m&aring;lemoduler, f&aring;r man naturligvis
              alarmer, hvis de i m&aring;lemodulerne programmerede v&aelig;rdier
              overskrides. Alle el-piloter vil efter min bedste overbevisning
              have stor gl&aelig;de af et MUI-modul, som s&aring; kan meddele at
              70&nbsp;% af Li-Po motorbatteriets faktiske kapacitet overskrides.
              Profi Box vil s&aring; sige &rdquo;Warning Capasity&rdquo;. Det er
              ogs&aring; smart, at man kan prioritere alarmernes hyppighed
              mellem 8sek, 15sek eller 22sek. Det vil v&aelig;re vigtigere for
              mig, at vide at modtager sp&aelig;ndingen er ved at blive lav, end
              at min afstand ud til modellen overstiger 200meter m&aring;lt
              p&aring; GPS satellit modtageren.{" "}
            </p>
            <h2>Power management system</h2>
            <p>
              Str&oslash;mforsyningen h&aring;ndteres af et indbygget power
              management kredsl&oslash;b, som g&oslash;r, at Profi Boxen enten
              kan:&nbsp;
            </p>
            <ol>
              <li>
                Str&oslash;mforsynes fra Li-Po cellen med en drift tid p&aring;
                max 3 timer, hvis man slukker lyset p&aring; sk&aelig;rmen.
                N&aring;r en cellesp&aelig;nding p&aring; 3,6&nbsp;V n&aring;s,
                brokker Profi Boxen sig med en stemme alarm.&nbsp;
              </li>
              <li>
                Str&oslash;mforsyne Profi Boxen direkte fra senderen. Dette
                g&aelig;lder kun mode 4. Via batteri menuen kan man afbryde
                Li-Po cellen og str&oslash;mforsyne via PPM stikket,
                s&aring;ledes at Profi Boxen t&aelig;ndes og slukkes sammen med
                senderen. (Auto switch off? Yes) Profi Boxen bruger ca. 50mA ved
                10&nbsp;V. T&aelig;ndt back light til sk&aelig;rm bruger
                yderligere ca. 7mA. Sendersp&aelig;ndingen kan afl&aelig;ses
                p&aring; Profi Boxen.{" "}
              </li>
              <li>
                Oplades og str&oslash;mforsynes fra UNI stikket med en
                sp&aelig;nding p&aring; mellem 5,7&nbsp;V og 20&nbsp;V.
              </li>
              <li>
                Oplades og str&oslash;mforsynes via USB kablet, hvis Windows
                Pc&rsquo;en er tilsluttet.
              </li>
              <li>
                Oplades og str&oslash;mforsynes fra en l&oslash;s USB lader.
                Opladetid ca. 1 time.
              </li>
            </ol>
            <p>
              Oplade effekten er 5&nbsp;W. Det hele kan styres og
              overv&aring;ges via batterimenuen.
              <br />
              Profi Boxen t&aelig;ndes f&oslash;rste gang ved, at der p&aring;
              siden sidder en lille fors&aelig;nket kontakt. Derefter holdes ESC
              tasten nede nogle sekunder. Efterf&oslash;lgende har jeg sat et
              lille stykke tape over kontakten, idet den under ingen
              omst&aelig;ndigheder m&aring; anvendes til at slukke Profi Boxen
              under drift. Hvis Profi Boxen ikke anvendes over l&aelig;ngere tid
              f.eks. om vinteren, lukkes Profi Boxen helt ned. Jeg anbefaler, at
              Li-Po sp&aelig;ndingen ligger i n&aelig;rheden af 3,8&nbsp;V, og
              at Profi Boxen opbevares k&oslash;ligt. &Aring;rsagen til dette
              er, at Profi Boxen indeholder en Li-Po celle, som vi &oslash;nsker
              stor levetid. Profi Boxen garanteres kun til at virke ned til 0
              grader, s&aring; ingen flyvning i frost vejr.&nbsp;
            </p>
            <h2>Del 2</h2>
            <p>
              I del 2 vil jeg belyse:
              <br />
              Hvordan Profi Boxen kan opsamle og gemme str&oslash;mmen af
              telemetri data under flyvningen.
              <br />
              Hvordan de opsamlede data kan vises under flyvningen via
              programmet Flight Monitor.
              <br />
              Hvordan de opsamlede data kan analyseres efter flyvningen via
              programmet Flight Monitor.
              <br />
              Kompatibiliteten mellem Duplex og Duplex EX samt ops&aelig;tning
              af flere profiler.
            </p>
            <p>
              <Link href="/Jeti/Jeti-Box-Profi-EX-Del-2" target="_blank">
                L&aelig;s del 2 lige her
              </Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
