import React, { Component } from "react";
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import MenuData from "./MenuData";

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

interface IState {}

interface IProps {
  classes: any;
  closeMenu: any;
}

class MenuMobile extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openMenu = id => () => {
    const element = `submenu${id}Open`;
    const value: boolean = this.state[element];
    const obj = {};
    obj[element] = !value;
    this.setState(obj);
  };

  render() {
    const { classes, closeMenu } = this.props;

    return (
      <div className={classes.fullList}>
        {MenuData.map(topMenu => {
          return (
            <div key={topMenu.id}>
              <List>
                <ListItem button onClick={this.openMenu(topMenu.id)}>
                  <ListItemText primary={topMenu.name} />
                  {this.state[`submenu${topMenu.id}Open`] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
              </List>
              {topMenu.children ? (
                <Collapse
                  in={this.state[`submenu${topMenu.id}Open`]}
                  timeout="auto"
                  unmountOnExit
                >
                  {topMenu.children.map(child => {
                    return (
                      <List key={child.name} disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          component={({ innerRef, ...props }) => (
                            <Link {...props} to={child.url} />
                          )}
                          onClick={() => {
                            closeMenu(false, child.url);
                          }}
                        >
                          <ListItemText primary={child.name} />
                        </ListItem>
                      </List>
                    );
                  })}
                </Collapse>
              ) : (
                ""
              )}
              <Divider />
            </div>
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles)(MenuMobile);
