import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/FlyByWire/VTOLTeknik/fig1.jpg";
import fig2 from "../../../images/FlyByWire/VTOLTeknik/fig2.png";
import fig3 from "../../../images/FlyByWire/VTOLTeknik/fig3.jpg";
import fig4 from "../../../images/FlyByWire/VTOLTeknik/fig4.png";
import fig5 from "../../../images/FlyByWire/VTOLTeknik/fig5.png";
import fig6 from "../../../images/FlyByWire/VTOLTeknik/fig6.png";
import fig7 from "../../../images/FlyByWire/VTOLTeknik/fig7.png";
import fig8 from "../../../images/FlyByWire/VTOLTeknik/fig8.jpg";
import fig9 from "../../../images/FlyByWire/VTOLTeknik/fig9.jpg";
import fig10 from "../../../images/FlyByWire/VTOLTeknik/fig10.jpg";
import fig11 from "../../../images/FlyByWire/VTOLTeknik/fig11.jpg";
import fig12 from "../../../images/FlyByWire/VTOLTeknik/fig12.jpg";
import fig13 from "../../../images/FlyByWire/VTOLTeknik/fig13.png";
import fig14 from "../../../images/FlyByWire/VTOLTeknik/fig14.png";
import fig15 from "../../../images/FlyByWire/VTOLTeknik/fig15.png";
import fig16 from "../../../images/FlyByWire/VTOLTeknik/fig16.png";
import fig17 from "../../../images/FlyByWire/VTOLTeknik/fig17.png";
import fig18 from "../../../images/FlyByWire/VTOLTeknik/fig18.png";
import fig19 from "../../../images/FlyByWire/VTOLTeknik/fig19.jpg";
import fig20 from "../../../images/FlyByWire/VTOLTeknik/fig20.jpg";
import fig21 from "../../../images/FlyByWire/VTOLTeknik/fig21.jpg";
import fig22 from "../../../images/FlyByWire/VTOLTeknik/fig22.jpg";
import fig23 from "../../../images/FlyByWire/VTOLTeknik/fig23.jpg";
import fig24 from "../../../images/FlyByWire/VTOLTeknik/fig24.jpg";
import fig25 from "../../../images/FlyByWire/VTOLTeknik/fig25.jpg";
import fig26 from "../../../images/FlyByWire/VTOLTeknik/fig26.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>VTOL teknik</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>VTOL teknik - Del 1: Principper, valg og bygning af model</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn&nbsp;</p>
            <p>Publiceret jan. 2019</p>
            <h2>Indledning</h2>
            <p>
              Vertikal Take Off and Landing begrebet d&aelig;kker over
              flyvemaskiner med faste vinger, som kan starte og lande lodret.
              Det betyder, at helikopteren og multirotor flyet ikke er VTOL fly.
            </p>
            <p>
              Fordelen ved VTOL flyet er naturligvis, at det ikke kr&aelig;ver
              start- eller landingsbaner, men samtidig er i besiddelse af
              fastvingeflyets evner til at flyve hurtigt, l&aelig;nge og langt.
              Ulempen ved VTOL flyet er, at det er mere kompliceret og
              kr&aelig;ver hj&aelig;lp af en computer, som kan stabilisere
              flyet.
            </p>
            <p>
              Modelfly har gennem mange &aring;r kopieret full size fly. Et
              omr&aring;de, som det har knebet med, er VTOL flyet. Forfatteren
              vil i 3 artikler beskrive udviklingen et VTOL modelfly. L&aelig;s
              med, hvis du vil &oslash;ge din viden om denne form for
              modelflyveteknik.
            </p>
            <p>
              <img src={fig1} />
            </p>
            <p>
              Det mest kendte VTOL full-size fly er den engelske Harrier bygget
              af British Aerospace i 1967. Maskinen kunne dreje jetmotorens
              udst&oslash;dningsluft til lodret gennem 4 drejbare dysser.
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Senere i 1989 fulgte Boeing med V-22 Osprey, som var et stort
              milit&aelig;rt transport fly med drejbare tiltrotorer. Projektet
              var 9 &aring;r undervejs, der var to nedstyrtninger med 23
              dr&aelig;bte. Udviklingsomkostningerne var enorme, og projektet
              var flere gange ved at blive stoppet af den amerikanske kongres og
              illustrerer p&aring; udm&aelig;rket vis, at VTOL fly er
              sv&aelig;re at have med at g&oslash;re.
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Til sidst skal n&aelig;vnes Lockheed Martins F35, der er
              afl&oslash;seren af det danske F16 kampfly. Flyet findes i flere
              versioner. F35B versionen er en VTOL udgave, som Danmark ikke har
              k&oslash;bt, fordi den er beregnet til hangarskibe. F35B kan dreje
              udst&oslash;dningsluften 90 grader ned mod jorden, samtidig med at
              der i forkroppen &aring;bnes lemme, som muligg&oslash;r lodret
              gennemstr&oslash;mning af luft frembragt af en stor horisontalt
              anbragt fan. Denne fan tr&aelig;kkes af jetmotoren gennem en
              gearkasse. Flyet vejer omkring 30 ton, s&aring; mit g&aelig;t er,
              at br&aelig;ndstoffet hurtigt bliver brugt op! F35B blev ramt af
              en nedstyrtning i september 2018, hvor &aring;rsagen
              forel&oslash;big er ukendt.{" "}
            </p>
            <p>
              Alle store udviklere i flyindustrien arbejder i dag med VTOL
              konstruktioner baseret p&aring; el-motorer. Her ser man en
              fremtid, hvor det helt lave luftrum op til 200m kan anvendes til
              alt muligt, s&aring; som fotografering, varer udbringning,
              flyvning med personer m.m. Eksempel kunne v&aelig;re Vahana fra
              Airbus.{" "}
            </p>
            <p>
              Hvis vi vender os mod modelflyvningen er det f&oslash;rst i de
              senere &aring;r, at VTOL flyet er blevet mulig grundet
              teknologiudviklingen. Denne artikel har til form&aring;l at belyse
              VTOL teknikken i teori og praksis s&aring;ledes, at det kunne
              tiltr&aelig;kke flere modelflyvere. Det kan n&aelig;vnes, at det
              f&oslash;rste VM i RC VTOL semiskalafly er afholdt i august 2018
              p&aring; Dornier Museet i Friedrichshafen.
            </p>
            <h2>VTOL flyvningens faser</h2>
            <p>
              Kendetegnet for VTOL konstruktioner er, at man kan dreje motorer,
              vinger, udst&oslash;dningsluft eller hele flyet 90 grader. Man kan
              ogs&aring; starte eller stoppe motorer. Der gives v&aelig;ldig
              mange muligheder, ikke mindst fordi udbredelsen af den
              b&oslash;rstel&oslash;se motor ESCen og Li-Po batterierne giver
              h&oslash;jstabil drift. VTOL flyet skal kunne flyve
              f&oslash;lgende faser:
            </p>
            <ol>
              <li>
                <strong>Hover up:</strong>&nbsp;Der skal v&aelig;re s&aring;
                stort et kraftoverskud, at flyet kan starte lodret. I denne
                tilstand som betegnes hover<strong>,</strong>&nbsp;er True Air
                Speed 0m/s. Flyet tilf&oslash;res kun beliggenhedsenergi. Man
                g&oslash;r klar til vandret start i f.eks. 20 meters
                h&oslash;jde.
              </li>
              <li>
                <strong>Outbound transition:</strong>&nbsp;Flyet skal nu skifte
                tilstand fra hover til at flyve hurtigt frem, Fast Forward
                Flying forkortet FFF. Der tilf&oslash;res nu ogs&aring;
                bev&aelig;gelsesenergi. Selve skiftet betegnes outbound
                transition og svarer til, at du starter p&aring; en normal
                startbane. Man&oslash;vren foretages mod vinden.
              </li>
              <li>
                <strong>FFF</strong>: Flyet flyver nu som en normal fastvinge
                flyver. Opdriften stammer kun fra vingen.
              </li>
              <li>
                <strong>Inbound transition:</strong>&nbsp;Ved afslutningen af
                FFF skal der fjernes bev&aelig;gelsesenergi fra flyet. Det
                bremses op af luftmodstanden s&aring;ledes at TAS bliver 0m/s.
                Man taler om inbound transition og der &rdquo;landes&rdquo;
                altid mod vinden. Bem&aelig;rk at flyet flyver bagl&aelig;ns i
                forhold til jorden, hvis TAS er 0m/s, og det bl&aelig;ser.
              </li>
              <li>
                <strong>Hover down:</strong>&nbsp;Landingen mod jorden foretages
                ved en langsom nedstigning, man hover atter. Se fig. 1.
              </li>
            </ol>
            <p>
              <img src={fig4} />
              <br />
              Fig. 1
            </p>
            <p>
              <strong>SFF</strong>: VTOL flyet skal ogs&aring; kunne flyve
              stabilt mellem hover tilstanden og FFF tilstanden. Denne tilstand
              kaldes for Slow Forward Flying forkortet SFF. Opdriften stammer
              &frac12; fra hover og &frac12; fra fastvingen. Man har skabt en
              hybrid i mellem de to konstruktioner. Man kan iagttage, at
              opdriften fra fastvingen tiltager efter h&aring;nden som opdriften
              fra hover aftager, s&aring;ledes at opdriften er konstant ved
              outbound transition. Det omvendte sker ved inbound transition. Se
              fig. 2. Flyet kan ogs&aring; indtage alle tilstande mellem Hover
              og FFF.
            </p>
            <p>
              <strong>Taxik&oslash;rsel:&nbsp;</strong>Det vil v&aelig;re
              &oslash;nskeligt at VTOL flyet ogs&aring; kan taxie.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig. 2
            </p>
            <h2>Valg af konceptet Vektor 3</h2>
            <p>
              I min tidligere artikel om multirotor fly, bragt i Modelflyvenyt
              Nr. 3 2017, var jeg inde p&aring; at bygge en quadcopter med en
              femte motor, omkring en canard konstruktion inspireret af Amazons
              drone til vare- udbringning. Her endte jeg med forskellige
              ul&oslash;ste problemer, som gjorde at jeg blev n&oslash;dt til at
              starte forfra. I det indledende arbejde unders&oslash;gte jeg,
              hvad der fandtes af Flight Controllere, som kunne l&oslash;se
              opgaven. Valget faldt p&aring; Hobby Kings KK2 udstyret med Open
              Aero softvare, som er specielt udviklet til VTOL fly. En stor
              hj&aelig;lp var, at der fandtes en manual, som beskrev hele det
              teoretiske og praktiske grundlag for VTOL teknikken.
            </p>
            <p>
              &nbsp;Se :&nbsp;
              <Link
                target="_blank"
                href="https://www.rcgroups.com/forums/showthread.php?1972686-OpenAeroVTOL-with-transitional-mixers-(perfect-for-VTOLs)"
              >
                https://www.rcgroups.com/forums/showthread.php?1972686-OpenAeroVTOL-with-transitional-mixers-(perfect-for-VTOLs)
              </Link>
            </p>
            <p>
              Med hensyn til modellen blev l&oslash;sningen en tricopter
              kombineret med en tomotoret fastvinge flyver. Ideen var, at man
              kunne dreje de to forreste motorer samtidig med, at man mindskede
              omdrejningerne p&aring; den bagerste motor for til sidst at stoppe
              den helt, n&aring;r flyet fl&oslash;j FFF. Se fig. 3.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig. 3
            </p>
            <p>
              En vigtig ting er, at tyngdepunktet for de to fly typer skal ligge
              samme sted. Vi pr&oslash;ver med et eksempel, hvor flyet vejer
              2100g. Tyngdepunktet for tricopteren bestemmes af at de to
              forreste motorer kun har den halve moment arm i forhold til den
              bagerste motor. (2 x 700g x 20cm = 700g x 40cm = 28000.) Se Fig.
              4.
            </p>
            <p>
              <img src={fig7} />
              <br />
              Fig. 4
            </p>
            <p>
              Fastvingeflyet tegnes s&aring;ledes at dette tyngdepunkt ligger 4
              cm foran fastvinge flyets neutralpunkt, derved bliver
              fastvingeflyet aerodynamisk stabilt. Se evt. Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt2.dk/modelflyvenyt2/2014/Modelflyvenyt%204-2014.pdf"
              >
                nr. 4 2014
              </Link>{" "}
              eller&nbsp;
              <Link
                target="_blank"
                href="https://elflytec.dk/Grundviden/Bestemmelse-af-tyngdepunkt"
              >
                Bestemmelse af tyngdepunkt
              </Link>{" "}
              Rent praktisk er der fra starten taget h&oslash;jde for at kunne
              justere tyngdepunktet ind v. hj. af batteriets placering, idet det
              ville v&aelig;re t&aring;beligt at anvende bly. Ydermere skal man
              kunne anvende 3 forskellige batterist&oslash;rrelser nemlig
              2200mA/h, 3300mA/h og 4200mA/h alle p&aring; 4S, samt fremtidige
              ikke kendte batterier. F&oslash;rende konsulenthuse mener at
              batteriers Watt time ydelse pr. kg. vil blive fordoblet i 2022 og
              tredoblet i 2025. Udvikling drives af et globalt kapl&oslash;b i
              automobilindustrien om at vinde markedet for elektriske biler.{" "}
            </p>
            <p>
              De to forreste motorer skal kunne drejes n&oslash;jagtig 90
              grader, for at kunne g&aring; fra hover til FFF. Dette giver
              anledning til forskellige overvejelser omkring den mekaniske
              konstruktion. I hover l&oslash;fter hver motor ca. 700g, s&aring;
              den statiske belastning p&aring; et enkelt leje i drejemekanismen
              bliver ca. 350g.{" "}
            </p>
            <p>
              Fordi en trikopter har et ulige antal rotorer, opst&aring;r der et
              resulterende drejmoment omkring yaw-aksen. Dette drejmoment
              modvirkes ved at tilte den bagerste rotor. Da denne tiltning
              styres af en servo, giver dette mulighed for at f&aring;
              trikopteren til at rotere begge veje omkring yaw aksen.
            </p>
            <h2>
              Beregninger, m&aring;linger, pr&oslash;ver og valg af komponenter
            </h2>
            <p>
              Kan tankerne omkring Vektor3 gennemf&oslash;res i praksis? Her
              starter processen med at foretage simulation i programmet i eCalc
              s xcopter program. Programmet kan hurtigt vise, hvad der sker,
              hvis man &aelig;ndrer v&aelig;gten. Programmet bliver ikke
              installeret p&aring; din Windows computer, men du k&oslash;ber dig
              tid til at k&oslash;re p&aring; eCalcs server i skyen. Det koster
              2,5 US dollar for 30 dage. Du kan teste programmet uden at betale,
              men med begr&aelig;nset tilgang. Se:&nbsp;
              <Link target="_blank" href="http://www.ecalc.ch/xcoptercalc.php">
                http://www.ecalc.ch/xcoptercalc.php
              </Link>
            </p>
            <p>
              Som supplement til simulationen m&aring;tte der gennemf&oslash;res
              nogle grundl&aelig;ggende m&aring;linger og beregninger for at
              afklare, om planen var realistisk.
            </p>
            <ul>
              <li>Hvor meget kunne de 3 motorer l&oslash;fte?</li>
              <li>Hvilke elektroniske komponenter skulle v&aelig;lges?</li>
              <li>Hvor meget v&aelig;gt ville der blive til selve modellen?</li>
              <li>Hvor store batterier ville det ende med?</li>
            </ul>
            <p>
              Der startes i en m&aring;lestand med drivlinien som best&aring;r
              af:
            </p>
            <ul>
              <li>En variabel str&oslash;mforsyning 0-16V, 0-40A. </li>
              <li>
                En ESC af typen FVT Little Bee 30A BLheli p&aring; kun 11g.
                Bem&aelig;rk at v&aelig;gten er meget lav for en ESC, som kan
                klare 3 til 6S max 30A. Den er uden BEC.
              </li>
              <li>
                Motoren som er af typen Dualsky XM2834MR-9 forsynet med en
                Graupner 10x5 propel.
              </li>
            </ul>
            <p>F&oslash;lgende bliver m&aring;lt:</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Ubat</p>
                    <p>Volt</p>
                  </TableCell>
                  <TableCell>
                    <p>Li-Po celle sp&aelig;nding</p>
                    <p>Volt</p>
                  </TableCell>
                  <TableCell>
                    <p>1motor</p>
                    <p>Amp</p>
                  </TableCell>
                  <TableCell>
                    <p>1motor</p>
                    <p>Tryk</p>
                  </TableCell>
                  <TableCell>
                    <p>1motor</p>
                    <p>Watt</p>
                  </TableCell>
                  <TableCell>
                    <p>3motorer</p>
                    <p>Amp ialt</p>
                  </TableCell>
                  <TableCell>
                    <p>3motorer</p>
                    <p>Samlet tryk</p>
                  </TableCell>
                  <TableCell>
                    <p>3motorer</p>
                    <p>watt</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>16Volt</p>
                  </TableCell>
                  <TableCell>
                    <p>4,0V</p>
                  </TableCell>
                  <TableCell>
                    <p>21,7A</p>
                  </TableCell>
                  <TableCell>
                    <p>1270g</p>
                  </TableCell>
                  <TableCell>
                    <p>347W</p>
                  </TableCell>
                  <TableCell>
                    <p>65,1A</p>
                  </TableCell>
                  <TableCell>
                    <p>3810g</p>
                  </TableCell>
                  <TableCell>
                    <p>1041W</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>12,4Volt</p>
                  </TableCell>
                  <TableCell>
                    <p>3,1V</p>
                  </TableCell>
                  <TableCell>
                    <p>15,8A</p>
                  </TableCell>
                  <TableCell>
                    <p>934g</p>
                  </TableCell>
                  <TableCell>
                    <p>196W</p>
                  </TableCell>
                  <TableCell>
                    <p>47,4A</p>
                  </TableCell>
                  <TableCell>
                    <p>2802g</p>
                  </TableCell>
                  <TableCell>
                    <p>588W</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Som det ses, falder det statiske tryk fra 3 motorer fra 3,8kg til
              2,8kg i aflade processen af 4S batteriet. Da det er
              n&oslash;dvendigt at have et overskud af tryk i den sidste del af
              flyvningen, hvor der skal hovers ned, er svaret, at modellens
              totale v&aelig;gt skal v&aelig;re ca. 2kg.
            </p>
            <p>
              For at f&aring; s&aring; god en ydeevne som overhovedet mulig,
              skal Vektor 3 bygges s&aring; let, som det kan lade sig
              g&oslash;re. S&aring; jeg starter med at foretage
              styrkem&aring;linger p&aring; forskellige materialer. Se billede 4
              og skema.
            </p>
            <p>
              <img src={fig8} />
              <br />
              BIllede 4.&nbsp;&rdquo;M&aring;leopstilling&rdquo; til
              styrkem&aring;ling.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Materiale</p>
                  </TableCell>
                  <TableCell>
                    <p>V&aelig;gt pr meter.</p>
                  </TableCell>
                  <TableCell>
                    <p>Brudstyrke</p>
                  </TableCell>
                  <TableCell>
                    <p>Bem&aelig;rkninger</p>
                  </TableCell>
                  <TableCell>
                    <p>Konklusion</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Balsa</p>
                    <p>3x3mm</p>
                  </TableCell>
                  <TableCell>
                    <p>1,03g</p>
                  </TableCell>
                  <TableCell>
                    <p>Ikke m&aring;lbar meget lav</p>
                  </TableCell>
                  <TableCell>
                    <p>Kn&aelig;kker let</p>
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Fyr3x3mm</p>
                  </TableCell>
                  <TableCell>
                    <p>6,22g</p>
                  </TableCell>
                  <TableCell>
                    <p>0,43kg</p>
                  </TableCell>
                  <TableCell>
                    <p>Kn&aelig;kker let</p>
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Kulr&oslash;r rund 3mm &Oslash;</p>
                  </TableCell>
                  <TableCell>
                    <p>5,90g</p>
                  </TableCell>
                  <TableCell>
                    <p>1,56kg</p>
                  </TableCell>
                  <TableCell>
                    <p>Spliner op i bruddet.</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Det runde kulr&oslash;r vejer ca. det samme som fyrlisten,
                      men er 3&frac12; gang st&aelig;rkere.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Kulr&oslash;r firkantet</p>
                    <p>3x3mm</p>
                  </TableCell>
                  <TableCell>
                    <p>7,82g</p>
                  </TableCell>
                  <TableCell>
                    <p>3,1kg</p>
                  </TableCell>
                  <TableCell>
                    <p>Spliner op i brudet.</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Det firkantede kulr&oslash;r er ca.30 % tungere end det
                      runde kulr&oslash;r, men har dobbelt s&aring; stor styrke.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Kulr&oslash;r firkantet</p>
                    <p>4x4mm</p>
                  </TableCell>
                  <TableCell>
                    <p>10,38g</p>
                  </TableCell>
                  <TableCell>
                    <p>8,6kg</p>
                  </TableCell>
                  <TableCell>
                    <p>Spliner op i brudet.</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Det firkantede kulr&oslash;r er et super fin
                      byggemateriale til bygning af kroppe.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Kulstang 3mm &Oslash;</p>
                  </TableCell>
                  <TableCell>
                    <p>10,82g</p>
                  </TableCell>
                  <TableCell>
                    <p>Over2,3kg&nbsp;</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan tr&aelig;kkes igennem ved 2,3kg. Der bliver ingen
                      varige deformationer
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kulstangen som er massiv har den dobbelte v&aelig;gt af
                      kulr&oslash;ret, men kn&aelig;kkede ikke ved testen.
                      Stangen har en h&oslash;j brudstyrke. Stangen kan bruges
                      til understelskonstruktioner.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Pianotr&aring;d</p>
                  </TableCell>
                  <TableCell>
                    <p>55,86g 3mm&oslash;</p>
                  </TableCell>
                  <TableCell>
                    <p>3,7kg</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan tr&aelig;kkes igennem ved 2,3kg. Der bliver varige
                      deformationer.
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>Er alt for tung.</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Kulmaterialerne er k&oslash;bt lokalt hos IC-Communication,
              Folehaven 12, K&oslash;benhavn.
            </p>
            <p>
              Derefter opstilles et v&aelig;gt regnskab med valg af komponenter
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Komponent</p>
                  </TableCell>
                  <TableCell>
                    <p>V&aelig;gt</p>
                  </TableCell>
                  <TableCell>
                    <p>Antal</p>
                  </TableCell>
                  <TableCell>
                    <p>V&aelig;gt i alt</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Jeti modtager rex 6 2,4GHz</p>
                  </TableCell>
                  <TableCell>
                    <p>15g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>15g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Flight Controller KK2 Hobby King</p>
                  </TableCell>
                  <TableCell>
                    <p>21g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>21g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Str&oslash;mfordeler</p>
                  </TableCell>
                  <TableCell>
                    <p>8g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>8g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Dualski switch mode str&oslash;mforsyning 3A</p>
                  </TableCell>
                  <TableCell>
                    <p>10g</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>20g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>
                      Jeti MUI 75 M&aring;ler til telemetri af
                      str&oslash;mforbrug
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>15g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>15g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Batteri 4S 2200mA/h</p>
                  </TableCell>
                  <TableCell>
                    <p>248g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>248g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Hovedkrop</p>
                  </TableCell>
                  <TableCell>
                    <p>124g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>124g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>N&aelig;sehjul incl. analog servo</p>
                  </TableCell>
                  <TableCell>
                    <p>42g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>42g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>
                      Drejeoph&aelig;ng til hale motor inkl. motor, ESC og
                      digitalservo
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>125g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>125g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Sidekroppe</p>
                  </TableCell>
                  <TableCell>
                    <p>110g</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>220g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Hoved understel</p>
                  </TableCell>
                  <TableCell>
                    <p>22g</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>44g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Sideror</p>
                  </TableCell>
                  <TableCell>
                    <p>19g</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>38g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Motorrotateenhed&nbsp;inkl. ESC/motor/propel</p>
                  </TableCell>
                  <TableCell>
                    <p>137g</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>274g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Mellemvinge 16cm</p>
                  </TableCell>
                  <TableCell>
                    <p>45g</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>90g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Ydervinger 2stk 45cm inkl. digital servo</p>
                  </TableCell>
                  <TableCell>
                    <p>135g</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>270g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Stabilisator inkl. digitalservo</p>
                  </TableCell>
                  <TableCell>
                    <p>90g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>90g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Kulr&oslash;r til samling 2stk</p>
                  </TableCell>
                  <TableCell>
                    <p>24g</p>
                  </TableCell>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>24g</p>
                  </TableCell>
                </TableRow>
                <tr />
                <TableRow>
                  <TableCell>
                    <p>Total v&aelig;gt</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <p>1668g</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Motorer, regulatorer, servoer samt Jeti udstyr er k&oslash;bt i
              RC-Netbutik.
            </p>
            <p>
              Konklusion blev, at der kan l&oslash;ftes minimum 2,8Kg.
              V&aelig;gtberegningerne viser, at maksimal v&aelig;gten p&aring;
              2,1kg kan overholdes, hvorefter tegning og bygning blev
              p&aring;begynd.
            </p>
            <h2>Bygning af Vektor3</h2>
            <p>
              <img src={fig9} />
              <br />
              Billede 5
            </p>
            <p>
              Rent byggeteknisk best&aring;r flyet af en hovedkrop, to
              sidekroppe, to mellemvinger, to ydervinger, to sideror, et
              h&oslash;jderor samt 3 understel i alt 13 forskellige dele, som
              bygges hvert for sig. Dette giver en stor frihed til at
              &aelig;ndre tingene undervejs. Se billede 5.
            </p>
            <p>
              Det hele samles v.hj.af 2stk. 1 meter lange kulr&oslash;r. Vektor3
              holdes sammen af en fjeder, som ligger inde i det tykkeste
              kulr&oslash;r ved den ene vingetip. I fjederens ene ende er der
              bundet en snor, som tr&aelig;kkes gennem kulr&oslash;ret til den
              anden vingetip. Her bliver snoren l&aring;st, ved at man
              s&aelig;tter en tandstik gennem et lille &oslash;je. Det er nu
              muligt at fjerne ydervingerne, s&aring; Vektor3 ikke
              beh&oslash;ver en stor bil.&nbsp;
            </p>
            <p>
              De firkantede kulr&oslash;r giver ekstrem st&aelig;rke og lette
              konstruktioner, som limes med cyan lim eller to-komponent 24
              timers epoxy lim. Sidstn&aelig;vnte limninger varmeh&aelig;rdes
              til opn&aring;elses af maksimal styrke. Til understel anvendes
              massive kulst&aelig;nger og ikke kulr&oslash;r. Hovedhjulenes
              understel bliver direkte koblet til kulr&oslash;ret, som s&aring;
              direkte optager de mekaniske kr&aelig;fter ved landingen.
              Understellet holdes kun af en lille nylonskrue og kan nemt
              fjernes, hvis det skulle kn&aelig;kke.
            </p>
            <p>
              Alle kulmaterialer sk&aelig;res over med en Dremmel
              sk&aelig;reskive og slibes til p&aring; en slibemaskine. Der
              anvendes kraftig sug for ikke at f&aring; kulst&oslash;v ud i
              luften.
            </p>
            <h2>Drejbare motorer</h2>
            <p>
              <img src={fig10} />
              <br />
              Billede 6
            </p>
            <p>
              <img src={fig11} />
              <br />
              Billede 7
            </p>
            <p>
              Som det fremg&aring;r af Vektor 3 konceptet var det
              n&oslash;dvendigt at dreje de to forreste motorer 90 grader. Se
              billede 6 og 7. Efter flere pr&oslash;ver sluttede jeg med at
              montere motoren direkte p&aring; siden af den digitale servo. Det
              er n&oslash;dvendigt med en digital servo, fordi den har maksimal
              holdekraft ved stor indstillings- n&oslash;jagtighed. Servohornet
              monteres fast p&aring; kroppen i den ene side med 4 skruer.
              P&aring; den anden side monteres en umbracoskrue, som g&aring;r
              ind i et kugleleje. De 3 teflonledninger til motoren vikles
              omkring skruen som en lille spole. Servoledningen aflastes
              omhyggeligt for ikke at kn&aelig;kke ved gentagne drejninger af
              motoren. Det er sv&aelig;rt at afg&oslash;re, hvor mange
              drejninger konstruktionen kan udf&oslash;re, uden at der
              kn&aelig;kker en ledning, til geng&aelig;ld undg&aring;s
              tr&aelig;kst&aelig;nger og slup. De to servoer er koblet til et
              Y-kabel, s&aring;ledes at motorerne altid f&oslash;lges ad. 90
              grader drejningen trimmes i Flight Controllerens software.
            </p>
            <p>
              <img src={fig12} />
              <br />
              Billede 8&nbsp;Vektor3 klar til det omfattende
              pr&oslash;veflyvningsprogram.
            </p>
            <p>2. del omhandler Flight Controlleren og dens software.</p>
            <p>3. del omhandler det omfattende testflyveprogram.</p>
            <h1>VTOL teknik - Del 2: Flight Controllerens hard- og software</h1>
            <p>
              Anden del omhandler fly-by-wire teknikken, som er en
              n&oslash;dvendig foruds&aelig;tning for at VTOL flyet overhovedet
              kan flyve.
            </p>
            <h2>Fly-by-wire princip</h2>
            <p>
              Der skal anvendes en Flight Controller (FC) forsynet med
              specialiseret VTOL firmware, som kan h&aring;ndtere en effektiv
              regulering af de forskellige faser, VTOL maskinen skal kunne
              gennemflyve.
            </p>
            <p>
              Lad os f&oslash;rst se p&aring; den grundl&aelig;ggende
              regulerings ide, som g&aelig;lder for roll- og pitch aksen. Se
              fig. 1
            </p>
            <p>
              <img src={fig13} />
              <br />
              Fig. 1
            </p>
            <p>
              R&oslash;d feed-back loop: Styrepinden er i midterstilling. Flyets
              vinger er vandrette. Nu rammes flyet af en forstyrrende kraft, som
              bringer flyet til at rulle 30 grader omkring roll-aksen. Denne
              p&aring;virkning registreres af stillingssensoren, som
              fort&aelig;ller, at flyet er rullet 30 grader. Beskeden sendes til
              venstre p&aring; tegningen og ned til et sammenlignings punkt
              (cirkel med kryds), hvor de 30 graders h&aelig;ldning bliver
              sammenlignet med referencen 0 grader, alts&aring; vandret. Som
              resultat af sammenligningen kommer et korrektionssignal p&aring;
              30 grader AL, som sendes videre gennem en volume regulering og et
              nyt additionspunkt til en signaloms&aelig;tter. Denne frembringer
              en analog styrepuls som via en servo eller ESC med motor,
              f&aring;r en regulator, til at bringe flyet i en stabil tilstand
              med vandrette vinger. Regulatoren er en propel eller/og
              balanceklapper. Flyet er nu automatisk stabiliseret uden pilotens
              indblanding, man taler om Auto Level. (AL){" "}
            </p>
            <p>
              Udover denne funktion modtages yderligere et stabiliserende signal
              fra et accelerometer. I &oslash;verste venstre hj&oslash;rne er
              vist en masse som er oph&aelig;ngt i fjedre. Denne masse bliver
              p&aring;virket af forstyrrelsen og sender et kortvarigt
              korrigerende signal til sumpunktet som l&aelig;gger sig sammen med
              AL signalet. Her anvendes ikke feed-back, men accelerometeret kan
              registrere pludselige bev&aelig;gelser.{" "}
            </p>
            <p>
              Bl&aring; feed-back loop: Her forestiller vi os, at piloten
              &oslash;nsker at dreje vingerne 30 grader omkring roll-aksen. Nu
              er det styrepinden, som er reference. Denne er 30 grader, men
              flyets stillingssensor m&aring;ler 0 grader til at begynde med.
              Reguleringssystemet begynder derefter at dreje flyet, til det
              h&aelig;lder 30 grader, hverken mere eller mindre. Betjeningen
              overruler AL reguleringen.{" "}
            </p>
            <p>
              Ved hj&aelig;lp af volumereguleringen er det muligt at bestemme,
              hvor stor en indflydelse styrepindens Pind signaler har, i forhold
              til AL signalet. Hvis Pind signalet er for lille i forhold til AL,
              bliver flyet s&aring; stabilt, at det n&aelig;sten ikke kan dreje.
              Omvendt kan man skrue s&aring; meget ned for AL signalet, at AL
              funktionen oph&oslash;rer, og VTOL flyet styrter ned. Dette er
              vigtigt at vide i pr&oslash;veflyvningsprogrammet.&nbsp;
            </p>
            <p>
              Disse fly-by-wire funktioner virker ogs&aring; p&aring;
              fuldst&aelig;ndig samme m&aring;de p&aring; pitch aksen. Derved
              skabes et fly, som virker helt anderledes end en normal flyver.
              Vektor 3 er udstyret med en all-moving-tail, som via reguleringen
              s&oslash;rger for, at flyet altid er vandret n&aring;r det flyver
              FFF. Man har ikke l&aelig;ngere en fast vinkelforskel mellem
              haleplanet og vingen. Dette er en foruds&aelig;tning for
              transition mellem hover og FFF.{" "}
            </p>
            <p>
              N&aring;r det drejer sig om yaw aksen, har jeg valgt kun at
              foretage en ureguleret styring af b&aring;de tilt servoen til
              halemotoren og n&aelig;sehjulet. Vektor3 er konstrueret til enten
              at holde stille (TAS = 0m/sek) eller flyve frem. Da den er
              forsynet med halefinner vil den altid dreje op imod den
              indkommende luft ligesom en vejrhane, fordi lateralcenteret ligger
              bag tyngdepunktet. Med hensyn til gyrostabilisering af
              n&aelig;sehjulet er det ikke n&oslash;dvendigt, da det kun
              anvendes til taxik&oslash;rsel.
            </p>
            <h2>Flight Controlerens hardware</h2>
            <p>
              <img src={fig14} />
              <br />
              Billede 1
            </p>
            <p>
              Den valgte hardware er Hobby Kings KK2 board. Se billede 2. Til
              venstre ses 5 tilslutninger til modtageren. Til h&oslash;jre er
              der 8 output, som er til r&aring;dighed til styring af VTOL flyet.
              Nederst fire programmerings knapper til direkte &aelig;ndring af
              programmet. Menuerne kan afl&aelig;ses p&aring; en lille belyst
              LCD sk&aelig;rm. Prisen er ca. 190 kr., t&aelig;nk p&aring; hvor
              billigt hardware er blevet. Se billede 1.
            </p>
            <p>
              &Oslash;verst p&aring; kortet ses en lille chip p&aring; 4x4mm,
              som indeholder den 3-aksede gyro samt det 3-aksede accelerometer .
              Denne er en s&aring;kaldt motion tracking chip, som er udviklet
              til at f&oslash;lge bev&aelig;gelser og tings stillinger i et
              3-dimentionelt rum. Man anvender MEMS teknik, en forkortelse for
              Micro Elektro Mekanisk Systemer. Chippen finder anvendelse i en
              m&aelig;ngde produkter som for eksempel, tablets, smartphones,
              biler til udl&oslash;sning af airbags og antiudskridnings systemer
              m.m. og er specificeret til at kunne t&aring;le 10000g.
            </p>
            <p>&nbsp;Vigtig for os er:</p>
            <ul>
              <li>At den kan huske stillingen vandret.</li>
              <li>
                At den kan m&aring;le, hvordan den vender i rummet med stor
                n&oslash;jagtighed i 3 planer.
              </li>
              <li>
                At den kan m&aring;le &aelig;ndringer i hastighed,
                alts&aring;&nbsp;acceleration, i 3 planer.
              </li>
            </ul>
            <p>
              Den er s&aring;ledes en foruds&aelig;tning for at foretage det
              teknologispring, som det er at holde et VTOL fly i luften.
            </p>
            <p>
              MEMS gyroen er baseret p&aring;, at en elastisk oph&aelig;ngt
              kapacitet kan p&aring;virkes af jordens corioliskraft. N&aring;r
              det micromekaniske system drejes, opst&aring;r en lille kapacitets
              &aelig;ndring, som p&aring;virker en oscillatorfrekvens. Man
              f&aring;r p&aring; denne m&aring;de en drejebev&aelig;gelse omsat
              til noget m&aring;lbart, som kan digitaliseres og sendes videre
              til microcontrolleren ATMEL Mega 644 til udf&oslash;relse af de
              n&oslash;dvendige beregninger under flyvningen. Microcontrolleren
              indeholder en 64K byte hukommelse. Denne indeholder ved
              k&oslash;bet programmel til forskellige multirotor modeller, som
              man desv&aelig;rre ikke kan anvende til VTOL fly. Det er derfor
              n&oslash;dvendigt at br&aelig;nde ny Open Aero softvare, som er
              specielt udviklet til VTOL fly, ind i KK2en. Denne proces er
              n&aelig;rmere beskrevet i vejledningen for Open-Aero-VTOL firmware
              til KK2 som kan findes her:{" "}
              <Link
                target="_blank"
                href="https://www.rcgroups.com/forums/showthread.php?1972686-OpenAeroVTOL-with-transitional-mixers-(perfect-for-VTOLs)"
              >
                https://www.rcgroups.com/forums/showthread.php?1972686-OpenAeroVTOL-with-transitional-mixers-(perfect-for-VTOLs)
              </Link>
            </p>
            <h2>Systemdiagram</h2>
            <p>
              <img src={fig15} />
              <br />
              Fig. 2
            </p>
            <p>
              Systemdiagrammet fig. 2, viser hvordan KK2 er koblet op i Vektor3.
              Senderen har forbindelse med en Jeti Rex 6 kanal modtager, som er
              forbundet til KK2 som vist. Ved ops&aelig;tningen af systemet skal
              man starte med, at senderen er nulstillet, s&aring;ledes at der
              ikke er nogen form for programmering i senderen, med undtagelse af
              EXPO, det g&aelig;lder ogs&aring; trimknapper. Det samme
              g&aelig;lder Jeti modtageren, undtaget failsafe som programmeres
              til FFF og slukkede motorer. &Aring;rsagen til disse nulstillinger
              er, at al den digitale signalbearbejdning kun skal finde sted i
              KK2. Det vi v&aelig;re helt uoverskueligt, om man f.eks.
              &aelig;ndrede pitch v&aelig;rdier 3 forskellige steder. Som det
              ses p&aring; fig. 2 er udgangen 1 til 3 er forbundet til ESCerne,
              som styrer de 3 motorer. De &oslash;vrige 5 udgange er forbundet
              til servoer som vist.{" "}
            </p>
            <p>
              Batterisp&aelig;ndingen fra 4S Li-Poen tilf&oslash;res to
              switch-mode power-supplyes, som dels forsyner modtageren og KK2
              computeren med 5V, dels servoerne. &Aring;rsagen til, at servoerne
              har deres egen PS, er at n&aring;r en servo starter opst&aring;r
              der et kortvarigt str&oslash;mst&oslash;d, som belaster
              forsyningssp&aelig;ndingen. Helt galt bliver det, hvis flere
              servoer starter samtidigt. Sp&aelig;ndingen kan blive s&aring;
              lav, at computeren g&aring;r i st&aring;, f&aring;r et
              s&aring;kaldt braun-out, hvilket kan medf&oslash;re en
              nedstyrtning. Derfor anvendes 2 adskilte power-supplyes.{" "}
            </p>
            <p>
              Det samlede forbrug m&aring;les via en Jeti MUI sensor. Resultatet
              (mA/h, str&oslash;mforbrug og batterisp&aelig;nding) telemetreres
              tilbage til piloten. Denne
              &rdquo;br&aelig;ndstof&rdquo;m&aring;ler er vigtig fordi VTOL
              flyet har et yderst variabelt str&oslash;mforbrug, samt at flyet
              styrter ned, hvis det k&oslash;rer t&oslash;r for str&oslash;m
              under hover down fasen.{" "}
            </p>
            <p>
              De forskellige forbindelser, som er vist inde i KK2 p&aring; fig.
              2, er etableret via softwaren.
            </p>
            <h2>Software arkitektur</h2>
            <p>
              <img src={fig16} />
              <br />
              Fig. 3
            </p>
            <p>
              Princippet i software er, at man kan programmere tricopteren og
              fastvingeflyet i to adskilte programmer. Her bestemmes blandt
              andet feed-back gains P og I v&aelig;rdier. Tricopterens program
              betegnes P1. Fastvingeflyets program betegnes P2. Ved hj&aelig;lp
              af den proportionale mixer er det muligt at blande de to
              programmer sammen i forholdet P1.0, som er ren hover, P1.17,
              P1,33, P1.50, som er en blanding mellem &frac12; tricopter og
              &frac12; fastvinge, P1,67, P1,83, og P2.0, der er det rene
              fastvinge fly. Mixeren styres af transition control kanalen, som
              er senderens 5 kanal. Transition kan enten styres proportionalt
              uden tidsforsinkelse eller med en 3 stillingsomskifter. Hvis man
              anvender 3 stillingsomskifteren, er det muligt at anvende
              transition timere med forskellige tider til inbound og outbound
              funktionen. Se Fig. 3.&nbsp;
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <p>Funktion</p>
                  </TableCell>
                  <TableCell>
                    <p>M1</p>
                  </TableCell>
                  <TableCell>
                    <p>M2</p>
                  </TableCell>
                  <TableCell>
                    <p>M3</p>
                  </TableCell>
                  <TableCell>
                    <p>Tilt M3</p>
                  </TableCell>
                  <TableCell>
                    <p>Ail</p>
                    <p>servos</p>
                  </TableCell>
                  <TableCell>
                    <p>Pitch</p>
                    <p>&nbsp;servo</p>
                  </TableCell>
                  <TableCell>
                    <p>Nose</p>
                    <p>Wheel</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor</p>
                    <p>1+2 tilt</p>
                  </TableCell>
                  <TableCell>
                    <p>Bruger tildelt funktion</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>Setting for pin</p>
                  </TableCell>
                  <TableCell>
                    <p>Out1</p>
                  </TableCell>
                  <TableCell>
                    <p>Out2</p>
                  </TableCell>
                  <TableCell>
                    <p>Out3</p>
                  </TableCell>
                  <TableCell>
                    <p>Out4</p>
                  </TableCell>
                  <TableCell>
                    <p>Out5</p>
                  </TableCell>
                  <TableCell>
                    <p>Out6</p>
                  </TableCell>
                  <TableCell>
                    <p>Out7</p>
                  </TableCell>
                  <TableCell>
                    <p>Out8</p>
                  </TableCell>
                  <TableCell>
                    <p>Udgange p&aring; KK2</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>Program</p>
                  </TableCell>
                  <TableCell>
                    <p>P11</p>
                  </TableCell>
                  <TableCell>
                    <p>P12</p>
                  </TableCell>
                  <TableCell>
                    <p>P13</p>
                  </TableCell>
                  <TableCell>
                    <p>P14</p>
                  </TableCell>
                  <TableCell>
                    <p>P15</p>
                  </TableCell>
                  <TableCell>
                    <p>P16</p>
                  </TableCell>
                  <TableCell>
                    <p>P17</p>
                  </TableCell>
                  <TableCell>
                    <p>P18</p>
                  </TableCell>
                  <TableCell>
                    <p>Program nummer i KK2</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>3</p>
                  </TableCell>
                  <TableCell>
                    <p>Device</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor</p>
                  </TableCell>
                  <TableCell>
                    <p>Dservo</p>
                  </TableCell>
                  <TableCell>
                    <p>Dservo</p>
                  </TableCell>
                  <TableCell>
                    <p>Dservo</p>
                  </TableCell>
                  <TableCell>
                    <p>Aservo</p>
                  </TableCell>
                  <TableCell>
                    <p>Dservo</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      V&aelig;lger mellem ESC (motor) digital eller analog
                      servo.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>4</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Thr. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>100</p>
                  </TableCell>
                  <TableCell>
                    <p>100</p>
                  </TableCell>
                  <TableCell>
                    <p>100</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer volumen fra throttle kanalen ved hover</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>5</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Thr. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>100</p>
                  </TableCell>
                  <TableCell>
                    <p>100</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer volumen fra throttle kanalen ved FFF</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>6</p>
                  </TableCell>
                  <TableCell>
                    <p>Thr. curve</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>line&aelig;r</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer energi fordeling i transition tilstanden.</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>7</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Ali. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>+30</p>
                  </TableCell>
                  <TableCell>
                    <p>-30</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>-50</p>
                  </TableCell>
                  <TableCell>
                    <p>50</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer volumen fra balance kanalen ved hover</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>8</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Ali. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>90</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer volumen fra balance kanalen ved FFF</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>9</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Ele. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>-15</p>
                  </TableCell>
                  <TableCell>
                    <p>-15</p>
                  </TableCell>
                  <TableCell>
                    <p>+30</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>30</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Bestemmer volumen fra h&oslash;jderor kanalen ved hover
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>10</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Ele. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>50</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer volumen fra h&oslash;jderorkanalen ved FFF</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>11</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Rud. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>75</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer volumen fra sideror kanalen ved hover</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>12</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Rud. volume</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>100</p>
                  </TableCell>
                  <TableCell>
                    <p>0</p>
                  </TableCell>
                  <TableCell>
                    <p>Bestemmer volumen fra sideror kanalen ved FFF</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>13</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Roll gyro</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>Kan sl&aring; roll gyro til eller fra ved hover</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>14</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Roll gyro</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>Kan sl&aring; roll gyro til eller fra ved FFF</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>15</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Pitch gyro</p>
                  </TableCell>
                  <TableCell>
                    <p>Scaled</p>
                  </TableCell>
                  <TableCell>
                    <p>Scaled</p>
                  </TableCell>
                  <TableCell>
                    <p>Scaled</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>Kan sl&aring; pitch gyro til eller fra ved hover</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>16</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Pitch gyro</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>Kan sl&aring; pitch gyro til eller fra ved FFF</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>17</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Yaw gyro</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>Scaled</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan sl&aring; yaw gyro til eller fra ved hover&nbsp;
                      <strong>Scaled</strong>
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>18</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Yaw gyro</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>Kan sl&aring; yaw gyro til eller fra ved FFF</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>19</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Roll AL</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan sl&aring; AutoLevel stabilisering til eller fra ved
                      hover
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>20</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Roll AL</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan sl&aring; AutoLevel stabilisering til eller fra ved
                      FFF
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>21</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Pitch AL</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan sl&aring; AutoLevel stabilisering til eller fra ved
                      hover
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>22</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Pitch AL</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan sl&aring; AutoLevel stabilisering til eller fra ved
                      FFF
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>23</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Alt damp</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>on</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Kan sl&aring; h&oslash;jde d&aelig;mpning til eller fra
                      ved hover
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>24</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Alt damp</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>off</p>
                  </TableCell>
                  <TableCell>
                    <p>Skal v&aelig;re off.</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>25</p>
                  </TableCell>
                  <TableCell>
                    <p>P1 Source A</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>Gear</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      V&aelig;lger kanal 5 p&aring; rx til tiltning af motorene
                      1 og 2.{" "}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>26</p>
                  </TableCell>
                  <TableCell>
                    <p>P2 Source A</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>none</p>
                  </TableCell>
                  <TableCell>
                    <p>Gear</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      V&aelig;lger kanal 5 p&aring; rx til tiltning af motorene
                      1 og 2.{" "}
                    </p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Fig. 4 viser, hvorledes den store mixer er sat op. Her bestemmes
              funktionen af KK2`s 8 output kanaler. Se ogs&aring; Fig. 2.{" "}
            </p>
            <p>Jeg vil nu give nogle eksempler.</p>
            <p>
              Se linie 3. Det f&oslash;rste man bestemmer er, hvad det er man
              tilkobler outputtet. De f&oslash;rste 3 output tilkobles
              ESC&acute;er, alts&aring; motorer. Valget af motor (Impulsfrekvens
              200Hz) tilknytter armeringsfunktionen, som sikrer at motorerne
              ikke starter i utide, n&aring;r batteriet tilsluttes. De
              efterf&oslash;lgende er tilkoblet digitale (Impulsfrekvens 200Hz)
              eller analoge servoer (Impulsfrekvens 50Hz)
            </p>
            <p>
              Se linie 4 og 5. Bem&aelig;rk at throttle volumen for motor 3 ved
              FFF er 0. Det er her, man lukker halemotoren M3 ned n&aring;r man
              flyver FFF.
            </p>
            <p>
              Se linie 7 og 8. Her bestemmes, hvor kraftig balanceklap kanalen
              skal virke p&aring; motor 1 og 2 og p&aring; balanceklapperne.
              Bem&aelig;rk at balanceklapperne er aktive ved b&aring;de Hover og
              FFF, mens motorerne ikke m&aring; reguleres ved FFF.{" "}
            </p>
            <p>
              Se line 23 og 24. Hvis Vektor3 pludselig falder, vil virkningen
              fra accelerometeret modvirke faldet ved kortvarigt at speede alle
              3 motorer op i omdrejninger. Man vil ikke kunne holde en konstant
              h&oslash;jde, men f&aring;r en udj&aelig;vning af
              h&oslash;jde&aelig;ndringer. Denne funktion er derimod ikke
              &oslash;nskelig ved FFF.
            </p>
            <p>
              Med de viste eksempler kan du se, at det er muligt v. hj. af
              softwaren at styre Vektor3 s flyveegenskaber fuldst&aelig;ndig,
              som det &oslash;nskes. Kombinationen af KK2 og Open Aero softwaren
              er s&aring; h&oslash;jfleksibel, at du p&aring; en hvilken som
              helst VTOL fly konstruktion, kan styre transition ved en hvilken
              som helst fart, retning eller tid. Til geng&aelig;ld f&aring;r
              systemet ikke input udefra i form af GPS, magnetometer eller
              h&oslash;jdem&aring;lere d.v.s., at det udelukkende er piloten,
              som styrer flyet, alts&aring; ingen automatiseret flyvning som ved
              Eagle Tree controleren i Vektor1.
            </p>
            <p>
              Systemet kan kobles sammen med alle kendte RC-anl&aelig;g s&aring;
              som Futaba, FrSky, Spektrum, Orange, Multiplex og Jeti. Som vist
              p&aring; Fig. 2 er alle 5 kanaler fra modtageren forbundet til KK2
              med 5 mellemledninger. Dette virker fint og letter
              forst&aring;elsen af systemet, men det er mere optimalt at anvende
              seriel data transmission mellem modtageren og KK2. Jeti Rex
              modtageren kan i Mode B/UDI levere serielle data, som KK2 kan
              forst&aring;. Derved undg&aring;s, at modtagerens digitale
              information om kanalindhold og kanalr&aelig;kkef&oslash;lge skal
              omdannes til parallelle analoge 1,5mSek styrepulser for derefter
              at g&aring; den modsatte vej inde i KK2 for at f&aring; skabt den
              samme digitale information. Nu kan alt overf&oslash;res p&aring; 1
              ledning b&aring;de hurtigere og mere pr&aelig;cist. Se fig 5.
            </p>
            <p>
              <img src={fig17} />
              <br />
              Fig. 5
            </p>
            <p>
              Vi er nu klar til del 3, som gennemg&aring;r testflyveprogrammet ,
              en vigtig del af VTOL teknikken.
            </p>
            <h1>
              VTOL teknik - Del 3: Testflyveprogram, flyvevejledning og data
            </h1>
            <p>
              Del 3 gennemg&aring;r testflyveprogrammet , som er en vigtig del
              af VTOL teknikken.
            </p>
            <p>
              Det er uhyre vigtigt, at testprogrammet er tilrettelagt p&aring;
              en s&aring;dan m&aring;de, at VTOL flyet aldrig kommer i en
              situation, hvor kontrollen mistes LOC (Loss of Control) og flyet
              g&aring;r tabt. Se fig. 1
            </p>
            <p>
              <img src={fig18} />
              <br />
              Fig. 1
            </p>
            <p>
              Derfor indeholder programmet mange ganske sm&aring; trin, hvor det
              hele tiden overvejes n&oslash;je hvilke risici, som kan
              f&oslash;re til LOC, alts&aring; at holde flyet inde i den
              gr&oslash;nne kasse i Fig. 1. Da alle flyets funktioner og
              egenskaber er styret af VTOL softwaren, skal denne dokumenteres
              skriftligt, opdateret og uden fejl. Man undg&aring;r ikke at have
              dokumentation med ud p&aring; flyvepladsen. Det er vigtigt ikke at
              indf&oslash;re nye fejl ved &aelig;ndringer i programmeringen,
              fordi du nemt f&aring;r LOC. Testflyvningen er opdelt i 9 faser,
              som har fundet sted over lang tid, hvor jeg fra begyndelsen ikke
              havde sikkerhed for, at projektet ville lykkes.
            </p>
            <h2>1. Indledende testops&aelig;tning p&aring; v&aelig;rksted</h2>
            <p>
              P&aring; nuv&aelig;rende tidspunkt er der ikke bygget ydervinger
              og haleplan, fordi det ville v&aelig;re spildt arbejde, hvis
              Vektor3 blev &oslash;delagt under hovertesten.
            </p>
            <p>
              De f&oslash;rste test finder sted p&aring; v&aelig;rkstedet. Der
              startes med en motortest af hver enkelt motor.
            </p>
            <p>
              &nbsp;Under fremstillingen monteres de 3-fasede ledninger til
              motorer, s&aring;ledes at motorerne k&oslash;rer den rigtige vej
              rundt. Hvis det ikke er tilf&aelig;ldet ombyttes, som bekendt, to
              faser.
            </p>
            <ul>
              <li>Propellerne skal ogs&aring; v&aelig;re rigtige. (CW- CCW)</li>
              <li>
                Der m&aring;les ved hvilken pulsbredde ESCen starter motoren.
                Dette foretages med en servotester, hvor pulsbredden kan
                m&aring;les, et helt uundv&aelig;rligt v&aelig;rkt&oslash;j. Se
                billede 1.
                <br />
                <img src={fig19} />
                <br />
                Billede 1.
              </li>
            </ul>
            <ul>
              <li>
                Der checkes, at alle motorer starter ved samme pulsbredde,
                nemlig 1030uS.
              </li>
              <li>
                KK2 indeholder en vibrations m&aring;ler. I menu 2 underpunkt 11
                er det muligt at anvende accelerometeret til at angive et tal
                for vibrationsniveauet. Se billede 2. Det var ikke
                n&oslash;dvendigt at afbalancere Graupner propellerne.
                <br />
                <img src={fig20} />
                <br />
                Billede 2.
              </li>
            </ul>
            <ul>
              <li>
                Motorerne tilsluttes nu KK2 og den f&aelig;lles
                str&oslash;mskinne via TX30 stik samtidig med, at propellerne
                fjernes af sikkerhedsm&aelig;ssige &aring;rsager.
              </li>
              <li>
                Test for om omdrejningerne f&oslash;lger roll og pitch pinden
                den rigtige vej. Man tester den bl&aring; loop p&aring; fig.1
                under del 2.
              </li>
              <li>
                Test for om omdrejningerne vil stabilisere flyet ved, at du
                &aelig;ndrer roll og pitch, ved at du tager fat i flyet og
                drejer det. Det er af st&oslash;rste vigtighed, at
                stabiliseringen virker den rigtige vej. Du kan have lavet en
                fortegnsfejl i softwaren, s&aring;ledes at du f&aring;r en
                kraftig ustabilitet p&aring; grund af medkobling i
                reguleringssl&oslash;jfen! Det g&oslash;res bedst ved lave motor
                omdrejninger. Man tester den r&oslash;de loop f&aring; fig. 1
                under del 2.
              </li>
              <li>
                Sluttelig indf&oslash;res armeringsprogrammering i KK2. Elfly
                er, som det er bekendt farlige, fordi motorerne kan starte i
                utide. Ved tilslutning af batteriet kan motorerne ikke starte,
                f&oslash;r man armerer flyet. Dette g&oslash;res ved at
                f&oslash;re begge styrepinde helt ud og ned. Ved armeringen
                t&aelig;nder en r&oslash;d lysdiode. Flyet er nu farligt.
                Dearmeringen, som foretages ved flyvningens afslutning,
                foretages ved at f&oslash;re begge styrepinde ind og ned i 10
                sekunder. V&aelig;r stadig forsigtig fordi sikkerheden er
                baseret p&aring; software. KK2 udsender nu 900uS pulser til
                ESCerne. Disse pulser er s&aring; korte, at ESCens
                intialiserings software ikke er aktiv. Under programmering kan
                motorerne ikke starte. Propellerne kan nu monteres.
              </li>
            </ul>
            <h2>2. Carport test</h2>
            <p>
              <img src={fig21} />
              <br />
              Billede 3
            </p>
            <p>
              N&aelig;ste test foretages ved at h&aelig;nge Vektor3 op i en snor
              i carporten. Se billede 3. Form&aring;let er her at justere
              virkningen af tiltningen af halerotoren, s&aring;ledes at flyet
              ikke roterer rundt om yawaksen ved neutral pind, og at det kan
              rotere lige godt begge veje rundt. Under denne test opdager man,
              at gyrostabiliseringssystemerne er underlige. &Aring;rsagen er
              naturligvis, at flyet ikke kan bev&aelig;ge sig frit.
            </p>
            <h2>3.&nbsp;F&oslash;rste hover flyvninger</h2>
            <p>
              De egentlige f&oslash;rste flyvefors&oslash;g foretages fra
              gr&aelig;s i absolut helt stille vejr eller indend&oslash;rs. Der
              startes med at &oslash;ge omdrejningerne meget forsigtigt for at
              iagttage, om flyet har tendenser til ikke at g&aring; lige op,
              n&aring;r det er meget t&aelig;t p&aring; at v&aelig;re
              v&aelig;gtl&oslash;st. Derefter &oslash;ges omdrejningerne. Man
              kan da nemt risikere, at flyet v&aelig;lter, hvis det letter
              p&aring; de to hovedhjul f&oslash;rst og st&aring;r p&aring;
              n&aelig;sehjulet. &Aring;rsagen er, at flyet ikke kan bev&aelig;ge
              sig frit ligesom ved carporttesten.
            </p>
            <p>
              Flyet bringes nu op i en flyveh&oslash;jde p&aring; 10 til 20 cm.
              Her skal KK2 softwaren nu trimmes indtil, at flyet kan glide
              ganske langsomt fremad uden at rotere eller glide til siden.
              Vektor 3 m&aring; h&oslash;jest flyve bagl&aelig;ns med 1 til 2
              sekundmeter for ikke at lave et tailflip. Fordi det n&aelig;sten
              aldrig er stille vejr har jeg valgt, at Vektor3 flyver lidt fremad
              med neutrale pinde.
            </p>
            <h2>4.&nbsp;Godkendt hover flyvning</h2>
            <p>
              Fase 4 var gradvis at for&oslash;ge flyveh&oslash;jden og
              gennemf&oslash;re en r&aelig;kke flyvninger i 5 til 30 meters, som
              en almindelig tricopter, for at l&aelig;re Vektor3 at kende.
            </p>
            <h2>5.&nbsp;F&aelig;rdig bygning af fastvingeflyet</h2>
            <p>
              Fase 5 var bygning af ydervinger, sideror og stabilisator, samt
              installation af f&oslash;lgende Jeti telemetri udstyr. Se billede
              4.
            </p>
            <ul>
              <li>
                MUI til m&aring;ling af mA/h, batterisp&aelig;nding og
                str&oslash;mforbrug. MUI m&aring;leren beholdes permanent, mens
                de efterf&oslash;lgende telemetrisensorer fjernes efter
                pr&oslash;veflyvningsprogrammet.
              </li>
              <li>
                &nbsp;GPSmodtager til m&aring;ling af distance, afstande m.m.
              </li>
              <li>
                &nbsp;Pitot r&oslash;r til m&aring;ling af True Air Speed.
              </li>
              <li>Variometer og h&oslash;jdem&aring;ler.</li>
              <li>Termometer til m&aring;ling af motortemperatur.</li>
              <li>Omdrejninger p&aring; halemotor (Motor nr.3)</li>
            </ul>
            <p>
              <img src={fig22} />
              <br />
              Billede 4
            </p>
            <p>
              Disse systemer med tilh&oslash;rende lydalarmer skulle medvirke
              til at underst&oslash;tte sikkerheden under flyvningen. Analyse af
              de efterf&oslash;lgende logfiler skulle medvirke til at
              ops&aelig;tte softwaren til et sikkert transition forl&oslash;b
              begge veje. Den lave v&aelig;gt af flyet muliggjorde, at flyets
              oprindelige testbatteri p&aring; 2200mA/h kunne erstattes med et
              FullPower 3300mAh4S 35C p&aring; 344g eller med et FullPower
              4200mAh4S 35C p&aring; 420g.
            </p>
            <h2>6. Test p&aring; v&aelig;rksted</h2>
            <p>F&oslash;lgendeunders&oslash;ges:</p>
            <ul>
              <li>
                90 grader rotation og kontrol af de to forreste motorer ved
                hj&aelig;lp af transition kanalen. Senderens kanal 5.
              </li>
              <li>
                For at styre kanalen mere pr&aelig;cist blev knappen p&aring;
                senderen forsynet med en kalibreret drejeskive. Se billede 5.
                Servoerne arbejder p&aring; samme styrepuls via et Y-kabel og
                vil derfor f&oslash;lges pr&aelig;cist ad. Endestop kan justeres
                i KK2s menu nr. 20 og 21.
                <br />
                <img src={fig23} />
                <br />
                Billede 5
              </li>
            </ul>
            <ul>
              <li>
                I KK2 er det muligt at trimme en off-set kurve p&aring; 7
                punkter fra P1.0 til P2.0 p&aring; alle 8 output. Dette
                g&oslash;r jeg ved omhyggelig brug af p&aring; halemotorens
                omdrejninger for at kunne styre overgangen, hvor omdrejninger
                gradvis skal kunne reducere til en helt stoppet motor ved P2.0
                FFF. Ved hover P1.0 anvendes ogs&aring; en reduktion for at
                sikre, at starten finder sted p&aring; de to hovedhjul, mens man
                ved begyndende forward transition P1.17 har brug for at Vektor3
                accelererer frem, og derfor &oslash;ger omdrejningerne.
              </li>
            </ul>
            <h2>Hover med vinger</h2>
            <p>
              Den 24. juli 2018 kl. 8.20 blev de f&oslash;rste 3 hover
              flyvninger p&aring;begyndt p&aring; H&oslash;je T&aring;strup
              Modelflyveklubs bane i helt stille vejr med max h&oslash;jde
              p&aring; 40 meter. Det blev samtidig starten p&aring; en
              systematisk opsamling af meget store m&aelig;ngder data, som
              efterf&oslash;lgende blev analyseret og gemt. Oprettelsen af en
              logbog var n&oslash;dvendig for at styre &aelig;ndringerne i
              softwaren. Blandt andet blev tiltningen af halerotoren flyttet fra
              sideroret til ballancekanalen. Der blev ogs&aring; konstateret, at
              der opstod kraftig oscillation omkring roll-aksen, hvis man sank
              for hurtig under hover.
            </p>
            <h2>8. Manuel transition</h2>
            <p>
              Den 17. aug. foretages det f&oslash;rste fors&oslash;g med manuel
              transition i en vind p&aring; 2m/sek og en sikkerhedsh&oslash;jde
              p&aring; 60m. Det foretages ved at dreje skiven (Se billede 5) fra
              hover til P1,17 under iagttagelse af modellen. Efter ca. 12
              sekunder blev der reguleret tilbage til hover. Da der ikke syntes
              at v&aelig;re de store problemer, blev der ved
              efterf&oslash;lgende flyvninger fortaget gradvis regulering op til
              P2.0. Ved FFF synes imidlertid at optr&aelig;den en markant
              forsinkelse af h&oslash;jderorets virkning, noget som var ret
              sp&aelig;ndende og hvor det var vigtigt, at jeg havde stor
              flyveh&oslash;jde til r&aring;dighed. &Aring;rsagen var at KK2s
              Integral reguleringen indf&oslash;rer en tidsforsinkelse, som
              ogs&aring; kunne spores som tydelige oscillationer i logfilens
              h&oslash;jdekurver. Derefter blev I v&aelig;rdien sat til 0 og
              fejlen fjernet.
            </p>
            <h2>9. Timer styret transition</h2>
            <p>
              Efter flyvning 13 blev drejeskiven erstattet af en 3
              stillingsomskifter med f&oslash;lgende funktion Hover &ndash; SFF
              og FFF. Da der skal tilf&oslash;res bev&aelig;gelsesenergi til
              flyet ved outbound transition, s&aelig;ttes timeren til 10 sek,
              svarende til, hvis du starter fra jorden. Inbound timeren
              s&aelig;ttes 5 sek, her opbremser flyet af luftmodstanden til det
              holder stille.
            </p>
            <p>
              <img src={fig24} />
              <br />
              Billede 6. Fotograf Kennet Partsch fanger det korte &oslash;jeblik
              hvor Vektor3 kun hviler p&aring; hovedhjulene, lige inde den
              starter med at hove.
            </p>
            <h2>10. Flyvevejledningog data&nbsp;</h2>
            <p>
              Softwaren er nu &rdquo;trimmet&rdquo; s&aring; meget ind, at
              Vektor3 kan udf&oslash;re yderligere 33 flyvninger med forskellige
              form&aring;l. Samlet blev der opsamlet ca. 39 Mbyte i Jetiboksen.
              S&aring; der kan nu laves en slags flyvevejledning.
            </p>
            <ul>
              <li>Krav til flyveplads 3X3 meter!</li>
              <li>
                N&aring;r flyet er armeret, er det klar til start og dermed
                farligt.
              </li>
              <li>
                Der startes altid mod vinden og holdende p&aring; baghjulene
                f&oslash;rst, ellers v&aelig;lter det. Se billede 6.
              </li>
              <li>Forvent at flyet driver med vinden som en ballon.</li>
              <li>
                Der m&aring; aldrig flyves bagl&aelig;ns med en True Air Speed
                p&aring; mere end minus 2m/Sek for at undg&aring; tailflip eller
                yawflip.&nbsp;
              </li>
              <li>
                Hvis det bl&aelig;ser mere end 5m/sek startes og landes i SFF
                mode.
              </li>
              <li>
                Hvis det bl&aelig;ser mere end 10m/sek, b&oslash;r der ikke
                flyves .
              </li>
              <li>
                Transition udf&oslash;res i sikker h&oslash;jde, som kan
                reduceres efterh&aring;nden, som man bliver dygtigere.
              </li>
              <li>
                Der landes ikke i FFF, fordi det er usikkert og strider imod
                VTOL ideen, men n&oslash;dlandinger kan udf&oslash;res.
              </li>
              <li>
                Hover nedstigning udf&oslash;res med en maksimal nedstignings
                fart p&aring; 2m/sek for at undg&aring; roll oscillationer.
              </li>
              <li>
                Jeti MUI sensoren indstilles til at give lydadvarslen
                &rdquo;battery capacity exceeded&rdquo; ved 40% af den faktiske
                kapacitet, s&aring;ledes at inbound transition kan
                p&aring;begyndes. Landingen b&oslash;r v&aelig;re
                gennemf&oslash;rt ved ca. 30% af den faktiske batterikapacitet
                for at sk&aring;ne batteriet. Udover dette er KK2 udstyret med
                en lavsp&aelig;ndingsalarm, som t&aelig;nder en kraftig
                blinkende LED, hvis sp&aelig;ndingen kommer under 3,4V/pr.celle,
                alts&aring; 13,6V. Alarmsp&aelig;ndingen er programmerbar.
              </li>
              <li>
                N&aring;r du n&aelig;rmer dig jorden, skal du reducere
                nedstigningen til 0,2 til 0,5m/sek og lukke helt for gassen,
                n&aring;r du er meget t&aelig;t p&aring; touch-down.
                &Aring;rsagen er, at n&aring;r flyet rammer jorden, vil der
                blive overf&oslash;rt energi til understellet, som derefter
                kaster flyet op igen. P&aring; dette tidspunkt er det t&aelig;t
                p&aring; at v&aelig;re v&aelig;gtl&oslash;st. Denne
                v&aelig;gtl&oslash;shed skal fjernes p&aring; det rigtige
                tidspunkt, ved at gassen lukkes hurtigt og helt, ellers hopper
                flyet ukontrollabelt op og ned og kan risikere LOC.
              </li>
              <li>
                Efter landing skal du vente 10 sek. med taxik&oslash;rsel indtil
                motorerne er i FFF stilling.
              </li>
              <li>
                Efter taxik&oslash;rsel f&oslash;res begge pinde helt ned og ind
                i 10 sekunder til dearmering, hvorefter batteriet kan fjernes.
              </li>
            </ul>
            <p>
              <img src={fig25} />
            </p>
            <p>
              Billede 7. Er et eksempel p&aring; en m&aring;ling af transition.
              Den &oslash;verste r&oslash;de kurve viser transition, alts&aring;
              drejningen af de to forreste motorer M1 og M2 . Man kommer fra FFF
              i ca. 60 meters h&oslash;jde med en TAS p&aring; 17m/sek (
              Bl&aring; kurve nederst til venstre) og g&aring;r derefter i
              inbound transition. Den mellemste kurve viser, at halerotoren
              g&aring;r fra stilstand og op p&aring; ca. 10000 omdrejninger.
              Str&oslash;mforbruget stiger fra ca. 16A til 44A som vist p&aring;
              den nederste r&oslash;de kurve. TAS er 0m/sek. Flyet holder
              stille, mens ground speed er m&aring;lt til 5km/timen p&aring;
              GPSen, fordi der er vind. P&aring; den h&oslash;jre side af
              graferne ses outbound transition, som varer 10 sekunder. Ved tiden
              5min og 15sek m&aring;les &oslash;jebliksv&aelig;rdien af samtlige
              sensorer helt ude til h&oslash;jre p&aring; billedet.
            </p>
            <p>M&aring;linger med 4200mA/h 4S batteri.</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Flyvemode</p>
                  </TableCell>
                  <TableCell>
                    <p>Program</p>
                  </TableCell>
                  <TableCell>
                    <p>Str&oslash;mforbrug</p>
                  </TableCell>
                  <TableCell>
                    <p>Ubat</p>
                  </TableCell>
                  <TableCell>
                    <p>Optaget effekt</p>
                  </TableCell>
                  <TableCell>
                    <p>TAS</p>
                  </TableCell>
                  <TableCell>
                    <p>Antal aktive motorer</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Hover</p>
                  </TableCell>
                  <TableCell>
                    <p>P1.0</p>
                  </TableCell>
                  <TableCell>
                    <p>44Amp</p>
                  </TableCell>
                  <TableCell>
                    <p>14,9V</p>
                  </TableCell>
                  <TableCell>
                    <p>655Watt</p>
                  </TableCell>
                  <TableCell>
                    <p>0m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>3</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>SFF</p>
                  </TableCell>
                  <TableCell>
                    <p>P1.5</p>
                  </TableCell>
                  <TableCell>
                    <p>28Amp</p>
                  </TableCell>
                  <TableCell>
                    <p>15,2V</p>
                  </TableCell>
                  <TableCell>
                    <p>425Watt</p>
                  </TableCell>
                  <TableCell>
                    <p>10m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>2&frac12;</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>FFF</p>
                  </TableCell>
                  <TableCell>
                    <p>P2.0</p>
                  </TableCell>
                  <TableCell>
                    <p>17Amp</p>
                  </TableCell>
                  <TableCell>
                    <p>15,6</p>
                  </TableCell>
                  <TableCell>
                    <p>265Watt</p>
                  </TableCell>
                  <TableCell>
                    <p>17m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              L&aelig;ngste m&aring;lte FFF flyvestr&aelig;kning 10,6km.
              L&aelig;ngste m&aring;lte FFF varighed 12min.
            </p>
            <h2>Afslutning</h2>
            <p>
              VTOL projektet Vektor3 har v&aelig;ret som at spise en stor
              elefant. Dette g&oslash;res som bekendt ved at spise sm&aring;
              stykker ad gangen. Jeg har samtidig skulle bringe mange
              fagomr&aring;der og viden i spil omkring, aerodynamik, mekanisk
              konstruktion, komponenter, elektronik, computerteknik,
              reguleringsteknik samt praktiske
              h&aring;ndv&aelig;rksm&aelig;ssige f&aelig;rdigheder. Dertil
              kommer anvendelsen af forskellige computer programmer til tegning,
              beregninger og simulation suppleret med adgang til Internettet.
            </p>
            <p>
              Som S&oslash;ren Kirkegaard siger &rdquo;Vejen er alt m&aring;let
              er intet&rdquo;. Det er processen omkring det vil lykkes, der er
              vigtig, ogs&aring; fordi det er sv&aelig;rt. Det er her den
              st&oslash;rste l&aelig;ring finder sted. Eksamen omkring Vektor3
              er benh&aring;rd, n&aring;r den h&aelig;nger oppe i luften. Se
              Billede 8, fordi flyvning giver en herlig, kontant og
              &oslash;jeblikkelig afregning, hvis alt ikke fungerer som det
              skal, i mods&aelig;tning til politik og mange andre af livets
              omr&aring;der. M&aring;ske kunne skattev&aelig;sen,
              sygehusv&aelig;sen og andre l&aelig;re af den systematik, som
              pr&aelig;ger al flyvning. Teknologi forst&aring;else bliver mere
              og mere n&oslash;dvendig for alle mennesker, n&aring;r
              selvk&oslash;rende biler, Internet of Things, kunstig intelligens
              og 5G netv&aelig;rk bliver hverdag lige om et &oslash;jeblik.
            </p>
            <p>
              <img src={fig26} />
            </p>
            <p>
              Billede 8. Fotograf Kennet Partsch fanger Vektor3, mens det flyver
              Slow Forward Flight. 50% tricopter, 50% fastvingefly.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
