import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles, Link } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/JetiDuplexGPSModtager/fig1.jpg";
import fig2 from "../../../images/Jeti/JetiDuplexGPSModtager/fig2.jpg";
import fig3 from "../../../images/Jeti/JetiDuplexGPSModtager/fig3.jpg";
import fig4 from "../../../images/Jeti/JetiDuplexGPSModtager/fig4.jpg";
import fig5 from "../../../images/Jeti/JetiDuplexGPSModtager/fig5.jpg";
import fig6 from "../../../images/Jeti/JetiDuplexGPSModtager/fig6.jpg";
import fig7 from "../../../images/Jeti/JetiDuplexGPSModtager/fig7.jpg";
import fig8 from "../../../images/Jeti/JetiDuplexGPSModtager/fig8.jpg";
import fig9 from "../../../images/Jeti/JetiDuplexGPSModtager/fig9.jpg";
import fig10 from "../../../images/Jeti/JetiDuplexGPSModtager/fig10.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Jeti Duplex GPS modtager med indbygget flight-logger</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>
              Jeti Duplex GPS modtager med indbygget flight-logger
              <br />
            </h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret juli 2010.</p>
            <p>
              Denne artikel introducerer et nyt m&aring;lemodul fra det
              innovative tjekkiske firma Jeti, som forts&aelig;tter udviklingen
              af deres avancerede 2,4GHz radioanl&aelig;g. Modulet er en
              h&oslash;jteknologisk GPS modtager med hukommelse til data
              logning. Du kan med fordel l&aelig;se Jeti Duplex artiklen bragt i{" "}
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2010/modelflyvenyt%201-2010.pdf"
                target="_blank"
              >
                Modelflyvenyt nr. 1-2010
              </Link>
              , som beskriver systemkomponenterne, deres virkem&aring;de og
              sammenkobling.&nbsp;
            </p>
            <h2>Princip for GPS modulet</h2>
            <p>
              Du kender sikkert allerede brugen af GPS (Global Position System)
              fra din navigator i bilen, s&aring; lad os se p&aring; princippet
              i Jeti GPS modulet. Satellitterne er placeret i n&oslash;jagtige
              positioner p&aring; himlen i baner roterende om jorden. De
              udsender hele tiden tidssignaler, med en ekstrem n&oslash;jagtig
              samtidighed, styret af atomure. Tidssignalerne udbredes af
              radiob&oslash;lger. N&aring;r disse tidssignaler modtages, vil der
              v&aelig;re en lille tidsforskydning imellem signalerne, som
              f&oslash;lge af at afstanden mellem satellitterne er forskellig.
              Derefter er det &rdquo;bare&rdquo; at f&aring; computeren til at
              foretage en pr&aelig;cis krydspejling. Hvis mindst 5 satellitter
              modtages, er Jeti Duplex GPS modulet i stand til at beregne og
              gemme en lang r&aelig;kke data fra dine flyvninger.
            </p>
            <h2>GPS modulets fysiske data</h2>
            <p>
              GPS modulet m&aring;ler 50x30x12,5mm og vejer 24g.
              Forsyningssp&aelig;nding 3,5 til 8,4&nbsp;V. Der er to 3-polede
              ledninger, sort stik tilsluttes dataindgangen p&aring; modtageren,
              r&oslash;dt stik kan sv&aelig;ve eller tilsluttes en servokanal.
              Gennemsnitligt str&oslash;mforbrug er 40mA, men kan n&aring;r
              processoren har travlt komme op p&aring; 100mA. Arbejdstemperatur
              -20 til 85&nbsp;C grader. 4MB udgaven har hukommelse til 9 timers
              flyvning. Hvis dette ikke er nok, findes der ogs&aring; en 8MB
              udgave, som har den dobbelte arbejdstid. Der m&aring; ikke
              v&aelig;re ledende materialer, s&aring;som kul eller metal, mellem
              GPS modulets antenne side og himlen. Se foto 1.
            </p>
            <p>
              <img src={fig1} />
              Billede 1
            </p>
            <p>
              USB interfacet bliver p&aring; jorden, idet det kan adskilles fra
              GPS modulet.
            </p>
            <h2>3 forskellige opkoblinger</h2>
            <p>
              Jeti Duplex GPS modulet kan anvendes p&aring; 3 forskellige
              m&aring;der. Se fig. 1.
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              F&oslash;rste mulighed: Jeti Duplex GPS modulet er forbundet til
              Jeti modtagerens dataindgang. Derfor kan der under flyvningen
              modtages &rdquo;sand tids&rdquo; GPS m&aring;linger fra flyet via
              tovejs forbindelsen. Disse GPS data kan piloten s&aring;
              udl&aelig;se, s&aring;vel mens der flyves, som efter landingen.
              Via en servo kanal p&aring; modtageren kan der sendes en besked
              ind i GPS modulet i form af en impulsbredde variation..
              <br />
              <br />
              Anden mulighed: Under flyvningerne logger GPS modulet
              l&oslash;bende data i sin 4MB/8MB hukommelse. Disse flightdata kan
              overf&oslash;res til en PC via USB-interfacet for n&aelig;rmere
              analyse efter landingen. M&aring;lingerne kan foretages med
              valgbare intervaller. Der er hukommelse nok til 9 eller 18 timers
              logning.&nbsp;
              <br />
              <br />
              Tredje mulighed: Du kan anvende GPS modulet uden sender og
              modtager p&aring; s&aelig;dvanlig vis, som for andre Jeti
              m&aring;lemoduler. Du kan eksempelvis bev&aelig;ge boks og modul
              ud i verden p&aring; cykel, bil, til fods eller som bjergbestiger.
              Der kr&aelig;ves dog tilsluttet et forsyningsbatteri til Jetiboxen
              p&aring; mellem 3,5 og 8,4&nbsp;Volt. Jetiboksen forbindes da
              direkte til GPS modulet, og de loggede data kan s&aring;
              udl&aelig;ses p&aring; s&aring;vel Jetiboks, som overf&oslash;res
              til PC.
            </p>
            <h2>Hvilke funktioner har GPSen?</h2>
            <p>
              Jeg vil f&oslash;rst starte med at beskrive Jeti Duplex GPS
              modulets menuer og funktioner, n&aring;r jeg har forbundet GPS
              modulet til modtageren og er klar til at flyve. N&aring;r
              anl&aelig;gget/modulet t&aelig;ndes vil der g&aring; lidt tid,
              f&oslash;r det finder satellitterne. N&aring;r dette er sket,
              forsvinder oplysningen &rdquo;No GPS signal&rdquo; p&aring;
              Jeti-boxen samtidig med, at lysdioden p&aring; siden af GPS
              modtageren blinker hurtigt. Derefter skal du, som det
              allerf&oslash;rste, bestemme startstedet, ved at taste venstre og
              h&oslash;jre tast p&aring; Jeti boxen ned samtidig, i menuen
              &rdquo;Set Origin&rdquo;. Dermed bestemmes det n&oslash;jagtige
              startsted p&aring; jordens overflade i forhold til satellitterne.
              Se Foto 2. Alle m&aring;linger foretages nu ud fra dette
              startsted. Hvis du skifter startsted/flyveplads, skal
              startstedsbestemmelsen gentages.
            </p>

            <p>
              <img src={fig3} />
              Foto 2.
            </p>
            <p>
              Forfatteren befinder sig her p&aring; startstedet, KFKs bane 09 i
              Soderup med sin Fanliner. Det er vigtigt, at startstedet
              s&aelig;ttes op i GPS modulet, idet alt beregnes i forhold til
              dette.
            </p>
            <p>
              Du kan nu slukke for systemet. N&aring;r det atter t&aelig;ndes,
              vil der fremkomme en startsk&aelig;rm, hvor du kan f&aring; oplyst
              flyveh&oslash;jde over startstedet, flyvefart over jorden,
              gennemfl&oslash;jet distance eller afstand ud til modellen fra
              startstedet, vist p&aring; 5 forskellige m&aring;der. Datalogning
              i hukommelsen startes samtidig med, at du t&aelig;nder
              anl&aelig;gget, og den f&oslash;rste fil dannes i hukommelsen.
              Under flyvningen kan yderligere udl&aelig;ses forskellige aktuelle
              v&aelig;rdier s&aring; som:
            </p>
            <ul>
              <li>
                <p>
                  Afstand i meter ud til hvor flyet befinder sig. Der kan her
                  ops&aelig;ttes en afstandsalarm, som hj&aelig;lper piloten til
                  at flyve inde i en defineret halvkugle boks, hvor piloten
                  st&aring;r i midten og dermed sikrer, at man ikke kommer for
                  langt v&aelig;k.
                </p>
              </li>
              <li>
                <p>
                  Flyvefart i forhold til jorden ogs&aring; kaldet ground speed.
                  Bem&aelig;rk dette er ikke det samme som den sande flyvefart i
                  forhold til den omgivne luft, med mindre det er vindstille.
                </p>
              </li>
              <li>
                <p>Den gennemfl&oslash;jne distance.</p>
              </li>
              <li>
                <p>Den kurs flyet flyver udl&aelig;st i kompasgrader.</p>
              </li>
              <li>
                <p>
                  Flyveh&oslash;jden i forhold startstedets h&oslash;jde, med en
                  opsat h&oslash;jdealarm p&aring; 90m.
                </p>
              </li>
              <li>
                <p>
                  Azimuth d.v.s. afstanden og kursen fra startstedet og ud til
                  modellen i meter og grader. Se fig. 3.
                </p>
              </li>
              <li>
                <p>
                  Dato og tid. Tidsangivelsen er ekstrem n&oslash;jagtig, langt
                  bedre end Stein Baggers guldur.
                </p>
              </li>
              <li>
                <p>
                  Satellitdata, s&aring;som den aktuelle l&aelig;ngde- og
                  breddegrad, h&oslash;jde over havet samt GMT tid kan ligeledes
                  bestemmes.
                </p>
              </li>
              <li>
                <p>
                  Hvor mange satellitter der modtages, samt modulets
                  forsyningssp&aelig;nding. Antallet af satellitter varierede
                  mellem 8 og 11 i mine test. Modtageren er
                  h&oslash;jf&oslash;lsom sammenlignet med min bilnavigator.
                </p>
              </li>
              <li>
                <p>
                  Dataoptagelsens filnummer og forbrugt hukommelse i procent.
                  Den samlede tid der kan optages afh&aelig;nger af, om
                  hukommelsen er p&aring; 4 eller 8Mb og er henholdsvis 9 eller
                  18timer.
                </p>
              </li>
              <li>
                <p>
                  Impuls input fra modtageren. Som du kan se p&aring; figur 1,
                  kan der tilkobles en kontrolkanal fra modtageren til GPS
                  modulet. Tanken er, at man kan optage pulsbredden i
                  mikrosekunder fra den p&aring;g&aelig;ldende kanal. Dette
                  kunne v&aelig;re gaskanalen v.h. af et Y-kabel eller en tom
                  kanal med en kontakt p&aring; senderen. Dermed kan man
                  afl&aelig;se tidsm&aelig;rker logget under flyvningen fra
                  kontrolkanalen. I stedet for anvendelsen af Y kablet, skal der
                  g&oslash;res opm&aelig;rksom p&aring; at Jeti Duplex
                  modtageren er st&aelig;rkt programmerbar. Det er s&aring;ledes
                  muligt f. eks. at f&aring; kanal 5 til at v&aelig;re en kopi
                  af kanal 3, gaskanalen, s&aring;ledes at Y-kablet bliver
                  overfl&oslash;digt.
                </p>
              </li>
            </ul>
            <p>
              Efter flyvningen kan afl&aelig;ses nogle min og max v&aelig;rdier
              fra flyvningen. F&oslash;r flyvningerne p&aring;begyndes, kan man
              programmere m&aring;leintervallerne til at v&aelig;re 1sek, 2sek,
              5sek eller 10sek. Om man vil vise hastighederne i m/s eller km/t,
              samt ops&aelig;tte forskellige alarmv&aelig;rdier s&aring; som max
              h&oslash;jde, max afstand og max flyvefart. Hvis du har behov for
              at unders&oslash;ge produkterne mere detaljeret, kan du finde
              tyske eller engelske bruger manualer p&aring; firmaet Electric
              Flight Equipments web-server p&aring;:&nbsp;
              <Link href="http://www.el-fly.dk/" target="_blank">
                www.el-fly.dk
              </Link>
            </p>
            <p>
              <strong>Forskellige praktiske erfaringer</strong>
              <br />
              Jeg vi nu beskrive en r&aelig;kke praktiske pr&oslash;ver, og de
              erfaringer jeg har gjort.&nbsp;
            </p>
            <h2>Flyvning med Fanlineren</h2>
            <p>
              GPS modulet monteres i Fanlineren i Jeti modtagerens data indgang,
              og s&aring; g&aring;r det l&oslash;s. H&oslash;jde og flyvefart
              kan nu udl&aelig;ses under flyvningen. Min st&oslash;rste afstand
              under flyvningen ud til modellen var 262 meter afl&aelig;st efter
              landingen. Da der var opsat en h&oslash;jdealarm p&aring; 90m,
              kunne flyveh&oslash;jden begr&aelig;nses til 97meter ligeledes
              afl&aelig;st efter landingen. Laveste flyvefart under flyvningen
              blev m&aring;lt til 6m/s mod vinden. Dette var 4m/s under
              stallgr&aelig;nsen! Hvis du imidlertid l&aelig;gger modvinden
              p&aring; 4m/s sammen med de m&aring;lte 6 m/s, f&aring;s den sande
              flyvefart i forhold til luften, ogs&aring; kaldet &rdquo;true
              airspeed&rdquo;.
              <br />
              Den maximale flyvefart blev 39m/s svarende til 140km i timen
              opn&aring;et i et let dyk i medvind. Den gennemfl&oslash;jne
              distance af en flyvning p&aring; 7 minutters varighed blev
              m&aring;lt til et trip p&aring; 9290 meter, langt mere end man
              umiddelbart tror. Da flyet (se foto 2) er taget i brug i 2005 (Se
              Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt.dk/modelflyvenyt/2006/Modelflyvenyt%202%202006.pdf"
              >
                2-2006
              </Link>
              ), g&aelig;tter jeg p&aring;, at det har tilbagelagt en samlet
              str&aelig;kning p&aring; m&aring;ske 800 km, hvis flyveruten blev
              rettet ud. Ved hj&aelig;lp af en co-pilot som hele tiden fortalte
              mig flyets kompaskurs, var det muligt at flyve stik nord (0
              grader) et stykke tid for derefter at flyve stik &oslash;st (90
              grader) Denne funktion m&aring; siges at have begr&aelig;nset
              funktion, fordi man n&oslash;dvendigvis ikke med et modelfly kan
              flyve lige ud ret lang tid ad gangen. Alts&aring; m&aring; man
              dreje. Se fig. 2&nbsp;
            </p>
            <p>
              <img src={fig4} />
              Fig 2.
            </p>
            <p>
              Man skal i &oslash;vrigt v&aelig;re opm&aelig;rksom p&aring;, at
              GPS kompasfunktionen kun virker, n&aring;r modulet er i
              bev&aelig;gelse med mindst 5 m/s samt s&aelig;tter
              m&aring;leinterval til 1 sekund i GPS&acute;en, fordi kompasset
              ikke er baseret p&aring; jordens magnetfelt, men p&aring;
              beregninger af satellit positioner!&nbsp;
            </p>
            <h2>Efters&oslash;gning af forsvundet fly</h2>
            <p>
              Hvis du st&aring;r med et magnetkompas i startstedet og
              afl&aelig;ser nord, f&aring;r du i azimuth menuen p&aring;
              Jetiboksen oplyst den n&oslash;jagtige kurs og afstand ud til
              flyet og dermed ogs&aring; til et u&oslash;nsket landingssted. Jeg
              har testet dette, og du kan g&aring; direkte ud og finde modellen
              i terr&aelig;net. Se fig. 3.
            </p>
            <p>
              <img src={fig5} />
              Fig 3.
            </p>
            <p>
              2,4GHz r&aelig;kkevidden er afh&aelig;ngig af flere forhold,
              specielt antenneh&oslash;jden p&aring; modtageren.
            </p>
            <p>
              Da dette blev for meget for en af mine klubkammerater, sagde han:
              &rdquo;Nu l&aring;ser jeg dig inde i klubhusets toilet, derefter
              tager jeg din flyver og gemmer den. N&aring;r du f&aring;r lov til
              at komme ud, f&aring;r du din sender, og s&aring; kan du se, om du
              kan finde din flyver. &rdquo; Da jeg s&aring; p&aring; Azimuth
              menuen, viste den 170 grader og 52 meter.&nbsp;
              <br />
              Ved hj&aelig;lp af et magnetkompas fandt jeg f&oslash;rst nord og
              kunne derefter bestemme den n&oslash;jagtige s&oslash;geretning.
              Da jeg s&aring; derud, var der en lang r&aelig;kke af tr&aelig;er
              og buske, som afgr&aelig;nsede flyvepladsen ind til en kornmark.
              Jeg gik af sted, indtil jeg mente at have n&aring;et 50m. Derefter
              bev&aelig;gede jeg servoerne og kunne lidt foran mig tydeligt
              h&oslash;re servost&oslash;j fra en helt tild&aelig;kket flyver
              inde under buskene. Jeti havde tilsyneladende best&aring;et
              pr&oslash;ven med glans.
              <br />
              Man skal imidlertid v&aelig;re opm&aelig;rksom p&aring;, at
              r&aelig;kkevidden p&aring; et 2,4GHz anl&aelig;g er st&aelig;rkt
              afh&aelig;ngig af det, man kalder
              str&aelig;kningsd&aelig;mpningen, senderantennens og de 2
              modtagerantenners position. (Se artikel Grundl&aelig;ggende 2,4GHz
              teknik i Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt.dk/modelflyvenyt/2009/modelflyvenyt%205-2009.pdf"
              >
                nr. 5-2009
              </Link>
              ). Hvis flyet lander i v&aring;dt gr&aelig;s, masser af buske og
              tr&aelig;er kan r&aelig;kkevidden v&aelig;re s&aring; lav som 50m.
              Brun cirkel, se fig. 3. Hvis flyet lander p&aring; &aring;ben
              gr&aelig;smark, vil r&aelig;kkevidden typisk v&aelig;re omkring
              250m Gr&oslash;n cirkel, se fig 3, mens den i normal
              flyveh&oslash;jde vil v&aelig;re ca. 10 gange h&oslash;jere,
              alts&aring; op til den maximale teoretiske r&aelig;kkevidde
              ca.3000m.
              <br />
              Bl&aring; cirkel, se fig. 3. Hvis du lander ude i terr&aelig;n,
              kan man derfor hurtig komme i den situation, at telemetrien
              svigter p&aring; grund af den meget lave antenneh&oslash;jde du
              f&aring;r, n&aring;r flyet ligger p&aring; jorden. Hvis du lander
              mere end 3000m fra startstedet p&aring; grund af en bortflyvning,
              hvidt felt p&aring; fig. 3, vil det vigtige telemetridata Azimuth
              og afstand mellem startsted og landingssted helt sikkert ikke
              kunne modtages via telemetrien.
              <br />
              Du b&oslash;r imidlertid v&aelig;re opm&aelig;rksom p&aring;, at
              GPS modtageren forts&aelig;tter med at registre, ogs&aring; selv
              om du er meget langt v&aelig;k fra startstedet.
              <br />
              Jeg har pr&oslash;vet at s&aelig;tte GPS modtageren og Jeti boksen
              op i min bil og oprette startstedet i min indk&oslash;rsel i
              Hvidovre og m&aring;le afstanden ud til flyvepladsen ved Soderup i
              luftlinje til 19683meter kurs 285grader. S&aring; GPS modtageren
              kan selvf&oslash;lgelig m&aring;le meget lange afstande meget
              n&oslash;jagtigt, s&aring; hvis jeg flyver v&aelig;k og lander
              f.eks. 3km ude i terr&aelig;n, er problematikken, om jeg kan komme
              s&aring; t&aelig;t ind p&aring; modellen, at jeg kan modtage data
              via Jetiboksen.
              <br />
              En mulighed er derefter at l&aelig;gge kurs og afstand ind
              p&aring; et generalstabskort og derefter finde modellen. En mere
              genial l&oslash;sning ville v&aelig;re, om det vigtige sidste
              &rdquo;bortflyvningsdata&rdquo; kunne v&aelig;re gemt i
              Jetiboksen. Hvis dette bliver en kommende feature, ville man
              ogs&aring; kunne finde en model, hvor modtageren med
              tilh&oslash;rende GPS var blevet &oslash;delagt ved en
              nedstyrtning. Dette l&aelig;gger hardwaren i jetiboksen ikke
              umiddelbart op til, der skal ogs&aring; v&aelig;re plads til
              fremtidig innovation. Det skal m&aring;ske ogs&aring; n&aelig;vnes
              at Jetisystemet, ogs&aring; uden m&aring;lemoduler, kan
              overf&oslash;re modtagerens antennesp&aelig;ndinger til Jeti
              boksen. Dette betyder, at hvis man g&aring;r i retningen mod et
              forsvundet fly, vil feltstyrken stige for at ende p&aring; max 9,
              n&aring;r man er t&aelig;t p&aring; modellen.
            </p>
            <h2>Sammenkobling af flere m&aring;lemoduler</h2>
            <p>
              Som n&aelig;vnt i den f&oslash;rste artikel om Jeti Duplex giver
              MUI modulet mulighed for at m&aring;le det n&oslash;jagtige antal
              mA/h under flyvningen. Denne
              &rdquo;br&aelig;ndstofm&aring;ler&rdquo; vil jeg under ingen
              omst&aelig;ndigheder undv&aelig;re i Fanlineren. Det er derfor
              n&oslash;dvendigt at sammenkoble MUI modulet og
              <br />
              MGPS modulet via expander modulet, hvor jeg s&aring; sluttelig
              p&aring; Jetiboxens display har valgt 4 oplysninger som vist
              p&aring; foto 3.
            </p>
            <p>
              <img src={fig6} />
              Foto 3.
            </p>
            <p>Visninger fra flere moduler.</p>
            <p>
              F&oslash;rste linje viser, hvor mange mA/h flyet har brugt og
              motorens str&oslash;mforbrug m&aring;lt med MUI modulet.
              N&aelig;ste linje viser modellens h&oslash;jde i forhold til
              startstedet, samt modellens flyvefart, m&aring;lt med MGPS
              modulet. Desv&aelig;rre er flyet landet, idet jeg har ret
              sv&aelig;rt ved at fotografere displayet under flyvningen! Hvis
              man &oslash;nsker adgang til det modul, som st&aring;r i linje 2
              tastes p&aring; h&oslash;jre pilen. Derved f&aring;r man adgang
              til MGPSmodulets fulde m&aring;linger, settings og alarmer. Man
              kommer tilbage ved at taste p&aring; venstre pilen. Expander
              modulet kan i alt tilsluttes 4 moduler, hvoraf kun to kan vises
              p&aring; en gang.&nbsp;
              <br />
              <br />
              Beskrivelse af flight-loggeren. Efter landing kan flightdata
              overf&oslash;res fra GPS modulet til en PC, hvor enkelte
              flyvninger s&aring; kan analyseres. For at g&oslash;re dette er
              det n&oslash;dvendigt at have et USB kit, som hardware
              m&aelig;ssigt kan forbinde USB modulet til Pc&rsquo;en. Se foto 4.
              Den n&oslash;dvendige software hentes over internettet fra elfly`s
              hjemmeside under MGPS produktet. Der skal installeres dels en
              driver til USB kittet, dels et program, som kan overf&oslash;re de
              loggede data i GPS modulet til PC&acute;en. Driveren er
              certificeret af Microsoft og kan derfor anvendes uden problemer
              p&aring; f&oslash;lgende Windows styresystemer: XP, Vista og 7.
              Ved hj&aelig;lp af programfilen &rdquo;getmgps1.exe&rdquo;, kan
              man nu overf&oslash;re GPSflightdata til ens PC, som
              kommaseparerede tekstfiler. Jeg har valgt at danne en linje af
              data for hvert sekund, fordi flyvning g&aring;r hurtigt. Ved at se
              n&aelig;rmere p&aring; et lille udsnit af de loggede data, se fig.
              4, kan udledes at flyet stiger fra 59,9m til 90,1m p&aring; 3
              sekunder! Se ogs&aring; fig. 5.
            </p>
            <p className={classes.breakWord}>
              $GPGGA,09400<span className={classes.red}>5</span>
              .000,5535.9099,N,01216.8092,E,1,10,0.84,
              <span className={classes.blue}>59.9</span>,M,42.0,M,,*52
              $GPGGA,09400<span className={classes.red}>6</span>
              .000,5535.9233,N,01216.8078,E,1,10,0.84,
              <span className={classes.blue}>70.2</span>,M,42.0,M,,*57
              $GPGGA,09400<span className={classes.red}>7</span>
              .000,5535.9356,N,01216.8021,E,1,10,0.84,
              <span className={classes.blue}>79.3</span>,M,42.0,M,,*50
              $GPGGA,09400<span className={classes.red}>8</span>
              .000,5535.9459,N,01216.7936,E,1,10,0.96,
              <span className={classes.blue}>90.1</span>,M,42.0,M,,*51&nbsp;
              <br />
              Fig. 4.
            </p>
            <p>
              Viser overf&oslash;rte&nbsp;flightdata&nbsp;af 3 sekunders
              flyvning.&nbsp;
              <span className={classes.red}>R&oslash;d tekst er sekunder</span>
              &nbsp;
              <span className={classes.blue}>
                Bl&aring; tekst er flyveh&oslash;jde
              </span>
            </p>
            <p>
              <img src={fig7} />
              Foto 4.
            </p>
            <p>
              Efter landing er data fra dagens flyvninger klar til download via
              USB kittet.
            </p>
            <p>
              For at disse tekstfiler kan blive til brugbar information, skal
              Pc&rsquo;en g&aring; p&aring; nettet og hente freeware programmet
              gpsvisualiser.com. I dette program kan man s&aring; indl&aelig;se
              data fra den p&aring;g&aelig;ldende flyvning. Derudover bestemmes,
              hvordan data skal vises ved at v&aelig;lge forskellige
              pr&aelig;sentations former under Choose an output format. Se foto
              5.&nbsp;
            </p>
            <p>
              <img src={fig8} />
              Foto 5.
            </p>
            <p>
              L&aelig;g m&aelig;rke til rammen Get startet now. Her foretages
              indtastningerne.
            </p>
            <p>
              Hvis der v&aelig;lges output formatet Google Map vil en flyvning
              se ud som vist p&aring; foto 6 Det er en flyvning, som stammer fra
              Electric Jet tr&aelig;ffet i Greve. Man kan tydelig se hvordan
              fanlineren har gennemfl&oslash;jet luftrummet over pladsen. Det
              skal p&aring;peges, at GPSVisualizer programmet ikke skal
              installeres, men at det ligger p&aring; en server p&aring;
              internettet til fri afbenyttelse. S&aring; hvis man vil se
              resultatet af sine flyvninger med det samme p&aring; en
              b&aelig;rbar PC ude i marken, skal PC&acute;en have internet
              adgang.&nbsp;
            </p>
            <p>
              <img src={fig9} />
              Foto 6.
            </p>
            <p>
              Greve RC-Center. Bem&aelig;rk racerbanen og s&oslash;en i bunden
              af billedet Generelt kommer flyet l&aelig;ngere ud end man
              umiddelbart tror.
              <br />
              <br />
              Hvis output formatet s&aelig;ttes til elevation format, vil man
              kunne f&aring; frembragt det billede af den samme flyvning, som
              vises p&aring; fig. 5. Her vises b&aring;de den fl&oslash;jne
              distance, flyveh&oslash;jderne og flyvefarten gennem hele
              flyvningen. Logningen starter, n&aring;r anl&aelig;gget
              t&aelig;ndes og slutter, n&aring;r det slukkes, der er s&aring;
              dannet en logfil.&nbsp;
            </p>
            <p>
              <img src={fig10} />
              Fig 5.
            </p>
            <p>
              Koordinatsystemet kan indrettes p&aring; mange forskellige
              m&aring;der efter eget valg. Bem&aelig;rk at max flyvefart er
              119km i timen i 20meters i et high-speed pass efterfulgt af en
              stigning til 98,5m. Flyveh&oslash;jden er under kontrol, fordi jeg
              har indstillet en h&oslash;jde alarm til 90m i GPS softwaren,
              s&aring;ledes at h&oslash;jden lige efter 2 kilometers flyvning
              ikke risikerer at blive overskredet.
            </p>
            <h2>Afslutning</h2>
            <p>
              Afslutning Igen m&aring; det siges at Jetis nye GPS modul med
              indbygget flight-logger, har udformet et glimrende produkt, som
              frembyder utrolig mange sp&aelig;ndende muligheder for den
              kreative modelflyver. Jeti har v&aelig;ret l&aelig;nge om at
              frigive produktet, givetvis fordi softwaren er meget omfattende,
              og fordi firmaet stiller h&oslash;je krav til kvaliteten.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
