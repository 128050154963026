import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/FlightMonitorKomponenter/fig1.jpg";
import fig2 from "../../../images/Jeti/FlightMonitorKomponenter/fig2.jpg";
import fig3 from "../../../images/Jeti/FlightMonitorKomponenter/fig3.jpg";
import fig4 from "../../../images/Jeti/FlightMonitorKomponenter/fig4.jpg";
import fig5 from "../../../images/Jeti/FlightMonitorKomponenter/fig5.jpg";
import fig6 from "../../../images/Jeti/FlightMonitorKomponenter/fig6.jpg";
import fig7 from "../../../images/Jeti/FlightMonitorKomponenter/fig7.jpg";
import fig8 from "../../../images/Jeti/FlightMonitorKomponenter/fig8.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Flight Monitor måling på komponenter</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Flight Monitor m&aring;ling p&aring; komponenter</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret oktober 2012</p>
            <h2>Indledning</h2>
            <p>
              Denne beskrivelse viser nogle forskellige anvendelses muligheder
              af et &rdquo;Black Box&rdquo; flight recorder system til modelfly.
              Data opsamles via telemetri i en Jeti Profi Box eller i den nye
              Jeti DC16/DS16 sender, med henblik p&aring; senere analyse i
              Windows programmet Jeti Flight Monitor. Systemet virker p&aring;
              samme m&aring;de som den sorte box, man altid leder efter ved
              flyveulykker.
            </p>
            <h2> M&aring;ling p&aring; et el-fly batteri under flyvning</h2>
            <p>
              <img src={fig1} />
            </p>
            <p>
              En af de vigtigste ting ved&nbsp;elflyvning&nbsp;er, at sikre at
              flyet ikke bliver fl&oslash;jet tomt for str&oslash;m p&aring; et
              uheldigt tidspunkt, og at de kostbare Li-Po batterier ikke bliver
              &oslash;delagt p&aring; grund af for stor afladning. Der anvendes
              et Jeti MUI m&aring;lemodel, som l&oslash;bende oplyser om mA/h
              forbruget. Bem&aelig;rk at man ikke med sikkerhed, hverken kan
              bruge en tidsm&aring;ling eller en sp&aelig;ndingsm&aring;ling. Se
              hvor meget str&oslash;mmen (r&oslash;d kurve) varierer under
              flyvningen, og hvor &rdquo;gummiagtig&rdquo; sp&aelig;ndingen
              (bl&aring; kurve) optr&aelig;der p&aring; grund af den varierende
              belastning. Bem&aelig;rk ogs&aring; at sp&aelig;ndingen p&aring;
              intet tidspunkt kommer under 10&nbsp;V, hvilket vil sige, at den
              enkelte Li-Po celle er over 3,0&nbsp;V p&aring; et 3 cellers
              batteri under foruds&aelig;tning af, at batteriet er i balance.
              Hvis sp&aelig;ndingen kommer under 9,9&nbsp;V afgives en lydalarm.
            </p>
            <p>
              Den gr&aring; kurve viser det opsamlede kapacitetsforbrug, som
              ender med, at der er brugt 2330&nbsp;mA/h. Dette er ca. 70&nbsp;%
              af den faktiske kapacitet p&aring; 3328&nbsp;mA/h. Batteriet er
              p&aring;stemplet 3700&nbsp;mA/h, men er 4 &aring;r gammelt og har
              haft ca. 70 flyvninger. Det skal bem&aelig;rkes, at
              Profi&nbsp;Boxen&nbsp;udsender en lydalarm, n&aring;r
              2000&nbsp;mA/h timer overskrides, s&aring;ledes at piloten
              b&oslash;r t&aelig;nke p&aring; at lande.
            </p>
            <h2>
              M&aring;ling af motorbatteriets sp&aelig;ndingsstabilitet (Ri)
            </h2>
            <p>
              <img src={fig2} />
              Bestemmelse af indre modstand.
            </p>
            <p>
              Ved tiden 9,03sek er batteristr&oslash;mmen 3,7&nbsp;A og
              sp&aelig;ndingen 11,9&nbsp;V, gassen &aring;bnes, s&aring; 3
              sekunder senere er batteristr&oslash;mmen 27,3&nbsp;A. Den
              stigende belastning f&aring;r sp&aelig;ndingen til at falde til
              11,1&nbsp;V. Vi unders&oslash;ger batteriets evne til at holde
              sp&aelig;ndingen under belastning&nbsp;d.v.s. batteriets indre
              modstand, som er
              (11,9&nbsp;V-11,1&nbsp;V)/(27,3&nbsp;A-3,7&nbsp;A) = 33,9
              milliohm. En faldende batterisp&aelig;nding giver lavere motor
              omdrejninger. Man vil kunne se forskellen p&aring; f.eks.
              25&nbsp;C, 35&nbsp;C eller 50&nbsp;C batterier.&nbsp;
            </p>
            <h2>
              M&aring;ling p&aring; temperaturp&aring;virkning af Jeti 2,4GHz
              modtager fra varmt batteri
            </h2>
            <p>
              Der &oslash;nskes unders&oslash;gt, hvor varm en modtager bliver
              ved at v&aelig;re monteret i kort afstand af et h&aring;rd
              belastet (28&nbsp;C i spidserne) Li-Po batteri i
              et&nbsp;ducted-fan fly. Isolationen er en 3mm&nbsp;depronplade.
              Pr&oslash;ven er udf&oslash;rt p&aring; jorden som en simuleret
              flyvning. Bem&aelig;rk at temperaturen forts&aelig;tter med at
              stige efter at str&oslash;mmen er stoppet, p&aring; grund af
              forsinkelsen i varmeudbredelsen i Li-Po pakken. Max tilladelig
              temperatur p&aring; pakken er 60 grader, som er t&aelig;t p&aring;
              at blive overskredet. Under flyvning vil forholdene v&aelig;re
              bedre p.gr. af afk&oslash;ling.
            </p>
            <p>
              <img src={fig3} />
              Temp. A er m&aring;lt p&aring; batteriet. Temp. B er m&aring;lt
              p&aring; Jeti modtageren.
            </p>
            <p>
              Denne kan t&aring;le 85&nbsp;C grader. Under flyvning vil
              forholdene v&aelig;re bedre p. gr. af afk&oslash;ling. Der findes
              eksempler p&aring;, at modtagere om sommeren er holdt op med at
              virke p.gr. af opvarmning fra motorbatteriet hvis komponenterne
              pakkes ind uden ventilation. P&aring; tilsvarende m&aring;de vil
              der kunne m&aring;les p&aring; fartregulatorer (ESC) og motorer,
              p&aring; jorden og under flyvning.
            </p>
            <h2>Belastningsm&aring;linger p&aring; en&nbsp;Dualsky&nbsp;BEC</h2>
            <p>
              Problemet er her, at en motorregulator til en
              b&oslash;rstel&oslash;s motor med indbygget BEC har svigtet. En
              overbelastning af en BEC med serieregulator kan medf&oslash;re, at
              regulatoren enten afbryder eller kortslutter. Ved en afbrydelse er
              der ikke forsyningssp&aelig;nding p&aring; modtager
              og&nbsp;servoer, alts&aring; et totalt radiosvigt. Ved en
              kortslutning vil der blive lagt fuld motorbatterisp&aelig;nding ud
              p&aring; modtager,&nbsp;servoer&nbsp;og m&aring;lemoduler. Det er
              et problem at fastsl&aring; belastningen p&aring; en
              serieregulator i en indbygget ESC.
            </p>
            <p>
              I stedet for anvendes derfor en udvendig switch mode BEC, som
              f.eks.&nbsp;Dualsky&nbsp;type VR-3. Dennes ydeevne vil i det
              efterf&oslash;lgende blive unders&oslash;gt grundigt.&nbsp;
            </p>
            <p>
              <img src={fig4} />
              Dualsky&nbsp;VR-3 switch mode BEC med belastningsmodstande. Input
              5,5&nbsp;V til 23&nbsp;V.
            </p>
            <p>
              Output max 3&nbsp;Amp kontinuerligt. Udgangssp&aelig;nding
              5&nbsp;V eller 6&nbsp;V. Switch frekvens 300kHz. Max virkningsgrad
              92&nbsp;%.
            </p>
            <p>
              <img src={fig5} />
              Der m&aring;les her p&aring; en&nbsp;Dualsky&nbsp;BEC p&aring;
              v&aelig;rksted.
            </p>
            <p>
              BEC`en&nbsp;m&aring;les f&oslash;rst uden belastning og derefter
              med en belastning p&aring; 1,2&nbsp;A, 2,1&nbsp;A og 2,6&nbsp;A.
              (Bl&aring; kurve) Den gr&aring; kurve viser den tilh&oslash;rende
              udgangssp&aelig;nding, som falder fra 5,2&nbsp;V til 4,5&nbsp;V,
              mens den r&oslash;de kurve viser temperaturstigningen fra
              24&nbsp;C til 59&nbsp;C grader.
            </p>
            <p>
              <img src={fig6} />
            </p>
            <p>
              P&aring; denne kurve er&nbsp;BECen&nbsp;monteret i en model, hvor
              jeg vedvarende p&aring; v&aelig;rkstedet bev&aelig;ger alle
              rorflader for at belaste&nbsp;BECen&nbsp;optimalt.
              Sluttemperaturen er 30 grader efter ca. 11 minutter.
            </p>
            <p>
              <img src={fig7} />
            </p>
            <p>
              Under ovenst&aring;ende test kontrolleres modtagerens
              forsyningssp&aelig;nding. Der forekommer sp&aelig;ndingsdyk,
              n&aring;r flere&nbsp;servoerstarter samtidig, men alt virker
              perfekt. Det ideelle vil v&aelig;re, at forsyningssp&aelig;ndingen
              er helt stabil. Sp&aelig;ndingen skal under 3,2&nbsp;V, f&oslash;r
              at Jeti modtagere svigter.
            </p>
            <p>
              <img src={fig8} />
            </p>
            <p>
              Derefter flyves der ved en omgivelsestemperatur p&aring; ca. 20
              grader. Bem&aelig;rk at temperaturen (R&oslash;d kurve) efter
              start falder p.gr.af luftafk&oslash;lingen.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
