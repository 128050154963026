import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig1.jpg";
import fig2 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig2.jpg";
import fig3 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig3.jpg";
import fig4 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig4.jpg";
import fig5 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig5.jpg";
import fig6 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig6.jpg";
import fig7 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig7.jpg";
//import fig8 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig8.jpg";
import fig9 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig9.jpg";
import fig10 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig10.jpg";
import fig11 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig11.jpg";
import fig12 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig12.jpg";
import fig13 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig13.jpg";
import fig14 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig14.jpg";
import fig15 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig15.jpg";
import fig16 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig16.jpg";
import fig17 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig17.jpg";
import fig18 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig18.jpg";
import fig19 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig19.jpg";
import fig20 from "../../../images/Andet/KonstruktionAfEnCompositAirliner/fig20.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Konstruktion af en composit airliner</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Konstruktion af en composit airliner</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret marts 2006.</p>
            <h2>Inspiration og valg af flytype</h2>
            <p>
              Flere forskellige kilder, ideer og nye materialer har inspireret
              mig til at g&aring; i gang med dette projekt.
            </p>
            <p>
              Der kan f&oslash;rst og fremmest n&aelig;vnes det store
              &aring;rlige EL-tr&aelig;f i Aspbach i Sydtyskland, hvor der
              fremvises og flyves med et stort antal forskellige elfly, mange i
              nyeste teknologi. (Se link) Egentlig ville jeg have bygget en
              Airbus 320, men efter at have set John Borgens flotte Airbus, stod
              det mig klart at der var to alvorlige problemer. Det ene var, at
              det ville blive sv&aelig;rt at starte fra gr&aelig;s, det andet
              var de lavt h&aelig;ngende ducted fan motorer, som er meget
              t&aelig;t p&aring; jorden. Efter nogen s&oslash;gen i Janes tykke
              bog om alverdens flytyper besluttede jeg mig til at anvende en
              Donier 328 som udgangspunkt. Denne flytype findes b&aring;de som
              turboprop og som rent jet fly det var desuden h&oslash;jvinget.
              For at f&aring; styr p&aring; flyets pr&aelig;stationer startede
              jeg med at unders&oslash;ge ydeevnen af en 90 mm Wemotec Midi Fan
              trukket af en b&oslash;rstel&oslash;s Mega 30/3 motor. (Se mine
              tidligere artikler i Modelflyve Nyt nr.1 og nr.5 2002.
            </p>
            <h2>Composit materialer og v&aelig;gt budget</h2>
            <p>
              Udfra m&aring;lingerne p&aring; motorerne stod det mig klart at
              der skulle bygges s&aring; let og st&aelig;rkt som muligt hvilket
              bet&oslash;d at jeg ville anvende en ABCD kombination af
              materialerne Aluminium, Balsa, Carbon og Depron.
              Sammens&aelig;tningen af materialer med forskellige egenskaber
              ben&aelig;vnes composit. Denne teknik pr&aelig;ger i &oslash;vrigt
              udviklingen af nye passagerfly b&aring;de hos Airbus og Boeing
              hvor v&aelig;gten er altafg&oslash;rende for &oslash;konomien i
              fremtidens fly. St&oslash;rre og st&oslash;rre dele af
              konstruktionen erstattes af f.eks. kulfiber.
            </p>
            <p>
              Nedenst&aring;ende skema sammenligner nogle af de materialer som
              er anvendt. L&aelig;g f.eks. m&aelig;rke til at 1 dm<sup>2</sup>
              &nbsp;1 mm balsafiner er tungere end 1 dm<sup>2</sup>&nbsp;2mm
              depron. Oplysningen v&aelig;gt pr. dm eller dm<sup>2</sup>&nbsp;er
              vigtig fordi jeg satte mig for at finde ud af hvad alle de
              indg&aring;ende materialer ville veje i et v&aelig;gtbudget og
              s&aring; kontrollere om det passede efterh&aring;nden som flyet
              blev bygget. Eksempelvis blev der anvendt i alt 11,2 m 4mm
              kulr&oslash;r = 112dm x 1,51g =169g. Tallene blev indsat i et
              regneark og afsl&oslash;rede flere forskellige overraskelser.
              F.eks. vejer de to 3 fasede ledninger mellem controllerne og de
              b&oslash;rstel&oslash;se motorer lige s&aring; meget som 4
              servoer.!!! Se fig1.
            </p>
            <p>
              <img src={fig1} />
              <br />
              Fig.1 109g til ledninger til motorer = 4 servoer
            </p>
            <p>Det er tankev&aelig;kkende.&nbsp;</p>
            <p>Langsg&aring;ende h&oslash;jstyrke materialer</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Materiale</p>
                  </TableCell>
                  <TableCell>
                    <p>V&aelig;gtfylde</p>
                  </TableCell>
                  <TableCell>
                    <p>V&aelig;gt pr dm</p>
                  </TableCell>
                  <TableCell>
                    <p>Leverance</p>
                    <p>m&aring;l</p>
                  </TableCell>
                  <TableCell>
                    <p>Egenskaber</p>
                  </TableCell>
                  <TableCell>
                    <p>Limes med</p>
                  </TableCell>
                  <TableCell>
                    <p>Leverand&oslash;r</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Kulr&oslash;r 3mm&oslash;</p>
                  </TableCell>
                  <TableCell>
                    <p>1,7</p>
                  </TableCell>
                  <TableCell>
                    <p>1,2g</p>
                  </TableCell>
                  <TableCell>
                    <p>100cm</p>
                  </TableCell>
                  <TableCell rowSpan={3}>
                    <p>Meget h&oslash;j styrke i l&aelig;ngde retning.</p>
                  </TableCell>
                  <TableCell>
                    <p>Cyano</p>
                    <p>Araldit</p>
                  </TableCell>
                  <TableCell>
                    <p>Hobbyforhandler</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Kulr&oslash;r 4mm&oslash;</p>
                  </TableCell>
                  <TableCell>
                    <p>1,7</p>
                  </TableCell>
                  <TableCell>
                    <p>1,51g</p>
                  </TableCell>
                  <TableCell>
                    <p>165cm</p>
                  </TableCell>
                  <TableCell>
                    <p>Cyano</p>
                    <p>Araldit</p>
                  </TableCell>
                  <TableCell rowSpan={2}>
                    <p>K&oslash;benhavns Dragecenter</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Kulr&oslash;r</p>
                    <p>6mm&oslash;</p>
                  </TableCell>
                  <TableCell>
                    <p>1,7</p>
                  </TableCell>
                  <TableCell>
                    <p>1,94g</p>
                  </TableCell>
                  <TableCell>
                    <p>165cm</p>
                  </TableCell>
                  <TableCell>
                    <p>Cyano</p>
                    <p>Araldit</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>&nbsp;Overflader og bekl&aelig;dning</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Materiale</p>
                  </TableCell>
                  <TableCell>
                    <p>V&aelig;gtfylde</p>
                  </TableCell>
                  <TableCell>
                    <p>V&aelig;gt pr dm2</p>
                  </TableCell>
                  <TableCell>
                    <p>Leverance</p>
                    <p>m&aring;l</p>
                  </TableCell>
                  <TableCell>
                    <p>Egenskaber</p>
                  </TableCell>
                  <TableCell>
                    <p>Limes med</p>
                  </TableCell>
                  <TableCell>
                    <p>Leverand&oslash;r</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Balsa 1mm</p>
                    <p>finer</p>
                  </TableCell>
                  <TableCell>
                    <p>0,08-0,15</p>
                  </TableCell>
                  <TableCell>
                    <p>1,9g</p>
                  </TableCell>
                  <TableCell>
                    <p>10 x100cm</p>
                  </TableCell>
                  <TableCell>
                    <p>Varierende v&aelig;gtfylde</p>
                  </TableCell>
                  <TableCell>
                    <p>Cyano, Hvid lim</p>
                  </TableCell>
                  <TableCell>
                    <p>Hobby</p>
                    <p>forhandler</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Depron</p>
                  </TableCell>
                  <TableCell>
                    <p>0,056</p>
                  </TableCell>
                  <TableCell>
                    <p>1,12g</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca 80x120cm</p>
                  </TableCell>
                  <TableCell>
                    <p>Kan kold formes ved 90 grader.</p>
                    <p>Har en bukke retning.</p>
                  </TableCell>
                  <TableCell>
                    <p>UHU Por kontakt lim</p>
                    <p>Hvid lim.</p>
                  </TableCell>
                  <TableCell>
                    <p>Hobbyforhandler</p>
                    <p>eller</p>
                    <p>Tutien &amp; Koch</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Aluminium</p>
                    <p>folie Monarflex</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca 0,46g</p>
                  </TableCell>
                  <TableCell>
                    <p>Rulle</p>
                    <p>5cm x 30m</p>
                    <p>0,04mm</p>
                    <p>tykt</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Meget st&aelig;rk selvkl&aelig;bende blank metalliseret
                      tape
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>Lim p&aring; bag side</p>
                  </TableCell>
                  <TableCell>
                    <p>T&oslash;mmerhandel eller</p>
                    <p>VVS - butik</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Maling</p>
                  </TableCell>
                  <TableCell>
                    <p>Pr&oslash;vemaling</p>
                    <p>D&aring;se med</p>
                    <p>0,38 liter</p>
                    <p>Pris 25 kr!!</p>
                  </TableCell>
                  <TableCell>
                    <p>Vandbaseret.</p>
                    <p>Kan leveres i alle mulige farver.</p>
                  </TableCell>
                  <TableCell>
                    <p>Flugger farvehandel</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>V&aelig;gtforbrug</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <p>Prop ver.</p>
                  </TableCell>
                  <TableCell>
                    <p>Ducted fan</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Vinge</p>
                  </TableCell>
                  <TableCell>
                    <p>536g</p>
                  </TableCell>
                  <TableCell>
                    <p>536g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Krop med understel</p>
                  </TableCell>
                  <TableCell>
                    <p>930g</p>
                  </TableCell>
                  <TableCell>
                    <p>930g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Haleplan</p>
                  </TableCell>
                  <TableCell>
                    <p>115g</p>
                  </TableCell>
                  <TableCell>
                    <p>115g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>2 stk Motorhuse og prop</p>
                  </TableCell>
                  <TableCell>
                    <p>205g</p>
                  </TableCell>
                  <TableCell>
                    <p>ingen</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>2 stk Motorer Mega30/3</p>
                  </TableCell>
                  <TableCell>
                    <p>428g</p>
                  </TableCell>
                  <TableCell>
                    <p>428g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>2 stk Wemotec fan</p>
                  </TableCell>
                  <TableCell>
                    <p>ingen</p>
                  </TableCell>
                  <TableCell>
                    <p>400g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>TMM reg 2 stk. og ledninger</p>
                  </TableCell>
                  <TableCell>
                    <p>260g</p>
                  </TableCell>
                  <TableCell>
                    <p>272g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Modtager og 4 stk. servoer</p>
                  </TableCell>
                  <TableCell>
                    <p>124g</p>
                  </TableCell>
                  <TableCell>
                    <p>124g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Rx. bat</p>
                  </TableCell>
                  <TableCell>
                    <p>59g</p>
                  </TableCell>
                  <TableCell>
                    <p>59g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>20 stk. GP 3300mA/h</p>
                  </TableCell>
                  <TableCell>
                    <p>1334g</p>
                  </TableCell>
                  <TableCell>
                    <p>1334g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Total v&aelig;gt</p>
                  </TableCell>
                  <TableCell>
                    <p>3991g</p>
                  </TableCell>
                  <TableCell>
                    <p>4198g</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Bygge pr&oslash;ver</h2>
            <p>
              For at komme i gang startede jeg med en pr&oslash;ve
              best&aring;ende af 20 cm lang krop med en diameter p&aring; 18 cm
              og en pr&oslash;ve vinge med en korde p&aring; 30cm.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Fig.2 4mm kulr&oslash;r og balsa ringe.
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig.3 Bekl&aelig;des med 2 mm Depron.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig 4 Bekl&aelig;dt og malet v&aelig;gt 29g pr 100 mm krop ved en
              diameter p&aring; 180mm.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig.5 Balsa ribber og 4 mm kulr&oslash;r p&aring; 2 mm depron
              plade.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig.6 Fors&oslash;gsvinge med balsa for- og bagkant.
            </p>
            <p>Bekl&aelig;dt med 2 mm depron og Monarflex tape.</p>
            <p>
              V&aelig;gt pr.dm<sup>2</sup>&nbsp;er 8g. Det er let!
            </p>
            <p>
              <img src={fig7} />
            </p>
            <p>
              Fig. 7 Fremstilling af 4 mm balsa webbing vhj af dremmel
              fr&aelig;ser med kuglehoved v&aelig;rkt&oslash;j.
            </p>
            <p>
              Webbing limes med cyano mellem &oslash;verste og nederste
              kulr&oslash;r med &aring;rerne p&aring; h&oslash;jkant.
            </p>
            <h2>Tegnings-, konstruktions- og simulationsprogrammer</h2>
            <p>
              Efter at have godkendt de viste pr&oslash;ver begyndte jeg at
              tegne flyet samtidig med at v&aelig;gtbereningen fandt sted. Til
              tegning af flyet blev anvendt programmet Micrografx Designer Ver
              9.0 som er glimrende egnet. Tegneprogrammet m&aring; p&aring; den
              ene side ikke v&aelig;re for tungt p&aring; den anden side skal
              det kunne l&oslash;se de opgaver, som kr&aelig;ves for at kunne
              tegne et modelfly. Se fig.8 som eksempel. Endvidere kr&aelig;ves
              at der kan udskrives store 1:1 tegninger, s&aring;ledes at der kan
              bygges oven p&aring; tegningen. Udprintningen l&oslash;ses ved at
              lime A4 sider sammen. Til kroppen medgik 18stk A4 ark.
            </p>
            <p>
              Tegneprocessen kr&aelig;ver nogen tilv&aelig;nning og
              f&oslash;lgende gode r&aring;d kan gives.
            </p>
            <ul>
              <li>
                <p>
                  Anvend en mus med scrollwheel, s&aring; du hurtigt kan zoome
                  ind og ud.
                </p>
              </li>
              <li>
                <p>Tegn omrids, alts&aring; alle yderm&aring;l f&oslash;rst.</p>
              </li>
              <li>
                <p>Opbyg derefter strukturen inde i konstruktionen.</p>
              </li>
              <li>
                <p>Anvend evt. flere lag i tegningen.</p>
              </li>
              <li>
                <p>Anvend kopi funktionen s&aring; meget som muligt.</p>
              </li>
              <li>
                <p>
                  Oprette et bibliotek af komponenter som kan hentes med et
                  museklik
                </p>
              </li>
              <li>
                <p>
                  Anvend symmetri linier og tegn f.eks. kun venstre haleplan
                  hvorefter h&oslash;jre kan tegnes ved at spejlvende tegningen.
                </p>
              </li>
            </ul>
            <p>
              Om konstruktionen kan siges at man &oslash;nsker at anvende de
              enkelte materialer s&aring; optimalt som muligt, med henblik
              p&aring; at optage og fordele de kr&aelig;fter som p&aring;virker
              flyet. Hver enkelt stump er vendt og drejet med hensyn til
              funktion, styrke og v&aelig;gt.
            </p>
            <p>
              Til udtegning at profilerne anvendes et profilprogram, som kan
              hentes p&aring; nettet under adressen Profili2.com. Programmet kan
              st&aelig;rkt anbefales. Som vingeprofil er anvendt MB253515 som er
              meget stall sikkert og som er udviklet til modelfly.
            </p>
            <p>
              For at f&aring; styr p&aring; flyets pr&aelig;stationer er der
              udf&oslash;rt omfattende afpr&oslash;vninger i simulations
              programmet MotorCalc ver. 8 som kan downloades fra nettet p&aring;
              www motocalc.com og anvendes gratis i fuld udgave i 30 dage.
              Derefter betales programmet med 35$. Programmet kan st&aelig;rkt
              anbefales for alle som vil optimere EL-fly. Det er s&aelig;rlig
              vigtigt for mig at programmet indeholder data p&aring; Wemotec
              fanen i sin database. Det anbefales at man s&aelig;tter sig
              grundigt ind i manualen. Det er en foruds&aelig;tning at man
              forst&aring;r engelsk og har grundl&aelig;ggende kendskab til
              aerodynamik.
            </p>
            <h2>Bygning</h2>
            <p>De viste billeder skal illustrere dele af byggeprocessen.</p>
            <p>
              <img src={fig9} />
              <br />
              Fig. 9 Der startes med lastrummet.
            </p>
            <p>
              <img src={fig10} />
              <br />
              Fig. 10 Derefter opbygges en kasse af kulr&oslash;r og balsa
              afstands stykker.
            </p>
            <p>
              <img src={fig11} />
              <br />
              Fig. 11 Kulr&oslash;r samles med et lille stykke&nbsp;cykeleger og
              bl&aring; araldit.
            </p>
            <p>
              <img src={fig12} />
              <br />
              Fig. 12 Kroppens kulr&oslash;r ender i en balsa X-finer ring.
            </p>
            <p>
              <img src={fig13} />
            </p>
            <p>
              Fig.13 Balsa ringe bygges af 4 lag 1x6 mm balsastrimler som er
              bl&oslash;dgjort i et badekar limes med cyano. Vejer 3g pr ring.
            </p>
            <p>
              <img src={fig14} />
              <br />
              Fig. 14 Delvis opbygget krop og vinge.
            </p>
            <p>
              <img src={fig15} />
              <br />
              Fig. 15 N&aelig;se opbygges af styropor ringe som slibes
              og&nbsp;bekl&aelig;des med glasfiber.
            </p>
            <p>
              <img src={fig16} />
              <br />
              Fig. 16 Propeludgaven efter gennemf&oslash;rt
              pr&oslash;veflyvning.
            </p>
            <p>
              <img src={fig17} />
              <br />
              Fig. 17 Der er her skiftet til ducted fan fremdrift.
            </p>
            <h2>Netv&aelig;rk</h2>
            <p>
              En interessant ting ved at anvende computeren, er at tegninger
              m.m. har kunnet overf&oslash;res via Internettet til en af mine
              klubkammerater, som dels har bygget et fly p&aring; basis af
              tegningen, dels har kunnet give forslag og kritik. Jeg har desuden
              v&aelig;ret i kontakt med andre dygtige model
              konstrukt&oslash;rer, som har kunnet give gode ideer til
              konstruktionen.
            </p>
            <p>
              <img src={fig18} />
              <br />
              Fig. 18 Ole Rosenbergs version af flyet.
            </p>
            <p>Alle tegninger overf&oslash;rt via nettet!!!</p>
            <h2>Fremdrift systemer og afpr&oslash;vning</h2>
            <p>
              Som omtalt kan flyet enten tr&aelig;kkes af propeller eller ducted
              fans. Som propeller anvendes et tysk system fra firmaet Ramoser,
              hvor man i et s&aelig;rligt nav kan udskifte propelbladene og
              samtidig kan justere stigningen mellem 2 og 10 tommer,
              desv&aelig;rre dog ikke under flyvningen! Ved at simulere
              forskellige kombinationer i MotoCalc kan der opn&aring;s en
              optimal ydeevne. I propel versionen anvendes 2 adskilte 10 cellers
              pakker GP 3300 mAh som drivkraft. I ducted fan versionen
              serieforbindes de to pakker til 20 celler og der etableres en
              parallelforbindelse til de to controllere. Se fig. 20. P.t.
              overvejer jeg at anvende 2 pakker Kokam 7S 4800 SHD, men batteri
              udviklingen er nu s&aring; hurtig, at der f&oslash;rst m&aring;
              k&oslash;bes ind i allersidste &oslash;jeblik.
            </p>
            <p>
              Som controller er anvendt TMM 5024 Expert , som er programmeret
              til at k&oslash;re en langsom reduktion af omdrejningerne
              n&aring;r akkumulatoren er ved at v&aelig;re t&oslash;mt. Det er
              vigtigt at der ikke opst&aring;r et motor cut off p&aring; et to
              motoret fly, for at undg&aring; et sk&aelig;vt tr&aelig;k.
              Controlleren skal endvidere kunne klare 20 celler fordi de to 10
              cellers pakker kobles op i serie n&aring;r der flyves ducted fan
              version. Controlleren er endvidere programmeret til langsom
              acceleration (660msek) for ikke at p&aring;virke
              opsp&aelig;ndingen af fanen mere end n&oslash;dvendig ved start af
              motor n&aring;r der g&aring;s fra 0 til 24000 omdr./min.
            </p>
            <p>
              Man skal v&aelig;re opm&aelig;rksom p&aring; at n&aring;r
              afstanden mellem batteri og motor &oslash;nskes for&oslash;get i
              et b&oslash;rstel&oslash;st system g&oslash;res dette bedst ved at
              forl&aelig;nge de 3 fasede ledning mellem controller og motor. Se
              fig. 1.
            </p>
            <p>
              Ledningerne flettes sammen og skal v&aelig;re n&oslash;jagtig lige
              lange. Dette g&oslash;res for at de elektriske st&oslash;jfelter
              udballanceres og for at der ikke opst&aring;r timing problemer.
              Denne ledningsforl&aelig;ngelse, de lange kulr&oslash;r samt
              aluminiumsbekl&aelig;dningen gjorde at der blev foretaget en meget
              grundig afpr&oslash;vning p&aring; jorden af radioforbindelsens
              kvalitet. Der testes med nedsl&aring;et antenne og med en afstand
              mellem sender og modtager hvor failsafe er p&aring; kanten til at
              blive aktiveret af Futaba 1024 PCM systemet. Her drejes flyet i
              alle mulige retninger samtidig med at motoromdrejningerne bliver
              &aelig;ndret fra 0 til max. En afpr&oslash;vning af denne type
              b&oslash;r du udf&oslash;re p&aring; et hvert elfly ogs&aring;
              selvom der ikke opst&aring;r gnister i den
              b&oslash;rstel&oslash;se motor.
            </p>
            <p>
              <img src={fig19} />
              <br />
              Fig.19 Billedet viser de forskellige halvf&aelig;rdige
              komponenter, som anvendes til fremdriften.
            </p>
            <p>
              Der kan skiftes mellem propel og fan idet enhederne er monteret
              p&aring; vingen med 2stk 4 mm nylonskruer. De magnetiske
              afbalancerings apparat er n&oslash;dvendigt.
            </p>
            <p>
              <img src={fig20} />
              <br />
              Fig 20. Adapter som kobler de to batterier i serie og derefter
              parallel forsyner de to controllere.
            </p>
            <h2>Pr&oslash;veflyvninger og ydeevne</h2>
            <p>
              Pr&oslash;veflyvningerne er gennemf&oslash;rt efter et n&oslash;je
              program. F&oslash;rste propelflyvning blev foretaget med et setup
              af tyngdepunktet ved 28% af plankorden og en m&aring;lt
              indfaldsvinkel p&aring; 3grader.
            </p>
            <p>
              Efter en meget lang rullestr&aelig;kning blev foretaget rotation
              med all moving h&oslash;jderoret og flyet gik perfekt i luften.
              Store dele af den 8 minutter lange flyvning blev videooptaget og
              det blev her afsl&oslash;ret ved et efterf&oslash;lgende
              gennemsyn, at flyet var tilb&oslash;jeligt til at vippe frem og
              tilbage omkring b&aring;de retnings- og tv&aelig;rakse. Analyser
              af begrebet dutch roll blev foretaget og derefter blev det
              besluttet at h&aelig;ve de 1,3kg tunge batterier op inde i
              kroppen, s&aring;ledes at afstanden op til opdriftcenteret blev
              mindre. Der var simpelthen opst&aring;et en pendul virkning ved at
              have den store v&aelig;gt liggende s&aring; langt nede. Dette
              bragte svingningerne til oph&oslash;r.
            </p>
            <p>
              Efter fire flyvninger blev der skiftet til fan systemerne. Her er
              s&aring; afpr&oslash;vningen g&aring;et i st&aring;, idet det
              viste sig at den ene controller lukkede motoren ned n&aring;r man
              var t&aelig;t p&aring; max ydelse. Af fejlkoden, som best&aring;r
              af bip-lyde, kunne konkluderes at motoren havde trukket for meget
              str&oslash;m. Dette kunne ikke have sin rigtighed, idet
              motorstr&oslash;mmen blev m&aring;lt til 27Amp og controlerens max
              str&oslash;m er opgivet til 50Amp. Konklusionen var at der defekte
              transistorer i udgangen og at controllerens software kunne
              identificere fejlen og lukke controlleren. Den blev sendt s&aring;
              sendt til reparation. Man b&oslash;r her v&aelig;re
              opm&aelig;rksom p&aring; at udviklingen p&aring; dette
              omr&aring;de g&aring;r s&aring; st&aelig;rkt at man ikke skal
              k&oslash;be sin hardware f&oslash;r i sidste &oslash;jeblik og at
              man ikke skal v&aelig;re sikker p&aring; at kunne k&oslash;be en
              controller som er mage til den man har. Problemet her er at de to
              controllere under flyvningen skal opf&oslash;res sig helt ens og
              at de ikke m&aring; have forskellige versioner af software,
              s&aring; man kan risikere, at skulle k&oslash;be ikke en, men to.
              Der udvikles tilstadighed nye software versioner. Da reparationen
              ikke lykkedes byttede firmaet de to controllere til nyeste version
              af TMM 5024/3 Ver 3.00 Expert serie, som kan programmeres via min
              computers USB port, ligesom data omkring max str&oslash;m,
              sp&aelig;nding og temperatur efter flyvningen kan afl&aelig;ses.
              Denne facilitet er yderst nyttig specielt er udl&aelig;sningen af
              max omdrejninger ved take-off en god ting.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Propel udgave</p>
                  </TableCell>
                  <TableCell>
                    <p>Ducted Fan udgave</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Praktisk flyvetid</p>
                  </TableCell>
                  <TableCell>
                    <p>14min</p>
                  </TableCell>
                  <TableCell>
                    <p>8min</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Max stig</p>
                  </TableCell>
                  <TableCell>
                    <p>4,4m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>4,5m/sek</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Synkehastighed i tomgang</p>
                  </TableCell>
                  <TableCell>
                    <p>1,5m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>1,6m/sek</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Stall speed</p>
                  </TableCell>
                  <TableCell>
                    <p>10,5m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>11,0m/sek</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Cruise speed*</p>
                  </TableCell>
                  <TableCell>
                    <p>13,3m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>14m/sek</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max speed</p>
                  </TableCell>
                  <TableCell>
                    <p>21m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>26m/sek</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max motor omdrejninger statisk</p>
                  </TableCell>
                  <TableCell>
                    <p>11200 RPM</p>
                  </TableCell>
                  <TableCell>
                    <p>24400 RPM</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max str&oslash;m pr motor ved start</p>
                  </TableCell>
                  <TableCell>
                    <p>33A</p>
                  </TableCell>
                  <TableCell>
                    <p>27A</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max statisk tr&aelig;k/tryk</p>
                  </TableCell>
                  <TableCell>
                    <p>2,4kg</p>
                  </TableCell>
                  <TableCell>
                    <p>2,6kg</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max flyve distance</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca.17km</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca 14km</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max h&oslash;jde **</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca 1500m</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca 1000m</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Opladetid GP3300 NiMh ***</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca 65min</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca 65min</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>*Bedste forhold mellem opdrift og modstand</p>
            <p>** Husk BL for modelfly max 100m.</p>
            <p>***Ved 3,5A konstant str&oslash;m, fra helt afladt.</p>
            <h2>Videre udvikling</h2>
            <p>
              Det videre forl&oslash;b vil v&aelig;re koncentreret omkrig Li-Po
              batterier med tilh&oslash;rende elektronik, hvor der sker s&aring;
              hurtig en udvikling at mulighederne skifter uge for uge. Et
              eksempel er at den Li-Po ballancer, som jeg byggede med min lille
              loddekolbe ( Se Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt.dk/modelflyvenyt/2005/Modelflyvenyt%205%202005.pdf"
              >
                Nr 5 2005
              </Link>{" "}
              side 16) nu kan erstattes af en boks indeholdende en
              microprocessor, som kan meget mere og som kun koster 300kr.
              (Hyperion EOS LBA6 Litium Balance Adapter)
            </p>
            <p>
              N&aelig;ste generation af flyet skal v&aelig;re forsynet med flaps
              og optr&aelig;kkeligt understel. V&aelig;gten skal
              &rdquo;betales&rdquo; af de lettere Li-Po celler.&nbsp;
            </p>
            <h2>Flydata</h2>
            <p>Sp&aelig;ndvidde 196cm.</p>
            <p>Kropsl&aelig;ngde 174 cm.</p>
            <p>V&aelig;gt som propelfly 3991g.</p>
            <p>V&aelig;gt som ducted fan fly 4198g.</p>
            <p>Profil MB253515, stall sikkert til 18 grader.</p>
            <p>Planareal 56 dm.</p>
            <p>Haleplan 12dm All moving med gradm&aring;ler.</p>
            <p>Planbelastning 70,4g/dm som propfly. 75g/dm som fan fly.</p>
            <p>2 stk motorer Mega 30/3.</p>
            <p>
              2 stk propeller Ramoser 3 bladet variabel stigning eller 2stk
              Wemotec 90mm Midi fan.
            </p>
            <p>2 stk controller TMM 5024/3 Expert uden BEC.</p>
            <p>
              Controlleren kan max arbejde med 24 celler NiCd eller 8 Li-Po.
            </p>
            <p>
              Modtager Futaba dobbelt super med PCM. Separat modtager batteri
              225mA/h 4 celler.
            </p>
            <p>
              2 x 10stk GP 3300 som adskilt forsyning til hver controller i
              propel udgaven. I ducted udgaven s&aelig;ttes de to batterier i
              serie.
            </p>
            <h2>Links</h2>
            <p>
              Til inspiration: link til John Borgens skalabyggeri i top klasse.
            </p>
            <p>
              <u>
                <Link
                  target="_blank"
                  href="http://www.rcgroups.com/forums/showthread.php?t=208512"
                >
                  http://www.rcgroups.com/forums/showthread.php?t=208512
                </Link>
              </u>
            </p>
            <p>Billeder fra Aspbach</p>
            <p>
              <Link
                target="_blank"
                href="https://elflytec.dk/Billeder/Aspach-2005"
              >
                https://elflytec.dk/Billeder/Aspach-2005
              </Link>
            </p>
            <p>Program til udtegning af profiler</p>
            <p>
              <u>
                <Link target="_blank" href="http://www.profili2.com/">
                  http://www.Profili2.com
                </Link>
              </u>
            </p>
            <p>
              Program til pr&aelig;stationsberegning og optimering af el-fly
            </p>
            <p>
              <u>
                <Link target="_blank" href="http://www.motocalc.com/">
                  http://www.motocalc.com
                </Link>
              </u>
            </p>
            <p lang="en-GB">Ramosa propelsystemer</p>
            <p>
              <u>
                <Link target="_blank" href="http://www.ramoser.de/">
                  www.ramoser.de
                </Link>
              </u>
            </p>
            <p>Ducted fan system</p>
            <p>
              <u>
                <Link target="_blank" href="http://www.wemotec.com/">
                  http://www.wemotec.com
                </Link>
              </u>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
