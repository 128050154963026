import React, { Component } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";
import {
  Grid,
  createMuiTheme,
  withTheme,
  MuiThemeProvider
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import "./App.css";
import Header from "./components/Header";
import FrontPage from "./components/FrontPage";
import Kfk from "./components/Albums/Kfk";
import Comet from "./components/Albums/Comet";
import Aspach2005 from "./components/Albums/Aspach2005";
import JetiDuplex24GHzTeknik from "./components/Artikler/Jeti/JetiDuplex24GHzTeknik";
import JetiDuplexGPSModtager from "./components/Artikler/Jeti/JetiDuplexGPSModtager";
import JetiDuplexTrueAirspeed from "./components/Artikler/Jeti/JetiDuplexTrueAirspeed";
import LogningOgAnalyseJetiGPS from "./components/Artikler/Jeti/LogningOgAnalyseJetiGPS";
import JetiBoxProfiExDel1 from "./components/Artikler/Jeti/JetiBoxProfiExDel1";
import JetiBoxProfiExDel2 from "./components/Artikler/Jeti/JetiBoxProfiExDel2";
import FlightMonitor24GHz from "./components/Artikler/Jeti/FlightMonitor24GHz";
import FlightMonitorFlyvninger from "./components/Artikler/Jeti/FlightMonitorFlyvninger";
import FlightMonitorKomponenter from "./components/Artikler/Jeti/FlightMonitorKomponenter";
import BeskrivelseAfJetisDatasystem from "./components/Artikler/Jeti/BeskrivelseAfJetisDatasystem";
import LipoTeknikITeoriOgPraksis from "./components/Artikler/LiPo/LipoTeknikITeoriOgPraksis";
import MaalingerPaaLipoBatterier from "./components/Artikler/LiPo/MaalingerPaaLipoBatterier";
import LipoContraLifepo4Batterier from "./components/Artikler/LiPo/LipoContraLifepo4Batterier";
import VaerdAtVideOmLipo from "./components/Artikler/LiPo/VaerdAtVideOmLipo";
import GrundlaeggendeElflyTeknik from "./components/Artikler/Grundviden/GrundlaeggendeElflyTeknik";
import Grundlaeggende24GHzTeknik from "./components/Artikler/Grundviden/Grundlaeggende24GHzTeknik";
import DigitaleServoerOgBECStroemforsyninger from "./components/Artikler/Grundviden/DigitaleServoerOgBECStroemforsyninger";
import BestemmelseAfTyngdepunkt from "./components/Artikler/Grundviden/BestemmelseAfTyngdepunkt";
import BeregningerAfEtElflysData from "./components/Artikler/Grundviden/BeregningerAfEtElflysData";
import SimulationAfEtElflysData from "./components/Artikler/Grundviden/SimulationAfEtElflysData";
import MaalingerAfEtElflysData from "./components/Artikler/Grundviden/MaalingerAfEtElflysData";
import FlyByWire from "./components/Artikler/FlyByWire/FlyByWire";
import BlendedWingBodyFremtidensFly from "./components/Artikler/FlyByWire/BlendedWingBodyFremtidensFly";
import UdviklingstendenserIRCTeknologierne from "./components/Artikler/FlyByWire/UdviklingstendenserIRCTeknologierne";
import FlyByWireFlyvningMedEagleTreeVector from "./components/Artikler/FlyByWire/FlyByWireFlyvningMedEagleTreeVector";
import MultirotorteknikTeoriOgPraksis from "./components/Artikler/FlyByWire/MultirotorteknikTeoriOgPraksis";
import VTOLTeknik from "./components/Artikler/FlyByWire/VTOLTeknik";
import DenMenneskeligeFaktor from "./components/Artikler/Andet/DenMenneskeligeFaktor";
import GenerationerAfRCTeknik from "./components/Artikler/Andet/GenerationerAfRCTeknik";
import KonstruktionAfEnCompositAirliner from "./components/Artikler/Andet/KonstruktionAfEnCompositAirliner";
import FourOhFour from "./components/FourOhFour";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: "#9ccc65"
    }
  },
  typography: {
    useNextVariants: true
  }
});

const history = createBrowserHistory({});

interface IState {
  visible: boolean;
}

interface IProps {
  classes: any;
}

class App extends Component<IProps, IState> {
  socket: any;
  rootRef: any;

  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
    this.rootRef = React.createRef();
    ReactGA.initialize("UA-57454758-2");
    ReactGA.pageview(location.pathname);
  }

  unlisten = history.listen(location => {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.setState({
        visible: true
      });
    }, 100);
    setTimeout(() => {
      ReactGA.pageview(location.pathname);
    }, 500);
  });

  render() {
    const baseUrl = "https://elflytec.dk";
    return (
      <MuiThemeProvider theme={theme}>
        <div ref={this.rootRef}>
          <Grid container className="GridContainer1">
            {
              <Router history={history}>
                <>
                  <Header />
                  <div
                    className={`fader ${this.state.visible ? "visible" : ""}`}
                  >
                    <Switch>
                      <Route
                        exact
                        path={["/", "/index.php"]}
                        render={props => (
                          <FrontPage {...props} canonical={`${baseUrl}/`} />
                        )}
                      />
                      <Route
                        exact
                        path={["/billeder/Aspach-2005", "/album1.php"]}
                        render={props => (
                          <Aspach2005
                            {...props}
                            canonical={`${baseUrl}/billeder/Aspach-2005`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={["/billeder/Comet", "/album2.php"]}
                        render={props => (
                          <Comet
                            {...props}
                            canonical={`${baseUrl}/billeder/Comet`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={["/billeder/KFK", "/album3.php"]}
                        render={props => (
                          <Kfk
                            {...props}
                            canonical={`${baseUrl}/billeder/KFK`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Jeti-Duplex-2.4-GHz-teknik",
                          "/JetiDuplex2,4GHzteknik.php"
                        ]}
                        render={props => (
                          <JetiDuplex24GHzTeknik
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Jeti-Duplex-2.4-GHz-teknik`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Jeti-Duplex-GPS-modtager",
                          "/JetiDuplexGPSmodtager.php"
                        ]}
                        render={props => (
                          <JetiDuplexGPSModtager
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Jeti-Duplex-GPS-modtager`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Jeti-Duplex-true-airspeed",
                          "/JetiDuplextrueairspeed.php"
                        ]}
                        render={props => (
                          <JetiDuplexTrueAirspeed
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Jeti-Duplex-true-airspeed`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Logning-og-analyse-Jeti-GPS",
                          "/Analysesoftware1.php"
                        ]}
                        render={props => (
                          <LogningOgAnalyseJetiGPS
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Logning-og-analyse-Jeti-GPS`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Jeti-Box-Profi-EX-Del-1",
                          "/Jetiboxprofiex.php"
                        ]}
                        render={props => (
                          <JetiBoxProfiExDel1
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Jeti-Box-Profi-EX-Del-1`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Jeti-Box-Profi-EX-Del-2",
                          "/Jetiboxprofiex2.php"
                        ]}
                        render={props => (
                          <JetiBoxProfiExDel2
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Jeti-Box-Profi-EX-Del-2`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Flight-Monitor-maaling-paa-2.4GHz-signaler",
                          "/Flight_Monitor_2.4GHz.php"
                        ]}
                        render={props => (
                          <FlightMonitor24GHz
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Flight-Monitor-maaling-paa-2.4GHz-signaler`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Flight-Monitor-maalinger-paa-flyvninger",
                          "/Flight_Monitor_maalinger_flyvninger.php"
                        ]}
                        render={props => (
                          <FlightMonitorFlyvninger
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Flight-Monitor-maalinger-paa-flyvninger`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Flight-Monitor-maaling-paa-komponenter",
                          "/Flight_Monitor_maaling_komponenter.php"
                        ]}
                        render={props => (
                          <FlightMonitorKomponenter
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Flight-Monitor-maaling-paa-komponenter`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Jeti/Beskrivelse-af-Jetis-datasystem",
                          "/Beskrivelse_af_Jetis_data_system.php"
                        ]}
                        render={props => (
                          <BeskrivelseAfJetisDatasystem
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Jeti/Beskrivelse-af-Jetis-datasystem`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Lipo/Lipo-teknik-i-teori-og-praksis",
                          "/LiPoteknikiteoriogpraksis.php"
                        ]}
                        render={props => (
                          <LipoTeknikITeoriOgPraksis
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Lipo/Lipo-teknik-i-teori-og-praksis`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Lipo/Maaling-paa-LiPo-batterier",
                          "/MaalingerpaaLiPobatterier.php"
                        ]}
                        render={props => (
                          <MaalingerPaaLipoBatterier
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Lipo/Maaling-paa-LiPo-batterier`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Lipo/LiPo-contra-LiFePo-4-batterier",
                          "/LiPocontraLiFePo4batterier.php"
                        ]}
                        render={props => (
                          <LipoContraLifepo4Batterier
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Lipo/LiPo-contra-LiFePo-4-batterier`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Lipo/Vaerd-at-vide-om-LiPo",
                          "/vaerdatvideomlipobat.php"
                        ]}
                        render={props => (
                          <VaerdAtVideOmLipo
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Lipo/Vaerd-at-vide-om-LiPo`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Grundviden/Grundlaeggende-elfly-teknik",
                          "/Grundlaeggendeelflyvteknik.php"
                        ]}
                        render={props => (
                          <GrundlaeggendeElflyTeknik
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Grundviden/Grundlaeggende-elfly-teknik`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Grundviden/Grundlaeggende-2.4-GHz-teknik",
                          "/Grundlaeggende%202,4%20GHz%20teknik.php"
                        ]}
                        render={props => (
                          <Grundlaeggende24GHzTeknik
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Grundviden/Grundlaeggende-2.4-GHz-teknik`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Grundviden/Digitale-servoer-og-BEC-stroemforsyninger",
                          "/DigitaleservoerogBECstroemforsyninger.php"
                        ]}
                        render={props => (
                          <DigitaleServoerOgBECStroemforsyninger
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Grundviden/Digitale-servoer-og-BEC-stroemforsyninger`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Grundviden/Bestemmelse-af-tyngdepunkt",
                          "/Tyngdepunkt.php"
                        ]}
                        render={props => (
                          <BestemmelseAfTyngdepunkt
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Grundviden/Bestemmelse-af-tyngdepunkt`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Grundviden/HvordanElflyetVirker/Beregninger-af-et-elflys-data",
                          "/hvordanelflyetvirkerdel1.php"
                        ]}
                        render={props => (
                          <BeregningerAfEtElflysData
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Grundviden/HvordanElflyetVirker/Beregninger-af-et-elflys-data`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Grundviden/HvordanElflyetVirker/Simulation-af-et-elflys-data",
                          "/hvordanelflyetvirkerdel2.php"
                        ]}
                        render={props => (
                          <SimulationAfEtElflysData
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Grundviden/HvordanElflyetVirker/Simulation-af-et-elflys-data`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Grundviden/HvordanElflyetVirker/Maalinger-af-et-elflys-data",
                          "/hvordanelflyetvirkerdel3.php"
                        ]}
                        render={props => (
                          <MaalingerAfEtElflysData
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Grundviden/HvordanElflyetVirker/Maalinger-af-et-elflys-data`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={["/FlyByWire/Fly-by-Wire", "/flybywire.php"]}
                        render={props => (
                          <FlyByWire
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/FlyByWire/Fly-by-Wire`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/FlyByWire/Blended-Wing-Body-Fremtidens-fly",
                          "/blendedwingbody.php"
                        ]}
                        render={props => (
                          <BlendedWingBodyFremtidensFly
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/FlyByWire/Blended-Wing-Body-Fremtidens-fly`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/FlyByWire/Udviklingstendenser-i-RC-teknologierne",
                          "/rctekudvikling.php"
                        ]}
                        render={props => (
                          <UdviklingstendenserIRCTeknologierne
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/FlyByWire/Udviklingstendenser-i-RC-teknologierne`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/FlyByWire/Fly-by-Wire-flyvning-med-Eagle-Tree-Vector",
                          "/eaglevectorflying.php"
                        ]}
                        render={props => (
                          <FlyByWireFlyvningMedEagleTreeVector
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/FlyByWire/Fly-by-Wire-flyvning-med-Eagle-Tree-Vector`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/FlyByWire/Multirotorteknik-Teori-og-praksis",
                          "/MultirotorteknikWEB.php"
                        ]}
                        render={props => (
                          <MultirotorteknikTeoriOgPraksis
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/FlyByWire/Multirotorteknik-Teori-og-praksis`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={["/FlyByWire/VTOL-teknik", "/VTOL.php"]}
                        render={props => (
                          <VTOLTeknik
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/FlyByWire/VTOL-teknik`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Andet/Den-menneskelige-faktor",
                          "/Den%20menneskelige%20faktor.php"
                        ]}
                        render={props => (
                          <DenMenneskeligeFaktor
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Andet/Den-menneskelige-faktor`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Andet/Generationer-af-RC-teknik",
                          "/teknikhis.php"
                        ]}
                        render={props => (
                          <GenerationerAfRCTeknik
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Andet/Generationer-af-RC-teknik`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/Andet/Konstruktion-af-en-composit-airliner",
                          "/Konstruktionafencompositairliner.php"
                        ]}
                        render={props => (
                          <KonstruktionAfEnCompositAirliner
                            {...props}
                            rootRef={this.rootRef}
                            canonical={`${baseUrl}/Andet/Konstruktion-af-en-composit-airliner`}
                          />
                        )}
                      />
                      <Route exact path="/404" component={FourOhFour} />
                      <Redirect to="/404" />
                    </Switch>
                  </div>
                </>
              </Router>
            }
            <CookieConsent
              acceptOnScroll={true}
              buttonText={"Det er i orden"}
              style={{ minHeight: "80px", zIndex: 1400 }}
            >
              Denne hjemmeside bruger Google Analytics og tilhørende cookies til
              statistik over hvilke sider der bliver besøgt. Du er velkommen til
              at bruge adblock/ublock for at blokere denne tracking.
            </CookieConsent>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withTheme()(App);
