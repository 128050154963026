const MenuData = [
  {
    id: 1,
    name: "Jeti RC og telemetri",
    url: "#",
    children: [
      {
        name: "Jeti Duplex 2,4 GHz teknik",
        url: "/Jeti/Jeti-Duplex-2.4-GHz-teknik"
      },
      {
        name: "Jeti Duplex GPS modtager",
        url: "/Jeti/Jeti-Duplex-GPS-modtager"
      },
      {
        name: "Jeti Duplex true airspeed",
        url: "/Jeti/Jeti-Duplex-true-airspeed"
      },
      {
        name: "Logning og analyse Jeti-GPS",
        url: "/Jeti/Logning-og-analyse-Jeti-GPS"
      },
      {
        name: "Jeti Box Profi EX Del 1",
        url: "/Jeti/Jeti-Box-Profi-EX-Del-1"
      },
      {
        name: "Jeti Box Profi EX Del 2",
        url: "/Jeti/Jeti-Box-Profi-EX-Del-2"
      },
      {
        name: "Flight Monitor måling på 2,4GHz signaler",
        url: "/Jeti/Flight-Monitor-maaling-paa-2.4GHz-signaler"
      },
      {
        name: "Flight Monitor måling på flyvninger",
        url: "/Jeti/Flight-Monitor-maalinger-paa-flyvninger"
      },
      {
        name: "Flight Monitor måling på komponenter",
        url: "/Jeti/Flight-Monitor-maaling-paa-komponenter"
      },
      {
        name: "Beskrivelse af Jetis datasystem",
        url: "/Jeti/Beskrivelse-af-Jetis-datasystem"
      }
    ]
  },
  {
    id: 2,
    name: "Li-Po batterier",
    url: "#",
    children: [
      {
        name: "Lipo teknik i teori og praksis",
        url: "/Lipo/Lipo-teknik-i-teori-og-praksis"
      },
      {
        name: "Måling på LiPo batterier",
        url: "/Lipo/Maaling-paa-LiPo-batterier"
      },
      {
        name: "LiPo contra LiFePo 4 batterier",
        url: "/Lipo/LiPo-contra-LiFePo-4-batterier"
      },
      {
        name: "Værd at vide om LiPo",
        url: "/Lipo/Vaerd-at-vide-om-LiPo"
      }
    ]
  },
  {
    id: 3,
    name: "Grundviden",
    url: "#",
    children: [
      {
        name: "Grundlæggende elfly teknik",
        url: "/Grundviden/Grundlaeggende-elfly-teknik"
      },
      {
        name: "Grundlæggende 2,4 GHz teknik",
        url: "/Grundviden/Grundlaeggende-2.4-GHz-teknik"
      },
      {
        name: "Digitale servoer og BEC strømforsyninger",
        url: "/Grundviden/Digitale-servoer-og-BEC-stroemforsyninger"
      },
      {
        name: "Bestemmelse af tyngdepunkt",
        url: "/Grundviden/Bestemmelse-af-tyngdepunkt"
      },
      {
        name: "Hvordan elflyet virker:",
        url: "#"
      },
      {
        name: "Del 1. Beregninger af et elflys data",
        url: "/Grundviden/HvordanElflyetVirker/Beregninger-af-et-elflys-data"
      },
      {
        name: "Del 2. Simulation af et elflys data",
        url: "/Grundviden/HvordanElflyetVirker/Simulation-af-et-elflys-data"
      },
      {
        name: "Del 3. Målinger af et elflys data",
        url: "/Grundviden/HvordanElflyetVirker/Maalinger-af-et-elflys-data"
      }
    ]
  },
  {
    id: 4,
    name: "Fly by wire controllers",
    url: "#",
    children: [
      {
        name: "Fly by Wire",
        url: "/FlyByWire/Fly-by-Wire"
      },
      {
        name: "Blended Wing Body - Fremtidens fly",
        url: "/FlyByWire/Blended-Wing-Body-Fremtidens-fly"
      },
      {
        name: "Udviklingstendenser i RC teknologierne",
        url: "/FlyByWire/Udviklingstendenser-i-RC-teknologierne"
      },
      {
        name: "Fly by Wire flyvning med Eagle Tree Vector",
        url: "/FlyByWire/Fly-by-Wire-flyvning-med-Eagle-Tree-Vector"
      },
      {
        name: "Multirotorteknik-Teori og praksis",
        url: "/FlyByWire/Multirotorteknik-Teori-og-praksis"
      },
      {
        name: "VTOL teknik (NY)",
        url: "/FlyByWire/VTOL-teknik"
      }
    ]
  },
  {
    id: 5,
    name: "Andet",
    url: "#",
    children: [
      {
        name: "Den menneskelige faktor",
        url: "/Andet/Den-menneskelige-faktor"
      },
      {
        name: "Generationer af RC-teknik",
        url: "/Andet/Generationer-af-RC-teknik"
      },
      {
        name: "Konstruktion af en composit airliner",
        url: "/Andet/Konstruktion-af-en-composit-airliner"
      }
    ]
  },

  {
    id: 6,
    name: "Billed albums",
    url: "#",
    children: [
      {
        name: "Aspach 2005",
        url: "/Billeder/Aspach-2005"
      },
      {
        name: "Comet",
        url: "/Billeder/Comet"
      },
      {
        name: "KFK",
        url: "/Billeder/KFK"
      }
    ]
  }
];
export default MenuData;
