import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  TableRow,
  TableHead,
  Table,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/FlightMonitor24GHz/fig1.jpg";
import fig2 from "../../../images/Jeti/FlightMonitor24GHz/fig2.jpg";
import fig3 from "../../../images/Jeti/FlightMonitor24GHz/fig3.jpg";
import fig4 from "../../../images/Jeti/FlightMonitor24GHz/fig4.jpg";
import fig5 from "../../../images/Jeti/FlightMonitor24GHz/fig5.jpg";
import fig6 from "../../../images/Jeti/FlightMonitor24GHz/fig6.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Flight Monitor målinger på 2,4GHz signaler</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Flight Monitor m&aring;linger p&aring; 2,4GHz signaler</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret november 2012</p>
            <h2>Indledning</h2>
            <p>
              Denne beskrivelse viser nogle forskellige anvendelsesmuligheder af
              et &rdquo;Black Box&rdquo; flight recorder system til modelfly.
              Data opsamles via telemetri i en Jeti Profi Box eller i den nye
              Jeti DC16/DS16 sender, med henblik p&aring; senere analyse i
              Windows programmet Jeti Flight Monitor. Systemet virker p&aring;
              samme m&aring;de som den sorte box, man altid leder efter ved
              flyveulykker.
            </p>
            <h2>
              Fastl&aelig;ggelse af nyt startpunkt p&aring; GPS og
              positionsstabilitet
            </h2>
            <p>
              <img src={fig1} />
            </p>
            <p>
              Hvis GPS m&aring;lemodulet skal anvendes, er det n&oslash;dvendigt
              at fastl&aelig;gge GPS startpunktet p&aring; jordens overflade.
              Det er ud fra startpunktet alle afstands- og h&oslash;jdedata
              bestemmes. Det betyder, at modelflyveren skal skifte GPS
              startpunkt, hvis der skiftes flyveplads, eller hvis GPS en skal
              udnyttes optimalt, s&aring;gar hvis der skiftes startbane. I det
              ovenst&aring;ende eksempel er startpunktet valgt forenden af bane
              27 i midten af den lodrette hvide bj&aelig;lke. Det er ud fra
              dette punk at afstand, h&oslash;jde
              og&nbsp;azimuth&nbsp;bestemmes.&nbsp;Azimuth&nbsp;er retningen i
              forhold til nord. Det viste fly som st&aring;r stille, er
              s&aring;ledes 170grader og 42m v&aelig;k i forhold til GPS
              startpunktet, alts&aring; n&aelig;sten syd for GPS startpunktet.
              Anl&aelig;gget er lige blevet t&aelig;ndt, og p&aring; den
              &oslash;verste bl&aring; kurve m&aring;les, hvor mange satellitter
              GPS modtageren har f&aring;et fat i.
            </p>
            <p>
              Man b&oslash;r vente et par minutter, f&oslash;r flyvningen
              p&aring;begyndes for at f&aring; fat i 9 til 11 satellitter.&nbsp;
            </p>
            <h2>
              M&aring;ling af r&aelig;kkevidde p&aring; jorden og i luften af
              2,4GHz signaler
            </h2>
            <p>
              <img src={fig2} />
            </p>
            <p>
              I ovenst&aring;ende fors&oslash;g g&aring;r piloten med flyet
              p&aring; jorden, indtil der ikke l&aelig;ngere er forbindelse
              mellem sender og modtager. Der har undervejs v&aelig;ret flere
              &rdquo;weak&nbsp;signal&rdquo; alarmer, som forekommer, n&aring;r
              begge antenner er under 1 samtidig. En anelse l&aelig;ngere
              v&aelig;k end 205m udl&oslash;ses &rdquo;lost signal&rdquo;
              alarmen. Godt 200m syntes du m&aring;ske er d&aring;rligt, men i
              terr&aelig;net er der en betydelig absorption af 2,4GHz signalet.
              Det er ogs&aring; muligt at m&aring;le pilotens absorption af
              2,4GHz str&aring;lingen. Hvis piloten kommer i mellem senderen og
              modellen vil antennestyrken falde fra f.eks. 6 til 4!
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Efterf&oslash;lgende&nbsp;unders&oslash;ges&nbsp;antennesignalerne
              med den samme flyver i luften ud til samme afstand. Billedet er nu
              helt anderledes, fordi du er fri af jorden, og der er direkte sigt
              mellem fly og pilot.
            </p>
            <p>
              Se artiklen Grundl&aelig;ggende 2,4GHz teknik p&aring;
              Elflytec.dk. hvor du kan l&aelig;se teori om udbredelsen og 2,4
              GHz antennerne. Disse m&aring;linger bekr&aelig;fter teorierne
              ganske godt.
            </p>
            <h2>Absorption af 2,4GHz signal med forskellige materialer</h2>
            <p>
              <img src={fig4} />
            </p>
            <p>
              Antenne 1 (bl&aring; kurve) afsk&aelig;rmes med forskellige
              materialer i ca. 1 minut ad gangen.
            </p>
            <p>
              Antenne 2 (r&oslash;d kurve) er uafsk&aelig;rmet og har samme
              polarisation.
            </p>
            <p>Antennesp&aelig;ndingen m&aring;les i en skala fra 0 til 9.</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Materiale</TableCell>
                  <TableCell>Tid</TableCell>
                  <TableCell>Antenne m&aring;ling</TableCell>
                  <TableCell>Bem&aelig;rkning</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Uafsk&aelig;rmet</TableCell>
                  <TableCell>0,30</TableCell>
                  <TableCell>6</TableCell>
                  <TableCell>Virker optimalt</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Plastr&oslash;r</TableCell>
                  <TableCell>1,30</TableCell>
                  <TableCell>6</TableCell>
                  <TableCell>
                    Du kan roligt bruge plastr&oslash;r til at beskytte antennen
                    mod mekaniske vibrationer
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kulr&oslash;r</TableCell>
                  <TableCell>2,30</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>Antennen skal udenfor kroppen</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Messingr&oslash;r</TableCell>
                  <TableCell>3,30</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell>St&aelig;rkt d&aelig;mpende</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Alur&oslash;r</TableCell>
                  <TableCell>4,30</TableCell>
                  <TableCell>3</TableCell>
                  <TableCell>St&aelig;rkt d&aelig;mpende</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vand</TableCell>
                  <TableCell>5,30</TableCell>
                  <TableCell>5</TableCell>
                  <TableCell>
                    Pas p&aring; at fugt ikke tr&aelig;nger ind i antennen
                    eller&nbsp;coaxialkablet.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Omsluttet af h&aring;nd</TableCell>
                  <TableCell>6,30</TableCell>
                  <TableCell>1-2</TableCell>
                  <TableCell>
                    Menneskets n&aelig;rhed absorberer 2,4GHz feltet. Pas
                    p&aring; sender antennen og n&aelig;rtst&aring;ende
                    personer.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              <img src={fig5} />
              Antennen er neds&aelig;nket i vand.
            </p>
            <h2>Fund af bortfl&oslash;jet model</h2>
            <p>
              P&aring; grundlag af de data, som er beskrevet under m&aring;ling
              af r&aelig;kkevidden, har man 3 muligheder for at finde en
              bortfl&oslash;jet model.
            </p>
            <ol>
              <li>
                N&aring;r man n&aelig;rmer sig en model, vil
                antennesp&aelig;ndingerne stige under foruds&aelig;tning af, at
                modtageren stadig virker. (GPS ikke n&oslash;dvendig) Endvidere
                kan man meget groft bestemme, om man har modellen foran sig
                eller den ligger bag en, ved at dreje sig en hel omdrejning
                rundt.
                <img src={fig6} />
              </li>
              <li>
                Hvis man afl&aelig;ser distancedata og&nbsp;azimuth, vil det
                v&aelig;re muligt at finde afstand og retning i forholdt til
                nord, v.hj.af et magnetisk kompas og skridtt&aelig;lling eller
                ved brug af Google Earth. Se billede.
              </li>
              <li>
                Hvis man afl&aelig;ser bredde- og l&aelig;ngdegrader er det
                muligt at finde modellen, v.&nbsp;hj. af en GPS modtager, af
                samme type som anvendes til&nbsp;geo&nbsp;tracking.
              </li>
            </ol>
            <p>
              Ved anvendelse af de to sidste muligheder skal man v&aelig;re
              opm&aelig;rksom p&aring;, at man skal have en b&aelig;rbar
              computer til r&aring;dighed for at kunne udl&aelig;se
              &rdquo;bortflyvnings data&rdquo; fra Jeti Profi Boxen via et USB
              kabel. Hvis modellen flyver udenfor senderens r&aelig;kkevidde er
              metoden ikke anvendelig.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
