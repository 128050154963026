import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Lipo/LipoContraLifepo4Batterier/fig1.jpg";
import fig2 from "../../../images/Lipo/LipoContraLifepo4Batterier/fig2.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Li-Po contra Li-FePo4 batterier</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Li-Po contra Li-FePo4 batterier</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret januar 2009.</p>
            <p>
              Som en forts&aelig;ttelse af artiklen om Li-Po batterier i{" "}
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2008/modelflyvenyt%206-2008.pdf"
                target="_blank"
              >
                Modelflyvenyt nr. 6 2008
              </Link>{" "}
              skal denne gang sammenlignes 4 forskellige batterier, hvoraf et er
              ny batteri teknologi, baseret p&aring; Lithium Jern Fosphat
              (Li-FePo4).
            </p>
            <p>
              Li-FePo4 cellerne er karakteristiske p&aring; f&oslash;lgende
              punkter:
            </p>
            <ol>
              <li>
                <p>
                  Fysik er cellen i et rundt metal hylster, som det kendes fra
                  nikkel cellen.
                </p>
              </li>
              <li>
                <p>
                  Max oplade sp&aelig;nding er 3,6&nbsp;V i mods&aelig;tning til
                  Li-Po som er 4,2&nbsp;V. Dette medf&oslash;rer at du skal have
                  lader som kan h&aring;ndtere Li-Fe celler.
                </p>
              </li>
              <li>
                <p>
                  Minimum sp&aelig;nding er 2,0&nbsp;V. Praktiske fors&oslash;g
                  viser at cellen ikke er s&aelig;rlig f&oslash;lsom for at
                  blive udsat for undersp&aelig;nding.
                </p>
              </li>
              <li>
                <p>Cellerne kan oplades hurtigt med op til 5&nbsp;C.</p>
              </li>
              <li>
                <p>Cellerne har h&oslash;j levetid.</p>
              </li>
              <li>
                <p>
                  Cellerne er meget brandsikre og robuste i forhold til Li-Po.
                </p>
              </li>
            </ol>
            <p>
              <img src={fig1} />
            </p>
            <p>
              Li-Fe cellerne findes i flere st&oslash;rrelser. Den meget store
              pakke er den testede 4S 2200&nbsp;mA/h, mens pakken i midten er
              p&aring; 1500&nbsp;mA/h begge sammenlignet med et AA
              batteri.&nbsp;
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              M&aring;linger p&aring; 4S 2200&nbsp;mA/h LiFePo4 batteriet.
              Bem&aelig;rk der er nogen spredning p&aring; de enkelte celler,
              men dette er uden betydning, idet cellerne er meget robuste
              overfor undersp&aelig;nding, i mods&aelig;tning til Li-Po celler.
            </p>
            <h2>Batteri sammenligningsskema Li-Po og LiFePo4</h2>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>1. Batteri</TableCell>
                  <TableCell>Sunhigh 3200&nbsp;mAh 15&nbsp;C</TableCell>
                  <TableCell>EGO 2200&nbsp;mAh</TableCell>
                  <TableCell>Sunhigh 2200&nbsp;mAh</TableCell>
                  <TableCell>Sunhigh 2200&nbsp;mAh</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2. Konstruktion</TableCell>
                  <TableCell>3S1P Li-Po</TableCell>
                  <TableCell>3S1P Li-Po</TableCell>
                  <TableCell>3S1P Li-Po</TableCell>
                  <TableCell>
                    <strong>4S1P Li-FePo4</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>3. Leverandør</TableCell>
                  <TableCell>IC-Communikation</TableCell>
                  <TableCell>Avionic</TableCell>
                  <TableCell>IC-Communikation</TableCell>
                  <TableCell>IC-Communikation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>4. Importør</TableCell>
                  <TableCell>IC-Communikation</TableCell>
                  <TableCell>Avionic</TableCell>
                  <TableCell>IC-Communikation</TableCell>
                  <TableCell>IC-Communikation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5. Producent</TableCell>
                  <TableCell>Sunhigh</TableCell>
                  <TableCell>????</TableCell>
                  <TableCell>Sunhigh</TableCell>
                  <TableCell>Sunhigh</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>6. Produceret i</TableCell>
                  <TableCell>Kina</TableCell>
                  <TableCell>????</TableCell>
                  <TableCell>Kina</TableCell>
                  <TableCell>Kina</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>7. Test tidspunkt</TableCell>
                  <TableCell>Okt 2008</TableCell>
                  <TableCell>Okt 2006</TableCell>
                  <TableCell>Okt 2008</TableCell>
                  <TableCell>Okt 2006</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>8. Indkøbspris</TableCell>
                  <TableCell>370kr</TableCell>
                  <TableCell>425kr</TableCell>
                  <TableCell>315kr</TableCell>
                  <TableCell>250kr</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>9. Vægt med stik</TableCell>
                  <TableCell>260g</TableCell>
                  <TableCell>188g</TableCell>
                  <TableCell>188g</TableCell>
                  <TableCell>
                    <strong>334g</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10. Mål</TableCell>
                  <TableCell>22x47x140</TableCell>
                  <TableCell>25x35x105</TableCell>
                  <TableCell>25x25x105</TableCell>
                  <TableCell>
                    <strong>27x72x110</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>11.Balancerstik</TableCell>
                  <TableCell>Kokam kompatibelt</TableCell>
                  <TableCell />
                  <TableCell>Kokam kompatibelt</TableCell>
                  <TableCell>Egen mont</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>12. Burn in foretaget</TableCell>
                  <TableCell>Ja</TableCell>
                  <TableCell>Ja</TableCell>
                  <TableCell>Ja</TableCell>
                  <TableCell>Nej</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    13. Resultatforøgelse som følge af burn in
                  </TableCell>
                  <TableCell>Ingen</TableCell>
                  <TableCell>Ingen</TableCell>
                  <TableCell>Ingen</TableCell>
                  <TableCell>Ingen</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>14. Måling foretaget ved cycle nr.</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>5</TableCell>
                  <TableCell>3</TableCell>
                  <TableCell>4</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    15. Opgivet C værdi for kontinuerlig strøm.
                  </TableCell>
                  <TableCell>15&nbsp;C=48&nbsp;A</TableCell>
                  <TableCell>35&nbsp;C=77&nbsp;A</TableCell>
                  <TableCell>20&nbsp;C=44&nbsp;A</TableCell>
                  <TableCell>10&nbsp;C</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>16. Målestrøm</TableCell>
                  <TableCell>36&nbsp;A</TableCell>
                  <TableCell>
                    <strong>57,7&nbsp;A</strong>
                  </TableCell>
                  <TableCell>33&nbsp;A</TableCell>
                  <TableCell>22&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>17. Kapacitet ved målestrømmen.</TableCell>
                  <TableCell>3420&nbsp;mA/h</TableCell>
                  <TableCell>2147&nbsp;mA/h</TableCell>
                  <TableCell>2264&nbsp;mA/h</TableCell>
                  <TableCell>2053&nbsp;mA/h ved 10&nbsp;C=22&nbsp;A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    18. Målt kapacitet i % af opgivet kapacitet
                  </TableCell>
                  <TableCell>
                    <strong>107&nbsp;%</strong>
                  </TableCell>
                  <TableCell>97,6&nbsp;%</TableCell>
                  <TableCell>
                    <strong>103&nbsp;%</strong>
                  </TableCell>
                  <TableCell>93&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    19. Målt kapacitet i forhold til vægt pr. gram
                  </TableCell>
                  <TableCell>13,15&nbsp;mA/h pr gram</TableCell>
                  <TableCell>11,24&nbsp;mA/h pr gram</TableCell>
                  <TableCell>12,00&nbsp;mA/h pr gram</TableCell>
                  <TableCell>6,14&nbsp;mA/h pr gram</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    20. Spænding ved 50&nbsp;% af målt max kapacitet.
                  </TableCell>
                  <TableCell>10,6&nbsp;V</TableCell>
                  <TableCell>10,22&nbsp;V</TableCell>
                  <TableCell>10,28&nbsp;V</TableCell>
                  <TableCell>11&nbsp;V</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>21. Opgivet burst kapasitet</TableCell>
                  <TableCell>22&nbsp;C</TableCell>
                  <TableCell>
                    <strong>60&nbsp;C</strong>
                  </TableCell>
                  <TableCell>30&nbsp;C</TableCell>
                  <TableCell>70&nbsp;A i max 5sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>22. Målt indre modstand pr celle</TableCell>
                  <TableCell>17,0&nbsp;mohm</TableCell>
                  <TableCell>
                    <strong>7,31&nbsp;mohm</strong>
                  </TableCell>
                  <TableCell>17,7&nbsp;mohm</TableCell>
                  <TableCell>26,75&nbsp;mohm</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>23. Temperatur stigning</TableCell>
                  <TableCell>29 grader</TableCell>
                  <TableCell>29 grader</TableCell>
                  <TableCell>30 grader</TableCell>
                  <TableCell>16 grader</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    24. Cellespredning ved afslutning af afladning
                  </TableCell>
                  <TableCell>3,31&nbsp;V 2,90&nbsp;V 3,35&nbsp;V</TableCell>
                  <TableCell>3,07&nbsp;V 3,10&nbsp;V 2,95&nbsp;V</TableCell>
                  <TableCell>3,15&nbsp;V 2,82&nbsp;V 2,90&nbsp;V</TableCell>
                  <TableCell>
                    1,92&nbsp;V 2,42&nbsp;V 2,39&nbsp;V 2,02&nbsp;V
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    25. Forventet cirka levetid i antal cycles.
                  </TableCell>
                  <TableCell>50&nbsp;C til 100&nbsp;C</TableCell>
                  <TableCell>50&nbsp;C til 100&nbsp;C</TableCell>
                  <TableCell>50&nbsp;C til 100&nbsp;C</TableCell>
                  <TableCell>
                    Bedre end 500&nbsp;C ved 20&nbsp;A afladestrøm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>26. Batteriets energi</TableCell>
                  <TableCell>36,25&nbsp;W/h</TableCell>
                  <TableCell>22,13&nbsp;W/h</TableCell>
                  <TableCell>23,27&nbsp;W/h</TableCell>
                  <TableCell>22,38&nbsp;W/h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>27. Batteriets energi pr gram.</TableCell>
                  <TableCell>139,4mW/g</TableCell>
                  <TableCell>117,7mW/g</TableCell>
                  <TableCell>123,8mW/g</TableCell>
                  <TableCell>
                    <strong>67mW/g</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>Bem&aelig;rkninger til skemaet:</p>
            <p>
              Linie 2 Der skal bruges 4 celler Li-FePo4 for at opn&aring; ca.
              samme sp&aelig;nding, som ved 3 Li-Po celler.
            </p>
            <p>
              Linie 18 Sunhigh Li-Po cellerne holder flot h&oslash;j kapacitet.
            </p>
            <p>
              Linie 9, 10 og 27: Li-FePo4 batteriet er stort og tungt. Se
              billedet.
            </p>
            <p>
              Linie 16, 21 og 22: Hvis der skal bruges store str&oslash;mme er
              Li-Po teknikken langt den bedste.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
