import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Lipo/MaalingerPaaLipoBatterier/fig1.jpg";
import fig2 from "../../../images/Lipo/MaalingerPaaLipoBatterier/fig2.jpg";
import fig3 from "../../../images/Lipo/MaalingerPaaLipoBatterier/fig3.jpg";
import fig4 from "../../../images/Lipo/MaalingerPaaLipoBatterier/fig4.jpg";
import fig5 from "../../../images/Lipo/MaalingerPaaLipoBatterier/fig5.jpg";
import fig6 from "../../../images/Lipo/MaalingerPaaLipoBatterier/fig6.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Målinger på Li-Po batterier</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>M&aring;linger p&aring; Li-Po batterier</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret april 2008.</p>
            <p>
              Dette er en forts&aelig;ttelse af artiklen{" "}
              <Link href="/Lipo/Lipo-teknik-i-teori-og-praksis" target="_blank">
                Li-Po teknik i teori og praksis
              </Link>{" "}
              bragt i Modelflyvenyt{" "}
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2007/Modelflyvenyt%206%202007.pdf"
                target="_blank"
              >
                nr. 6 2007
              </Link>
              . Denne gang vil jeg g&aring; mere i dybden med pr&aelig;cise
              m&aring;linger p&aring; Li-Po batterier. Til dette form&aring;l er
              udviklet et system, som med stor n&oslash;jagtighed kan
              m&aring;le: batteriernes kapacitet, cellespredning, temperatur,
              belastningskurver ved forskellige C v&aelig;rdier, samt indre
              modstand. Over tid kan ogs&aring; bestemmes batteriernes levetid.
            </p>
            <h2>M&aring;lesystemet</h2>
            <p>
              <img src={fig1} />
              <br />
              Fig. 1
            </p>
            <p>
              M&aring;lesystemet best&aring;r af en Bantam BC8 lader, en
              s&aelig;rlig udviklet konstantstr&oslash;msbelastning og en PC med
              et LogView program. (Se Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt.dk/modelflyvenyt/2007/Modelflyvenyt%202%202007.pdf"
              >
                nr. 2 2007
              </Link>
              ) Princippet er, at batteriet aflades igennem BC-8 med en
              afladestr&oslash;m p&aring; 0,1&nbsp;Amp. Parallelt over BC-8 er
              forbundet en modstand, som frembringer den egentlige
              h&oslash;jstr&oslash;msbelastning af batteriet. Kravet til denne
              modstand er, at den dels kan justeres, s&aring; den kan
              tr&aelig;kke en n&oslash;jagtig str&oslash;m, dels at den kan
              holde denne str&oslash;m helt konstant under hele
              afladeforl&oslash;bet. Som eksempel aflades et 3700mA/time batteri
              med 10&nbsp;C = 37&nbsp;A se str&oslash;mpilene. Se fig. 1. Data
              overf&oslash;res fra BC-8&acute;s dataport til PC&acute;en via en
              USB adaptor.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Billedet viser konstantstr&oslash;msbelastningen omgivet af de
              andre enheder. Den er af gode grunde ikke lukket inde i en kasse,
              men forsynet med en 12&nbsp;cm bl&aelig;ser!
            </p>
            <h2>Konstantstr&oslash;msbelastningen</h2>
            <p>
              Konstantstr&oslash;msbelastningen kan klare max 100&nbsp;Amp og en
              maksimal effekt p&aring; 1000&nbsp;W. Indstillingen af
              str&oslash;mmen foretages med et 10-turns potentiometer og
              registreres dels med et vejledende analoginstrument p&aring;
              forpladen, dels med et kalibreret digital voltmeter som tilsluttes
              apparatet. Derved opn&aring;s en m&aring;len&oslash;jagtighed
              p&aring; +/- 1&nbsp;%. Den indstillede str&oslash;m holdes stabil
              indenfor 0,1&nbsp;%. Belastningen kan kobles ind og ud med en
              kontakt. For de elektronik interesserede kan oplyses, at den store
              str&oslash;m styres af 8 parallelforbundne Power MOSFET
              transistorer monteret p&aring; k&oslash;leplader. Med disse
              transistorer kan man styre en stor str&oslash;m v.h.a. en
              sp&aelig;nding. Str&oslash;mmen i gennem de 8 transistorer samles
              nu i en enkelt modstand R4. Sp&aelig;ndingen over denne modstand
              er proportional med str&oslash;mmen igennem den (ohm lov). Nu kan
              s&aring; denne sp&aelig;nding sammenlignes med en reference
              sp&aelig;nding fra R15 i sammenligningsforst&aelig;rkeren IC3B.
              Resultatet af denne sammenligning styrer s&aring; str&oslash;mmen
              i MOSFET transistorerne til den indstillede v&aelig;rdi. Som en
              sikkerhedsforanstaltning m&aring;les batterisp&aelig;ndingen i den
              anden sammenlignings-forst&aelig;rker IC3&nbsp;A, s&aring; hvis
              den kommer under 9&nbsp;V eller 10&nbsp;V, valgt v. h. af en
              omskifter, afbrydes belastningen. Dette for at sikre, at et 3
              cellers Li-Po batteri ikke ved et uheld kommer ned under den
              kritiske undersp&aelig;nding og derved bliver &oslash;delagt.
              Varmen p&aring; k&oslash;lepladerne bliver reduceret af en
              12&nbsp;cm bl&aelig;ser, som sikre at chiptemperaturen p&aring;
              MOSFETterne holdes et p&aelig;nt stykke under 175&nbsp;C grader
              ved fuld belastning. Se diagrammet fig 2.
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig. 2
            </p>
            <h2>M&aring;ling af batteriets indre modstand</h2>
            <p>
              Batteriets indre modstand plus modstand i ledninger og stik
              m&aring;les p&aring; f&oslash;lgende m&aring;de. Batteriet
              tilsluttes m&aring;lesystemet. For hvert &frac12; minut tilsluttes
              henholdsvis fjernes en konstantstr&oslash;msbelastning p&aring;
              37&nbsp;Amp, derved opst&aring;r &aelig;ndringerne p&aring; den
              bl&aring; sp&aelig;ndings kurve. Se graf 1. Hvis man afl&aelig;ser
              sp&aelig;ndingsvariationen mellem cursorlinierne i starten af
              forl&oslash;bet, vil man se, at sp&aelig;ndingen &aelig;ndrer sig
              1,2&nbsp;V ved en str&oslash;m&aelig;ndring p&aring; 37&nbsp;Amp..
              Hvis man anvender ohms lov, bliver batteriets indre modstand
              1,2&nbsp;V/37&nbsp;A = 33 milliohm. Denne modstand inkluderer
              ogs&aring; ledninger og stik. L&aelig;g m&aelig;rke til at den
              indre modstand falder efterh&aring;nden som batteriet bliver
              varmt. Den ender slutteligt p&aring; 23 milliohm. Bem&aelig;rk
              ogs&aring; en anden ting nemlig, at ved afladeforl&oslash;bets
              afslutning, spreder sp&aelig;ndingen p&aring; de enkelte celler
              ret pludseligt. Celle 1&acute;s sp&aelig;nding falder, fordi der
              er produktionsforskelle i kapaciteten af de enkelte celler. Celle
              1 bliver f&oslash;rst t&oslash;mt. Den ideelle regulator burde
              derfor reagere p&aring; den enkelte celle og ikke p&aring; summen
              af cellernes sp&aelig;nding. Disse regulatorer er nu p&aring; vej
              fra f. eks. Schulze i deres future-value serie.
            </p>
            <h2>Li-Po cellens kapacitet</h2>
            <p>
              Ved overgangen til Li-Po teknik besluttede jeg at f&oslash;lge
              hver enkelt batteripakke systematisk, ved at m&aring;le pakkens
              kapacitet. Jeg konstaterede her, at den p&aring; pakkerne
              p&aring;stemplede kapacitet var rigelig optimistisk, som
              s&aelig;dvanlig. S&aring;ledes ligger Hyperion VX 3700&nbsp;mA/h
              mellem 3260&nbsp;mA/h og 3450&nbsp;mA/h m&aring;lt p&aring; 4 stk.
              Der aflades med kun 1&nbsp;Amp, indtil den f&oslash;rste celle
              n&aring;r 3,0&nbsp;Volt. En af pakkerne m&aring;lte fra starten
              3310&nbsp;mA/h og m&aring;ler nu efter 20 op- og afladninger
              3150&nbsp;mA/h. I praksis kan du regne med kun at anvende
              75&nbsp;% af den p&aring;stemplede kapacitet: 3,7&nbsp;A/h x
              0,75=2775&nbsp;mA/h brugbar kapacitet. Kapaciteten er ikke ene
              afg&oslash;rende, langt vigtigere er det, at batteriet kan holde
              sp&aelig;ndingen under stor belastning.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Graf 1
            </p>
            <h2>Batteridiagrammet</h2>
            <p>
              For at f&aring; en bedre forst&aring;else af betydningen af
              batteriets indre modstand, skal vi se p&aring; et diagram af
              batterikredsl&oslash;bet fig. 3. Til venstre ses 3 serieforbundne
              Li-Po celler (3S), der som udgangspunkt helt uden belastningen og
              fuldt opladt, har en tomgangssp&aelig;nding p&aring; 12,5&nbsp;V.
              Inde i batteriet er der en usynlig indre modstand, hvis
              st&oslash;rrelse er bestemt af batteriets kemi. For at g&oslash;re
              cellerne tilg&aelig;ngelige er der n&oslash;dvendigvis to
              ledninger, en til plus og en til minus. I disse ledninger er der
              en ohms modstand, som har betydning p&aring; grund af de store
              str&oslash;mme, som tr&aelig;kkes. Modstanden af disse ledninger
              samles i den viste modstand. Endelig er der en stikforbindelse,
              som ogs&aring; m&aring; medregnes i en vist modstand. Det som er
              afg&oslash;rende for motorens ydeevne, er nyttesp&aelig;ndingen
              helt inde ved regulatorens tilslutningspunkter. For at bestemme
              denne sp&aelig;nding m&aring; man derfor tr&aelig;kke det samlede
              sp&aelig;ndingsfald over disse 3 tabsmodstande fra. I et Li-Po
              batteri 3S 3700&nbsp;mA/h 20&nbsp;C, som har v&aelig;ret anvendt
              50gange, blev den indre modstand som vist m&aring;lt til
              33milliohm ved en str&oslash;m p&aring; 10&nbsp;C = 37&nbsp;Amp..
              Det betyder, at sp&aelig;ndingen p&aring; regulatoren falder til
              kun 11&nbsp;V. Se skema. De r&oslash;de modstande er et udtryk for
              tab, de bliver ganske enkelt varme. Den indre modstand inde i
              batteriet er lidt speciel, idet den er kemisk betinget.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig. 3
            </p>
            <p>Beregninger ved 37&nbsp;Amp konstant str&oslash;m</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Batteriets indre modstand</TableCell>
                  <TableCell>2 stk 4mm guldstik</TableCell>
                  <TableCell>Ledninger</TableCell>
                  <TableCell>Samlet tab</TableCell>
                  <TableCell>Regulator</TableCell>
                  <TableCell>Systemets sum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Modstand</TableCell>
                  <TableCell>
                    <span className={classes.red}>
                      28,6&nbsp;mohm= 9,53&nbsp;mohm pr. celle
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>
                      2x0,2&nbsp;mohm = 0,4&nbsp;mohm
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>
                      60&nbsp;cm 2,5 kvadrat = 4&nbsp;mohm
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>33&nbsp;mohm</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.blue}>297&nbsp;mohm</span>
                  </TableCell>
                  <TableCell>330&nbsp;mohm</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sp&aelig;ndingsfald</TableCell>
                  <TableCell>
                    <span className={classes.red}>1,058&nbsp;V</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>15&nbsp;mV</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>148&nbsp;mV</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>1,2&nbsp;V</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.blue}>11&nbsp;V</span>
                  </TableCell>
                  <TableCell>12,2&nbsp;V</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Afsat effekt</TableCell>
                  <TableCell>
                    <span className={classes.red}>39,1&nbsp;W</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>&frac12;W</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>5,48&nbsp;W</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.red}>45&nbsp;W</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.blue}>407&nbsp;W</span>
                  </TableCell>
                  <TableCell>451&nbsp;W</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>Vi kan konkludere at:</p>
            <ul>
              <li>
                <p>
                  Batteriets virkningsgrad er (407&nbsp;W/451&nbsp;W)x100 =
                  90&nbsp;% i det viste eksempel. 45&nbsp;W bliver til varmetab.
                </p>
              </li>
              <li>
                <p>
                  Den nyttesp&aelig;nding, som er p&aring; regulatoren, er
                  yderst afh&aelig;ngig af batteriets indre modstand. Der er
                  batterier, som slet ikke er brugbare ved 10&nbsp;C, fordi
                  sp&aelig;ndingen under belastning falder til under 3&nbsp;V pr
                  celle!
                </p>
              </li>
              <li>
                <p>
                  Den indre modstand er normalt ikke oplyst af fabrikanten, men
                  at man i stedet bliver oplyst om, med hvor mange C batteriet
                  kan belastes med f.eks. 10&nbsp;C, 15&nbsp;C, 25&nbsp;C o.s.v.
                </p>
              </li>
              <li>
                <p>
                  Jo flere C batteriet kan klare, desto mindre indre modstand
                  m&aring; batteriet forventes at have.
                </p>
              </li>
              <li>
                <p>Den indre modstand stiger med faldende temperatur.</p>
              </li>
              <li>
                <p>
                  Den indre modstand stiger med alderen og antallet af op- og
                  afladninger.
                </p>
              </li>
            </ul>
            <h2>M&aring;ling af batteriets kapacitet</h2>
            <p>
              <img src={fig6} />
              <br />
              Graf 2
            </p>
            <p>
              Ved n&aelig;ste m&aring;ling bestemmes batteriets kapacitet. Se
              graf 2. Efter 10 sekunder tilkobles
              konstantstr&oslash;msbelastningen, s&aring;ledes at der samlet
              tr&aelig;kkes 37&nbsp;Amp.. Efter 5min og 23sek rammer den
              f&oslash;rste celle 3&nbsp;Volt, og aflade forl&oslash;bet
              afbrydes. Batteriets anvendelige kapacitet ved 10&nbsp;C kan nu
              bestemmes, idet 5minutter og 23sek er lig 0,08966 time x
              37&nbsp;Amp = 3318mA/time. Dette er 89,7&nbsp;% af den
              p&aring;stemplede v&aelig;rdi. Ved en m&aring;ling p&aring;
              1&nbsp;C vil kapaciteten blive lidt st&oslash;rre, ved mere end
              10&nbsp;C vil den blive mindre af &aring;rsager, som det blev
              beskrevet ved m&aring;lingen af den indre modstand. N&aring;r du
              flyver, b&oslash;r du kun anvende 70&nbsp;% af den faktiske
              kapacitet alts&aring; 70&nbsp;% af 3318mA = 2322mA/time. Nu er vi
              s&aring; nede p&aring; 62,8&nbsp;% af den p&aring;stemplede
              v&aelig;rdi.
            </p>
            <p>
              Generelt holder batterier ikke den lovede kapacitet, m&aring;ske
              fordi det er et hobbymarked. I flyvemaskineindustrien kasseres
              batterierne f.eks., hvis de kommer under 85&nbsp;% af den
              p&aring;stemplede kapacitet m&aring;lt ved en specificeret
              procedure. Et helt nyt Harald Nyborg bilbatteri p&aring;
              44&nbsp;Amp/timer er m&aring;lt til 32&nbsp;Amp/timer! N&aring;,
              men det var ogs&aring; p&aring; tilbud. Der er dog lyspunkter,
              m&aring;linger p&aring; Sanyo Eneloop AAA og AA NiMh batterier
              viser, at de faktisk holder, hvad de lover.
            </p>
            <p>
              Nu vil vi se p&aring; temperaturen, som stiger fra 20&nbsp;C til
              48&nbsp;C grader. Dette er m&aring;lt uden p&aring; cellerne med
              en sensor, idet det af gode grunde ikke er muligt at stikke et
              stegetermometer ind i pakken. Det m&aring; antages, at der inde i
              den midterste pakke er en temperatur, som er ca. 4 grader
              h&oslash;jere. Hvis det nu havde v&aelig;ret en sommerdag, hvor
              omgivelsestemperaturen havde v&aelig;ret 30&nbsp;C grader, var man
              kommet t&aelig;t p&aring; den maximale temperatur, som cellerne
              kan t&aring;le nemlig 60&nbsp;C grader, og dette allerede ved et
              str&oslash;mforbrug p&aring; 10&nbsp;C! Nu har batterierne det
              heldigvis normalt bedre i luften p&aring; grund af et mere
              varieret str&oslash;mforbrug og bedre k&oslash;ling.
            </p>
            <p>
              L&aelig;g endvidere m&aelig;rke til hvad der sker med
              sp&aelig;ndingen, efter at afladningen er afsluttet. Den
              ubelastede sp&aelig;nding stiger med ca. 1&nbsp;V. Det er ikke,
              fordi batteriet ikke er blevet t&oslash;mt, men fordi belastningen
              er fjernet. Konklusionen er, at man ikke kan bestemme batteriers
              forbrugte kapacitet ved at m&aring;le sp&aelig;ndingen.
            </p>
            <p>
              Med disse m&aring;letekniske betragtninger h&aring;ber jeg
              p&aring;, at du bedre kan g&oslash;re dine valg, n&aring;r du
              k&oslash;ber dit n&aelig;ste Li-Po batteri, v&aelig;r s&aelig;rlig
              opm&aelig;rksom p&aring;, at flere af fabrikanterne nu tilbyder
              forskellige serier med forskellige egenskaber. Et batteri er ikke
              bare et batteri.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
