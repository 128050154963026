import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig1.jpg";
import fig2 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig2.jpg";
import fig3 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig3.jpg";
import fig4 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig4.jpg";
import fig5 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig5.jpg";
import fig6 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig6.jpg";
import fig7 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig7.jpg";
import fig8 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig8.jpg";
import fig9 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig9.jpg";
import fig10 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig10.jpg";
import fig11 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig11.jpg";
import fig12 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig12.jpg";
import fig13 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig13.jpg";
import fig14 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig14.jpg";
import fig15 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig15.jpg";
import fig16 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig16.jpg";
import fig17 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig17.jpg";
import fig18 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig18.jpg";
import fig19 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig19.jpg";
import fig20 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig20.jpg";
import fig21 from "../../../images/FlyByWire/MultirotorteknikTeoriOgPraksis/fig21.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Multirotorteknik - Teori og praksis</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Multirotorteknik - Teori og praksis</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn&nbsp;</p>
            <p>Publiceret feb. 2017.</p>
            <h2>Indledning</h2>
            <p>
              Artiklen skal belyse principperne i multirotor flyet,
              n&aelig;rmere pr&aelig;ciseret quadcopteren. Se m&aring;ske
              f&oslash;rst Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt2.dk/modelflyvenyt2/2015/Modelflyvenyt.2.2015.pdf"
              >
                Nr 2 2015
              </Link>{" "}
              eller&nbsp;
              <Link href="http://elflytec.dk/FlyByWire/Udviklingstendenser-i-RC-teknologierne">
                Udviklingstendenser i RC teknologierne
              </Link>
              &nbsp;hvor nogle grundl&aelig;ggende teknologi begreber og termer
              gennemg&aring;s. Quadcopteren optr&aelig;der i forskellige
              varianter afh&aelig;ngig af de mange form&aring;l, den skal
              anvendes til. Mit udgangspunkt er en quadcopter, som kan udbygges
              til en konstruktion, der ogs&aring; har faste vinger, et
              s&aring;kaldt VTOL-fly.
            </p>
            <h2>Quadcopterens virkem&aring;de&nbsp;</h2>
            <p>
              Quadcopteren bruger ikke servoer til styringen, men &aelig;ndrer
              omdrejningstallet p&aring; rotorerne. Der anvendes et lige antal
              rotorer, hvoraf halvdelen nemlig M1 og M3 k&oslash;rer mod uret
              (Anti Clock Wise) for at oph&aelig;ve drejmomentet. Hvis rotor M1
              og M3 mindsker 200 omdrejninger samtidig med at rotor M2 og M4
              &oslash;ges 200 omdrejninger, vil den samlede opdrift v&aelig;re
              den samme, men quadcopteren vil rotere omkring sin yaw akse. Hvis
              M3 og M4 &oslash;ger omdrejninger samtidig med at M1 og M2
              mindsker omdrejninger, vil quadcopteren flyve fremad i pilens
              retning. Hvis alle motorer &oslash;ger lige mange omdrejninger,
              vil den naturligvis stige.
            </p>
            <p>
              Bem&aelig;rk at tyngdepunktet skal ligge der, hvor de to
              diagonaler krydser hinanden, og at l&aelig;ngden af diagonalen
              betegner quadcopterens rammem&aring;l. Se Fig. 1.
            </p>
            <p>
              <img src={fig1} />
              <br />
              Fig. 1
            </p>
            <p>
              Princippet i den enkelte rotor er vores velkendte propel. Man skal
              bem&aelig;rke, at stigningen ikke b&oslash;r overstige halvdelen
              af diameteren, fordi man kun er interesseret i at f&aring; et
              s&aring; stort statisk tryk som muligt. Dette g&aelig;lder dog
              ikke, hvis man kun er interesseret i at flyve st&aelig;rkt.
            </p>
            <p>
              Om propellen b&oslash;r du vide, at hvis du &aelig;ndrer
              diameteren, sker der store &aelig;ndringer. Teoretisk vil en
              propel optage dobbelt s&aring; meget effekt, hvis du g&aring;r fra
              10 tommer til 12 tommer alt andet lige, hvilket b&aring;de motor
              og den Elektroniske Speed Controller (ESC) skal kunne
              t&aring;le.&nbsp;Hvis du fordobler omdrejningerne, 8 dobler du den
              effekt propellen optager, samtidig falder virkningsgraden. Man kan
              endvidere sige, at man gerne vil have en s&aring; lille
              diskbelastning p&aring; rotoren som mulig d.v.s store rotorer og
              en lav v&aelig;gt p&aring; flyet.
            </p>
            <p>
              Beregningseksempel: Der haves 4 stk. 10 tommer propeller.
              Diskarealet er 4 x 3,14 x 1,25dm x 1,25dm = 19,6 kvadratdecimeter.
              Hvis quadcopterens samlede v&aelig;gt er 1600g, vil
              diskbelastningen v&aelig;re 1600/19,6 = 82g/dm. Dette kan
              sammenlignes lidt med planbelastningen p&aring; et fastvingefly.
              Det er vigtigt at propellerne er s&aring; lette som muligt, fordi
              man er interesseret i, at omdrejningerne kan varieres s&aring;
              hurtigt som muligt, hvilket &oslash;ger
              man&oslash;vredygtigheden.&nbsp;
            </p>
            <p>
              Hvor stort et tryk er n&oslash;dvendigt? Hvis maskinen lige
              akkurat frembringer det samme tryk, som den vejer, kan den
              teoretisk kun lige holde sig flyvende. Dette er i praksis ikke
              tilstr&aelig;kkeligt. Der &oslash;nskes en opdrift som er mellem
              1,5 og 2 gange st&oslash;rre end v&aelig;gten, hvis der skal
              opn&aring;s en velflyvende quadcopter. Hvis flyet flyver fremad
              eller skal kunne st&aring; stille i modvind, skal der frembringes
              en fremdrift, samtidig med at h&oslash;jden skal holdes. Dette
              betyder, at rotortrykket skal &oslash;ges med 260g til 1860g, hvis
              flyet er tiltet 30 grader. N&aring;r de 920 grams fremdrift er lig
              med en luftmodstand af samme st&oslash;rrelse, vil flyet have
              fundet sin maximale flyvefart fremad. Se fig. 2. Udtrykket FFF
              betyder Fast Forward Flying.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Fig. 2
            </p>
            <p>
              En anden &aring;rsag til, at man &oslash;nsker et overskud af
              l&oslash;ft er, at mens der ikke er nogle problemer med at holde
              konstant h&oslash;jde eller stige, ser det helt anderledes ud,
              hvis man foretager en hurtig nedstigning. Grunden er, at propellen
              flyver ned i gennem sin egen turbulente slipstr&oslash;m, og
              derved kan bringes til at miste sin opdrift, s&aring;ledes
              maskinen i v&aelig;rste fald styrter ned. Se fig. 3. Et forvarsel
              er begyndende ustabilitet og st&aelig;rk for&oslash;get
              rotorst&oslash;j. Hvis det sker, skal der gives
              &oslash;jeblikkelig fuld gas og fuld fremad pind (FFF)
              s&aring;ledes, at man fors&oslash;ger at undvige
              slipstr&oslash;mmen. Dette kan v&aelig;re en ganske voldsom
              belastning, hvor jeg i praksis har registreret vibrationer og
              g-p&aring;virkninger p&aring; 3 til 4 g, fordi jeg kortvarigt har
              stoppet motorerne for derefter at give fuld gas.
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig. 3
            </p>
            <h2>Feedback princip</h2>
            <p>
              Uden anvendelse af en computer med tilh&oslash;rende gyroer vil
              det ikke v&aelig;re muligt at holde multicopteren flyvende. Man
              anvender et s&aring;kaldt feedback princip.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig. 4
            </p>
            <p>
              Til venstre er den v&aelig;rdi, som &oslash;nskes ogs&aring;
              kaldet referencev&aelig;rdien. Til h&oslash;jre er den
              v&aelig;rdi, der haves.
            </p>
            <p>
              Man bem&aelig;rker, at der f&oslash;res en del af den v&aelig;rdi
              der haves, tilbage til et subtraktionspunkt, hvor man foretager en
              sammenligning med den &oslash;nskede v&aelig;rdi, hvorved der
              muligvis opst&aring;r en forskel.
            </p>
            <p>Der kan nu forekomme tre tilstande:</p>
            <ol>
              <li>
                Hvis den v&aelig;rdi der haves, er lig med den &oslash;nskede
                v&aelig;rdi, er systemet i balance. Forskellen er nul.
              </li>
              <li>
                Hvis den v&aelig;rdi der haves, er mindre end den &oslash;nskede
                v&aelig;rdi, fremkommer der en forskel, som vil bringe systemet
                mod balance.
              </li>
              <li>
                Hvis den v&aelig;rdi der haves, er st&oslash;rre end den
                &oslash;nskede v&aelig;rdi, fremkommer der en forskel, som
                ligeledes vil bringe systemet mod balance, blot den modsatte
                vej. Som du kan se, bev&aelig;ger man sig fra en tilstand til en
                anden tilstand. Det skal gerne ske s&aring; hurtigt som muligt.
                Da der er tale om fysiske ting, som er i bev&aelig;gelse, kan
                flyet finde p&aring; at bev&aelig;ge sig lidt for langt p&aring;
                grund af enertien, se den r&oslash;de kurve p&aring; fig. 5.
                Hvis potentiometeret p&aring; fig. 4 drejes ned mod minimum,
                mindsker man feedback funktionen, indtil en passende
                st&oslash;rrelse haves illustreret ved den sorte kurve. For lav
                feedback er vist ved bl&aring; kurve, hvor systemet bliver for
                sl&oslash;vt. Denne justering kan foretages i mulicopterens
                computer firmware og betegnes som Gain, Proportional, Integral
                og Derivate v&aelig;rdier.
              </li>
            </ol>
            <p>
              <img src={fig5} />
              <br />
              Fig. 5
            </p>
            <p>
              Quadcopteren skal alts&aring; automatisk tilstr&aelig;be at
              opn&aring; en eller flere &oslash;nskede referencev&aelig;rdier.
              Flyven&oslash;jagtigheden bestemmes altid af
              referencev&aelig;rdiernes n&oslash;jagtighed. Som minimum modtages
              hele tiden en reference fra en 3-akset gyroplatform, baseret
              p&aring; et Micro Elektro Mekanisk System, som holder Quadcopteren
              i balance i det vandrette plan. Referencen kan ogs&aring;
              v&aelig;re en bestemt flyveh&oslash;jde, en kompaskurs eller et
              bestemt sted p&aring; jorden i form af GPS koordinater. Man skal
              v&aelig;re opm&aelig;rksom p&aring; at feedbackreguleringen kun
              virker til en vis grad, hvis udefra kommende p&aring;virkninger
              bliver for store. Det kunne v&aelig;re at tyngdepunktet l&aring;
              meget forkert, eller at det bl&aelig;ste for meget til at kunne
              holde multicopteren l&aring;st til en bestemt GPS
              referencekoordinat.
            </p>
            <h2>Flight Controlleren Eagle-Tree</h2>
            <p>
              Jeg vil nu se n&aelig;rmere p&aring; et eksempel p&aring; en
              Flight Controller (FC) til en quadcopter, af fabrikatet EagleTree.
              Denne Flight Controller har v&aelig;ret beskrevet tidligere i
              Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvenyt2.dk/modelflyvenyt2/2015/Modelflyvenyt.3.2015.pdf"
              >
                Nr 3 2015
              </Link>{" "}
              eller{" "}
              <Link href="https://elflytec.dk/FlyByWire/Fly-by-Wire-flyvning-med-Eagle-Tree-Vector">
                Fly by Wire flyvning med Eagle Tree Vector
              </Link>{" "}
              hvor den blev anvendt i et fastvinge fly. FCen er nu konfigureret
              til Quadcopter brug. Se blokdiagrammet fig. 7. Piloten giver input
              til RC-modtagerens 6 kanaler, hvor output er impulser, hvis
              l&aelig;ngde kan varieres fra 1 millisekund til 2 millisekunder.
              Se fig.6. Bem&aelig;rk at 1mS svarer til 0 %, og at 2mS svarer til
              100&nbsp;%. Alt hvad piloten foretager sig p&aring; de 6 kanaler,
              bliver registreret i Flight Data Recorderen i formatet 0&nbsp;%
              til 100&nbsp;%. Den er et rigtig vigtigt v&aelig;rkt&oslash;j for
              udviklingen af min quadcopter og en &aring;rsag til, at jeg har
              valgt Eagle-Tree FCen blandt flere andre muligheder.
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig. 6
            </p>
            <p>Eagle Tree Vector kan logge f&oslash;lgende data:</p>
            <ul>
              <li>
                Alt hvad piloten foretager sig med senderen registreres og kan
                ikke bortforklares senere!
              </li>
              <li>De 4 motores omdrejninger m&aring;lt som input i procent.</li>
              <li>
                Multicopterens n&oslash;jagtige stilling omkring de tre akser.
              </li>
              <li>Multicopterens g-p&aring;virkninger.</li>
              <li>
                Elektriske st&oslash;rrelser som str&oslash;mforbrug, mA/h
                forbrug og batterisp&aelig;ndingen.
              </li>
              <li>Temperatur.</li>
              <li>N&oslash;jagtig h&oslash;jde m&aring;ling.</li>
              <li>GPS koordinater.</li>
              <li>Antal modtagne satellitter.</li>
              <li>Kompaskurs.</li>
              <li>Afstand fra pilot/homepoint.&nbsp;</li>
              <li>
                Logger forskellige notifikationer dvs. notater om FCens status.
              </li>
              <li>Der ud over optegnes flyvningen i Google Earth.</li>
            </ul>
            <p>
              Recorderens hukommelse er p&aring; 2Gbyte og med 5 m&aring;linger
              pr. sekund, er der kapacitet til 100 minutters flyvning. Der er
              ind til nu opsamlet 26,2 GB flight data fra i alt 50 flyvninger.
              Se eksempler senere.
            </p>
            <p>
              FCen frembringer hele tiden referencev&aelig;rdier fra den 3
              aksede gyroplatform, en barometrisk h&oslash;jdem&aring;ler, samt
              en GPS modtager med indbygget magnetometer. Sidstn&aelig;vnte
              m&aring;ler p&aring; jordens magnetfelt. Dette er
              n&oslash;dvendigt, fordi kursberegninger kun kan foretages,
              n&aring;r GPS koordinaterne &aelig;ndrer sig. Da dette ikke sker,
              n&aring;r Quadcopteren st&aring;r stille, m&aring; kursen
              bestemmes med magnetometeret.&nbsp;Kombinationen af pilotens input
              og referencev&aelig;rdierne sendes til styring af de 4 motorer
              efter et omfattende og hurtigt beregningsarbejde udf&oslash;rt af
              central processorens firmware.&nbsp;
            </p>
            <p>
              Bem&aelig;rk at de styreimpulser som tilf&oslash;res de
              Elektroniske Speed Controllere har f&aring;et fjernet den lange
              pause p&aring; 18 mS, som ellers findes i radiosignalet fra
              senderen. S&aring; i stedet for at blive opdateret 50 gange i
              sekundet bliver motorerne opdateret med en hastighed p&aring; 430
              gange i sekundet med referencer fra blandt andet gyroplatformen.
              Dette skal ESCen kunne h&aring;ndtere.
            </p>
            <p>
              De nyeste versioner af softwaren kan ogs&aring; arbejde med
              Oneshot125- og Multishot ESCere, hvor motorerne opdateres op til
              8000 gange i sekundet beregnet til Quadracere. Det skal endvidere
              bem&aelig;rkes, at man nu i Google Earth kan indl&aelig;gge op til
              50 wavepoints, s&aring;ledes at Eagle-Tree kan foretage en
              fuldst&aelig;ndig autonom flyvning. Det sidste rejser
              sp&oslash;rgsm&aring;let, om det overhovedet er en interessant
              form for modelflyvning eller om teknologien er drevet for vidt?
              Vil en selvstyrende Farrari sportsvogn kunne s&aelig;lges?
            </p>
            <p>
              Til quadracer markedet har Eagle-Tree udviklet ny hardware nemlig
              en lille MicroVector Controller p&aring; 13g ligesom at
              satellitmodtageren nu ogs&aring; kan modtage Glonass satellitter
              til forbedring af positionsreferencen.
            </p>
            <p>
              Som du kan se udvikler Eagle-Tree stadig deres produkter, og det
              antydes, at man med et pitotr&oslash;r som reference vil kunne
              flyve true airspeed p&aring; fastvinge modelfly og m&aring;ske
              kunne lande dem automatisk ved hj&aelig;lp af landingsbanens GPS
              koordinater og h&oslash;jdem&aring;leren. Det bliver
              sp&aelig;ndende!
            </p>
            <p>
              <img src={fig7} />
              <br />
              Fig. 7
            </p>
            <p>
              <img src={fig8} />
            </p>
            <p>
              GWS MT-1 er et testapparat, som kan udsende pulser af variabel
              l&aelig;ngde og derved erstatte RC-anl&aelig;ggets sender og
              modtager. P&aring; det viste billede ses at impulsen er 1,236
              millisekunder lang. Denne impuls tilsluttes ESCen, som f&aring;r
              en af motorerne til at k&oslash;re. Bem&aelig;rk at jeg anvender
              en Nexus 7 tablet til at m&aring;le vibrationer.
            </p>
            <h2>On Screen Display</h2>
            <p>
              Som det fremg&aring;r af fig. 7 anvender Eagle Tree ogs&aring; et
              Videosystem (se de gule blokke), som muligg&oslash;r at piloten
              f&aring;r et On Screen Display til r&aring;dighed p&aring; jorden.
              Jeg anvender hovedsagelig sk&aelig;rmen til f&oslash;lgende:
            </p>
            <ul>
              <li>Ops&aelig;tning af Flight Recorder.</li>
              <li>Sikkerhed for at mindst 8 satellitter modtages (SAT FIX)</li>
              <li>
                Indl&aelig;sning af RTH koordinater og nulstilling af
                h&oslash;jde m&aring;ler.
              </li>
              <li>Kontrol af Flight Controlerens mode.</li>
              <li>L&oslash;bende kontrol af batterisp&aelig;ndingen.</li>
              <li>Justeringen af og l&aring;sning af flyveh&oslash;jde.</li>
              <li>Forbrugt batterikapacitet.</li>
            </ul>
            <p>
              Desuden er sk&aelig;rmen sat op til at opl&aelig;se
              batterisp&aelig;ndingen for hver 30 sek. samt at afgive
              forskellige alarmer i klar tale.
            </p>
            <h2>Indledende m&aring;linger p&aring; multikopteren Vektor 1</h2>
            <p>
              Jeg vil nu fors&oslash;ge at beskrive, hvordan jeg har udviklet
              multikopteren Vektor 1 fra bunden. Jeg starter med at m&aring;le
              p&aring; en drivlinie best&aring;ende af en Dualsky XM2834MR-9
              motor og en Graupner 10x5 propel i en teststand, for at f&aring;
              en ide om hvad der kan lade sig g&oslash;re. Propellen f&aring;s
              som Clock Wise og Anti Clock Wise.&nbsp;
            </p>
            <p>
              M&aring;linger p&aring; en motor med Dualsky Lite 22A Controller
              (Timing middle) udf&oslash;rt i en teststand og med en reguleret
              Power Supply.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Sp&aelig;nding</p>
                  </TableCell>
                  <TableCell>
                    <p>Str&oslash;m</p>
                  </TableCell>
                  <TableCell>
                    <p>Effekt</p>
                  </TableCell>
                  <TableCell>
                    <p>Max tryk fra en motor</p>
                  </TableCell>
                  <TableCell>
                    <p>g/watt</p>
                  </TableCell>
                  <TableCell>
                    <p>Samlet tryk fra 4 motorer</p>
                  </TableCell>
                  <TableCell>
                    <p>Max v&aelig;gt</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>10&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>11,3&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>113&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>663&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>5,86</p>
                  </TableCell>
                  <TableCell>
                    <p>2652&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1325&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>11&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>13,0&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>143&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>740&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>5,17</p>
                  </TableCell>
                  <TableCell>
                    <p>2960&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1480&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>12&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>14,9&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>179&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>865&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>4,83</p>
                  </TableCell>
                  <TableCell>
                    <p>3460&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1730&nbsp;g</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Vi skal v&aelig;re sikker p&aring;, at drivlinien ikke bliver
              overbelastet, fordi et svigt i drivlinien vil f&oslash;re til
              &oslash;jeblikkelig nedstyrtning. Dualsky oplyser f&oslash;lgende
              om motoren: Max effekt 250W i 15sek. Max str&oslash;m 23A i 15sek.
              Cruising power 137W i 8min. S&aring; vi er p&aring; den sikre
              side. Hvis en motor svigter, kan multirotor fly sikres mod
              nedstyrtning ved at anvende 6, 8 eller flere rotorer, altid et
              lige antal.
            </p>
            <p>
              Som det ses vil den maximale flyvev&aelig;gt ligge p&aring; ca.
              1500g udfra at trykket skal v&aelig;re ca. dobbelt s&aring; stort
              som v&aelig;gten.
            </p>
            <p>
              Du kan her se de anvendte komponenter, som alle er k&oslash;bt i
              RC-Netbutik.
            </p>
            <p>
              <strong>Vægtregnskab</strong>
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Vægt</TableCell>
                  <TableCell>Antal</TableCell>
                  <TableCell>Budget</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Dualsky motor XM2834MR-9</TableCell>
                  <TableCell>70</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>280</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dualsky 22A ESC</TableCell>
                  <TableCell>23</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>92</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Graupner Prop 10x5 CW og CCW</TableCell>
                  <TableCell>12</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>48</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>LiPo 3S 4000mA/h</TableCell>
                  <TableCell>320</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>320</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Jeti RX</TableCell>
                  <TableCell>15</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>15</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Eagle Controller</TableCell>
                  <TableCell>21</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>21</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Eagle MUI</TableCell>
                  <TableCell>15</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>15</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Video Tx</TableCell>
                  <TableCell>30</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>30</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Camera</TableCell>
                  <TableCell>34</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>34</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Eagle GPS</TableCell>
                  <TableCell>13</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>13</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>XT60 han connector</TableCell>
                  <TableCell>3,12</TableCell>
                  <TableCell>3</TableCell>
                  <TableCell>9,36</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>XT60 hun connector</TableCell>
                  <TableCell>3,32</TableCell>
                  <TableCell>3</TableCell>
                  <TableCell>9,96</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Fordeler</TableCell>
                  <TableCell>12</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>12</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ex30 Han/hun con</TableCell>
                  <TableCell>1,72</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>6,88</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Krop</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>500</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell>1406,2</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Derefter m&aring;les der p&aring; drivlinien ved 11V stabil
              forsyningssp&aelig;nding og med et stigende pulsinput.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Puls input</p>
                  </TableCell>
                  <TableCell>
                    <p>Puls input</p>
                  </TableCell>
                  <TableCell>
                    <p>Str&oslash;m</p>
                  </TableCell>
                  <TableCell>
                    <p>Effekt</p>
                  </TableCell>
                  <TableCell>
                    <p>Tryk</p>
                  </TableCell>
                  <TableCell>
                    <p>g/watt</p>
                  </TableCell>
                  <TableCell>
                    <p>Samlet tryk</p>
                  </TableCell>
                  <TableCell>
                    <p>Max v&aelig;gt</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>1,0&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>0&nbsp;%</p>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,1&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>10&nbsp;%</p>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,184&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>18,4&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>Start</p>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,2&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>20&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>0,3&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>3,3&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>23&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>6,96&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>92&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>46&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,3&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>30&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>1,4&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>15,4&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>124&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>8,05&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>496&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>248&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,4&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>40&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>3,0&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>33&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>240&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>7,27&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>960&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>480&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,5&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>50&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>4,7&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>51,7&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>356&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>6,88&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>1424&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>712&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,6&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>60&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>7,8&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>85,8&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>510&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>5.94&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>2040&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1020&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,7&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>70&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>12,8&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>141&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>740&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>5,24&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>2960&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1480&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,8&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>80&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>12,8&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>141&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>740&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>5,24&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>2960&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1480&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>1,9&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>90&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>12,8&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>141&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>740&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>5,24&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>2960&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1480&nbsp;g</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>2,0&nbsp;mS</p>
                  </TableCell>
                  <TableCell>
                    <p>100&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>12,8&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>141&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>740&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>5,24&nbsp;g/w</p>
                  </TableCell>
                  <TableCell>
                    <p>2960&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>1480&nbsp;g</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Udfra tabellen kan ses, at man ved lidt over 1,5msek kan f&aring;
              en motor til at frembringe et tryk p&aring; 375g. Hvis dette
              ganges med 4 f&aring;s en 1,5kg drone i balance = 5,0&nbsp;A pr
              motor = 20&nbsp;A i alt ved 11&nbsp;V. Bem&aelig;rk at gramtryk
              pr. tilf&oslash;rt watt falder jo hurtigere, motoren k&oslash;rer.
              S&aring; store langsomt k&oslash;rende rotorer giver bedre
              virkningsgrad alts&aring; mere tryk pr. tilf&oslash;rt watt.
            </p>
            <p>
              &nbsp;Nu kan s&aring; belastningen p&aring; Li-Po batteriet og ca.
              flyvetiden bestemmes.
            </p>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Batteribelastning ved hover.</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      4000&nbsp;mA/h 3S Belastning 20&nbsp;A/4&nbsp;A = 5&nbsp;C
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Hover flyvetid&nbsp;ved&nbsp;30%&nbsp;sikkerhed</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      70&nbsp;% af 4&nbsp;A/t =
                      2,8&nbsp;A/t&nbsp;/&nbsp;20&nbsp;A = 0,14t = 8,4min.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Disk belastning</p>
                  </TableCell>
                  <TableCell>
                    <p>4,9dm x 4 = 19,6dm 1500/19,6=76g/dm</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Tryk/V&aelig;gt forhold</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca.3&nbsp;kg/ca.1,5&nbsp;Kg = ca. 2 gange.</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>eCalc&nbsp;s xcopter simulation af Vektor 1</h2>
            <p>
              Som supplement til de praktiske m&aring;linger har jeg ogs&aring;
              foretaget en beregning i eCalc s xcopter program. Se udskrifter. I
              det &oslash;verste felt ses de tastede inddata. Efter at have
              trykket p&aring; Calculate fremkommer alle de efterf&oslash;lgende
              data, som viser sig at stemme rimeligt med den virkelige verden,
              herunder det der er m&aring;lt i Flight Loggeren. Programmet kan
              hurtigt vise hvad der sker, hvis man &aelig;ndrer f. eks. propel-
              eller batterist&oslash;rrelser. I databasen findes stort set alle
              eksisterende elmotorer beregnet til multicoptere. Programmet
              bliver ikke installeret p&aring; din Windows computer, men du
              k&oslash;ber dig tid til at k&oslash;re p&aring; eCalcs server i
              skyen. Det koster 2,5 US dollar for 30 dage. Du kan teste
              programmet uden at betale, men med begr&aelig;nset tilgang.
            </p>
            <p>
              <img src={fig9} />
            </p>
            <p>
              <img src={fig10} />
            </p>
            <h2>Vektor 1 opbygning</h2>
            <p>
              <img src={fig11} />
            </p>
            <p>
              Vektor 1 flyet er bygget op omkring 4 stk. 4mm 1 meter lange
              kulr&oslash;r. Motoroph&aelig;nget er en stabil trekant-
              konstruktion, hvor det nederste kulr&oslash;r er 5mm i diameter.
              Dette muligg&oslash;r at de 3 ledninger til motoren, kan
              f&oslash;res igennem r&oslash;ret og ind til ESCerne i kroppen. I
              bunden af kroppen sidder en udtagelig kasse, som indeholder Li-Po
              batteriet. Dette batteri kan justeres frem og tilbage for at
              justere tyngdepunktet. Understellet er 3mm kulst&aelig;nger, som
              kan udskiftes uden brug af v&aelig;rkt&oslash;j. Det samme
              g&aelig;lder halefinnen. GPS og magnetometer og kamera sidder
              l&aelig;ngst fremme i kroppen. Videosenderen er placeret
              l&aelig;ngst bagude, men FCen skal placeres pr&aelig;cist i
              quadcopterens tyngdepunkt for at kunne m&aring;le rigtigt.
            </p>
            <h2>M&aring;linger p&aring; Vektor 1 i luften</h2>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Flight mode</p>
                  </TableCell>
                  <TableCell>
                    <p>Ubat</p>
                  </TableCell>
                  <TableCell>
                    <p>A</p>
                  </TableCell>
                  <TableCell>
                    <p>Pin</p>
                  </TableCell>
                  <TableCell>
                    <p>Bem&aelig;rkninger</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Ved start</p>
                  </TableCell>
                  <TableCell>
                    <p>11,7&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>13,48&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>157&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>Konstant h&oslash;jde og nul fart</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Ved slutning</p>
                  </TableCell>
                  <TableCell>
                    <p>10,8V</p>
                  </TableCell>
                  <TableCell>
                    <p>14,58&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>157&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>Konstant h&oslash;jde og nul fart</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max stigning 4,5m/sek</p>
                  </TableCell>
                  <TableCell>
                    <p>10,95&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>23&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>251&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>Ved start p&aring; climb er spidsstr&oslash;mmen 26A</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Max FFF</p>
                  </TableCell>
                  <TableCell>
                    <p>10,8&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>17,1&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>184&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>Max FFF-speed = 50km/t Pitch 15grader</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Bem&aelig;rk at str&oslash;mmen stiger n&aring;r
              batterisp&aelig;ndingen falder. Feed back systemet holder
              h&oslash;jden. Dette kr&aelig;ver 157W.
            </p>
            <p>Forl&oslash;bet af quadcopterens flyvning.</p>
            <p>
              Flyvningen kan opdeles i 5 forskellige flight modes Se fig. 8.
            </p>
            <ol>
              <li>Start og hover up.</li>
              <li>
                Outbound Transition. Overgang fra lodret til vandret flyvning.
              </li>
              <li>Fast Forward Flying. (FFF)</li>
              <li>
                Inbound Transition. Overgang fra vandret til lodret flyvning
              </li>
              <li>Hover down og landing.</li>
            </ol>
            <p>
              <img src={fig12} />
              <br />
              Fig. 8
            </p>
            <p>Flyvningen styres af f&oslash;lgende:</p>
            <ul>
              <li>
                En 3 stillings mode switch p&aring; senderen, som er en Futaba
                T9CP, der hidtil har v&aelig;ret anvendt til fastvinge fly.
              </li>
              <li>
                En Kill drejeknap p&aring; senderen. Jeg foretr&aelig;kker en
                drejeknap frem for en switch, som man nemt kan komme til at
                vippe under flyvningen, med den konsekvens at multicopteren
                styrter ned.
              </li>
              <li>En FFF 2 stillings switch p&aring; senderen.</li>
              <li>1 joystick til gas/h&oslash;jdereference og yaw kontrol.</li>
              <li>
                1 joystick til pitch- og rollkontrol samt skift mellem autonom
                og manuel flyvning samt arm/disarm kontrol. Se fig. 11.
              </li>
            </ul>
            <p>
              3 stillings mode kontakt har f&oslash;lgende indflydelse p&aring;
              Quadcopterens modes:
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Grad af</p>
                    <p>selvstyring</p>
                  </TableCell>
                  <TableCell>
                    <p>Flight</p>
                    <p>mode</p>
                  </TableCell>
                  <TableCell>
                    <p>CH</p>
                  </TableCell>
                  <TableCell>
                    <p>Styrepuls</p>
                    <p>i mS</p>
                  </TableCell>
                  <TableCell>
                    <p>Styrepuls</p>
                    <p>i&nbsp;procent</p>
                  </TableCell>
                  <TableCell>
                    <p>Beskrivelse</p>
                  </TableCell>
                  <TableCell>
                    <p>Reference</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Lidt</p>
                  </TableCell>
                  <TableCell>
                    <p>2DH</p>
                  </TableCell>
                  <TableCell>
                    <p>5</p>
                  </TableCell>
                  <TableCell>
                    <p>1017uS</p>
                  </TableCell>
                  <TableCell>
                    <p>1&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>Holder tildelt h&oslash;jde.</p>
                  </TableCell>
                  <TableCell>
                    <p>Barometer</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Delvis</p>
                  </TableCell>
                  <TableCell>
                    <p>Loiter</p>
                  </TableCell>
                  <TableCell>
                    <p>5</p>
                  </TableCell>
                  <TableCell>
                    <p>1526uS</p>
                  </TableCell>
                  <TableCell>
                    <p>52&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>Holder udover tildelt h&oslash;jde</p>
                    <p>ogs&aring; positionen I det vandrette plan.</p>
                  </TableCell>
                  <TableCell>
                    <p>Barometer +</p>
                    <p>GPS koordinater</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Total</p>
                  </TableCell>
                  <TableCell>
                    <p>RTH</p>
                  </TableCell>
                  <TableCell>
                    <p>5</p>
                  </TableCell>
                  <TableCell>
                    <p>1971uS</p>
                  </TableCell>
                  <TableCell>
                    <p>97&nbsp;%</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Gennemf&oslash;rer en autonom tilbage flyvning med
                      tilh&oslash;rende landing p&aring; startstedet.
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>Barometer +</p>
                    <p>GPS koordinater + indl&aelig;st RTH koordinat.</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              En fuld flyvesekvens best&aring;r af f&oslash;lgende handlinger:
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Linje</p>
                  </TableCell>
                  <TableCell>
                    <p>Flight sekvens</p>
                  </TableCell>
                  <TableCell>
                    <p>Handlinger</p>
                  </TableCell>
                  <TableCell>
                    <p>Tx mode switch</p>
                  </TableCell>
                  <TableCell>
                    <p>FFF switch</p>
                  </TableCell>
                  <TableCell>
                    <p>Kill knap</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>1</p>
                  </TableCell>
                  <TableCell>
                    <p>USB tilsluttes Windows computer</p>
                  </TableCell>
                  <TableCell>
                    <p>Programmering m.m.</p>
                  </TableCell>
                  <TableCell>
                    <p>Uden betydning</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Ja</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>2</p>
                  </TableCell>
                  <TableCell>
                    <p>Killed</p>
                  </TableCell>
                  <TableCell>
                    <p>Is&aelig;tning af batteri</p>
                  </TableCell>
                  <TableCell>
                    <p>2DH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Ja</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>3</p>
                  </TableCell>
                  <TableCell>
                    <p>Killed</p>
                  </TableCell>
                  <TableCell>
                    <p>Programmering via sk&aelig;rm</p>
                  </TableCell>
                  <TableCell>
                    <p>2DH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Ja</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>4</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor start</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor start</p>
                  </TableCell>
                  <TableCell>
                    <p>2DH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>5</p>
                  </TableCell>
                  <TableCell>
                    <p>Hover op</p>
                  </TableCell>
                  <TableCell>
                    <p>Opstigning</p>
                  </TableCell>
                  <TableCell>
                    <p>2DH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>6</p>
                  </TableCell>
                  <TableCell>
                    <p>Stop</p>
                  </TableCell>
                  <TableCell>
                    <p>Holder stille p&aring; stedet</p>
                  </TableCell>
                  <TableCell>
                    <p>Loiter</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>7</p>
                  </TableCell>
                  <TableCell>
                    <p>Outbound trans.</p>
                  </TableCell>
                  <TableCell>
                    <p>Accelerer fremad</p>
                  </TableCell>
                  <TableCell>
                    <p>Loiter</p>
                  </TableCell>
                  <TableCell>
                    <p>Ja</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>8</p>
                  </TableCell>
                  <TableCell>
                    <p>FFF</p>
                  </TableCell>
                  <TableCell>
                    <p>Flyver hurtigt frem</p>
                  </TableCell>
                  <TableCell>
                    <p>Loiter</p>
                  </TableCell>
                  <TableCell>
                    <p>Ja</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>9</p>
                  </TableCell>
                  <TableCell>
                    <p>Inbound trans.</p>
                  </TableCell>
                  <TableCell>
                    <p>Opbremsning</p>
                  </TableCell>
                  <TableCell>
                    <p>Loiter</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>10</p>
                  </TableCell>
                  <TableCell>
                    <p>Stop</p>
                  </TableCell>
                  <TableCell>
                    <p>Holder stille p&aring; stedet</p>
                  </TableCell>
                  <TableCell>
                    <p>Loiter</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>11</p>
                  </TableCell>
                  <TableCell>
                    <p>RTH</p>
                  </TableCell>
                  <TableCell>
                    <p>Autolanding p&aring; home</p>
                  </TableCell>
                  <TableCell>
                    <p>RTH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>12</p>
                  </TableCell>
                  <TableCell>
                    <p>Hover ned</p>
                  </TableCell>
                  <TableCell>
                    <p>Nedstigning</p>
                  </TableCell>
                  <TableCell>
                    <p>RTH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>13</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor stop</p>
                  </TableCell>
                  <TableCell>
                    <p>Motor stop</p>
                  </TableCell>
                  <TableCell>
                    <p>2DH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>14</p>
                  </TableCell>
                  <TableCell>
                    <p>Killed</p>
                  </TableCell>
                  <TableCell>
                    <p>Udtagning af batteri</p>
                  </TableCell>
                  <TableCell>
                    <p>2DH</p>
                  </TableCell>
                  <TableCell>
                    <p>Nej</p>
                  </TableCell>
                  <TableCell>
                    <p>Ja</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              I linje 1 er Quadcopteren tilkoblet en Windows computer. Man kan
              her:
            </p>
            <ul>
              <li>Opdatere firmwaren til nye versioner.</li>
              <li>Ops&aelig;tte og tilpasse Eagle-Tree systemet</li>
              <li>
                Downloade flight data fra Flight Recorderen efter endt flyvning.
              </li>
            </ul>
            <p>
              I linje 2 is&aelig;ttes batteriet. Her skal man sikre sig at, kill
              drejeknappen er aktiv, s&aring;ledes at motorerne ikke kan starte.
              Dette sikres via obligatorisk brug af en checkliste.{" "}
            </p>
            <p>
              I linje 3 foretages forskellig programmering via sk&aelig;rmen
              if&oslash;lge en elektronisk tjekliste. Vigtigt er at man skal
              v&aelig;re sikker p&aring; at have sikker forbindelse til mellem 8
              og 12 satellitter (SAT_FIX), s&aring; man kan indl&aelig;se RTH
              ReTurn-to-Home koordinaterne samtidig med at man nulstiller
              h&oslash;jdem&aring;leren. F&oslash;r kan man ikke flyve.&nbsp;
            </p>
            <p>
              I linje 4 opstartes motorerne. Der er en r&aelig;kke logiske
              betingelser, som skal v&aelig;re opfyldt. Se AND gaten Fig. 9. Der
              skal v&aelig;re et Ja svar, p&aring; alle 7 indgange, for at
              motorerne starter op, ved at ESCerne f&aring;r tilf&oslash;rt
              1,2mS pulser. Trekanten med ringen, som sidder 3 steder p&aring;
              Fig.9, laver et Ja svar om til et Nej svar, og et Nej svar om til
              et Ja svar. S&aring; hvis Kill switch er aktiv, alts&aring; ja,
              vil man inde p&aring; ben 2 have et Nej svar, motorerne vil ikke
              starte, fordi man jo skulle have Ja svar, p&aring; alle 7
              indgange. Ja svar p&aring; ben 5 til 7 og et ja svar inde p&aring;
              ben 4 skal sikre, at starten bliver lodret, s&aring; man ikke
              f&aring;r quadcopteren i hovedet under starten. Samtidig starter
              ogs&aring; Data Flight Recorderen.{" "}
            </p>
            <p>Logiske betingelser for opstart af motorer</p>
            <p>
              <img src={fig13} />
              <br />
              Fig. 9
            </p>
            <p>
              I linje 5 gives der forsigtigt gas, og quadcopteren stiger og er
              under manuel kontrol. Hvis der er vind, driver den med vinden som
              en ballon.&nbsp;
            </p>
            <p>
              I linje 6 skiftes der til Loiter mode. Her vil quadcopteren blive
              l&aring;st af satellitterne til at st&aring; stille over jorden.
              Hvis man samtidig regulerer h&oslash;jden forsigtigt, kan man
              indl&aring;se en h&oslash;jdereference, s&aring; flyet holder
              konstant h&oslash;jde styret af h&oslash;jdem&aring;leren.
            </p>
            <p>
              I linje 7 og 8 skiftes til FFF flyvning ved at vippe FFF switchen.
              Flyveh&oslash;jden er helt stabil reguleret FCen. Retningen styres
              af rollsticken. FFF switchen indkobler en mixer i senderen, som
              giver fast pitch v&aelig;rdi ind. Dette g&oslash;r, at piloten kun
              skal t&aelig;nke p&aring;, hvad vej quadcopteren skal flyve og
              intet andet.
            </p>
            <p>
              I linje 9 og 10 stoppes FFF og quadcopteren bremser op og holder
              stille.
            </p>
            <p>
              I linje 11 vippes mode switchen til RTH. Quadcopteren begynder nu
              at flyve hjem og lander ganske automatisk, hvis alt er sat rigtigt
              op. Fig. 10 viser de logiske betingelser for den autonome retur
              flyvning og landing.
            </p>
            <p>
              <img src={fig14} />
              <br />
              Fig. 10
            </p>
            <p>
              Hvis RTH skal udl&oslash;ses skal der v&aelig;re Ja svar p&aring;
              alle 5 indgange af AND Gaten i Fig. 10. Ja svaret p&aring; indgang
              5 kommer fra en OR Gate, hvor det blot er n&oslash;dvendigt, at
              der er et Ja svar. RTH udl&oslash;ses, hvis max. flyveh&oslash;jde
              er overskredet, eller max flyveafstand er overskredet, eller RTH
              switch er udl&oslash;st, eller modtageren udsender et fail-safe
              signal. Sidst n&aelig;vnte kunne opst&aring;, hvis quadcopteren
              kom uden for senderens r&aelig;kkevidde.
            </p>
            <p>
              Man bringer pitchsignalet op i det r&oslash;de omr&aring;de med
              FFF switchen uden at flytte pinden! Se fig11. Det er her uhyre
              vigtigt for piloten at vide, quadcopteren&nbsp;
              <strong>ikke</strong>&nbsp;flyver RTH, hvis den flyver FFF. Jeg vi
              her pr&oslash;ve at vise hvorfor. Tankegangen er, at hvis
              Roll/Pitch sticken er inde i det gule felt, vil RTH v&aelig;re
              aktiv og quadcopteren flyver autonom. Hvis man af en eller anden
              grund &oslash;nsker at gribe ind og styre manuelt, skal man blot
              bringe pinden udenfor det gule omr&aring;de, hvorefter der
              opn&aring;s manuel kontrol.
            </p>
            <p>
              <img src={fig15} />
              <br />
              Fig. 11
            </p>
            <p>
              Man bem&aelig;rker endvidere, at man ved at f&oslash;re pinden
              helt ned i h&oslash;jre side, udl&oslash;ser et armeringssignal
              som tillader motorerne at starte. Se pin 3 p&aring; Fig. 9. Dette
              er en ekstra sikkerhed mod at komme til skade p&aring; grund af
              roterende propeller.
            </p>
            <p>
              <strong>
                <u>
                  I den dialog som piloten f&oslash;rer med Fly-By-Wire
                  systemet, skal piloten virkelig kende reglerne, ellers kan man
                  hurtig blive i tvivl om, hvad der sker og hvem der bestemmer.
                </u>
              </strong>
            </p>
            <p>
              <img src={fig16} />
              <br />
              Fig. 12
            </p>
            <p>
              R&oslash;d kurve viser afstand fra RTH punkt. Gr&oslash;n kurve
              viser Pitchen. Sort kurve viser throttle input. Bl&aring; kurve
              viser flyveh&oslash;jden. Efter 127 sekunder overskrides den ind
              programmerede flyveh&oslash;jde p&aring; 60m i en afstand af 120m
              fra startstedet. Det udl&oslash;ses et RTH signal og efter at have
              n&aring;et en h&oslash;jde p&aring; godt 70m, p&aring;begyndes en
              autonom tilbage flyvning. Ved 170 sekunder h&aelig;nger Vektor 1
              n&oslash;jagtig over startstedet. H&oslash;jden reduceres
              yderligere til den ind programmerede h&oslash;jde p&aring; 24
              meter. Ved godt 205 sek. giver piloten fuld gas, uden dette har
              nogen virkning. Vektor 1 bringes ved 236 sek. ud af sin autonome
              tilstand ved at Pitchen kommer under 43&nbsp;% (Se fig. 11.) og
              der kan nu flyves videre manuelt. Man kunne ogs&aring; i software
              ops&aelig;tningen have valgt, at Vektor 1 skulle have landet
              automatisk med en nedstigningshastighed p&aring; 1m/sek.
            </p>
            <h2>Bestemmelse af flyvetid</h2>
            <p>
              Som udgangspunkt er beregningen af flyvetiden for multirotorflyet
              mere simpel end for beregningen af fastvingeflyets flyvetid. Til
              geng&aelig;ld medf&oslash;rer et tomt batteri, at multirotorflyet
              styrter ned i et frit fald. Det sidstn&aelig;vnte blev
              dokumenteret i min 16. flyvning. Batteriet g&aring;r fra 9,9V
              (3,3V pr. celle) til 9,0&nbsp;V (3,0&nbsp;V pr. celle) p&aring;
              17sek. En medvirkende &aring;rsag til at det g&aring;r s&aring;
              st&aelig;rkt, er at h&oslash;jdem&aring;leren fors&oslash;ger at
              holde flyveh&oslash;jden. Dette kr&aelig;ver st&aelig;rkt stigende
              motorstr&oslash;m, fordi den tilf&oslash;rte effekt til motorerne
              skal v&aelig;re konstant p&aring; trods af faldende
              sp&aelig;nding. Se Fig. 13.&nbsp;
            </p>
            <p>
              &Aring;rsagen til nedstyrtningen var, at piloten ikke anvendte
              tjeklisten. Nu gik det lige s&aring; godt, samtidig stod han og
              snakkede med vennerne, mens han monterede et batteri som allerede
              havde fl&oslash;jet 7 minutter. P&aring; dette tidspunkt var
              batterisp&aelig;ndingen ikke programmeret til LOW BATTERI
              AUTOLAND. S&aring;dan er flyvning, &oslash;jeblikkelig afregning
              for sjusk!
            </p>
            <p>
              <img src={fig17} />
              <br />
              Fig. 13
            </p>
            <p>
              Nedstyrtning: Ved 315 sek. kommer der en low batteri warning. Se
              r&oslash;d prik. Piloten flyver videre i 32 meters h&oslash;jde!
              Batterisp&aelig;ndingen er s&aelig;rlig hastig faldende, fordi
              automatikken i feedback systemet regulerer str&oslash;mmen op til
              32Amp. Nu begynder Vektor 1 at falde ned, piloten giver fuld gas
              uden nogen virkning. Til sidst m&aring;les et fald p&aring;
              9m/sek. ved sammenst&oslash;det med jorden, og Vektor 1
              kn&aelig;kker to af kulbenene i landingsstellet. Flight logger
              systemet viser sin v&aelig;rdi.
            </p>
            <p>
              <img src={fig18} />
              <br />
              Fig. 14
            </p>
            <p>
              Her h&aelig;nges Vektor 1 op i 5 meters h&oslash;jde og flyves tom
              for str&oslash;m med vilje, hvorefter der udl&oslash;ses en LOW
              BAT AUTOLAND.
            </p>
            <p>
              Li-Po Batteriet som er p&aring;stemplet 4000&nbsp;mA/h har kun en
              faktisk kapacitet p&aring; 3600&nbsp;mA/h, fordi det er gammelt.
              Den faktiske kapacitet findes ved at aflade batteriet til at den
              f&oslash;rste celle n&aring;r 3,0&nbsp;V i laderens afladeprogram.
              Batteriets faktiske kapacitet indl&aelig;ses under opstart i
              Preflight Tjeklisten.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Forl&oslash;b</p>
                  </TableCell>
                  <TableCell>
                    <p>Sammenlagt antal minutter flyvetid</p>
                  </TableCell>
                  <TableCell>
                    <p>Batteri</p>
                    <p>sp&aelig;nding</p>
                  </TableCell>
                  <TableCell>
                    <p>Sp&aelig;nding</p>
                    <p>pr. celle.</p>
                  </TableCell>
                  <TableCell>
                    <p>Sammenlagt forbrugt kapacitet</p>
                  </TableCell>
                  <TableCell>
                    <p>Alarmer</p>
                  </TableCell>
                  <TableCell>
                    <p>Bem&aelig;rkninger</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Sikker flyvetid</p>
                  </TableCell>
                  <TableCell>
                    <p>7min</p>
                  </TableCell>
                  <TableCell>
                    <p>10,9&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>3,63&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>2000&nbsp;mA/h</p>
                    <p>333&nbsp;mA/h pr. min.</p>
                  </TableCell>
                  <TableCell>
                    <p>Sender timer nr1</p>
                  </TableCell>
                  <TableCell>
                    <p>Ved blandet flyvning</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Indflyvning og landing</p>
                  </TableCell>
                  <TableCell>
                    <p>10min</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <p>3000mA/h</p>
                  </TableCell>
                  <TableCell>
                    <p>Sender timer nr2</p>
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Reserve</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca.1min</p>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <p>3200&nbsp;mA/h</p>
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    <p>B&oslash;r ikke anvendes</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Autolanding</p>
                  </TableCell>
                  <TableCell>
                    <p>Ca.1min</p>
                  </TableCell>
                  <TableCell>
                    <p>10,5&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>3,5&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>3400&nbsp;mA/h</p>
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    <p>B&oslash;r ikke anvendes</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Frit fald!</p>
                  </TableCell>
                  <TableCell>
                    <p>12min 15 sek.</p>
                  </TableCell>
                  <TableCell>
                    <p>9&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>3,0&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>3600&nbsp;mA/h</p>
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    <p>M&aring; ikke ske!!!</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Udvikling af Vektor 2 VTOL-fly</h2>
            <p>
              Vektor 1 blev unders&oslash;g for hvor stor en nyttelast, den
              kunne l&oslash;fte, ved at der i tyngdepunktet blev anbragt
              blylodder. Gr&aelig;nsen var max 400g (Se skema), hvis maskinen
              skulle flyve fornuftigt.{" "}
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Payload</p>
                  </TableCell>
                  <TableCell>
                    <p>Ibat</p>
                  </TableCell>
                  <TableCell>
                    <p>U bat</p>
                  </TableCell>
                  <TableCell>
                    <p>Effekt</p>
                  </TableCell>
                  <TableCell>
                    <p>Stigevne</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Ingen</p>
                  </TableCell>
                  <TableCell>
                    <p>14,6&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>11,5&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>168&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>4,5&nbsp;m/sek</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>200&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>17,9&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>11,1&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>199&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>2,8&nbsp;m/sek</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>400&nbsp;g</p>
                  </TableCell>
                  <TableCell>
                    <p>20,1&nbsp;A</p>
                  </TableCell>
                  <TableCell>
                    <p>11,4&nbsp;V</p>
                  </TableCell>
                  <TableCell>
                    <p>229&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>1,8&nbsp;m/sek</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Tanken med nyttelasten er at kunne l&oslash;fte et HD kamera som
              vejer ca. 100g eller at udvikle multicopteren videre som et
              egentligt VTOL fly, som vil kr&aelig;ve en
              v&aelig;gtfor&oslash;gelse p&aring; ca. 300g.{" "}
            </p>
            <p>
              Jeg vil nu sammenligne multirotorflyet med et tilsvarende
              fastvingefly. Begge vejer ca. 1500g og anvender et Li-Po batteri
              p&aring; 3S 4000&nbsp;mA/t hvoraf der forbruges ca.
              2500&nbsp;mA/h.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <p>Start og landing</p>
                  </TableCell>
                  <TableCell>
                    <p>Tilf&oslash;rt effekt</p>
                  </TableCell>
                  <TableCell>
                    <p>Flyvetid</p>
                  </TableCell>
                  <TableCell>
                    <p>Distance</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Multirotor</p>
                  </TableCell>
                  <TableCell>
                    <p>Lodret</p>
                  </TableCell>
                  <TableCell>
                    <p>168&nbsp;W/184&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>7&nbsp;min</p>
                  </TableCell>
                  <TableCell>
                    <p>5,8&nbsp;km</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Fastvinge *</p>
                  </TableCell>
                  <TableCell>
                    <p>Beh&oslash;ver bane</p>
                  </TableCell>
                  <TableCell>
                    <p>73&nbsp;W</p>
                  </TableCell>
                  <TableCell>
                    <p>20,3&nbsp;min</p>
                  </TableCell>
                  <TableCell>
                    <p>16,7&nbsp;km</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              * Se artikler i Modelflyvenyt{" "}
              <Link
                target="_blank"
                href="http://modelflyvning.dk/modelflyvenyt/gamle-numre-af-modelflyvenyt/modelflyvenyt-aargang-2010-2019/aargang-2013.aspx"
              >
                nr. 4 til 6 2013
              </Link>{" "}
              eller elflytec.dk
            </p>
            <p>
              Man kan naturligvis se, at det er mere energi&oslash;konomisk at
              frembringe opdriften med en fastvinge end med rotorer. Derfor
              g&aring;r mit projekt ud p&aring; at kombinere de to flytyper, en
              tanke som mange arbejder med.
            </p>
            <p>
              Multicopteren forsynes nu med to vinger og en 5. motor inspireret
              af Amazon Prime Air dronen. Se billedet. Som det fremg&aring;r af
              fig. 15, er det meningen, opdriften skal frembringes ved, at den
              femte motor i halen startes, og der derved gradvis opn&aring;s
              opdrift fra de faste vinger samtidig med at de 4 multirotorer
              g&aring;r ned i omdrejninger. Se fig. 16. Dette forventes at ske
              automatisk, fordi hvis maskinen stiger det mindste vil feedback
              systemet via h&oslash;jdem&aring;leren mindske multirotorernes
              opdrejninger. Man letter maskinen for v&aelig;gt ved at frembringe
              lift fra de faste vinger. Omvendt n&aring;r man g&aring;r i
              inbound transistion.
            </p>
            <p>
              En vigtig ting er, at n&aring;r maskinen g&aring;r fra det ene
              opdriftprincip til det andet, m&aring; tyngdepunktet ikke flyttes.
              Da tyngdepunktet p&aring; multicopteren ligger fast, bliver man
              n&oslash;d til at konstruere fastvingeflyet udfra denne
              kendsgerning. Samtidig er det et ufravigeligt krav, at
              tyngdepunktet p&aring; et fastvinge fly skal ligge foran
              neutralpunktet, ellers bliver flyet aerodynamisk ustabilt. Dette
              f&oslash;rer til en carnard konstruktion, hvor man med forvingens
              st&oslash;rrelse kan bestemme hvor neutralpunktet kommer til at
              ligge og dermed bestemme stabilitetsgraden p&aring; fastvinge
              flyet. Fastvingeflyet styres af ballanceklapper p&aring; bagvingen
              og af en vipbar forvinge. Projektet er under fortsat udvikling.
            </p>
            <p>
              <img src={fig19} />
              <br />
              Fig. 15
            </p>
            <p>
              <img src={fig20} />
              <br />
              Fig. 16
            </p>
            <p>
              <img src={fig21} />
            </p>
            <p>
              Denne Amazon Prime Air drone skal kunne flyve sm&aring; postpakker
              direkte ud til kunderne og dermed underst&oslash;tte webbutikker
              uden at anvende store postbiler i distributionen. Leveringstiden
              skal v&aelig;re ganske kort 15 til 30 minutter.
              Fors&oslash;gsflyvninger har fundet sted i Australien og England
              p&aring; grund af manglende flyvetilladelser i USA. Denne
              dronetrafik finder sted i samme luftrum som vores modelflyvning og
              vil p&aring; sigt muligvis true modelflyvningen. Vi g&aring;r en
              sp&aelig;ndende tid i m&oslash;de.
            </p>
            <p>Link:</p>
            <p>
              <Link
                target="_blank"
                href="https://elflytec.dk/FlyByWire/Udviklingstendenser-i-RC-teknologierne"
              >
                Udviklingstendenser i RC teknologierne
              </Link>
            </p>
            <p>
              <Link
                target="_blank"
                href="https://elflytec.dk/FlyByWire/Fly-by-Wire-flyvning-med-Eagle-Tree-Vector"
              >
                Fly by Wire flyvning med Eagle Tree Vector
              </Link>
            </p>
            <p>
              <Link
                target="_blank"
                href="http://elflytec.dk/Grundviden/Bestemmelse-af-tyngdepunkt"
              >
                Bestemmelse af tyngdepunkt
              </Link>
            </p>
            <p>
              <Link
                target="_blank"
                href="http://www.rc-netbutik.dk/product.asp?product=3962"
              >
                http://www.rc-netbutik.dk/product.asp?product=3962
              </Link>
            </p>
            <p>
              <Link target="_blank" href="http://www.ecalc.ch/xcoptercalc.php">
                http://www.ecalc.ch/xcoptercalc.php
              </Link>
            </p>
            <p>
              <Link
                target="_blank"
                href="http://eagletreesystems.com/Manuals/vector.pdf"
              >
                http://eagletreesystems.com/Manuals/vector.pdf
              </Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
