import React, { Component } from "react";
import {
  withStyles,
  GridList,
  GridListTile,
  Typography,
  Dialog
} from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Helmet } from "react-helmet";

const styles = theme => ({
  root: {
    overflow: "hidden" as "hidden",
    width: "100%"
  },
  title: {
    textAlign: "center" as "center",
    padding: theme.spacing.unit * 3
  },
  clickable: {
    cursor: "pointer"
  }
});

interface IState {
  open: boolean;
  selectedImg: string;
}

interface IProps {
  classes: any;
  width: any;
  canonical: string;
}

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    "../../images/fotoalbum/Aspach2005/",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

class Aspach2005 extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedImg: ""
    };
  }

  getGridListCols() {
    if (isWidthUp("xl", this.props.width)) {
      return 6;
    }

    if (isWidthUp("lg", this.props.width)) {
      return 4;
    }

    if (isWidthUp("md", this.props.width)) {
      return 3;
    }

    if (isWidthUp("sm", this.props.width)) {
      return 2;
    }

    return 1;
  }

  handleClickOpen = url => event => {
    this.setState({
      open: true,
      selectedImg: url
    });
  };

  handleClose = value => {
    this.setState({ open: false });
  };

  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Elflytec.dk - Aspach 2005 billeder</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <div className={classes.root}>
          <Typography variant="h2" className={classes.title}>
            Aspach 2005 Billed album
          </Typography>
          <GridList
            cellHeight={280}
            className={classes.gridList}
            cols={this.getGridListCols()}
          >
            {images.map(tile => (
              <GridListTile
                key={tile}
                cols={1}
                className={classes.clickable}
                onClick={this.handleClickOpen(tile)}
              >
                <img src={tile} />
              </GridListTile>
            ))}
          </GridList>
          <Dialog open={this.state.open} onClose={this.handleClose}>
            <img src={this.state.selectedImg} />
          </Dialog>
        </div>
      </>
    );
  }
}

export default withWidth()(withStyles(styles)(Aspach2005));
