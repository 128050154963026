import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Grundviden/GrundlaeggendeElflyTeknik/fig1.jpg";
import fig2 from "../../../images/Grundviden/GrundlaeggendeElflyTeknik/fig2.jpg";
import fig3 from "../../../images/Grundviden/GrundlaeggendeElflyTeknik/fig3.jpg";
import fig4 from "../../../images/Grundviden/GrundlaeggendeElflyTeknik/fig4.jpg";
import fig5 from "../../../images/Grundviden/GrundlaeggendeElflyTeknik/fig5.jpg";
import fig6 from "../../../images/Grundviden/GrundlaeggendeElflyTeknik/fig6.jpg";
import fig7 from "../../../images/Grundviden/GrundlaeggendeElflyTeknik/fig7.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Grundlæggende elflyvteknik</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Grundl&aelig;ggende elflyvteknik</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret juli 2010.</p>
            <h2>Fordele ved elfly</h2>
            <p>
              De pr&aelig;stationer, som kan opn&aring;s med elfly, er s&aring;
              overbevisende at de stort set p&aring; alle omr&aring;der kan
              m&aring;le sig med modelfly, som anvender flydende
              br&aelig;ndstof. &Aring;rsagen er, dels anvendelsen af den
              b&oslash;rstel&oslash;se motor, dels udviklingen af batterierne
              is&aelig;r Litium Polymere. Li-Po teknikken er p&aring; kort tid
              g&aring;et fra et fors&oslash;gs stadie, hvor nogle f&aring;
              piloter heksede med at lodde p&aring; l&oslash;se celler og bygge
              balancere, til et fuldt udbygget kommercielt marked. Der har
              ogs&aring; fundet en kraftig udvikling sted af regulatorerne, som
              anvendes til styring af den b&oslash;rstel&oslash;se motor og til
              overv&aring;gning af batteriet.
            </p>
            <p>
              El-flyvning byder p&aring; en r&aelig;kke fordele s&aring; som:
            </p>
            <ul>
              <li>
                <p>
                  Lang mindre st&oslash;j, som betyder en mindre lukningsrisiko
                  for modelflyvepladsen.
                </p>
              </li>
              <li>
                <p>
                  N&aelig;sten ingen vibrationer overf&oslash;rt til modellen,
                  som betyder l&aelig;ngere levetid og st&oslash;rre sikkerhed.
                  Vibrationer som stammer fra stempelmotoren, overf&oslash;res
                  til flyet og elektronikken, er rigtig farlige og kan
                  medf&oslash;re brud som f&oslash;rer til nedstyrtninger.
                </p>
              </li>
              <li>
                <p>
                  Ingen svineri fra olie. Du kan stille det landede el-fly
                  bekvemt direkte ind i bilen. Du f&aring;r desuden en
                  l&aelig;ngere levetid p&aring; flyet, fordi der ikke siver
                  olie ind i konstruktionen.
                </p>
              </li>
              <li>
                <p>
                  Elmotoren er nem og sikker at starte. Det er en s&aelig;rlig
                  fordel i ducted-fan konstruktioner, hvor motoren sidder inde i
                  et r&oslash;r. El-motoren og fanen illuderer en jet-motor.
                </p>
              </li>
              <li>
                <p>
                  Muligg&oslash;r meget driftsikre to- eller fleremotorede
                  modelfly.
                </p>
              </li>
              <li>
                <p>
                  El-motoren kan startes og stoppes i luften. Det giver en
                  mulighed for, at man p&aring; en bekvem m&aring;de kan flyve
                  med store sv&aelig;vefly. N&aring;r motoren stoppes klapper
                  propellen sammen, for at mindske luftmodstanden. Metoden
                  erstatter h&oslash;jstarten, hvor man med et gummitov eller et
                  spil via en lang nylonline trak flyet op for at finde termik.
                </p>
              </li>
              <li>
                <p>
                  T&oslash;mningen af batteriet for str&oslash;m flytter ikke
                  flyets tyngdepunkt. Dette kan til geng&aelig;ld justeres v. h.
                  af batteriets placering.
                </p>
              </li>
            </ul>
            <p>
              Det skal dog bem&aelig;rkes at det koster noget mere at starte
              el-flyvning end flyvning med br&aelig;ndstof motorer, idet
              indk&oslash;bet udover el-motoren, ogs&aring; omfatter batterier,
              lader og regulator. Hvis man specielt er interesseret i store
              kunstflyvningsfly er en metanol- eller benzinmotor en billigere
              l&oslash;sning.&nbsp;
            </p>
            <h2>Grundl&aelig;ggende EL-viden</h2>
            <p>
              Det er n&oslash;dvendigt at EL-piloten har en grundl&aelig;ggende
              elektrisk viden, for at kunne h&aring;ndtere sit udstyr p&aring;
              en fornuftig og sikker m&aring;de. Et indlysende problem, er at
              den elektriske str&oslash;m er usynlig. Til geng&aelig;ld er det
              nemt at opleve virkningen af str&oslash;mmen ved f. eks. at se en
              motor dreje rundt. I mods&aelig;tning til aerodynamik er det
              ogs&aring; nemt at m&aring;le de forskellige st&oslash;rrelser,
              s&aring; som sp&aelig;nding, str&oslash;m og modstand. Vi starter
              med den elektriske sp&aelig;nding. Den kommer fra et batteri, den
              er en j&aelig;vnsp&aelig;nding og den er en foruds&aelig;tning
              for, at der overhovedet kan ske noget. Der kan alts&aring; kun
              opst&aring; en str&oslash;m, hvis der er en sp&aelig;nding.
              Sp&aelig;ndingen er &aring;rsagen, str&oslash;mmen er virkningen.
              Sp&aelig;ndingen m&aring;les i volt og m&aring;les med et
              voltmeter. Hvis vi grundl&aelig;ggende skal forst&aring; hvad der
              sker, m&aring; der kobles et forbrug til batteriet, s&aring;ledes
              at der kan dannes et sluttet kredsl&oslash;b. Fig.1 danner det
              simplest mulige kredsl&oslash;b.
              <br />
              Vi benytter os her af et elektrisk diagram. Til venstre har vi et
              batteri symbol med en plus- og en minus pol. Polariteten bestemmer
              str&oslash;mmens retning. Str&oslash;mmen flyder igennem en
              sluttet kontakt og videre igennem en modstand og tilbage til
              batteriet. Str&oslash;mmen er den samme over alt i
              kredsl&oslash;bet. Vi benytter os her af 3 forskellige symboler.
              Et diagram med symboler er n&oslash;dvendigt, fordi det hurtigt
              kan blive mere kompliceret at vise hvad der sker. (Se eksempel
              p&aring; kompliceret diagram side xx) Det viste diagram kunne
              v&aelig;re en lommelygte. Modstanden er s&aring; p&aelig;ren, som
              s&aring; ville lyse hvis kontakten er sluttet. Modstanden kunne
              ogs&aring; symbolisere en kulmotor eller b&oslash;rstel&oslash;s
              regulator.
            </p>
            <p>
              <img src={fig1} />
              <br />
              Fig. 1
            </p>
            <p>
              Vi vil nu se n&aelig;rmere p&aring; hvad der bestemmer
              str&oslash;mmens st&oslash;rrelse. Str&oslash;mmen er lig
              sp&aelig;ndingen divideret med modstanden. Dette virker ganske
              logisk, hvis man t&aelig;nker p&aring; at jo st&oslash;rre
              sp&aelig;ndingen er desto st&oslash;rre str&oslash;m kan der
              presses i gennem en given modstand. Det er ogs&aring; logisk at jo
              st&oslash;rre modstand man har desto mindre str&oslash;m flyder
              der i gennem modstanden ved en given sp&aelig;nding. For lettere
              at kunne h&aring;ndtere de forskellige begreber anvendes der
              f&oslash;lgende betegnelser:
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Begreb</TableCell>
                  <TableCell>Forkortes til</TableCell>
                  <TableCell>M&aring;les i</TableCell>
                  <TableCell>Som forkortes til</TableCell>
                  <TableCell>M&aring;les med et</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Sp&aelig;nding</TableCell>
                  <TableCell>U</TableCell>
                  <TableCell>Volt</TableCell>
                  <TableCell>V</TableCell>
                  <TableCell>Voltmeter</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Str&oslash;m</TableCell>
                  <TableCell>I</TableCell>
                  <TableCell>Ampere</TableCell>
                  <TableCell>A</TableCell>
                  <TableCell>Amperemeter</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Modstand</TableCell>
                  <TableCell>R</TableCell>
                  <TableCell>Ohm</TableCell>
                  <TableCell>Ohm</TableCell>
                  <TableCell>Ohmmeter</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Effekt</TableCell>
                  <TableCell>P</TableCell>
                  <TableCell>Watt</TableCell>
                  <TableCell>W</TableCell>
                  <TableCell>Beregnes</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Nu kan vi begynde at regne p&aring; det viste kredsl&oslash;b.
            </p>
            <p>
              <img src={fig2} />
            </p>
            <p>
              Hvis man ganger U med I f&aring;r man den effekt som
              afs&aelig;ttes i modstanden, alts&aring; er P=UxI. (skal
              v&aelig;re effektivv&aelig;rdier) Disse sammenh&aelig;nge
              ben&aelig;vnes ohms lov. Hvis man eksempelvis har en
              batterisp&aelig;nding p&aring; 10&nbsp;V og modstanden er
              1/3&nbsp;Ohm vil str&oslash;mmen blive 30&nbsp;A. Den afsatte
              effekt i modstanden vil v&aelig;re 300&nbsp;W. Regn selv efter.
              Dette kunne v&aelig;re typiske st&oslash;rrelser for et
              mellemstort el-fly med 3 Li-Po celler. Hvis sp&aelig;ndingen
              fordobles til 20&nbsp;V bliver str&oslash;mmen ogs&aring; dobbelt
              s&aring; stor, alts&aring; 60&nbsp;A. Den afsatte effekt vil
              s&aring; v&aelig;re 1200&nbsp;W! alts&aring; en firedobling. Der
              m&aring;ske godt at vide, n&aring;r du skal v&aelig;lge antallet
              af celler i din batteripakke.&nbsp;
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Da der forekommer kontakter flere forskellige steder i
              EL-flyverens udstyr vil vi ogs&aring; se p&aring; den kontakt som
              sidder i kredsl&oslash;bet. N&aring;r kontakten er sluttet dannes
              der et sluttet kredsl&oslash;b og der kan flyde str&oslash;m. Vi
              bruger nu ohms lov p&aring; kontakten. N&aring;r kontakten er
              sluttet er der nul ohm i kontakten. Sp&aelig;ndingen over
              kontakten bliver 30&nbsp;Ax0&nbsp;ohm = 0&nbsp;V Effekten afsat i
              den sluttede kontakt bliver 30&nbsp;Ax0&nbsp;V= 0&nbsp;W. Hvis
              kontakten er afbrudt flyder der ingen str&oslash;m. En afbrudt
              kontakt har en uendelig stor modstand. Str&oslash;mmen igennem
              kontakten bliver: I=10&nbsp;V/ooOhm=0&nbsp;A. Effekten afsat i den
              afbrudte kontakt bliver 0&nbsp;Ax10&nbsp;V= 0&nbsp;W. Det er
              alts&aring; ligegyldigt om kontakten er sluttet (on) eller afbrudt
              (off), s&aring; bliver der ikke afsat effekt i den, noget som har
              stor betydning i f. eks. en fartregulator eller en switch mode
              BEC. (se senere) Vi vil nu unders&oslash;ge hvordan man kan
              m&aring;le de forskellige st&oslash;rrelser. Det nemmeste er at
              m&aring;le sp&aelig;ndingen, som m&aring;les med et voltmeter.
              Dette tilsluttes tv&aelig;rs over den komponent man &oslash;nsker
              at m&aring;le sp&aelig;ndingen p&aring;. Se fig. 2.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig. 2
            </p>
            <p>
              Hvis str&oslash;mmen skal m&aring;les, skal
              str&oslash;mm&aring;leren, amperemeteret, kobles ind i selve
              ledningen. Dette giver dels en m&aring;lefejl, dels skal
              str&oslash;mm&aring;leren kunne m&aring;le store str&oslash;mme og
              endeligt er det besv&aelig;rligt. EL-piloten kan derfor med stor
              fordel anskaffe et tangamperemeter, som m&aring;ler
              str&oslash;mmen p&aring; basis af det magnetfelt, der opst&aring;r
              udenom ledningen. Se fig.3.
            </p>
            <p>
              <img src={fig5} />
              <br />
              Fig. 3 Nem m&aring;ling af motorstr&oslash;m v.h.af tang
              amperemeter.
            </p>
            <p>
              <br />
              Voltmeteret, amperemeteret og ohmmeteret er forenet i et digitalt
              multimeter, som kan k&oslash;bes til en pris af under 100kr. Se
              fig. 4. Dette m&aring;leinstrument er n&oslash;dvendigt for
              EL-piloten. Det kan m&aring;le sp&aelig;nding, str&oslash;m (max
              10&nbsp;A) og modstand herunder kortslutninger (0&nbsp;ohm) og
              afbrydelser (uendelig mange ohm). Husk at hvis du m&aring;ler
              modstand p&aring; et kredsl&oslash;b m&aring; der ikke v&aelig;re
              sp&aelig;nding p&aring; det.&nbsp;
            </p>
            <p>
              <img src={fig6} />
              <br />
              Fig. 4&nbsp;Det digitale multimeter som er billigt, men alligevel
              n&oslash;jagtigt.
            </p>
            <p>
              Den afsatte effekt kan ikke m&aring;les direkte, men findes ved at
              man ganger sp&aelig;ndingen med str&oslash;mmen, under
              foruds&aelig;tning af at det er j&aelig;vnsp&aelig;nding og
              j&aelig;vnstr&oslash;m.&nbsp;
              <strong>
                Hvis man ganger effekten med tiden f&aring;s Watt-timer.
              </strong>
              &nbsp;Watt-timer er et udtryk for det arbejde du kan f&aring;
              udf&oslash;rt og det er da ogs&aring; det begreb du kender fra din
              el-regning.&nbsp;
            </p>
            <h2>El-fly systemets teknologi</h2>
            <p>
              El-flyvning er startet for mange &aring;r siden, men det er
              f&oslash;rst siden fremkomsten af Litium-Polymer batteriet,
              Microprocessoren, Field Effekt Transistoren og den
              b&oslash;rstel&oslash;se motor at el-flyvningen har foretaget et
              kvantespring. Litium-Polymer batteriet kan udf&oslash;re ca
              3,5gange mere arbejde end det gamle og giftige Nikkel Cadium
              batteri for den samme v&aelig;gt. Microprocessoren er den lille
              computer chip som giver uanede styrings- og kontrolmuligheder for
              regulatoren som er motorens &rdquo;gaspedal&rdquo;. Field Effekt
              Transistoren er en n&aelig;sten ideel elektronisk kontakt, som kan
              &aring;bne og lukke for meget store str&oslash;mme. Og endelig den
              b&oslash;rstel&oslash;se motor, som ikke indeholder kul, som
              bliver slidt og som er en konstruktion som n&aelig;rmest er
              genial. Disse fire teknologi komponenter er ogs&aring; vejen frem
              for fremtidens transportmidler, s&aring;som el-cykler, el-scootere
              og el-biler. En b&oslash;rstel&oslash;s motor direkte i hvert
              hjul, som ogs&aring; kan anvendes som bremse. Ingen gearkasser,
              ingen mekanik og ekstrem lav vedligeholdelse, lyder det ikke godt.
            </p>
            <h2>Virkningsgrad</h2>
            <p>
              En vigtig ting er systemets virkningsgrad. Det er afg&oslash;rende
              at vi f&aring;r udf&oslash;rt s&aring; meget arbejde som muligt og
              at vi dermed holder varmetabet nede. For at forst&aring;
              virkningsgraden kan vi forestille os et system hvor vi
              tilf&oslash;rer elektrisk effekt og hvor vi foretager en
              &aelig;ndring fra en energiform til en anden. Se fig. 5. Tag
              f.eks. en el-p&aelig;re hvor der tilf&oslash;res elektrisk effekt
              og der &oslash;nskes lys, men som en u&oslash;nsket bivirkning
              f&aring;s ogs&aring; varmetab. Den kan aldrig blive over
              100&nbsp;%. P&aring; din gamle el-p&aelig;re med
              gl&oslash;detr&aring;d er virkningsgraden kun s&oslash;lle
              6&nbsp;% resten bliver til varme, godt om vinteren, kedeligt om
              sommeren.&nbsp;
            </p>
            <p>
              <img src={fig7} />
              <br />
              Fig. 5
            </p>
            <p>
              <strong>
                Virkningsgraden defineres som nytteeffekten divideret den
                tilf&oslash;rte effekt gange 100 og kommer ud i procent.&nbsp;
              </strong>
              Virkningsgraden kan aldrig blive over 100&nbsp;%. Overalt er det
              vigtigt at virkningsgraden er h&oslash;j og varmetabet er lavt. I
              din hverdag kender du begreber som: sparep&aelig;ren, det AAA
              m&aelig;rkede k&oslash;leskab, bilen som k&oslash;rer langt
              p&aring; literen, det kondenserende gasfyr o.s.v. Hvis el-flyet
              skal have gode egenskaber i form af stigevne, hastighed og
              flyvetid, spiller virkningsgraden en afg&oslash;rende rolle,
              samtidig med at den udviklede varme holdes nede.
            </p>
            <p>
              Nedenst&aring;ende tabel viser de forskellige
              energioms&aelig;ttere som elflyet best&aring;r af , med et
              tilh&oslash;rende bud p&aring; virkningsgrader og tab. Begreberne
              vil blive belyst n&aelig;rmere i de efterf&oslash;lgende afsnit.
              N&aring;r man beregner hele systemet m&aring; man t&aelig;nke
              p&aring; output fra den foreg&aring;ende energioms&aelig;tter
              overf&oslash;res som input til den efterf&oslash;lgende, s&aring;
              det ender desv&aelig;rre med at kun omkring 1/3 til 1/4del af den
              til r&aring;dighed v&aelig;rende effekt bliver til nytte, resten
              er tab som altid bliver til varme.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Energioms&aelig;tter</TableCell>
                  <TableCell>Typiske ca. virkningsgrader</TableCell>
                  <TableCell>Typiske ca. tab</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Li-Po batteriet</TableCell>
                  <TableCell>88&nbsp;%</TableCell>
                  <TableCell>12&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Regulatoren</TableCell>
                  <TableCell>95&nbsp;%</TableCell>
                  <TableCell>5&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>B&oslash;rstel&oslash;smotor</TableCell>
                  <TableCell>75&nbsp;%</TableCell>
                  <TableCell>25&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Eventuel gear kasse</TableCell>
                  <TableCell>93&nbsp;%</TableCell>
                  <TableCell>7&nbsp;%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Propel eller impeller</TableCell>
                  <TableCell>50&nbsp;%</TableCell>
                  <TableCell>50&nbsp;%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Den energi som sluttelig tilf&oslash;res flyet vil nu kun
              v&aelig;re: 0,88x0,95x0,75x0,93x0,50 = 0,29= 29&nbsp;%
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
