import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Andet/DenMenneskeligeFaktor/fig1.jpg";
import fig2 from "../../../images/Andet/DenMenneskeligeFaktor/fig2.jpg";
import fig3 from "../../../images/Andet/DenMenneskeligeFaktor/fig3.jpg";
import fig4 from "../../../images/Andet/DenMenneskeligeFaktor/fig4.jpg";
import fig5 from "../../../images/Andet/DenMenneskeligeFaktor/fig5.jpg";
import fig6 from "../../../images/Andet/DenMenneskeligeFaktor/fig6.jpg";
import fig7 from "../../../images/Andet/DenMenneskeligeFaktor/fig7.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>RC piloten og den menneskelige faktor</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>RC piloten og den menneskelige faktor</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret juli 2012</p>
            <p>
              Forfatteren skriver normalt artikler om teknik, men retter denne
              gang fokus mod RC-piloten. Dette emne f&aring;r underlig nok ikke
              megen opm&aelig;rksomhed. Det skal denne artikel pr&oslash;ve at
              r&aring;de bod p&aring;. En tak skal rettes til John Borgen samt
              tidligere SAS kaptajn og human factor specialist Torben Back
              S&oslash;rensen for inspiration og hj&aelig;lp med stoffet. Denne
              artikel og andre med mere teknisk indhold, kan ogs&aring; findes
              her p&aring; forfatterens webserver.
            </p>
            <h2>Feedback</h2>
            <p>
              Fjernstyring af modelfly er som bekendt baseret p&aring;, at
              RC-piloten st&aring;r nede p&aring; jorden og styrer et modelfly,
              som flyver rundt i luften. For at dette kan lade sig g&oslash;re
              kr&aelig;ves det, at piloten f&aring;r feedback fra modellen. Det
              vigtigste er, at piloten har visuel kontakt med flyet, men
              ogs&aring; motorlyd eller telemetrisystemer med tale kan
              indg&aring; i styringen. RC-Piloten m&aring; s&aring; reagere ud
              fra disse indtryk.
            </p>
            <h2>RC-pilotens&nbsp;informations proces</h2>
            <p>
              Som udgangspunkt vil jeg pr&oslash;ve at opstille en model af
              RC-pilotens informationsproces,
            </p>
            <p>
              alts&aring; det der sker inde i hovedet af piloten. Se fig. 1.
            </p>
            <p>
              <img src={fig1} />
            </p>
            <p>
              P&aring; input siden har vi f&oslash;rst og fremmest
              &oslash;jnene. Det er karakteristisk, at &oslash;jet fokuserer mod
              et bestemt punkt, hvor skarpheden og farvef&oslash;lsomheden er
              st&oslash;rst. Omkring fokuspunktet befinder sig det perifere syn,
              hvor skarpheden er langt mindre. &Oslash;jets sensorceller kan
              dels opdeles i stave, som opfatter lys og m&oslash;rke, dels tappe
              som opfatter farver. Det er karakteristisk, at farver kun
              opfattes, hvis fladerne er tilstr&aelig;kkelige store, det vil
              sige, at alle sm&aring; detaljer opfattes som sort eller hvide.
              Der er derfor n&aelig;sten ikke farver p&aring; en model, som er
              langt v&aelig;k.
            </p>
            <p>
              Bag &oslash;jet ligger en sensorhukommelse, som n&aelig;rmest kan
              opfattes som en fremskudt del af hjernens hukommelse. Denne
              hukommelse leverer input til&nbsp;
              <strong>
                opm&aelig;rksomheden, som er karakteristisk ved, at den stort
                set kun kan fokusere p&aring; en ting ad gangen, alts&aring; lav
                simultan kapacitet.
              </strong>
              &nbsp;For at alle de meningsl&oslash;se elektriske signaler, som
              kommer ind fra nethinden, hele tiden kan blive tolket til noget
              forst&aring;eligt, sendes de via perceptionsboksen videre til den
              centrale beslutningstager. Perceptionen er en fortolker, som
              danner en for dig i hjernen begribelig model af den virkelige
              verden som omgiver dig, og som blev opbygget fra du var
              sp&aelig;d. Den centrale beslutningstager m&aring; p&aring;
              grundlag af de mange input v&aelig;lge at tr&aelig;ffe en handling
              blandt ofte flere mulige. Som du kan se underst&oslash;ttes den
              centrale beslutningstager af dels af langtidshukommelsen, hvor al
              din erfaring findes, dels af korttidshukommelsen, som kun kan
              huske ganske f&aring; informationer i kort tid. Output fra din
              centrale beslutningstager er en handling til din sender via
              musklerne i din h&aring;nd. En hurtigere genvej til handling
              g&aring;r via kassen med ubeviste kompetente rutiner, ting som
              ligger p&aring; din rygrad, hvis du er en erfaren pilot.
            </p>
            <h2>Responstid</h2>
            <p>
              Hvis man ser p&aring; de reaktionstider, der er i det samlede
              system n&aring;r du flyver, kan det opdeles som f&oslash;lger:
            </p>
            <ul>
              <li>
                Tekniske forsinkelser i elektronikken s&aring; som servoers
                reaktionstid (typisk 0,1sek for 60graders bev&aelig;gelse)
                senderens og modtagerens prosessortid (normalt uden betydning)
              </li>
              <li>
                Modellens tr&aelig;ghed til at reagere p&aring; &aelig;ndringer
                afh&aelig;ngig af v&aelig;gt, energi, rorflader, motorkraft m.m.
              </li>
              <li>
                Pilotens responstid som vil v&aelig;re meget forskellig af
                erfaring og tr&aelig;ning.&nbsp;
                <strong>
                  Det skal is&aelig;r p&aring;peges, at reaktionstiden bliver
                  lang hvis der sker noget som er uforudset.
                </strong>
              </li>
            </ul>
            <h2>Arbejdsbelastningen og panik</h2>
            <p>
              Lige som en computer kan din informationsproces oppe i din hjerne
              ogs&aring; blive overbelastet. Hvis du f.eks. flyver med en
              selvstabil sv&aelig;veflyver i stor h&oslash;jde i roligt vejr,
              kan du l&aelig;gge senderen fra dig i gr&aelig;sset. Den mentale
              ydeevne vil da v&aelig;re lav. Se fig. 2. Den optimale ydeevne og
              l&aelig;ring opn&aring;s, hvis du foretager lidt kompliceret
              flyvning, som du dog har fuld kontrol over. Der kan imidlertid
              hurtigt opst&aring; situationer som kr&aelig;ver din fulde
              procesydeevne. Her er det s&aring;, at du is&aelig;r som begynder
              kommer i en overflow situation, hvor du slet ikke kan reagere
              rigtigt l&aelig;ngere.&nbsp;
              <strong>
                Hvis du oplever panik, vil du f&aring; en indsn&aelig;vret
                perception, man kan sige at du bliver handlingslammet.
              </strong>
              &nbsp;Se den r&oslash;de kurve. Dette g&aelig;lder ikke den pilot,
              som landede Airbus 320 maskinen med to stoppede motorer p&aring;
              Hudson floden i New York og hvor alle overlevede. Han trak
              p&aring; al sin viden, havde ekstrem h&oslash;j procesbelastning
              og traf alle de rigtige handlinger i den rigtige
              r&aelig;kkef&oslash;lge. Det er den form for adf&aelig;rd,
              modelflyveren b&oslash;r efterstr&aelig;be.&nbsp;
              <strong>
                I overflow situationen er det s&aelig;rlig vigtigt at reagere
                p&aring; det, som er v&aelig;sentligt f&oslash;rst
              </strong>
              , alts&aring; ikke at flyve modellen v&aelig;k eller ramme jorden
              med fuld kraft.
            </p>
            <p>
              <img src={fig2} />
            </p>
            <h2>Kan du stole p&aring; det du ser?</h2>
            <p>
              Hvilket liniestykke er l&aelig;ngst A eller B?<strong> </strong>Se
              fig. 3.
            </p>
            <p>
              <img src={fig3} />
              <br />
              Fig. 3
            </p>
            <p>
              Liniestykke A er tilsyneladende tydeligvis kortere end liniestykke
              B. S&aring;dan tolker din hjerne tegningen. I den virkelige
              fysiske verden er stykkerne m&aring;ske lige lange. Pr&oslash;v at
              m&aring;le efter! Der er alts&aring; ikke overensstemmelse mellem
              det du ser og fakta. Din opfattelsesevne snyder dig.
            </p>
            <p>
              N&aring;r du flyver med en RC-model, er du ekstrem afh&aelig;ngig,
              af hvad du ser. Flyver modellen imod dig eller v&aelig;k fra dig?
              Det har vi vist alle pr&oslash;vet.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig. 4 Hvad vej flyver flyveren?
            </p>
            <p>
              Du kan m&aring;ske selv f&aring; din hjerne til at v&aelig;lge, om
              modellen flyver imod dig eller v&aelig;k fra dig. Se Fig.4.
              Bem&aelig;rk at farver forsvinder op mod solen og i m&oslash;rkt
              vejr. Problemet l&oslash;ses ved, at du i din korttidshukommelse
              godt ved, hvad du har bedt modellen at g&oslash;re. Hvis du
              alligevel ved sidel&aelig;ns flyvning langt v&aelig;k bliver
              snydt, skal du undlade at anvende det input, du f&aring;r fra
              &oslash;jnene, indtil du med sikkerhed kan se hvad vej flyet
              flyver. Med hensyn til afstanden ud til modellen viser GPS
              telemetrim&aring;linger at du ofte er l&aelig;ngere v&aelig;k og
              h&oslash;jere oppe end du tror.
            </p>
            <p>
              Specielt ved landinger, hvor man &oslash;nsker at ramme starten af
              en landingsbane, kr&aelig;ver det en meget pr&aelig;cist afstands
              bed&oslash;mmelse. Det kan v&aelig;re en hj&aelig;lp at st&aring;
              samme sted i forhold til&nbsp;touch&nbsp;down punktet og i
              &oslash;vrigt foretage s&aring; f&aring; korrektioner som muligt.{" "}
            </p>
            <h2>Hvad kan ovenst&aring;ende viden bruges til?</h2>
            <p>
              F&oslash;rst og fremmest kan man foretage analyser af forskellige
              h&aelig;ndelser og ulykker, som RC-pilotens modeller kommer ud
              for. Man kunne dele h&aelig;ndelserne op i forskellige
              &aring;rsager, s&aring; som:
            </p>
            <ul>
              <li>Fejl i syn eller perception.</li>
              <li>Fejl i opm&aelig;rksomhed.</li>
              <li>Fejl i hukommelserne</li>
              <li>
                Manglende eller forkerte beslutninger i den centrale
                beslutningstager.
              </li>
              <li>For h&oslash;j arbejdsbelastning, for mange input.</li>
            </ul>
            <p>
              Ofte vil en kombination af ovenst&aring;ende medf&oslash;re skaden
              p&aring; eller tabet af modellen.
            </p>
            <h2>K&aelig;de af h&aelig;ndelser</h2>
            <p>
              <strong>
                Ulykker opst&aring;r n&aelig;sten altid som f&oslash;lge af en
                k&aelig;de af flere forskellige uheldige h&aelig;ndelser, som
                sker samtidig
              </strong>
              . Som udgangspunkt for sikkerhedsteori opstilles derfor en
              r&aelig;kke af sikkerhedslag som skal forhindre ulykker. Se
              tegningen. I den &oslash;verste tegning er man brudt igennem 4
              lag. Nu mangler blot det sidste svigt, og ulykken er en
              kendsgerning. Normalt skulle alle sikkerhedslag sp&aelig;rre af
              for ulykken, men som det ses p&aring; den nederste tegning, kan et
              lag st&aring; &aring;bent, uden at det f&aring;r konsekvenser. Man
              b&oslash;r blot v&aelig;re klar over, at det st&aring;r
              &aring;bent!
            </p>
            <p>
              <img src={fig5} />
            </p>
            <p>
              Lad os se p&aring; et eksempel hvor 4 sikkerhedslag brister for
              RC-piloten.
            </p>
            <ol>
              <li>
                Piloten er ikke s&aelig;rlig rutineret&nbsp;<strong>og</strong>
              </li>
              <li>
                Piloten flyver i kraftig medvind&nbsp;<strong>og</strong>
              </li>
              <li>
                Som f&oslash;lge af medvinden kommer piloten overraskende
                hurtigt meget langt v&aelig;k&nbsp;<strong>og</strong>&nbsp;
              </li>
              <li>
                Piloten holder ikke sin flyvefart oppe i forhold til medvinden.
                (For lav true airspeed.)
              </li>
            </ol>
            <p>
              Flyet staller og skifter derfor flyveretning, samtidig tabes
              h&oslash;jde. Ophobningen af ovenst&aring;ende h&aelig;ndelser kan
              hurtig f&oslash;re til bortflyvning eller nedstyrtning
              <strong>
                . Den dygtige pilot har en stor situationsopm&aelig;rksomhed
              </strong>
              &nbsp;og kan p&aring; forh&aring;nd fornemme, at der er ved at
              opst&aring; en tilspidset situation, p&aring; samme m&aring;de som
              den kloge bilist kan &rdquo;l&aelig;se&rdquo; trafikken omkring
              sin bil. Det g&aelig;lder alts&aring; om at erkende de enkelte
              faktorer, som kan f&oslash;re frem til ulykker. Denne tankegang
              g&oslash;r sig i h&oslash;j grad g&aelig;ldende ved
              flykonstruktion, idet det ikke kan accepteres, at svigtet af en
              enkelt del i flyet kan f&oslash;re til nedstyrtning.
            </p>
            <p>
              S&aelig;rlig s&aring;rbar er forsyningssp&aelig;ndingen til
              RC-modtageren, is&aelig;r hvis man anvender BEC, hvorfor man i
              store og dyre fly anvender en str&oslash;mforsyning baseret
              p&aring; to modtager batterier med automatisk omkobling, hvis et
              af batterierne svigter. Flere parallelforbundne stik giver
              ogs&aring; for&oslash;get sikkerhed p&aring; samme m&aring;de,
              anvendes princippet om at anvende flere antenner i 2,4GHz
              modtagerne.
            </p>
            <h2>Check-lister</h2>
            <p>
              <strong>
                En god hj&aelig;lp kan v&aelig;re at anvende check-lister
              </strong>
              , til b&aring;de at pakke bilen, samle flyet samt v&aelig;re
              sikker p&aring; at al set-up er i orden f&oslash;r starten.
              P&aring; min Fanliner kontrolleres f&oslash;r starten blandt andet
              at GPS&rsquo;en starter fra det rigtige sted p&aring; jordkloden,
              at en switch ikke st&aring;r i landingsmode, og at flight
              datarecorderen p&aring;begynder optagelse i Jeti Profi boxen.
            </p>
            <p>
              <img src={fig6} />
            </p>
            <p>
              John Borgens skala DC9 er her samlet. John anvender en tjekliste
              til p&aring; 26 punkter at samle modellen og en p&aring; 18
              punkter til briefing af piloten Eric Huber f&oslash;r flyvningen.
              Dette er absolut n&oslash;dvendigt p&aring; grund af modellens
              kompleksitet. Modellen anvender Futaba 2,4GHz anl&aelig;g og Jeti
              telemetri, som giver tilbagemelding&nbsp;til en co-pilot af blandt
              andet flyvefart og forbrugt kapacitet p&aring; motorbatterierne.
              Anvendelsen af en kompetent&nbsp;hj&aelig;lper er en god ide til
              at mindske pilotens arbejdsbelastning. Alle data optages i en
              flightrecorder til analyse&nbsp;efter flyvningen. Modellen er
              udstyret med 23 servoer, som blandt andet anvendes til styring af
              flaps, spoilers,&nbsp;slats, hjulbremser og thrust reverse.
            </p>
            <h2>Software</h2>
            <p>
              Moderne digital fjernstyringsteknik medf&oslash;rer en omfattende
              programmering af software.&nbsp;
              <strong>
                Det kan anbefales, at du foretager programmeringen i fred og ro
                p&aring; v&aelig;rkstedet med brug af manualer
              </strong>
              , og ikke ude p&aring; flyvepladsen, hvor du kan lave en fejl,
              fordi det skal g&aring; st&aelig;rkt. Du kan hurtigt f&aring; et
              ror, der virker modsat, eller starte med en forkert model i
              senderen. Specielt programmering af ladere til Li-Po batterier
              b&oslash;r foretages med stor opm&aelig;rksomhed, fordi du kan
              f&aring; alvorlige problemer med brandfare.
            </p>
            <p>
              <img src={fig7} />
            </p>
            <p>
              P&aring; min Fanliner har jeg optalt 105 mulige software
              menupunkter fordelt p&aring; speed regulatorerne
              (2x9stk),&nbsp;Jeti modtageren (34stk), m&aring;lemodul til Li-Po
              cellerne(16stk) samt GPS modtageren (37stk),&nbsp;og s&aring; er
              der ikke medtaget programmeringen i senderen og Jeti Profi Boxen.
            </p>
            <h2>Eksempler p&aring; h&aelig;ndelser fra RC-modelflyvning</h2>
            <p>
              Efterf&oslash;lgende vil jeg her pr&oslash;ve at give nogle
              f&aring; eksempler p&aring; og analyser af h&aelig;ndelser, som
              jeg eller andre har v&aelig;ret ude for.
            </p>
            <p>
              <em>Kortslutning af Li-Po batteri.&nbsp;</em>Et 3000mA/h 3S Li-Po
              batteri med 4mm guldstik skal tilsluttes en regulator i en
              elmodel. Batteriet har et han- og et hunstik, s&aring;ledes at en
              fejlpolarisering af regulatoren kan undg&aring;s. Hanstikket
              p&aring; batteriet kan imidlertid s&aelig;ttes i hunstikket,
              hvorved batteriet bliver kortsluttet. Der kom lidt r&oslash;g ud
              af batteriet, derefter skete der heldigvis ikke mere.
              Efterf&oslash;lgende unders&oslash;gelse viser, at
              terminalledningen mellem celle 2 og 3 har virket som en sikring
              ved at den er br&aelig;ndt over. &Aring;rsagen til at fejlen
              finder sted er, at piloten har en l&aelig;ngere samtale med sine
              kammerater om alt muligt, mens flyet klarg&oslash;res. Man kan
              ogs&aring; sige, at det sker, fordi det kan lade sig g&oslash;re.
              Efterf&oslash;lgende skiftede piloten alle de l&oslash;se guldstik
              ud med Dean stik, som ikke kan polariseres forkert, s&aring;ledes
              at fejlen ikke kan gentages.
            </p>
            <p>
              <em>Manglende balanceklapper.&nbsp;</em>Umiddelbart efter starten
              styrter et Extra 300 kunstflyvningsfly ned. &Aring;rsagen var ikke
              sv&aelig;r at finde, idet det viste sig, at ballanceklapperne ved
              samling af modellen aldrig var blevet tilsluttet modtageren.
              Piloten mente, at han havde testet rorene ved starten. Om
              &aring;rsagen til den manglende tilslutning, siger piloten, at han
              blev afbrudt under samling af modellen for at svare p&aring; et
              sp&oslash;rgsm&aring;l fra en tilskuer. Her er der tale om en
              dobbeltfejl, testen f&oslash;r start var ikke effektiv.
            </p>
            <p>
              <em>Slukket modtager.&nbsp;</em>Test af rorbev&aelig;gelser kunne
              ogs&aring; have forhindret bortflyvning af et stort sv&aelig;vefly
              ved start fra et gummitov, fordi modtageren aldrig blev
              t&aelig;ndt. Modellen blev fundet 2 dage senere langt v&aelig;k
              fra startstedet.
            </p>
            <p>
              <em>Svigtende tr&aelig;kkraft&nbsp;</em>Kort efter en sl&oslash;v
              start med en el-model bliver piloten n&oslash;dt til at udelande i
              h&oslash;jt gr&aelig;s. &Aring;rsagen er, at piloten forveksler et
              opladt motor batteri med et afladt. Man b&oslash;r l&aelig;re af
              sine fejl, s&aring; efterf&oslash;lgende forsyner han altid fuldt
              opladte batterier med et gummib&aring;nd, som fjernes umiddelbart
              f&oslash;r start, til underst&oslash;ttelse af sin svage
              hukommelse. Det er m&aring;ske en god ide at have en lille
              batterim&aring;ler som f.eks. Hyperion EOS Sentry med ud p&aring;
              flyvepladsen. M&aring;leren tilsluttes balancer stikket og Li-Po
              batteriets ladetilstand kommer ud i procent.
            </p>
            <p>
              Disse 4 h&aelig;ndelser kalder alle p&aring; manglende
              opm&aelig;rksomhed. I rigtig flyvning taler man om at cockpittet
              er sterilt under starter og landinger, d.v.s.&nbsp;
              <strong>
                piloterne kun taler om det, som har med flyvningen at
                g&oslash;re og ikke alt mulig andet
              </strong>
              . M&aring;ske kan du l&aelig;re noget?
            </p>
            <h2>Teknisk- eller menneskelige fejl?</h2>
            <p>
              Under termik flyvning i stor afstand med et sv&aelig;veplan
              forsynet med klappropel, konstaterer piloten, at flyet ikke stiger
              ved start af elmotoren. Flyet kan stadig styres og viser p&aring;
              telemetri displayet kun at have bruget 1/3 af motorbatteriets
              kapacitet. Den forundrede pilot bliver nu nerv&oslash;s for
              manglende forsyningssp&aelig;nding til radioanl&aelig;gget, fordi
              der anvendes BEC. Han v&aelig;lger nu at hjemtage flyet for hurtig
              landing. Efterf&oslash;lgende konstateres at klappropellen er
              tabt, noget man ikke umiddelbart forestiller sig. Denne
              h&aelig;ndelse m&aring; betegnes som er teknisk fejl, selv om
              man&nbsp;
              <strong>
                teoretisk kan p&aring;st&aring;, at alle fejl er menneskelige
              </strong>
              . Fejlen kunne have v&aelig;ret undg&aring;et, hvis propellen
              havde v&aelig;ret eftersp&aelig;ndt, men hvem t&aelig;nker
              p&aring; det, n&aring;r den har siddet fast i 5&aring;r?
            </p>
            <h2>Afslutning</h2>
            <p>
              <strong>
                Foretag altid en analyse af dine fejl og v&aelig;r &aring;ben om
                dem
              </strong>
              . Det er flot at modelflyveren, som br&aelig;ndte sin bil af p.
              gr. af en fejlprogrammeret lader, fort&aelig;ller om det i
              Modelflyvenyt. (
              <Link
                target="_blank"
                href="http://modelflyvenyt.dk/modelflyvenyt/2011/modelflyvenyt%203-2011.pdf"
              >
                Nr.3 2011
              </Link>
              ) Du kan ogs&aring; ganske gratis fors&oslash;ge at analysere
              andres fejl. Pr&oslash;v ogs&aring; at t&aelig;nke p&aring; dine
              egne fejl, du har sikkert lavet nogle.&nbsp;
              <strong>L&aelig;r af fejlene og gentag dem ikke!</strong>
            </p>
            <p>
              Den menneskelige faktor g&oslash;r sig naturligvis ogs&aring;
              g&aelig;ldende, med alvorlige konsekvenser, indenfor andre
              omr&aring;der end flyvning, s&aring; som trafik, kraftv&aelig;rker
              og hospitaler. Da de fleste modelflyvere ogs&aring; er bilister,
              skal jeg oplyse at vejdirektoratet har opgjort at 95 % af alle
              ulykker med d&oslash;delig udgang, skyldes menneskelige fejl,
              noget du m&aring;ske skulle t&aelig;nke over, hvis du k&oslash;rer
              bil.
            </p>
            <p>Happy Landing.</p>
            <p>J&oslash;rgen Bj&oslash;rn</p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
