import React, { Component } from "react";
import {
  Grid,
  withStyles,
  Drawer,
  AppBar,
  Toolbar,
  Fab,
  Hidden
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import MobileMenu from "./MenuMobile";
import logo from "../logo.png";
import DesktopMenu from "./MenuDesktop";
import { Link } from "react-router-dom";

const styles = theme => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed" as "fixed",
    zIndex: 1400
  },
  logo: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: "100%",
    maxWidth: "400px"
  },
  logoImg: {
    width: "100%",
    maxWidth: "400px"
  },
  drawer: {
    bottom: "90px",
    maxHeight: "calc(100% - 80px)"
  },
  "@media print": {
    fab: {
      display: "none"
    }
  }
});

interface IState {
  menuDrawerOpen: boolean;
}

interface IProps {
  classes: any;
}

class Header extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      menuDrawerOpen: false
    };
  }

  toggleDrawer = (open: boolean, url?: string) => {
    if (url && url === "#") {
      // Ignore
    } else {
      this.setState({
        menuDrawerOpen: open
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <div className={classes.logo}>
                  <Link to="/">
                    <img src={logo} alt="Logo" className={classes.logoImg} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={12}>
                <Hidden mdDown>
                  <DesktopMenu />
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Hidden lgUp>
          <Drawer
            anchor="bottom"
            open={this.state.menuDrawerOpen}
            onClose={() => {
              this.toggleDrawer(false);
            }}
            classes={{ paper: classes.drawer }}
          >
            <MobileMenu closeMenu={this.toggleDrawer} />
          </Drawer>
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.fab}
            onClick={() => {
              this.toggleDrawer(!this.state.menuDrawerOpen);
            }}
          >
            {this.state.menuDrawerOpen ? <CloseIcon /> : <MenuIcon />}
          </Fab>
        </Hidden>
      </>
    );
  }
}

export default withStyles(styles)(Header);
