import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/FlyByWire/FlyByWire/fig1.jpg";
import fig2 from "../../../images/FlyByWire/FlyByWire/fig2.jpg";
import fig3 from "../../../images/FlyByWire/FlyByWire/fig3.jpg";
import fig4 from "../../../images/FlyByWire/FlyByWire/fig4.jpg";
import fig5 from "../../../images/FlyByWire/FlyByWire/fig5.jpg";
import fig6 from "../../../images/FlyByWire/FlyByWire/fig6.jpg";
import fig7 from "../../../images/FlyByWire/FlyByWire/fig7.jpg";
import fig8 from "../../../images/FlyByWire/FlyByWire/fig8.jpg";
import fig9 from "../../../images/FlyByWire/FlyByWire/fig9.jpg";
import fig10 from "../../../images/FlyByWire/FlyByWire/fig10.jpg";

interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Fly by Wire til modelfly</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Fly by Wire til modelfly</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret juli 2013.</p>
            <h2>Fly by Wire begrebet</h2>
            <p>
              FBW teknikken blev for alvor kendt, da flyproducenten Airbus i
              1984 introducerede deres A320 fly, hvor man radikalt &aelig;ndrede
              m&aring;den at styre fly p&aring;. Mellem piloten og flyet blev
              der indskudt et computer system, hvor igennem alle pilotens
              beslutninger skulle passere. Hensigten var at beskytte flyet mod
              en r&aelig;kke tilstande s&aring; som: For lav fart, for
              h&oslash;j fart, g-p&aring;virkninger, for skarpe drej, for stor
              indfaldsvinkel m.m.
            </p>
            <p>
              Denne FBW teknik kan nu anvendes i fastvinge modelfly takket
              v&aelig;re fremkomsten af micro mekaniske komponenter, s&aring;
              som 3 akslede gyroer og&nbsp;accelerometre, kombineret
              med&nbsp;micro&nbsp;computere indeholdende flight software.
              Fremkomsten af disse MEMS kredse (Mikro Elektro Mekaniske
              Systemer.) er blandt andet drevet af bilindustrien til brug for
              udl&oslash;sning af airbags, antiudskridnings bremser, automatisk
              nedbremsning m.m., hvor man &oslash;nsker at kompensere for
              f&oslash;rerens manglende kunnen.&nbsp;
            </p>
            <h2>Feed back princippet</h2>
            <p>
              For at forst&aring; FBW begrebet er det n&oslash;dvendigt at se
              p&aring;, hvordan&nbsp;feed&nbsp;back virker i et system. Se Fig.
              1.
            </p>
            <p>
              <img src={fig1} />
              <br />
              Fig. 1
            </p>
            <p>
              Til h&oslash;jre er den v&aelig;rdi der haves, til venstre er den
              v&aelig;rdi, som &oslash;nskes.
            </p>
            <p>
              Man bem&aelig;rker, at der f&oslash;res en del af den v&aelig;rdi,
              der haves, tilbage til et subtraktionspunkt, hvor man foretager en
              sammenligning med den &oslash;nskede v&aelig;rdi. Der kan nu
              forekomme 3 tilstande:
            </p>
            <ol>
              <li>
                Hvis den v&aelig;rdi der haves, er lig med den &oslash;nskede
                v&aelig;rdi, er systemet i balance.
              </li>
              <li>
                Hvis den v&aelig;rdi der haves, er mindre end den &oslash;nskede
                v&aelig;rdi, fremkommer der en forskel, som vil bringe systemet
                mod balance.
              </li>
              <li>
                Hvis den v&aelig;rdi der haves, er st&oslash;rre end den
                &oslash;nskede v&aelig;rdi, fremkommer der en forskel, som
                ligeledes vil bringe systemet mod balance, blot den modsatte
                vej.
              </li>
            </ol>
            <p>
              Dette princip anvender du f.eks. til styring af dine servoer, hvor
              en impulsbredde p&aring; 1,5millisekund svarer til
              neutralstillingen p&aring; senderens styrepind. Hvis du nu flytter
              pinden, &aelig;ndrer du nu pulsbredden til f.eks. 1,8 mS,
              og&nbsp;servoenk&oslash;rer indtil forskellen er nul.
            </p>
            <p>
              Som du kan se, bev&aelig;ger man sig fra en tilstand til en anden
              tilstand. Dette skal gerne ske s&aring; hurtigt som muligt. Da der
              er tale om fysiske ting, som er i bev&aelig;gelse,
              kan&nbsp;servoen&nbsp;finde p&aring; at k&oslash;re lidt for langt
              p&aring; grund af enertien, se den r&oslash;de kurve p&aring; fig.
              2. Hvis potentiometeret p&aring; fig. 1. drejes ned mod minimum,
              mindsker man&nbsp;feed-back&nbsp;funktionen, indtil en passende
              st&oslash;rrelse haves illustreret ved den sorte kurve. For lav
              feed-back er vist ved bl&aring; kurve, hvor systemet bliver for
              sl&oslash;vt.
            </p>
            <p>
              <img src={fig2} />
              <br />
              Fig. 2
            </p>
            <p>
              Feed-back har altid v&aelig;ret brugt indenfor elektronik og
              reguleringsteknik. Det nye er, at fysiske tings stillinger og
              bev&aelig;gelser kan registreres pr&aelig;cist med MEMS sensorer,
              som ikke er mere end 100 nanometer store. Du ser ogs&aring;
              teknikken anvendt i Pads, Smartphones, spillet Wii, ligesom den
              nye Jeti sender DS 16.
            </p>
            <h2>Eagle Tree Guardian 2D/3D Stabiliser</h2>
            <p>
              Vi skal nu se p&aring; Eagle Trees FBW system, som kan anvendes
              p&aring; alle fastvinge fly uafh&aelig;ngigt af radiofabrikat.
              V&aelig;gten er 11g. Forsyningssp&aelig;nding 3,5V til 16V.
              Str&oslash;mforbrug ca.31mA. Guardian 2D/3D forhandles af
              RC-Netbutik til omkring 500 kr. Der er foretaget en
              unders&oslash;gelse af temperatur stabiliteten og konstateret en
              drift p&aring; omkring 0,3 % ved en temperatur stigning fra 20C
              grader til 60C.
            </p>
            <p>
              Eagle&nbsp;Tree&nbsp;Guardian m&aring; siges at v&aelig;re
              temperaturstabil.
            </p>
            <p>
              <img src={fig3} />
            </p>
            <p>
              Med de tre potentiometre kan man &aelig;ndre stabiliseringen til
              at virke den&nbsp;rigtige vej. Der udf&oslash;res en vippetest med
              modellen i alle 3 planer. Du kan garanteret ikke styre modellen,
              hvis ops&aelig;tningen er forkert! Eneste indvendig mod produktet
              er, at&nbsp;Eagle&nbsp;Tree&nbsp;burde have bygget systemet ind i
              en kasse, som kunne monteres mere fast og sikkert.
            </p>
            <p>
              <img src={fig4} />
              <br />
              Fig. 3
            </p>
            <p>
              Som det ses p&aring; Fig.3, er der koblet en FBW box ind i mellem
              modtagerens udgange og servoerne.
            </p>
            <p>
              Servoerne modtager nu styring b&aring;de fra piloten og fra
              modellens orientering i luften. Modellens stilling og
              bev&aelig;gelser registreres af en 3 akset gyro og et
              accelerometer, som sender besked til computeren, der beregner de
              endelige stillinger p&aring; servoerne.
            </p>
            <p>
              Controlmode signalet kommer fra en 3 stillings kontakt p&aring;
              senderen.
            </p>
            <p>
              I midterstilling (1,5mS) er systemet koblet ud, servosignalerne
              g&aring;r ubehandlede igennem boxen, og du flyver normalt.
              Lysdioden er slukket.
            </p>
            <p>
              I 2D stillingen (1mS) kobles systemet ind og foretager en
              automatisk stabilisering omkring flyverens l&aelig;ngde- og
              tv&aelig;rakse. Du kan stadig styre normalt, men hvis du slipper
              pinden, vil flyveren rette sig op og ende med at flyve stabilt af
              sig selv, under foruds&aelig;tning af at systemet er kalibreret.
              Lysdioden blinker.
            </p>
            <p>
              I 3D stillingen (2mS) kobles systemet ind og foretager en
              automatisk stabilisering omkring flyverens l&aelig;ngde-,
              tv&aelig;r- og h&oslash;jeakse. Du kan stadig styre normalt, men
              hvis du slipper pinden, vil flyveren ikke rette sig op. 3D
              stillingen er beregnet til aerobatic og vil for eksempel kunne
              stabilisere en 3D flyver til at h&aelig;nge i propellen. Lysdioden
              er t&aelig;ndt.
            </p>
            <p>
              Ved hj&aelig;lp af en drejeknap p&aring; senderen kan man
              &aelig;ndre forst&aelig;rkningen i systemets alle 3 kanaler
              p&aring; en gang. Det er n&oslash;dvendigt at have den rigtige
              m&aelig;ngde negativ feedback i systemet, som beskrevet oven for.
              Hvis der er for lidt f. eks 1,2 mS, vil systemet kompensere for
              lidt. Hvis der er for meget (2,0 mS), vil systemet kompensere for
              meget og begynde at oscillere, hvorved belastningen p&aring;
              servoer og str&oslash;mforbrug stiger. Se fig. 4. som viser
              p&aring;virkningen af forsyningssp&aelig;ndingen.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig5} />
                  <br />
                  Fig. 4
                </p>
              </Grid>
              <Grid item xs={12} md={6} className={classes.box}>
                <p>
                  Forsyningssp&aelig;nding fra 2000mA Eneloop batteri, mens
                  servorer oscillerer.
                </p>
                <p>Minimum sp&aelig;nding = 3,5V!</p>
                <p>Din forsyningssp&aelig;nding skal v&aelig;re i orden.</p>
              </Grid>
            </Grid>
            <h2>Ops&aelig;tning og kalibrering</h2>
            <p>
              Fly by wire boxen monteres vandret i flyveretningen i
              n&aelig;rheden af modellens tyngdepunkt. Hvis boxen river sig
              l&oslash;s, kan modellen naturligvis styrte ned, fordi den ikke
              l&aelig;ngere kan m&aring;le p&aring; flyets bev&aelig;gelser.!
              S&aring; s&aelig;t den godt fast. Reset af boxen til 2D
              funktionen, og hermed indl&aelig;sning af de to &oslash;nskede
              reference v&aelig;rdier til vandret stabilisering foretages ved,
              at line modellen op til at v&aelig;re vandret ved hj&aelig;lp af
              et vaterpas. Al ops&aelig;tning af systemet kan foretages uden
              brug af en computer, men jeg har foretrukket at
              anvende&nbsp;Eagle&nbsp;Trees&nbsp;fremragende&nbsp;configurations&nbsp;software
              p&aring; en Windows 7 computer. Se billedet som viser fanebladet
              generelt. Det vil her f&oslash;re for vidt at gennemg&aring;
              indholdet&nbsp;af den&nbsp;13 sider store amerikanske
              brugsanvisning, som er meget detaljeret og af h&oslash;j kvalitet.
              Brugsanvisningen kan hentes p&aring; RC-Netbutiks&nbsp;hjemmeside.
              F&oslash;lgende skal n&aelig;vnes omkring ops&aelig;tningen:
            </p>
            <ul>
              <li>Individuelt gain&nbsp;og retning for hver akse.</li>
              <li>
                Heading Sub Mode som l&aring;ser flyets flyveretning, indtil
                balancepinden p&aring; senderen bev&aelig;ges.
              </li>
              <li>
                Automatisk&nbsp;Turn&nbsp;Coordination&nbsp;s&aring; du kan lave
                drej med &rdquo;kuglen i midten&rdquo;.
              </li>
              <li>Foretage ops&aelig;tninger til V-haler m. m.</li>
              <li>
                Regulere gain til optimal stabilisering centralt fra senderen.
              </li>
              <li>Forskellige 3D ops&aelig;tninger.</li>
              <li>Begr&aelig;nse max udslag p&aring; servoerne.</li>
              <li>&Oslash;ge puls frekvensen til digitale servoer.</li>
            </ul>
            <p>
              <img src={fig6} />
              <br />
              Der ses her den kunstige horisont, f&oslash;r der er trykket
              p&aring; reset.
            </p>
            <h2>Flyveerfaringer</h2>
            <p>
              Billederne viser de 2 testfly, som er gamle velpr&oslash;vede
              egenkonstruktioner, udstyret den en m&aelig;ngde Jeti telemetri
              med blackbox recorder, som blev brugt til analyser af FBW
              systemets ydeevne.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig7} />
                  <br />
                  Flexflyeren&nbsp;var det f&oslash;rste fors&oslash;gsfly.
                  <br />
                  Der blev b&aring;de testet med 2D og 3D.&nbsp;
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig8} />
                  <br />
                  Fanlineren blev kun testet med 2D.
                </p>
              </Grid>
            </Grid>

            <p>
              En absolut foruds&aelig;tning for at jeg k&oslash;bte den
              f&oslash;rste FBW box, var at systemet kunne kobles helt ud og ind
              under flyvningen, s&aring;ledes at der ikke var tvivl om, hvem det
              var der bestemte.
            </p>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig9} />
                  <br />
                  Den lange 3 stillingsomskifter (r&oslash;d&nbsp;dot)
                  bestemmer&nbsp;FBW mode. 2D, FWB OFF eller 3D. <br />
                  Drejereguleringen&nbsp;(bl&aring;&nbsp;dot) i siden af
                  senderen bestemmer&nbsp;Gain.
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>
                  <img src={fig10} />
                  <br />
                  FBW&nbsp;boxen&nbsp;skal monteres sikkert for
                  at&nbsp;undg&aring; nedstyrtninger.
                </p>
              </Grid>
            </Grid>

            <p>
              Flexflyerflyet&nbsp;blev taget op i godt 60 meter, f&oslash;r
              unders&oslash;gelserne blev p&aring;begyndt i 2D mode. Under
              flyvningen blev der &aelig;ndret p&aring; den centrale
              forst&aelig;rkning af alle 3 kanaler, hvor
              et&nbsp;gain&nbsp;svarende til 1,5&nbsp;msekund&nbsp;blev fundet
              passende. Efter at have fl&oslash;jet lidt rundt foretog jeg et
              loop, som modellen absolut ikke ville finde sig i, idet den
              p&aring; toppen af loppet lynhurtig vred sig rundt for at f&aring;
              hjulene nedad. Senere unders&oslash;gelser med de to test modeller
              viser, at FBW boxen i 2D mode retter modellerne op med et meget
              ringe h&oslash;jdetab, uanset hvilke umulige flyvestillinger de
              bliver bragt i. I 2D haves alts&aring; en &oslash;nsket
              referencev&aelig;rdi, som altid vi holde modellen vandret omkring
              l&aelig;ngde- og tv&aelig;raksen. Ud over dette skal modellen
              naturligvis kunne styres. Hvis man derfor giver modellen ordre om
              at stige 20 grader sender man denne &oslash;nskede v&aelig;rdi ind
              i FBW boxen, og modellen vil nu begynde at stige 20 grader, indtil
              der ikke l&aelig;ngere er forskel j&aelig;vnf&oslash;r feed-back
              princippet. P&aring; tilsvarende m&aring;de virker 3D funktionen
              p&aring; alle 3 akser, men uden den sikkerhed der ligger i at
              modellen selv kan rette op som i 2D. Dette betyder, at
              hvis&nbsp;Flexflyeren&nbsp;havde haft motor kraft nok, ville den
              kunne h&aelig;nges op i lodret stilling og v&aelig;re selvstabil.
              Som du kan se, er det et helt andet princip end blot at styre dine
              servoer direkte fra senderen. I 2D opn&aring;s f&oslash;lgende
              fordele:
            </p>
            <ul>
              <li>Modellen bliver ekstrem selvstabil.</li>
              <li>
                Hvis&nbsp;headinghold&nbsp;funktionen anvendes, holder flyet
                selv sin flyveretning, men driver naturligvis med vinden, hvis
                der er sidevind. Kan med stor fordel anvendes ved landinger,
                idet flyet selv holder retningen mod banen.
              </li>
              <li>
                Flyet er nu autonomt, hvilket betyder, at det kan flyve af sig
                selv. Det betyder, at man ikke beh&oslash;ver at v&aelig;re
                nerv&oslash;s, hvis man flyver langt v&aelig;k og ikke kan se
                modellens pr&aelig;cise stilling i luften. Fanlineren er logget
                til at flyve sikkert i en afstand, som er over 400m v&aelig;k.
              </li>
              <li>
                Flyet forbliver stabilt i selv voldsom turbulens. Man kan
                &oslash;ge forst&aelig;rkningen og konstatere,
                at&nbsp;servoerne&nbsp;arbejder flittigt. Selv den bedste pilot
                kan ikke udf&oslash;re denne stabiliseringsopgave lige s&aring;
                hurtigt. Den samlede virkning p&aring; modellen er, at en lille
                model f&oslash;les som en meget st&oslash;rre model med stor
                inerti. Modellen flyver stabilt som p&aring; skinner.&nbsp;
              </li>
              <li>
                Flyet &aelig;ndrer ikke h&oslash;jde, fordi du g&aring;r fra
                fuld gas til tomgang eller omvendt, fordi det regulerer sig til
                at flyve vandret. Det er klart, at flyet ikke kan blive ved med
                at flyve og holde sin h&oslash;jde ved for lave
                motoromdrejninger, men man kan med stor grad af sikkerhed holde
                flyet flyvende t&aelig;t p&aring; stall gr&aelig;nsen og dermed
                flyve meget langsomt.
              </li>
              <li>
                Den egenskab, at flyet stabiliserer sig vandret, g&oslash;r sig
                ogs&aring; g&aelig;ldende, hvis du anvender flaps eller
                luftbremser eller hvis din tr&aelig;klinje p&aring; motoren er
                forkert.
              </li>
              <li>
                Hvis du anvender fail-safe skal du s&oslash;rge for at
                modesignalet bliver 2D = 1mS, samt at stoppe motoren. Modellen
                kan s&aring; lande af sig selv, et eller andet sted!
              </li>
            </ul>
            <p>Man skal v&aelig;re opm&aelig;rksom p&aring; at:</p>
            <ul>
              <li>
                <strong>
                  <u>
                    Piloten skal altid vide om systemet er koblet ud, er i 2D
                    eller 3D mode
                  </u>
                </strong>
                . I 1998 styrtede Airbus ned med en A320 p&aring; grund af en
                forkert mode indstilling af FBW systemet. Den menneskelige
                faktor er stadig vigtig.&nbsp;
              </li>
              <li>
                Piloten skal v&aelig;nne sig til at fjerne fingrene fra
                styrepindene, hvis der opst&aring;r nedstyrtningsfare og selv
                lade systemet stabilisere modellen.
              </li>
              <li>
                Begyndertr&aelig;ning b&oslash;r foretages uden 2D FBW
                tilkoblet, men modellen kan lynhurtigt reddes fra nedstyrtning,
                hvis man kobler systemet til i kritiske situationer.
              </li>
              <li>
                Arbejdsbelastningen p&aring; pilotens hjerne falder dramatisk!
              </li>
              <li>
                Der er en tilb&oslash;jelighed til hurtigt at miste almindelig
                flyvef&aelig;rdighed, hvis du altid anvender 2D mode. Dette
                problem er ogs&aring; velkendt for Airbus piloter, som mister
                basal flyvef&aelig;rdighed p&aring; grund af anvendelsen af
                automatik.
              </li>
              <li>
                FBW systemet vil i 2D mode fors&oslash;ge at holde modellen
                stabil inden for flyets flight envelope. Systemet vil f.eks.
                kompensere for et forkert tyngdepunkt. Men reguleringen vil give
                op til sidst, hvis du bliver ved med at flytte tyngdepunktet
                l&aelig;ngere bagud. Flyet b&oslash;r grundl&aelig;ggende
                v&aelig;re trimmet korrekt.
              </li>
              <li>
                Hvis systemet er koblet ind, opf&oslash;rer radioanl&aelig;gget
                sig som om, det er p&aring; sammenbruddets rand nede p&aring;
                jorden. Enhver bev&aelig;gelse medf&oslash;rer nerv&oslash;se
                ror. Efter en landing vil man f.eks. dreje v&aelig;k fra
                landingsbanen. Flyet ved trods alt ikke, at det er landet,
                derfor vil&nbsp;headinghold&nbsp;funktionen bev&aelig;ge
                balanceklapperne helt ud! S&aring; ved taxik&oslash;rsel under
                selve starten og efter landing, kobler jeg systemet ud.
              </li>
              <li>
                B&oslash;r nok ikke anvendes ved konkurrencer og til
                opn&aring;else af A-certifikater.
              </li>
            </ul>
            <p>
              Man kan naturligvis mene, at FBW er en form for snyd, men det kan
              redde mange modeller, og fungerer fremragende som anden
              h&oslash;jteknologi. FBW teknik anvendes ogs&aring; i dag
              integreret i nogle Spektrum modtagere. Jeg tror, at FBW teknik vil
              blive meget udbredt i fremtiden og dermed radikalt &aelig;ndre den
              m&aring;de, der flyves modelflyvning p&aring;.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
