import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  withStyles,
  Link,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table
} from "@material-ui/core";
import styles from "../ArtikelStyles";
import ArtikelFabs from "../ArtikelFabs";
import fig1 from "../../../images/Jeti/JetiDuplexTrueAirspeed/fig1.jpg";
import fig2 from "../../../images/Jeti/JetiDuplexTrueAirspeed/fig2.jpg";
import fig3 from "../../../images/Jeti/JetiDuplexTrueAirspeed/fig3.jpg";
import fig4 from "../../../images/Jeti/JetiDuplexTrueAirspeed/fig4.jpg";
import fig5 from "../../../images/Jeti/JetiDuplexTrueAirspeed/fig5.jpg";
import fig6 from "../../../images/Jeti/JetiDuplexTrueAirspeed/fig6.jpg";
interface IState {}

interface IProps {
  classes: any;
  rootRef: any;
  canonical: string;
}

class Article extends Component<IProps, IState> {
  render() {
    const { classes, canonical } = this.props;
    return (
      <>
        <Helmet>
          <title>Jeti Duplex modul til måling af true airspeed</title>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <ArtikelFabs rootRef={this.props.rootRef} />
        <div className={classes.root}>
          <div className={classes.article}>
            <h1>Jeti Duplex modul til m&aring;ling af true airspeed</h1>
            <p>Af J&oslash;rgen Bj&oslash;rn</p>
            <p>Publiceret januar 2010.</p>
            <p>
              Denne artikel er forel&oslash;big den sidste, som omhandler det
              innovative 2,4GHz radioanl&aelig;g med telemetri fra det tjekkiske
              firma Jeti. Denne gang skal vi se p&aring; et m&aring;lemodul, som
              kan m&aring;le true airspeed (TAS). Du kan med fordel l&aelig;se
              om hele Jeti systemet i{" "}
              <Link
                href="http://modelflyvenyt.dk/modelflyvenyt/2010/modelflyvenyt%201-2010.pdf"
                target="_blank"
              >
                Modelflyvenyt nr. 1-2010
              </Link>
              .
            </p>
            <h2>True airspeed contra ground speed</h2>
            <p>
              Jeg vil f&oslash;rst beskrive, hvad der forst&aring;s ved true
              airspeed (TAS). N&aelig;st efter flyveh&oslash;jden er flyvefarten
              p&aring; et fly det vigtigste flydata. Indenfor flyvning er der
              imidlertid to begreber for flyvefart, nemlig den fart flyet har i
              forhold til jorden, Ground Speed (GS), og den fart flyet har i
              forhold til den omgivende luft (TAS). Da du desv&aelig;rre ikke
              kan se lufthastigheden omkring flyet, er det ground speed
              RC-piloten vurderer flyvefarten p&aring;. Hvis stall hastigheden
              p&aring; et fly er 8m/sek, vil du se, at der i nedenst&aring;ende
              tabel er en farlig situation. Flyvning i medvind, og is&aelig;r
              medvindssving, har mange &oslash;delagte modeller p&aring;
              samvittigheden. Jeg har faktisk oplevet piloter, som i fuldt alvor
              p&aring;stod, at radioanl&aelig;gget svigtede p&aring; grund af
              flyvning i kraftig medvind. Problemstillingen g&aelig;lder
              ligeledes full-size fly, specielt sv&aelig;vefly og ultraletfly.
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Konstant ground speed</TableCell>
                  <TableCell>Vind</TableCell>
                  <TableCell>Resulterende true airspeed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Modvind 4m/sek</TableCell>
                  <TableCell>14m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Medvind 4m/sek</TableCell>
                  <TableCell>
                    6m/sek (Flyet staller og falder m&aring;ske ned!)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Modvind 12m/sek</TableCell>
                  <TableCell>
                    22m/sek (T&oslash;mmer batteriet hurtigere p&aring; elfly!)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Ingen vind 0m/sek</TableCell>
                  <TableCell>10m/s GS=TAS</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Situationen med den kraftige modvind, hvor et elfly opbruger
              kapaciteten hurtigere end normalt kan kontrolleres med Jetis MUI
              modul, som tilbagemelder de forbrugte mA/h under flyvningen
              p&aring; fremragende vis.
            </p>
            <p>
              Hvis man nu t&aelig;nker sig at man kunne holde true airspeed
              konstant, s&aring; bem&aelig;rk hvor forskellig flyvefarten er i
              forhold til jorden, alts&aring; det du ser med dine &oslash;jne.{" "}
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Konstant true airspeed</TableCell>
                  <TableCell>Vind</TableCell>
                  <TableCell>Ground speed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Modvind 4m/sek</TableCell>
                  <TableCell>6m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Medvind 4m/sek</TableCell>
                  <TableCell>14m/sek</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Modvind 12m/sek</TableCell>
                  <TableCell>
                    -2m/sek (Flyet flyver fint bagl&aelig;ns)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10m/sek</TableCell>
                  <TableCell>Ingen vind 0m/sek</TableCell>
                  <TableCell>10m/s TAS=GS</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>
              Det kunne alts&aring; v&aelig;re interessant at kunne m&aring;le
              og fastholde true airspeed og f&aring; alarmer (f. eks. stall
              warning), hvis der flyves for langsomt. Det er det Jeti MSPEED
              modulet kan.
            </p>
            <h2>Virkem&aring;de</h2>
            <p>
              Jeti MSPEED modulet er bygget op p&aring; n&oslash;jagtig de samme
              principper, som anvendes i full-size fly. Et pitotr&oslash;r peger
              fremad enten p&aring; forkanten af vingen eller p&aring; flyets
              n&aelig;se. Trykket af luftmodstanden forrest p&aring;
              pitotr&oslash;ret kaldes det dynamiske tryk. Det statiske tryk er
              luftens tryk omkring flyet, hvis flyet ellers ikke var der og
              forstyrrede. Se fig. 1. Trykforskellen mellem det dynamiske- og
              det statiske tryk benyttes til at vise den sande flyvefart (TAS).
              De to tryk f&oslash;res via luftslanger til en tryktransducer, som
              m&aring;ler forskellen, og hvor elektronik digitaliserer signalet,
              s&aring; det kan overf&oslash;res i sand tid via dataforbindelsen
              til piloten p&aring; jorden. Hvis hastigheden i forhold til jorden
              (GS) &oslash;nskes m&aring;lt, g&oslash;res dette med Jetis GPS
              modtager, som blev beskrevet i sidste nummer af Modelflyvenyt.
            </p>
            <p>
              <img src={fig1} />
              P&aring; figuren herover ses et skematisk snit af
              pitotr&oslash;ret.
            </p>
            <p>
              De to tryk f&oslash;res frem til m&aring;lemodulet via slanger.
              Summen af statisk og dynamisk tryk er konstant i en given
              luftmasse.
            </p>
            <p>
              <img src={fig2} />
              Pitotr&oslash;ret monteret p&aring; n&aelig;sen af fanlineren.
            </p>
            <p>
              Ideelt burde det sidde helt ude p&aring; spidsen af n&aelig;sen,
              men bliver s&aring; for mekanisk s&aring;rbart. M&aring;ske kan du
              se de sm&aring; huller p&aring; siden af r&oslash;ret som
              m&aring;ler det statiske tryk.
            </p>
            <p>
              <img src={fig3} />2 stk 1m lange silikone slanger forbinder
              pitotr&oslash;ret med elektronikken Udgangskablet (sort stik)
              tilsluttes Jeti Duplex modtagerens dataindgang.
            </p>
            <h2>Tekniske data</h2>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>V&aelig;gt</TableCell>
                  <TableCell>21g</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>N&oslash;jagtighed</TableCell>
                  <TableCell>5km/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>M&aring;leomr&aring;de</TableCell>
                  <TableCell>20km/t til 350km/t</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Temperatur omr&aring;de</TableCell>
                  <TableCell>0 til 85&nbsp;C grader</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Forsyningssp&aelig;nding</TableCell>
                  <TableCell>3,5 til 8,4&nbsp;V</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Str&oslash;mforbrug</TableCell>
                  <TableCell>10mA</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <h2>Installation</h2>
            <p>
              Pitotr&oslash;ret monteres i fronten af kroppen eller p&aring;
              vingen. Det er vigtigt at undg&aring; propellens slipstr&oslash;m,
              s&aring; p&aring; propelfly med kun en motor, er det
              n&oslash;dvendigt at montere pitotr&oslash;ret p&aring; den
              yderste del af vingen. Det m&aring; siges, at dette ikke vil
              v&aelig;re specielt nemt p&aring; et f&aelig;rdigt fly, idet
              trykslangerne skal f&oslash;res gennem vingen. En installation
              p&aring; sv&aelig;vefly, tomotorede fly, ducted fan fly, eller
              turbine drevne fly, frembyder ingen problemer, fordi
              installationen kan foretages p&aring; kroppen. Elektronik delen
              b&oslash;r holdes v&aelig;k fra kraftige varmekilder.
            </p>
            <p>
              <img src={fig4} />
              N&aelig;sen er afmonteret for montage af pitotr&oslash;r. En nem
              operation p&aring; et to-motoret fly.&nbsp;
            </p>
            <h2>M&aring;lemodulets funktioner</h2>
            <p>
              Jeti MSPEED modulet kan kun m&aring;le true air speed og
              temperatur. M&aring;lingen af true air speed indikeres p&aring; 4
              forskellige m&aring;der to visuelle og to typer lydalarmer.
              P&aring; jetiboksens display vises hastigheden direkte m&aring;lt
              i kilometer pr. time, meter pr. sekund eller miles pr. time. Der
              udover er der indikation best&aring;ende af 8 segmenter, som
              skifter med stigende fart, en slags speedometer. Se billede af
              Jetiboksen. Skiftet af disse segmenter kan programmeres frit efter
              behov. De to lydalarmsystemer er dels en high/low speed alarm,
              dels et lydsystem, som skal sikre, at man kan flyve med en
              konstant true airspeed. Se fig. 2. Ideen er, at hvis man flyver
              for langsomt, f&aring;r man tiltagende lange bip-signaler ud af
              senderen, hvis man flyver med den &oslash;nskede fart, er der
              ingen lyd, og hvis man flyver for hurtigt, f&aring;r man
              tiltagende korte bip-signaler. Man kan f.eks. v&aelig;lge at
              sl&aring; de korte bip-signaler fra, s&aring;ledes at man kun
              f&aring;r lydsignaler for langsom flyvefart. Man kan ogs&aring;
              v&aelig;lge at slukke for systemet ved hj&aelig;lp af en kontakt
              p&aring; senderen, hvis man tilslutter det r&oslash;de stik til
              den kanal p&aring; modtageren, som styres fra kontakten. Videre
              skal n&aelig;vnes, at minimum og maksimum opn&aring;et fart kan
              udl&aelig;ses efter landingen. Det samme g&aelig;lder
              temperaturen.
            </p>
            <p>
              <img src={fig5} />
            </p>
            <h2>Praktiske erfaringer</h2>
            <p>
              Inden du starter, m&aring; du v&aelig;lge m&aring;leenheden. Jeg
              har valgt m. pr. sek. (10m/sek = 36km/t), fordi jeg under min
              konstruktion af modellerne har brugt simulationsprogrammet
              MotoCalc og derfor nu kan m&aring;le, hvordan den beregnede
              minimum og maksimum fart stemmer overens med de m&aring;lte data i
              m/sek. N&aring;r Jeti MSPEED modulet skal programmeres, vil jeg
              foresl&aring;, at du anvender en hj&aelig;lper, som kan
              afl&aelig;se din flyvefart i m pr. sek. eller km pr. time, mens du
              gennemf&oslash;rer f&oslash;lgende flyveprogram. Du skal
              unders&oslash;ge, hvor langsomt flyet kan flyve (stall
              gr&aelig;nse), derefter en normal fornuftig flyvefart ligeud i
              konstant h&oslash;jde, samt hvad max. fart er uden at dykke. Du
              kan nu efter landingen programmere din set point v&aelig;rdi og
              afstanden mellem bip-signalerne, s&aring;ledes at systemet bliver
              kalibreret fornuftigt i forhold til din model. Selv om jeg mener,
              jeg er en erfaren pilot, blev jeg alligevel nogle gange overrasket
              over meldingen om for lav fart ved indgangen til flyvning i
              medvind. Grunden m&aring; v&aelig;re, at der g&aring;r lidt tid
              f&oslash;r flyets TAS fart tilpasser sig medvinden.
            </p>
            <h2>Afslutning</h2>
            <p>
              Jeti Duplex 2,4GHz anl&aelig;gget med de mange m&aring;lemoduler
              s&aelig;tter nye gr&aelig;nser for, hvad et RC-anl&aelig;g er og
              kan. Det nedenst&aring;ende skema giver dig et samlet overblik. Du
              skal v&aelig;re opm&aelig;rksom p&aring;, at de enkelte
              m&aring;lemoduler ikke beh&oslash;ver at anvende senderen og
              modtageren, men kan anvendes sammen med jetiboksen alene. Derved
              f&aring;r du et komplet m&aring;lesystem, som kan l&oslash;se alle
              t&aelig;nkelige opgaver i forbindelse med modelflyvning. Ydermere
              kan Jetiboksen ogs&aring; anvendes som servotester og til
              programmering af Jeti regulatorer. Anvendelsen af den l&oslash;se
              Jetibox ser m&aring;ske ikke helt s&aring; smart ud sammenlignet
              med andre fabrikater, men det giver p&aring; genialvis mange flere
              muligheder, som jeg n&oslash;dig vil undv&aelig;re.
            </p>
            <p>
              <img src={fig6} />
              Jetiboksen er her tilsluttet MSPEED modulet. M&aring;ler
              luftstr&oslash;mshastighed p&aring; en fan. En helt ny udgave af
              boksen er p&aring; vej.&nbsp;
            </p>
            <p>
              Jeti Duplex &ndash; oversigt over data som kan m&aring;les og
              opsamles samt afgivne alarmer.&nbsp;
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Enhed</TableCell>
                  <TableCell>Variant</TableCell>
                  <TableCell>
                    M&aring;ler l&oslash;bende p&aring; display i sandtid.
                  </TableCell>
                  <TableCell>
                    Opsamler og gemmer data til visning efter landing
                  </TableCell>
                  <TableCell>
                    Alarmer p&aring; display og som morsekoder
                  </TableCell>
                  <TableCell>Tilkobling</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Sender-modul (Tx)</TableCell>
                  <TableCell>7 typer</TableCell>
                  <TableCell>
                    Tx sp&aelig;nding
                    <br />
                    Rx signal styrke p&aring; begge modtager antenner
                  </TableCell>
                  <TableCell>
                    Tx min og max
                    <br />
                    Sp&aelig;nding
                  </TableCell>
                  <TableCell>
                    Tx min sp&aelig;nding
                    <br />
                    Svigtende r&aelig;kkevidde
                  </TableCell>
                  <TableCell>
                    Sendermodul
                    <br />
                    Tilkobles Jetibox
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Modtager (Rx)</TableCell>
                  <TableCell>21 typer</TableCell>
                  <TableCell>Rx sp&aelig;nding</TableCell>
                  <TableCell>Rx min og max sp&aelig;ndin</TableCell>
                  <TableCell>Rx min sp&aelig;nding</TableCell>
                  <TableCell>Binding til Tx</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Str&oslash;mforsyning
                    <br />
                    MAX BEC 2D
                  </TableCell>
                  <TableCell>2 typer *1)</TableCell>
                  <TableCell>
                    Batterisp&aelig;nding A<br />
                    Batterisp&aelig;nding B<br />
                    Temperatur p&aring; power supply
                    <br />
                    Udgangssp&aelig;nding
                  </TableCell>
                  <TableCell>
                    Min og max sp&aelig;nding p&aring; batteri A og B<br />
                    Min og max temperatur
                  </TableCell>
                  <TableCell>
                    Min sp&aelig;nding p&aring; batteri A og B<br />
                    Max temperatur
                  </TableCell>
                  <TableCell>De to batterier og modtageren</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    ”Br&aelig;ndstof-m&aring;ler” til elfly
                    <br />
                    MUI
                  </TableCell>
                  <TableCell>
                    30&nbsp;A
                    <br />
                    50&nbsp;A
                    <br />
                    75&nbsp;A
                    <br />
                    150&nbsp;A
                    <br />
                    200&nbsp;A
                  </TableCell>
                  <TableCell>
                    Sp&aelig;nding
                    <br />
                    Str&oslash;m
                    <br />
                    mA/h forbrug
                  </TableCell>
                  <TableCell>
                    Min og max sp&aelig;nding
                    <br />
                    Middel og max str&oslash;m
                  </TableCell>
                  <TableCell>
                    Min sp&aelig;nding
                    <br />
                    Max str&oslash;m
                    <br />
                    Forbrugt kapacitet (Br&aelig;ndstof- m&aring;ler)
                  </TableCell>
                  <TableCell>
                    Str&oslash;mm&aring;ler monteres i serie og
                    sp&aelig;ndings-m&aring;ler i parallel med batteri
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Omdrejnings-m&aring;ler
                    <br />
                    MRPM
                  </TableCell>
                  <TableCell>
                    B&oslash;rstel&oslash;s
                    <br />
                    Optisk
                  </TableCell>
                  <TableCell>
                    Omdrejninger
                    <br />
                    Afsat mekaniske effekt i propellen og virkningsgrad
                  </TableCell>
                  <TableCell>
                    Min og max omdrejninger
                    <br />
                    Min og max mekanisk effekt
                  </TableCell>
                  <TableCell>
                    Max omdrejninger
                    <br />
                    Min omdrejninger
                  </TableCell>
                  <TableCell>
                    Tilkobles 2 faser p&aring; b&oslash;rstel&oslash;s motor
                    eller lysreflektion
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    H&oslash;jdem&aring;ler og variometer
                    <br />
                    MVARIA
                  </TableCell>
                  <TableCell>Ingen</TableCell>
                  <TableCell>
                    Relativ og absolut h&oslash;jde
                    <br />
                    Stig eller synk
                    <br />
                    Absolut lufttryk
                    <br />
                    Lufttemperatur
                  </TableCell>
                  <TableCell>
                    Max stig og synk
                    <br />
                    Max flyveh&oslash;je
                    <br />
                    Min og max
                    <br />
                    temperatur
                  </TableCell>
                  <TableCell>
                    Max stig
                    <br />
                    Max synk
                    <br />
                    Max flyveh&oslash;jde
                  </TableCell>
                  <TableCell>
                    Ingen (m&aring;ler p&aring; statisk lufttryk)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Termometer
                    <br />
                    MTx
                  </TableCell>
                  <TableCell>
                    125
                    <br />
                    300
                  </TableCell>
                  <TableCell>To Temperaturer A og B</TableCell>
                  <TableCell>Min og max temperatur A og B</TableCell>
                  <TableCell>Min og max temperatur A og B</TableCell>
                  <TableCell>
                    Sensor tilkobles fysisk m&aring;leobjekt
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>GPS modtager og datalogger</TableCell>
                  <TableCell>Ingen</TableCell>
                  <TableCell>
                    Flyveh&oslash;jde og fart (GS)
                    <br />
                    Afstand og kompaskurs fra sender
                    <br />
                    Fl&oslash;jet distance
                  </TableCell>
                  <TableCell>
                    Min og max h&oslash;jde
                    <br />
                    Max afstand fra sender
                    <br />
                    Max hastighed (GS)
                    <br />
                    Fl&oslash;jet distance
                    <br />
                    Download til PC af optagne GPS data via USB port
                    <br />
                    Kan afl&aelig;ses via Google map/earth m.m.
                  </TableCell>
                  <TableCell>
                    Max H&oslash;jde
                    <br />
                    Max Afstand
                    <br />
                    Max hastighed(GS)
                  </TableCell>
                  <TableCell>Satellitter</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    MSPEED
                    <br />
                    Sand fartm&aring;ler
                  </TableCell>
                  <TableCell>Ingen</TableCell>
                  <TableCell>Sand flyvefart (TAS)</TableCell>
                  <TableCell>
                    Max og min TAS
                    <br />
                    Max og min temperatur
                  </TableCell>
                  <TableCell>
                    For lav (stall varning) og/eller for h&oslash;j TAS
                  </TableCell>
                  <TableCell>Pitotr&oslash;r</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p>*1) Der findes ogs&aring; en MAX BEC uden data transmission.</p>
            <p>
              Jeti vil givetvis forts&aelig;tte sin innovative kurs. Man kunne
              f.eks. forestille sig, at de ville udvikle en ny el-regulator, som
              kunne kobles sammen med MSPEED modulet, s&aring;ledes at True Air
              Speed kunne holdes konstant, alts&aring; en autopilot funktion. En
              forbedret kommunikation til piloten i form af klar tale kunne
              v&aelig;re et andet forslag. Hvis du &oslash;nsker at
              unders&oslash;ge Jeti-systemet n&aelig;rmere, kan
              brugervejledninger m. m. hentes p&aring; Electric Flight
              Equipments webside:&nbsp;
              <Link href="http://www.el-fly.dk/" target="_blank">
                www.el-fly.dk
              </Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Article);
